import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button, IconButtonProps as MuiIconButtonProps } from '@mui/material';

const mappings: any = {
  remove: RemoveIcon,
  plus: AddIcon,
  'arrow-up': ArrowUpwardIcon,
  'arrow-down': ArrowDownwardIcon,
};

type IconButtonProps = MuiIconButtonProps & {
  icon: string;
  iconProps?: object;
};

const IconButton = (props: IconButtonProps) => {
  const { icon, className, iconProps, ...otherProps } = props;
  const IconComp = mappings[icon];
  return (
    <Button {...otherProps} size="small">
      <IconComp {...iconProps} />
    </Button>
  );
};

export default IconButton;
