import React from 'react';
import { inject, observer } from 'mobx-react';
import Highlighter from 'react-highlight-words';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  faPen,
  faTrash,
  faUpload,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { IMetadataService } from '@extensions/services/IMetadataService';

import AddIcon from '@extensions/utils/AddIcon';
import Link from '@extensions/components/core/Link';
import MetaDocument from '@extensions/models/MetaDocument';
import ConfirmIconButton from '@extensions/components/core/ConfirmIconButton';

const StyledDatasetCol = styled('col')(({
  width: '32.5%'
}));

const StyledCol = styled('col')(({
  width: '22.5%'
}));

const StyledAddIcon = styled(AddIcon)(({
  marginLeft: '.5rem',
  color: 'red'
}));

const StyledModifiedCol = styled('col', {
  shouldForwardProp: (props) => props !== 'isPublishedSearchType'
})<{ isPublishedSearchType: boolean }>(({ isPublishedSearchType }) => {
  if (!isPublishedSearchType) {
    return {
      width: '22.5%',
    }
  } else {
    return {
      width: '0%'
    }
  }
});

export interface IDocumentsTableProps {
  className?: string;
  historyService?: IHistoryService;
  metadataService?: IMetadataService;
  documents: MetaDocument[];
  searchQuery: string;
  isPublishedSearchType: boolean;
}

export interface IDocumentsTableState { }

@observer
export class DocumentsTable extends React.Component<
  IDocumentsTableProps,
  IDocumentsTableState
> {
  formatCellValue = ({
    value,
    highlight,
  }: {
    value?: string;
    highlight?: boolean;
  }): React.ReactNode => {
    if (!value) {
      return <>&mdash;</>;
    }
    if (highlight) {
      return (
        <Highlighter
          searchWords={[this.props.searchQuery]}
          autoEscape={true}
          textToHighlight={value}
        />
      );
    }
    return value;
  };

  render() {
    const {
      documents,
      historyService,
      metadataService,
      isPublishedSearchType,
    } = this.props;
    if (!historyService) {
      return null;
    }
    return (
      <TableContainer
        component={Paper}
      >
        <Table>
          <colgroup>
            <StyledDatasetCol />
            <StyledCol />
            <StyledCol />
            <StyledModifiedCol isPublishedSearchType={isPublishedSearchType} />
          </colgroup>
          <TableHead sx={{
            background: theme.palette.grey['300']
          }}>
            <TableRow>
              <TableCell>Dataset</TableCell>
              <TableCell>Status</TableCell>
              {!isPublishedSearchType ? <TableCell>Creator</TableCell> : null}
              <TableCell>Last Modified</TableCell>
              <TableCell aria-label="upload button column" />
              <TableCell aria-label="edit button column" />
              <TableCell aria-label="delete button column" />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              documents
                .sort((a, b) => b.lastModified.valueOf() - a.lastModified.valueOf())
                .map((doc) => (
                  <TableRow key={doc.id} sx={{ cursor: 'pointer' }}>
                    <TableCell>
                      {isPublishedSearchType ? (
                        <Link to={`/ds/${doc.datasetName}`}>
                          {this.formatCellValue({
                            value: doc.datasetName,
                            highlight: true,
                          })}
                        </Link>
                      ) : (
                        this.formatCellValue({
                          value: doc.datasetName,
                          highlight: true,
                        })
                      )}
                      {doc.accessRequiresMfa && (
                        <StyledAddIcon
                          icon={faLock}
                          aria-label="Proprietary"
                          size="sm"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {doc.isSubmitted
                        ? 'Pending'
                        : isPublishedSearchType
                          ? 'Published'
                          : 'Draft'}
                    </TableCell>
                    {
                      !isPublishedSearchType
                        ? <TableCell>
                          {this.formatCellValue({
                            value: `${doc.author.firstName} ${doc.author.lastName}`,
                            highlight: true,
                          })}
                        </TableCell>
                        : null
                    }
                    <TableCell>{doc.lastModified.format('MM/DD/YYYY')}</TableCell>
                    <Tooltip
                      title={
                        doc.isSubmitted || doc.isPublished
                          ? 'Upload Files'
                          : 'Files cannot be uploaded to draft metadata submissions'
                      }
                    >
                      <TableCell>
                        <IconButton
                          disabled={!doc.isSubmitted && !doc.isPublished}
                          onClick={() =>
                            historyService.history.push(
                              `ds/${doc.datasetName}/upload`
                            )
                          }
                          sx={{
                            padding: theme.spacing(1.5),
                          }}
                        >
                          <AddIcon
                            icon={faUpload}
                            aria-label="upload"
                            size="sm"
                          />
                        </IconButton>
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={
                        doc.can.write
                          ? 'Edit metadata'
                          : 'You do not have permissions to edit this submission'
                      }
                    >
                      <TableCell>
                        <IconButton
                          disabled={!doc.can.write}
                          onClick={() => {
                            metadataService?.setSelectedDocAndAction(
                              doc,
                              false,
                              true
                            );
                            historyService.history.push(`/metadata/edit/${doc.id}`);
                          }}
                          sx={{
                            padding: theme.spacing(1.5),
                          }}
                        >
                          <AddIcon
                            icon={faPen}
                            aria-label="edit"
                            size="sm"
                          />
                        </IconButton>
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={
                        doc.can.delete
                          ? 'Delete metadata submission'
                          : doc.isPublished
                            ? 'Published metadata cannot be deleted'
                            : 'You do not have permissions to delete this submission'
                      }
                    >
                      <TableCell>
                        <ConfirmIconButton
                          disabled={!doc.can.delete}
                          confirmOptions={{
                            confirmationText: 'Yes, delete',
                            cancellationText: 'No',
                            title: `Delete submission for ${doc.datasetName}?`,
                          }}
                          beforeConfirm={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                          onClick={() => metadataService?.deleteDocument(doc)}
                          sx={{
                            padding: theme.spacing(1.5),
                          }}
                        >
                          <AddIcon
                            icon={faTrash}
                            aria-label="delete document"
                            size="sm"
                          />
                        </ConfirmIconButton>
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
  historyService: store.historyService,
  metadataService: store.metadataService,
}))(DocumentsTable);
