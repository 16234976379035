import React from 'react';
import { inject, observer } from 'mobx-react';
import { reaction, observable, action, runInAction, makeObservable } from 'mobx';

import { styled } from '@mui/material/styles';

import { INotificationService } from '@extensions/services/INotificationService';

import Dataset from '@extensions/models/Dataset';
import { fetchSchema } from '@extensions/utils/metadata';
import MetaDocument from '@extensions/models/MetaDocument';
import { getFileUploadUrls } from '@extensions/utils/metadata';
import MetadataSchema from '@extensions/models/MetadataSchema';
import SummaryPanel from '@extensions/components/metadata/review/SummaryPanel';

const StyledRootDiv = styled('div')(({}));

export interface ISplitViewProps {
  className?: string;
  document: MetaDocument;
  schema?: MetadataSchema | null;
  notificationService?: INotificationService;
}

export interface ISplitViewState { }

@observer
export class SplitView extends React.Component<
  ISplitViewProps,
  ISplitViewState
> {
  @observable
  schema: MetadataSchema | null = null;

  constructor(props: ISplitViewProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    reaction(
      (): [string | null, MetadataSchema | null] => {
        const { document, schema } = this.props;
        return [
          document.metadata?.identifierSchema?.type || null,
          schema || null,
        ];
      },
      this.initializeSchema,
      { fireImmediately: true }
    );
  }

  @action
  initializeSchema = (args: [string | null, MetadataSchema | null]) => {
    const { document, notificationService } = this.props;
    const type = args[0];
    const schema = args[1];
    if (schema) {
      this.schema = schema;
    } else if (type && notificationService) {
      const loadSchema = async () => {
        const schemaResp = await fetchSchema({
          projectName: Dataset.extractProjectName(document.datasetName),
          type,
        });
        const schema = new MetadataSchema(schemaResp);
        runInAction(() => (this.schema = schema));
      };
      notificationService.showStateInUI({
        pending: loadSchema(),
        notificationId: 'Submitted-schema',
        errorMessage: 'Failed to load submission',
        errorDescription: 'Please email us if this problem persists.',
      });
    }
  };

  render() {
    const { document } = this.props;

    if (!this.schema) {
      return null;
    }

    let adjustedUiSchema = this.schema?.uiSchema;
    if (document.metadata !== null) {
      adjustedUiSchema = {
        ...this.schema?.uiSchema,
        fileExtensions: {
          'ui:field': 'invisible'
        }
      }
    }

    const summaryPanelProps = {
      dataSchema: this.schema?.dataSchema,
      uiSchema: !document.can.publish ? adjustedUiSchema : this.schema?.uiSchema,
      changes: undefined,
    };

    return (
      <StyledRootDiv>
        <SummaryPanel
          {...summaryPanelProps}
          data={document.metadata}
          canPublish={document.can.publish}
          fileUploadUrls={getFileUploadUrls({ document })}
          kindsToHighlight={[]}
        />
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  notificationService: store.notificationService,
}))(SplitView);
