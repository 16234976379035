import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import {
  faAddressCard,
  faUser,
  faCaretDown,
  faUsers,
  faShoppingBasket,
  faEdit,
  faCloudUploadAlt,
  faTasks,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import theme from '@extensions/services/Theme';
import { Button, Menu, MenuItem } from '@mui/material';

import AddIcon from '@extensions/utils/AddIcon';
import UserTool from '@extensions/models/UserTool';
import Link from '@dapclient/components/core/Link';

export interface IUserToolMenuProps {
  tools: UserTool[] | null;
}

const ICON_MAP = {
  vcard: faAddressCard,
  users: faUsers,
  'shopping-basket': faShoppingBasket,
  edit: faEdit,
  'cloud-upload': faCloudUploadAlt,
  tasks: faTasks,
};

@observer
class UserToolMenu extends React.Component<IUserToolMenuProps> {
  @observable
  anchorEl: HTMLElement | null = null;

  @action
  handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.anchorEl = event.currentTarget;
  };

  @action
  handleClose = () => (this.anchorEl = null);

  constructor(props: IUserToolMenuProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { tools } = this.props;
    const menuId = 'user-tools-menu';
    if (!tools) {
      return null;
    }
    return (
      <div>
        <Button
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={this.handleOpen}
          aria-label="user tools"
          sx={{
            borderRadius: 0,
            padding: '0.6rem 1.3rem',
            fontSize: '.9rem',
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              textDecoration: 'none',
            },
          }}
        >
          <AddIcon
            icon={faUser}
            style={{
              marginRight: '0.25rem',
            }}
          />
          <AddIcon icon={faCaretDown} />
        </Button>
        <Menu
          id={menuId}
          anchorEl={this.anchorEl}
          keepMounted
          open={Boolean(this.anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            '& ul': {
              backgroundColor: theme.palette.secondary.light,
            },
          }}
        >
          {tools.map((tool) => (
            <Link
              to={tool.url}
              key={tool.url}
              target={tool.external ? '_blank' : undefined}
              ignoreTarget={true}
            >
              <MenuItem
                key={tool.url}
                onClick={this.handleClose}
                sx={{
                  backgroundColor: 'inherit',
                  color: theme.palette.common.white,
                  textTransform: 'uppercase',
                  fontWeight: theme.typography.fontWeightRegular,
                  fontSize: theme.typography.pxToRem(14),
                  '&:hover': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.secondary.main,
                  },
                  '&.Mui-focusVisible': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                <AddIcon
                  icon={ICON_MAP[tool.fontAwesomeIconName]}
                  style={{
                    marginRight: '0.5rem',
                  }}
                />
                {tool.label}
                {tool.external && (
                  <AddIcon
                    icon={faExternalLinkAlt}
                    size="xs"
                    style={{
                      marginLeft: '0.5rem',
                    }}
                  />
                )}
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </div>
    );
  }
}

export default UserToolMenu;
