import { createContext } from 'react';
import { Diff } from 'deep-diff';

export type HighlightOption = Omit<Diff<Record<string, any>>['kind'], 'A'>;

export interface SummaryClasses {
  label: string;
  editedValue: string;
  addedValue: string;
  deletedValue: string;
}
export interface SummaryContextValue {
  changes: Diff<Record<string, any>>[] | undefined;
  kindsToHighlight: HighlightOption[];
  // classes: SummaryClasses;
}

const SummaryContext = createContext<SummaryContextValue>({
  changes: undefined,
  kindsToHighlight: []
  // classes: {
  //   label: '',
  //   editedValue: '',
  //   addedValue: '',
  //   deletedValue: '',
  // },
});

export default SummaryContext;
