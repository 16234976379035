import React from 'react';
import { styled } from '@mui/material/styles';

import AddIcon from '@extensions/utils/AddIcon';
import QualityWarning from '../dataset/QualityWarning';
import { faLock, faStar } from '@fortawesome/free-solid-svg-icons';
import creativeCommonsZeroLogo from '@extensions/resources/cc-zero.png';

export interface IDatasetResultIconsProps {
    datasetName: string;
    isDataQualityAffected: boolean;
    isGeneralUseReady: boolean;
    isPublic: boolean;
    isMfaRestricted: boolean;
    classes?: any;
}

const StyledIconGroupSpan = styled('span')(({ theme }) => ({
    '& > *': {
        marginRight: theme.spacing(1),
    },
}));

const StyledProprietaryIconAddIcon = styled(AddIcon)(({ theme }) => ({
    color: theme.palette.error.dark,
}));

const StyledRecommendedIconAddIcon = styled(AddIcon)(({ theme }) => ({
    color: theme.palette.success.light,
}));

const StyledImg = styled('img')(({
    height: '1rem',
    verticalAlign: 'middle'
}));

const DatasetResultIcons: React.FC<IDatasetResultIconsProps> = ({
    datasetName,
    isDataQualityAffected,
    isGeneralUseReady,
    isPublic,
    isMfaRestricted,
    ...props
}) => {

    return (
        <StyledIconGroupSpan>
            {isDataQualityAffected && (
                <QualityWarning
                    datasetName={datasetName}
                />
            )}
            {isGeneralUseReady && (
                <StyledRecommendedIconAddIcon
                    icon={faStar}
                    title="Data is recommended for general use"
                />
            )}
            {isPublic && (
                <StyledImg
                    src={creativeCommonsZeroLogo}
                    title="Data is public domain"
                    alt="CC0 Public Domain Dedication"
                />
            )}
            {isMfaRestricted && (
                <StyledProprietaryIconAddIcon
                    icon={faLock}
                    title="Data is [MFA] restricted"
                />
            )}
        </StyledIconGroupSpan>
    )
};

export default DatasetResultIcons;
