import React from 'react';
import { FieldProps } from '@rjsf/utils';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { TextField, FormControlLabel, Checkbox, Box } from '@mui/material';

import MetadataService from '@extensions/services/MetadataService';

import Measurement, { Standard } from '@extensions/models/Measurement';
import VirtualizedAutocomplete from '@extensions/components/core/VirtualizedAutocomplete';

const StyledTextField = styled(TextField)(({
  '& .MuiIconButton-root': {
    padding: '2px',
  }
}))

export interface IMeasurementFieldProps extends FieldProps<Measurement> {
  className?: string;
  metadataService?: MetadataService;
}

export interface IMeasurementFieldState { }

enum MeasurementOptions {
  StandardName = 'standardName',
  StandardUnit = 'standardUnit'
}

@observer
export class MeasurementField extends React.Component<
  IMeasurementFieldProps,
  IMeasurementFieldState
> {
  getId = (field: keyof Measurement) =>
    `${this.props.idSchema['$id']}-${field}`;
  getChangeHandler = (
    field: keyof Measurement,
    getValFromArgs: (...args) => any = (event) => event.target.value
  ) => {
    const { formData, onChange } = this.props;
    return (...args) => {
      const value = getValFromArgs(...args);
      let fieldValue;
      if (formData && formData.isPrimary && !value && value !== false) {
        fieldValue = { ...formData };
        fieldValue[field] = null as never;
        // unit field needs to be empty string if not filled, to pass validation.
        if (field === 'unit' && !value) {
          fieldValue[field] = '';
        }
        onChange(fieldValue);
      } else if (formData && !formData.isPrimary && !value && value !== false) {
        if (field === MeasurementOptions.StandardName || field === MeasurementOptions.StandardUnit) {
          fieldValue = { ...formData };
          fieldValue[field] = {} as never;
          onChange(fieldValue);
        } else {
          onChange({ ...formData, [field]: value });
        }
      } else if (formData) {
        onChange({ ...formData, [field]: value });
      }
    };
  };

  checkError = (input: Standard | null | undefined, isPrimary: boolean | undefined): boolean => {
    if (isPrimary === true) {
      if (input === null || input === undefined) {
        return true;
      } else if (input && Object.keys(input).length === 0) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { formData, metadataService } = this.props;
    if (!metadataService || !metadataService.loaded) {
      return null;
    }

    if (formData && formData.isPrimary && (formData.standardName && Object.keys(formData.standardName).length === 0)) {
      formData.standardName = {} as Standard;
    } else if ((formData && formData.standardName === null) || (formData && formData.standardName && Object.keys(formData.standardName).length === 0)) {
      formData.standardName = {} as Standard;
    }

    if (formData && formData.isPrimary && (formData.standardUnit && Object.keys(formData.standardUnit).length === 0)) {
      formData.standardUnit = {} as Standard;
    } else if ((formData && formData.standardUnit === null) || (formData && formData.standardUnit && Object.keys(formData.standardUnit).length === 0)) {
      formData.standardUnit = {} as Standard;
    }
    return (
      <fieldset style={{ border: 'none' }}>
        <legend>Measurement</legend>
        <StyledTextField
          error={formData && !formData.specificName}
          helperText={formData && !formData.specificName && "Name is a required property."}
          id={this.getId('specificName')}
          label="Name"
          value={formData && formData.specificName}
          onChange={this.getChangeHandler('specificName')}
          variant="outlined"
          fullWidth
          required
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: -11.5, mt: 2 }}>
          <FormControlLabel
            sx={{ marginLeft: '5rem' }}
            control={
              <Checkbox
                checked={formData && Boolean(formData.isPrimary)}
                onChange={this.getChangeHandler(
                  'isPrimary',
                  (event) => event.target.checked
                )}
                name="isPrimary"
              />
            }
            label="Primary Measurement"
          />
        </Box>
        <VirtualizedAutocomplete
          options={metadataService.standardMeasures || []}
          value={formData && formData.standardName}
          isOptionEqualToValue={(option, value) =>
            option.identifier === value.identifier
          }
          onChange={this.getChangeHandler(
            'standardName',
            (event, value) => value
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="Standardized Name"
              variant="outlined"
              size="medium"
              margin="normal"
              required={formData && Boolean(formData.isPrimary)}
              error={this.checkError(formData && formData.standardName, formData && formData.isPrimary)}
              helperText={(formData && Boolean(formData.isPrimary)) && "Standardized Name is a required property."}
            />
          )}
          getOptionLabel={(opt) => opt.displayName || ''}
        />
        <VirtualizedAutocomplete
          options={metadataService.standardUnits || []}
          value={formData && formData.standardUnit}
          isOptionEqualToValue={(option, value) =>
            option.identifier === value.identifier
          }
          onChange={this.getChangeHandler(
            'standardUnit',
            (event, value) => value
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="Standardized Unit"
              variant="outlined"
              size="medium"
              margin="normal"
              required={formData && Boolean(formData.isPrimary)}
              error={this.checkError(formData && formData.standardUnit, formData && formData.isPrimary)}
              helperText={(formData && Boolean(formData.isPrimary)) && "Standardized Unit is a required property."}
            />
          )}
          getOptionLabel={(opt) => opt.displayName || ''}
        />
        <StyledTextField
          variant="outlined"
          id={this.getId('unit')}
          label="Custom Unit"
          fullWidth
          value={(formData && formData.unit) ? formData.unit : ''}
          onChange={this.getChangeHandler('unit')}
          margin="normal"
        // Todo - Need to clairfy workflow for Measurement Field - when Custom Unit is required and when is not??
        // required={formData && Boolean(!formData.isPrimary)}
        // error={formData && !formData.isPrimary && !formData.unit}
        // helperText={formData && Boolean(!formData.isPrimary) && "Unit is a required property."}
        />
        <StyledTextField
          id={this.getId('description')}
          label="Description"
          multiline
          minRows={4}
          fullWidth
          value={formData && formData.description}
          onChange={this.getChangeHandler('description')}
          margin="normal"
          required
          error={formData && !formData.description}
          helperText={"Description is a required property."}
          InputProps={{
            inputComponent: 'textarea',
            inputProps: {
              style: {
                resize: 'vertical',
                overflow: 'auto',
                minHeight: '4em',
              }
            }
          }}
        />
      </fieldset>
    );
  }
}

const Wrapped = inject((store: any) => ({
  metadataService: store.metadataService,
}))(MeasurementField);

export default function MeasurementFieldFunc(props: FieldProps) {
  return <Wrapped {...props} />;
}
