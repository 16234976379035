import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, runInAction, makeObservable } from 'mobx';

import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Grid, Typography } from '@mui/material';

import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import { ICachingService } from '@extensions/services/ICachingService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import Link from '@extensions/components/core/Link';
import Citation from '@extensions/components/order/Citation';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import Breadcrumb from '@extensions/components/core/Breadcrumb';
import DatasetList from '@extensions/components/order/DatasetList';
import FileSummary from '@extensions/components/order/FileSummary';
import OrderOptions from '@extensions/components/order/OrderOptions';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';
import HeadManager from './HeadManager';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: '0.5rem',
}));

const StyledDatasetList = styled(DatasetList)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledFileSummary = styled(FileSummary)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledCitation = styled(Citation)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StyledBreadcrumb = styled(Breadcrumb)(({
  marginBottom: '0.5rem',
}));

export interface IOrderDetailsProps
  extends RouteComponentProps<{ id: string }> {
  cachingService?: ICachingService;
  notificationService?: INotificationService;
  securityService?: ISecurityService;
}

const NOTIFICATION_ID = 'ORDER_DETAILS';

@inject('cachingService', 'notificationService', 'securityService')
@observer
class OrderDetails extends React.Component<IOrderDetailsProps> {
  @observable
  order: FileOrderGroup | null = null;
  @observable
  orderNotFound: boolean | null = null;

  constructor(props: IOrderDetailsProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { cachingService, notificationService, securityService } = this.props;
    if (
      cachingService &&
      notificationService &&
      securityService &&
      securityService.userIsLoggedIn
    ) {
      cachingService
        .getOrder(this.getOrderId())
        .then((order) => {
          runInAction(() => {
            this.order = order;
            this.orderNotFound = false;
          });
          order.loadDatasets();
        })
        .catch((error) => {
          if (error.status === 404) {
            runInAction(() => {
              this.orderNotFound = true;
            });
          } else {
            notificationService.addNotification(
              NOTIFICATION_ID,
              Status.Error,
              'Could not fetch order',
              error
            );
          }
        });
    }
  }

  getOrderId = (): string => {
    const { match } = this.props;
    return match.params.id;
  };
  render() {
    const { securityService } = this.props;
    let helmet;
    let body: React.ReactNode;
    if (securityService && !securityService.userIsLoggedIn) {
      helmet = (
        <HeadManager
          title="A2e: Atmosphere to Electrons Order Detail - Access Restricted"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
      )
      body = (
        <Alert severity="error">
          <AlertTitle>
            You must <Link to="/login">sign in </Link> to view data orders.
          </AlertTitle>
        </Alert>
      );
    } else if (this.orderNotFound) {
      helmet = (
        <HeadManager
          title="A2e: Atmosphere to Electrons Order Not Found"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
      )
      body = (
        <Alert severity="error">
          <AlertTitle>Not Found</AlertTitle>
          The order you are looking for can not be found.
        </Alert>
      );
    } else if (
      this.order === null ||
      (this.order && this.order.insufficientAuth === null)
    ) {
      body = <CenteredCircularProgress />;
    } else if (this.order && this.order.insufficientAuth === true) {
      helmet = (
        <HeadManager
          title="A2e: Atmosphere to Electrons Order Detail - Access Restricted"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
      )
      body = (
        <Alert severity="error">
          <AlertTitle>
            You must sign in using Multifactor Authentication (MFA) to view this
            order
          </AlertTitle>
          This order contains proprietary data which can not be downloaded
          without Multifactor Authentication (MFA).
        </Alert>
      );
    } else if (this.order && this.order.insufficientAuth === false) {
      helmet = (
        <HeadManager
          title="A2e: Atmosphere to Electrons Order Details"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
      )
      body = (
        <StyledGrid container spacing={5} >
          <Grid item xs={12} lg={6}>
            <StyledDatasetList order={this.order} />
            <StyledFileSummary order={this.order} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <StyledCitation order={this.order} />
          </Grid>
          <OrderOptions order={this.order} />
        </StyledGrid>
      );
    }
    return (
      <div>
        <StyledBreadcrumb
          to="/profile/orders"
          name="My Data Orders"
        />
        <StyledTypography variant="h1">
          Order
        </StyledTypography>
        {helmet}
        {body}
      </div>
    );
  }
}

export default (OrderDetails);
