import { WidgetProps } from '@rjsf/utils';
import { Typography, Box } from '@mui/material';
import theme from '@extensions/services/Theme';

const ExpandableDescriptionTypography = ({
    id,
    value,
    label,
}: WidgetProps) => {
    return (
        <Box sx={{ width: '100%', maxWidth: '600px', overflow: 'hidden' }}>
            <Typography sx={{
                "&&": {
                    marginBottom: theme.spacing(0.25),
                    color: theme.palette.primary.dark
                }
            }}>{label}</Typography>
            <Typography key={id} sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}>{value}</Typography>
        </Box>
    )
}

export default ExpandableDescriptionTypography;