import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import theme from '@extensions/services/Theme';

import { IStepParams } from '@extensions/models/EditorStep';
import ButtonBar from '@extensions/components/metadata/ButtonBar';
import CancelBtn from '@extensions/components/metadata/CancelBtn';
import SplitView from '@extensions/components/metadata/review/SplitView';

const StyledRootDiv = styled('div')(({}));

const StyledSplitViewBody = styled(SplitView)(({
  marginTop: '1.5rem',
  marginBottom: '3rem',
}));

export interface IReviewProps extends IStepParams {
  className?: string;
}

export interface IReviewState { }

@observer
export class Review extends React.Component<
  IReviewProps,
  IReviewState
> {
  render() {
    const {
      destination,
      document,
      previous,
      cancel,
    } = this.props;

    if (!destination || !destination.schema) {
      return null;
    }

    return (
      <StyledRootDiv>
        <Typography variant="h2">Submit For Review</Typography>
        <StyledSplitViewBody
          document={document}
          schema={destination.schema}
        />

        <ButtonBar>
          <Button onClick={previous}>Back</Button>
          <div>
            <CancelBtn onClick={cancel} />
            <Button variant="outlined" onClick={document.saveDraft}>
              Save Draft
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={document.submit}
              sx={{
                marginRight: theme.spacing(4)
              }}
            >
              Submit
            </Button>
          </div>
        </ButtonBar>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({}))(Review);
