import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';

import theme from '@extensions/services/Theme';

import DateFilter from '@extensions/components/cart/DateFilter';
import { ICartService } from '@extensions/services/ICartService';
import OrderButton from '@extensions/components/cart/OrderButton';
import OrderSummary from '@extensions/components/cart/OrderSummary';
import AppliedFilters from '@extensions/components/cart/AppliedFilters';
import CenteredCircularProgress from '../core/CenteredCircularProgress';
import { ISecurityService } from '@extensions/services/ISecurityService';
import FileTypesFilter from '@extensions/components/cart/FileTypesFilter';
import ExtensionsFilters from '@extensions/components/cart/ExtensionsFilters';

const StyleTypography = styled(Typography)(({
  fontSize: `${theme.spacing(6)} !important`,
  marginBottom: `${theme.spacing(5)} !important`
}));

const StyledAppliedFilters = styled(AppliedFilters)(({
  marginBottom: theme.spacing(2)
}));

const StyledDateFilter = styled(DateFilter)(({
  marginBottom: theme.spacing(2),
}));

const StyledOrderButton = styled(OrderButton)(({
  marginTop: theme.spacing(2),
}));

export interface ICartProps {
  className?: string;
  cartService?: ICartService;
  securityService?: ISecurityService;
}

@inject('cartService', 'securityService')
@observer
class Cart extends React.Component<ICartProps> {
  render() {
    const { cartService, securityService } = this.props;
    if (!cartService || !securityService) {
      return null;
    }

    const needsTimeFilter = (cartService.datasets || []).some(d => {
      return d.dynamoDataBegins !== null && d.dynamoDataEnds !== null;
    });

    const orderDetails: React.ReactNode = (
      <>
        <OrderSummary />
        <StyledOrderButton />
      </>
    );
    return (
      <div>
        <StyleTypography
          variant="h1"
        >
          Data Cart
        </StyleTypography>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="h2" sx={{
              fontSize: '31px !important',
              marginBottom: `${theme.spacing(3)} !important`
            }}>
              Filters
            </Typography>
            <StyledAppliedFilters />
            {needsTimeFilter && (
              <StyledDateFilter />
            )}
            <FileTypesFilter
              styleObject={{
                root: {
                  marginBottom: theme.spacing(2),
                  width: '100%',
                  marginTop: theme.spacing(2),
                },
                filterOptionList: {
                  maxHeight: '350px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  borderBottom: `1px solid ${theme.palette.grey[400]}`,
                },
              }}
            />
            <ExtensionsFilters
              styleObject={{
                filterBlock: { marginBottom: theme.spacing(2) },
                optionsList: {
                  maxHeight: '350px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  borderBottom: `1px solid ${theme.palette.grey[400]}`,
                }
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="h2"
              sx={{
                fontSize: '1.9375rem !important',
                marginBottom: `${theme.spacing(3)} !important`
              }}
            >
              Order
            </Typography>
            {cartService.statsAreLoading ? (
              <CenteredCircularProgress />
            ) : (
              orderDetails
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Cart;
