import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import compose from 'lodash/fp/compose';
import theme from '@extensions/services/Theme';

import { ICartService } from '@extensions/services/ICartService';

import Dataset from '@extensions/models/Dataset';
import { FilterTitle } from '@extensions/components/search-core';

export interface IFileTypesFilterProps {
  className?: string;
  cartService?: ICartService;
  styleObject?: any;
}

@inject('cartService')
@observer
class FileTypesFilter extends React.Component<IFileTypesFilterProps> {
  render() {
    const { cartService, styleObject } = this.props;
    if (
      !cartService ||
      cartService.datasets === null ||
      cartService.datasets.length === 0
    ) {
      return null;
    }

    const uniqueFileTypes = compose(
      uniq,
      flatten,
      filter((fileTypes: string[] | null) => fileTypes !== null),
      map((dataset: Dataset) => dataset.dynamoFileTypes)
    )(cartService.datasets);

    if (uniqueFileTypes.length === 0) {
      return null;
    }

    return (
      <FormControl
        component="div"
        sx={
          styleObject && Object.keys(styleObject.root).length === 0
            ? { width: '100%' }
            : { ...styleObject.root }
        }
      >
        <FilterTitle>File Type</FilterTitle>
        <FormGroup
          sx={
            styleObject && Object.keys(styleObject.filterOptionList).length === 0
              ? {}
              : { ...styleObject.filterOptionList, flexWrap: 'nowrap' }
          }
        >
          {uniqueFileTypes.map(fileType => (
            <FormControlLabel
              sx={{
                marginLeft: '-11px',
                '& .MuiTypography-root': {
                  color: theme.palette.grey[700],
                },
              }}
              key={fileType}
              control={
                <Checkbox
                  checked={cartService.fileTypesFilter.has(fileType)}
                  onChange={() => {
                    cartService.toggleFileType(fileType);
                  }}
                  name={fileType}
                />
              }
              label={fileType}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }
}

export default FileTypesFilter;
