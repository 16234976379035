import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';

export const MODEL_REACTIVE_ID = 'ModelFilter';

export default class ModelFilter extends React.Component<{
  react?: react;
}> {
  render = () => {
    return (
      <MultiList
        className="data-filter"
        componentId={MODEL_REACTIVE_ID}
        dataField="model.keyword"
        title="Model"
        filterLabel="Model"
        showSearch={false}
        react={this.props.react}
        URLParams={true}
      />
    );
  };
}
