import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface IResponsiveSRProps {
  desktop: React.ReactNode;
  mobile: React.ReactNode;
}

export default function ResponsiveSR({
  desktop,
  mobile,
}: IResponsiveSRProps): React.ReactElement | null {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const menu = isMobile ? mobile : desktop;
  
  return <>{menu}</>;
}
