import {
  IChangeEvent
} from '@rjsf/core';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import Form from '@rjsf/mui';
import { styled } from '@mui/material';
import { Alert, Button, Typography } from '@mui/material';
 
import { IStepParams } from '@extensions/models/EditorStep';
import ButtonBar from '@extensions/components/metadata/ButtonBar';
 
import theme from '@extensions/services/Theme';
import { IMetadataService } from '@extensions/services/IMetadataService';
 
import { getFileUploadUrls } from '@extensions/utils/metadata';
import CancelBtn from '@extensions/components/metadata/CancelBtn';
import ConfirmButton from '@extensions/components/core/ConfirmButton';
import InvisibleField from '@extensions/components/metadata/InvisibleField';
import HasEmailWidget from '@extensions/components/metadata/HasEmailWidget';
import FileUploadField from '@extensions/components/metadata/FileUploadField';
import MeasurementField from '@extensions/components/metadata/MeasurementField';
 
import validator from '@rjsf/validator-ajv8';
import { RJSFValidationError } from '@rjsf/utils';
import AddButton from '@extensions/components/metadata/AddButton';
import DescriptionWidget from '@extensions/components/metadata/DescriptionWidget';
 
const StyledRootDiv = styled('div')(({}));
 
const StyledFormContainerDiv = styled('div')(({
  marginTop: '1.5rem',
  marginBottom: '1rem',
}));
 
const StyledAlert = styled(Alert)(({
  ...theme.MuiAlert.outlinedError,
  backgroundColor: `${theme.MuiAlert.outlinedError.backgroundColor} !important`,
}));
 
const StyledReviewerButtonBar = styled(ButtonBar)(({
  marginTop: '3rem',
  justifyContent: 'flex-end'
}));
 
const StyledButtonBar = styled(ButtonBar)(({
  marginTop: '3rem'
}));
 
export enum FormMode {
  REVIEWER,
  AUTHOR,
}
 
export interface IProvideMetadataProps extends IStepParams {
  className?: string;
  metadataService?: IMetadataService;
  mode?: FormMode;
}
 
export interface IProvideMetadataState { }
 
@observer
export class ProvideMetadata extends React.Component<
  IProvideMetadataProps,
  IProvideMetadataState
> {
  @observable
  errors: RJSFValidationError[] | undefined;
  @observable
  isDescriptionError: boolean = false;
 
  @action
  clearErrors = () => {
    this.errors = undefined;
  };
 
  @action
  handleErrors = (errors: RJSFValidationError[] | undefined) => {
    this.errors = errors;
  };
 
  @action
  handleChange = ({ formData }: IChangeEvent, e) => {
    this.props.document.setMetadata(formData);
    this.validateDescription(formData.description);
  };
 
  @action
  handleSubmit = ({ errors }) => {
    this.errors = errors;
    if (this.errorsAreNotEmpty(this.errors)) {
      this.renderErrorList(this.errors);
    } else {
      this.props.document.setDeleteOnCancel(false);
      this.props.next(true);
    }
  };
 
  @action
  validateDescription = (description: string) => {
    if (description && description.length > 2000) {
      this.isDescriptionError = true;
    } else {
      this.isDescriptionError = false;
    }
  };
 
  errorsAreNotEmpty = (errors: RJSFValidationError[] | undefined) => {
    if (errors && errors.length) {
      return true;
    }
    return false;
  };
 
  constructor(props: IProvideMetadataProps) {
    super(props);
    makeObservable(this);
    this.initializeValidationState();
  }
 
  initializeValidationState() {
    const { document } = this.props;
    if (document.metadata && document.metadata.description) {
      this.validateDescription(document.metadata.description);
    }
  }
 
  renderErrorList = (errors: RJSFValidationError[] | undefined) => {
    if (errors && this.errorsAreNotEmpty(errors)) {
      return <StyledAlert
        onClose={this.clearErrors}
        severity="error"
        key={'error-alert'}
      >
        Please fix errors above.
      </StyledAlert>
    }
  }
 
  renderButtonBar = (mode: FormMode, buttons: JSX.Element) => {
    if (mode === FormMode.REVIEWER) {
      return <StyledReviewerButtonBar>{buttons}</StyledReviewerButtonBar>
    } else {
      return <StyledButtonBar>{buttons}</StyledButtonBar>
    }
  }

  customValidate = (formData, errors) => {
    if (formData.events) {
      formData.events.forEach((event, index) => {
        if (event.startDate && event.endDate && new Date(event.startDate) > new Date(event.endDate)) {
          errors.events[index].endDate.addError('End date must be after start date');
        }
      });
    }
    return errors;
  }

  render() {
    const {
      previous,
      cancel,
      document,
      destination,
      mode = FormMode.AUTHOR,
    } = this.props;
    if (!destination) {
      return null;
    }
    const buttons =
      mode === FormMode.AUTHOR ? (
        <>
          <ConfirmButton
            confirmOptions={{
              confirmationText: 'Yes, go back',
              cancellationText: 'No',
              description:
                'Changing previous selections may cause entries on this page to be overwritten.',
            }}
            onClick={previous}
          >
            Back
          </ConfirmButton>
          <div>
            <CancelBtn onClick={cancel} confirm />
            <Button variant="outlined" onClick={document.saveDraft}>
              Save Draft
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={this.isDescriptionError}>
              Save &amp; Review
            </Button>
          </div>
        </>
      ) : (
        <>
          <CancelBtn onClick={cancel} confirm />
          <Button variant="contained" color="primary" type="submit" disabled={this.isDescriptionError}>
            Resubmit
          </Button>
        </>
      );
 
    let body: React.ReactNode;
    if (destination.schema !== null && document.metadata !== null) {
      const adjustedUiSchema = {
        ...destination.schema.uiSchema,
        fileExtensions: {
          'ui:field': 'invisible'
        },
      };
      const formDataWithDefaults = {
        ...document.metadata,
        fileExtensions: document.metadata.fileExtensions || [{ label: 'File Type' }],
      };
      body = (
        <StyledFormContainerDiv>
          <Form
            schema={destination.schema.dataSchema}
            uiSchema={!document.can.publish ? adjustedUiSchema : destination.schema.uiSchema}
            validator={validator}
            liveValidate={false}
            formData={formDataWithDefaults}
            noHtml5Validate={false}
            showErrorList={false}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            onError={this.handleErrors}
            fields={{
              invisible: InvisibleField,
              fileupload: FileUploadField,
              measurement: MeasurementField,
            }}
            widgets={{
              hasemail: HasEmailWidget,
              expandableDescription: DescriptionWidget,
            }}
            templates={{ ButtonTemplates: { AddButton } }}
            formContext={{
              fileUploadUrls: getFileUploadUrls({ document }),
            }}
            customValidate={this.customValidate}
          >
            <div>
              {this.renderErrorList(this.errors)}
              {this.renderButtonBar(mode, buttons)}
            </div>
          </Form>
        </StyledFormContainerDiv >
      );
    }
    return (
      <StyledRootDiv>
        {mode === FormMode.AUTHOR && (
          <Typography variant="h2">
            Edit Metadata for {document.metadata?.identifier}
          </Typography>
        )}
        {body}
      </StyledRootDiv>
    );
  }
}
 
export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
  metadataService: store.metadataService,
}))(ProvideMetadata);