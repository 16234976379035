import React from 'react';
import { styled } from '@mui/material/styles';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import theme from '@extensions/services/Theme';

const StyledFormGroup = styled(FormGroup)(({
  flexWrap: 'nowrap',
}));

export interface IDefaultExtensionFilterProps {
  className?: string;
  values: string[];
  selected: Set<string>;
  styleObject?: any;
  toggle: (value: string) => void;
}

class DefaultExtensionFilter extends React.Component<
  IDefaultExtensionFilterProps
> {
  render() {
    const { values, selected, styleObject, toggle } = this.props;
    return (
      <StyledFormGroup
        sx={
          styleObject.optionsList && Object.keys(styleObject.optionsList).length === 0
            ? { width: '100%' }
            : { ...styleObject.optionsList, width: '100%' }
        }
      >
        {values.map(value => (
          <FormControlLabel
            key={value}
            sx={{
              marginLeft: '-11px',
              '& .MuiTypography-root': {
                color: theme.palette.grey[700],
              },
            }}
            control={
              <Checkbox
                checked={selected.has(value)}
                onChange={() => {
                  toggle(value);
                }}
                name={value}
              />
            }
            label={value}
          />
        ))}
      </StyledFormGroup>
    );
  }
}

export default DefaultExtensionFilter;
