import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import { TextField, Chip, Autocomplete } from '@mui/material';

import Reference from '@extensions/models/Reference';
import RefFile from '@extensions/models/RefFileUpload';
import DownloadIconButton from '@extensions/components/core/DownloadIconButton';

export interface IRefFileSelectorProps {
  className?: string;
  uploadedFiles: RefFile[];
  references: Reference[] | undefined;
  onReferencesChange: (newReferences: Reference[]) => void;
  labeledBy: string;
}

export interface IRefFileSelectorState { }

@observer
export class RefFileSelector extends React.Component<
  IRefFileSelectorProps,
  IRefFileSelectorState
> {
  @computed
  get autocompleteValue(): RefFile[] {
    const { references } = this.props;
    if (!references) {
      return [];
    }
    return references.map(this.refToRefFile);
  }

  getRelativeURL = (url: string): string => {
    const base = window.location.origin;
    const parsed = new URL(url, base);
    return `${parsed.pathname}${parsed.search}`;
  };

  refToRefFile = (ref: Reference): RefFile => {
    return {
      url: ref.referenceURL,
      name: ref.referenceTitle,
      caption: '',
      size: ref?.custom?.fileSize,
      mime: ref?.custom?.fileType,
      extension: ref?.custom?.fileExtension,
    };
  };

  refFileToRef = (ref: RefFile): Reference => {
    const output: Reference = {
      referenceURL: ref.url,
      referenceTitle: ref.name,
    };
    if (ref.extension != null && ref.mime != null && ref.size != null) {
      output.custom = {
        fileSize: ref.size,
        fileType: ref.mime,
        fileExtension: ref.extension
      };
    }
    return output;
  };

  urlsEqual = (url1: string, url2: string): boolean => {
    return this.getRelativeURL(url1) === this.getRelativeURL(url2);
  };

  refFilesEqual = (ref1: RefFile, ref2: RefFile) => {
    return this.urlsEqual(ref1.url, ref2.url);
  };

  handleChange = (event, newValue: RefFile | RefFile[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    this.props.onReferencesChange(
      newValue.map(refFile => this.refFileToRef(refFile))
    );
  };

  render() {
    const { uploadedFiles, labeledBy } = this.props;
    return (
      <>
        <Autocomplete
          options={uploadedFiles}
          getOptionLabel={opt => opt.name}
          isOptionEqualToValue={this.refFilesEqual}
          value={this.autocompleteValue}
          onChange={this.handleChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              aria-labelledby={labeledBy}
            />
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((opt, index) => (
              <Chip
                icon={
                  <DownloadIconButton url={opt.url} iconProps={{ size: 'xs' }} />
                }
                label={opt.name}
                {...getTagProps({ index })}
              />
            ))
          }
          multiple
        />
      </>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(RefFileSelector);
