import React from 'react';
import { inject } from 'mobx-react';
import {
  ProjectFilters as DapProjectFilters,
} from '@dapclient/components/project-search/ProjectFilters';
import { styled } from '@mui/material/styles';
import { MultiList } from '@extensions/components/search-core';

export const PROJECT_KEYWORDS_ID = 'PROJECT_KEYWORDS_ID';
export const PROJECT_PARTICIPATING_ORGS_ID = 'PROJECT_PARTICIPATING_ORGS';
export const REACTIVE_IDS = [PROJECT_PARTICIPATING_ORGS_ID, PROJECT_KEYWORDS_ID];


const StyledMultiList = styled(MultiList)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  '& h6': {
    fontSize: '0.875rem'
  }
}));

export class ProjectFilters extends DapProjectFilters {
  getFilterReactiveIds = () => REACTIVE_IDS;
  renderDatasetCriteria = () => {
    return null;
  };
  renderProjectCriteria = () => {
    return (
      <>
        {this.getProjectCriteriaTitle()}
        <StyledMultiList 
          componentId={PROJECT_PARTICIPATING_ORGS_ID}
          title="Participating Organizations"
          dataField="contactPoint.hasOrg.keyword"
          react={{
            and: this.allReactiveIdsExcept(PROJECT_PARTICIPATING_ORGS_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams 
          filterLabel="Participating Organizations"
        />
        {this.getDatasetCriteriaTitle()}
        <MultiList
          componentId={PROJECT_KEYWORDS_ID}
          title="Keyword"
          filterLabel="Keyword"
          dataField="keyword.keyword"
          react={{
            and: this.allReactiveIdsExcept(PROJECT_KEYWORDS_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams
        />
      </>
    );
  };
}


export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(ProjectFilters);