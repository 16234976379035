import React from 'react';

import { ButtonProps, Tooltip } from '@mui/material';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';

import {
  DownloadOption,
  reasonCantUseOption,
} from '@extensions/models/FileOrder';
import FileOrderGroup, {
  OperatingSystem,
} from '@extensions/models/FileOrderGroup';
import AddIcon from '@extensions/utils/AddIcon';
import SplitButton, {
  IButtonOptions,
} from '@extensions/components/core/SplitButton';

export interface IScriptOptionProps {
  className?: string;
  order: FileOrderGroup;
}

class ScriptOption extends React.Component<IScriptOptionProps> {
  render() {
    const { order } = this.props;
    const reasonDisabled = reasonCantUseOption(DownloadOption.SCRIPT, order);
    const disabled = Boolean(reasonDisabled);

    const sharedButtonProps: Partial<ButtonProps> = {
      startIcon: <AddIcon icon={faTerminal} />,
      variant: 'contained',
      size: 'large',
      disabled,
    };

    const buttonOptions: IButtonOptions[] = [
      {
        value: OperatingSystem.LINUX,
        buttonProps: {
          ...sharedButtonProps,
          children: 'Linux Script',
        },
      },
      {
        value: OperatingSystem.MAC,
        buttonProps: {
          ...sharedButtonProps,
          children: 'Mac Script',
        },
      },
      {
        value: OperatingSystem.WINDOWS,
        buttonProps: {
          ...sharedButtonProps,
          children: 'Windows Script',
        },
      },
    ];

    const styleObject = {
      root: {
        width: '100%',
      },
      mainButton: {
        flex: '1 1 auto',
      },
      buttonPadding: {
        paddingTop: '.75rem',
        paddingBottom: '.75rem',
      },
    }

    const button = (
      <SplitButton
        styleObject={styleObject}
        options={buttonOptions}
        onClick={value => order.downloadClient(value as OperatingSystem)}
        tooltipTitle={disabled ? undefined : 'Script to run on your computer'}
      />
    );
    if (disabled) {
      return (
        <Tooltip title={reasonDisabled as string}>
          <span tabIndex={0}>{button}</span>
        </Tooltip>
      );
    }
    return button;
  }
}

export default ScriptOption;
