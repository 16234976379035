import React from 'react';
import ReactGA from 'react-ga4';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import {
  IDs,
  INotificationService,
  Status,
  Notification,
} from '@extensions/services/INotificationService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import User from '@extensions/models/User';
import DatasetModel from '@extensions/models/Dataset';
import { formatBytes } from '@extensions/utils/format';
import ButtonLink from '@extensions/components/core/Link';
import Distribution from '@extensions/models/Distribution';
import LargeDataOrder from '@extensions/components/dataset/LargeDataOrder';
import SmallDataOrder from '@extensions/components/dataset/SmallDataOrder';
import FeedbackForm from '@extensions/components/core/feedback/FeedbackForm';
import { DOWNLOAD_LIMITS, DownloadOption } from '@extensions/models/FileOrder';
import DistributionType, { typeToLabel } from '@extensions/models/DistributionType';

import {
  Link,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import PowerIcon from '@mui/icons-material/Power';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export const LEGACY_ORDER_MAX_FILES =
  DOWNLOAD_LIMITS[DownloadOption.LINKS].fileCount ?? 500;

const StyledDiv = styled('div')(({
  marginTop: '2rem',
  display: 'flex',
  justifyContent: 'center'
}));

const StyledExitToAppIcon = styled(ExitToAppIcon)(() => ({
  ...theme.linkIcon
}));

const StyledGetAppIcon = styled(GetAppIcon)(() => ({
  ...theme.linkIcon
}));

const StyledPowerIcon = styled(PowerIcon)(() => ({
  ...theme.linkIcon
}));

const StyledTableCell = styled(TableCell)(({
  fontSize: '16px !important',
  color: `${theme.palette.secondary.main} !important`
}));


// TODO consider creating a context for the app to use once there are a few good uses cases
// https://medium.com/@thehappybug/using-react-context-in-a-typescript-app-c4ef7504c858
export interface IDistributionTableProps {
  datasetService?: IDatasetService;
  securityService?: ISecurityService;
  notificationService?: INotificationService;
}

export enum OrderMode {
  SMALL = 'small',
  LARGE = 'large',
}
export interface IDistributionTableState {
  dataOrderModalVisible: boolean;
  feedbackFormVisible: boolean;
  orderMode: OrderMode | null;
}

@observer
export class DistributionTable<
  ExtraProps extends object = {}
> extends React.Component<
  IDistributionTableProps & ExtraProps,
  IDistributionTableState
> {
  static defaultProps = {
    datasetService: undefined,
    notificationService: undefined,
    securityService: undefined,
  };
  orderPlacedReaction: any;

  constructor(props) {
    super(props);

    this.state = {
      dataOrderModalVisible: false,
      feedbackFormVisible: false,
      orderMode: null,
    };
  }

  componentDidMount() {
    const datasetService = this.props.datasetService;

    reaction(
      () => datasetService?.dataset,
      (dataset) => {
        if (dataset) {
          datasetService?.getDatasetFilesMetadataIfNeeded();
        }
      },
      { fireImmediately: true }
    );

    reaction(
      () => datasetService?.dataset?.dynamoFileCount,
      (fileCount) => {
        if (fileCount != null) {
          const { REACT_APP_LARGE_ORDERS = 'false' } = process.env;
          const largeOrdersOn = {
            false: false,
            true: true,
          }[REACT_APP_LARGE_ORDERS.toLowerCase()];
          if (largeOrdersOn === undefined) {
            throw Error(
              `Unknown value for REACT_APP_LARGE_ORDERS: ${REACT_APP_LARGE_ORDERS}`
            );
          }
          this.handleFullFileList(largeOrdersOn, fileCount, datasetService);
        }
      },
      { fireImmediately: true }
    );

    // the side effect won't be run directly when created, but only after the data expression returns a new value for the first time
    // reaction(() => data, (data, reaction) => { sideEffect }
    this.orderPlacedReaction = reaction(
      () => this.props.notificationService ? this.props.notificationService?.getNotification(IDs.ORDER_PLACED) : null,
      (currentOrderStatus: Notification | null) => {
        if (
          currentOrderStatus &&
          currentOrderStatus.status === Status.Success
        ) {
          this.setState({ dataOrderModalVisible: false, feedbackFormVisible: true });
        }
      }
    );
  }

  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset loads
    this.orderPlacedReaction();
  }

  handleFullFileList(largeOrdersOn: boolean | undefined, fileCount: number, datasetService?: IDatasetService) {
    if (!largeOrdersOn || fileCount < LEGACY_ORDER_MAX_FILES) {
      this.setState({ orderMode: OrderMode.SMALL });
      if (datasetService?.dataset?.getDownloadDistribution()) {
        datasetService?.loadDatasetFilesIfNeeded();
      }
    } else {
      this.setState({ orderMode: OrderMode.LARGE });
    }
  }

  openDownloadModal = () => {
    this.setState({ dataOrderModalVisible: true });
  };

  onCancel = (e) => {
    this.setState({
      dataOrderModalVisible: false,
    });
  };

  captureDownloadLinkClickMetric = (outboundUrl) => {
    const dataset: DatasetModel | null = this.props.datasetService?.dataset ?? null;
    const user: User | null = this.props.securityService?.user ?? null;
    if (dataset) {
      ReactGA.event({
        category: 'External Dataset Download',
        action: dataset.name,
        label: `${dataset.name} ${outboundUrl}`,
      });
      if (user) {
        ReactGA.event({
          category: 'Users Who Downloaded',
          action: dataset.name,
          label: user.username,
        });
      }
    }
  };

  renderExternalDistroLink(distribution: Distribution) {
    if (distribution.accessURL) {
      return (
        <Link
          color="primary"
          href={distribution.accessURL}
          target="_blank"
          onClick={(e) =>
            this.captureDownloadLinkClickMetric(distribution.accessURL)
          }
          underline='hover'
        >
          <StyledExitToAppIcon /> External Link
        </Link>
      );
    }

    if (distribution.downloadURL) {
      return (
        <Link
          color="primary"
          href={distribution.downloadURL}
          onClick={(e) =>
            this.captureDownloadLinkClickMetric(distribution.downloadURL)
          }
          underline='hover'
        >
          <StyledGetAppIcon /> Download
        </Link>
      );
    }
  }

  renderApiDistroLink(distribution: Distribution) {
    const currentUrl = window.location.href;
    const pathSegments: string[] = currentUrl.split('/');
    const datasetName = `${pathSegments[4]}/${pathSegments[5]}`;
    const apiUrl = `/ds/${datasetName}/api`;
    
    return (
      <ButtonLink color="primary" component={Link} href={apiUrl}>
        <StyledPowerIcon /> API
      </ButtonLink>
    );
  }

  renderInternalDistroLink(distribution: Distribution) {
    return (
      <ButtonLink color="primary" onClick={this.openDownloadModal}>
        <StyledGetAppIcon /> Multi-Download
      </ButtonLink>
    );
  }

  renderDistroLink = (distribution: Distribution) => {
    if (
      distribution.distributionType === DistributionType.ByRequest ||
      distribution.distributionType === DistributionType.DownloadExternal ||
      distribution.distributionType === DistributionType.ExternalLink
    ) {
      return this.renderExternalDistroLink(distribution);
    }
    if (distribution.distributionType === DistributionType.API) {
      return this.renderApiDistroLink(distribution);
    }
    if (distribution.distributionType === DistributionType.DownloadDap) {
      return this.renderInternalDistroLink(distribution);
    }
    return <Typography>Not Available</Typography>;
  };

  renderFeedbackForm = (feedbackFormVisible: boolean) => {
    if (feedbackFormVisible) {
      return (
        <StyledDiv>
          <FeedbackForm />
        </StyledDiv>
      )
    }
  }

  renderTableRow = (distribution: Distribution, dataset: DatasetModel, idx: number) => {
    return (
      <TableRow key={`${distribution.identifier}-${idx}`}>
        <TableCell component="th" scope="row" sx={{ fontSize: '14px', color: '#000' }}>
          {(
            distribution.distributionType === DistributionType.DownloadDap ||
            (dataset.dynamoFileCount !== null && dataset.dynamoFileCount > 0)
          ) ? (
            <>
              <strong>
                {(dataset.dynamoFileCount || 0).toLocaleString()}
              </strong> files,&nbsp;
              <strong>
                {formatBytes(dataset.dynamoTotalFileSize || 0)}
              </strong>
            </>
          ) : typeToLabel(distribution.shortName)}
        </TableCell>
        <TableCell sx={{ fontSize: '14px' }}>{this.renderDistroLink(distribution)}</TableCell>
      </TableRow>
    )
  }

  render() {
    const dataset: DatasetModel | null = this.props.datasetService?.dataset ?? null;;
    const { orderMode, feedbackFormVisible } = this.state;

    let downloadModal: React.ReactNode;
    switch (orderMode) {
      case OrderMode.SMALL:
        downloadModal = (
          <SmallDataOrder
            visible={this.state.dataOrderModalVisible}
            onOrderCancel={this.onCancel}
          />
        );
        break;
      case OrderMode.LARGE:
        downloadModal = (
          <LargeDataOrder
            visible={this.state.dataOrderModalVisible}
            onOrderCancel={this.onCancel}
          />
        );
        break;
      default:
        downloadModal = null;
        break;
    }
    let content: React.ReactNode;
    if (dataset?.distribution && dataset?.distribution.length > 0) {
      content = (
        <div>
          {downloadModal}

          <TableContainer component={Paper}>
            <Table aria-label="data files table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Data Distribution</StyledTableCell>
                  <StyledTableCell>Access Method</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataset.distribution.map((distribution: Distribution, idx: number) => (
                  this.renderTableRow(distribution, dataset, idx)
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {Boolean(process.env.REACT_APP_FEEDBACK_TOOL_ENABLED) && this.renderFeedbackForm(feedbackFormVisible)}
        </div>
      );
    } else {
      content = <Typography>No Distributions Configured</Typography>;
    }
    return content;
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(DistributionTable);
