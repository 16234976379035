import { useState } from 'react';
import { inject } from 'mobx-react';
import { saveAs } from 'file-saver';

import {
  FormControl,
  Grid,
  Button,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

import { IUploadService } from '@extensions/services/IUploadService';
import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';

import { IStepProps, Step } from '@extensions/components/data/uploaders/Step';

interface IStepDownloadProps extends IStepProps {
  uploadService?: IUploadService;
  notificationService?: INotificationService;
}

const downloadOptions = {
  'linux-amd64': 'Linux - x86 64-bit',
  'linux-arm64': 'Linux - ARM 64-bit',
  'windows-amd64': 'Windows - x86 64-bit',
  'windows-arm64': 'Windows - ARM 64-bit',
  'windows-386': 'Windows - 386 32-bit',
  'darwin-amd64': 'MacOS - x86 64-bit (Intel)',
  'darwin-arm64': 'MacOS - ARM 64-bit (Apple Silicon)',
};

const StepDownload = inject(
  'uploadService',
  'notificationService'
)(
  ({
    uploadService,
    notificationService,
    ...stepProps
  }: IStepDownloadProps) => {
    const [os, setOs] = useState('');

    const downloadUrl = uploadService
      ? uploadService.getExecutableDownloadUrl(os)
      : '';

    const download = async () => {
      if (!downloadUrl || !notificationService) {
        return;
      }
      const notificationId = 'downloadClientExe';
      notificationService.addNotification(
        notificationId,
        Status.Running,
        'Downloading client executable ...'
      );
      try {
        const r = await fetch(downloadUrl);
        const filename = (r.headers.get('Content-Disposition') || '')
          .split('filename=')
          .pop();
        const blob = await r.blob();
        await saveAs(blob, filename || `dap-upload-${os}`);
        notificationService.addNotification(
          notificationId,
          Status.Success,
          'Executable downloaded'
        );
      } catch (e) {
        notificationService?.addNotification(
          notificationId,
          Status.Error,
          `Error downloading client executable: ${e}`
        );
      }
    };

    return (
      <Step
        {...stepProps}
        title="Download &amp; Run Client Executable"
        body={
          <>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.65)' }} variant="body1">
              Download the client executable for the computer where the data
              files reside. A client is specific to a project, but can be used
              for multiple datasets. &nbsp;
              <em>
                <strong>NOTE</strong>: If you are uploading files for two
                different projects, you cannot run both clients on the same
                machine at the same time.
              </em>
            </Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="os-select">
                    Select Operating System ...
                  </InputLabel>
                  <Select
                    native
                    value={os}
                    inputProps={{
                      id: 'os-select',
                    }}
                    onChange={(e) => setOs(e.target.value as string)}
                  >
                    <option value="" />
                    {Object.entries(downloadOptions).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={os === ''}
                  onClick={() => download()}
                >
                  <GetAppIcon />
                  Download
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: 'rgba(0, 0, 0, 0.65)' }}
                  variant="body1"
                >
                  <em>
                    Once downloaded,{' '}
                    <strong>
                      run the client executable on the machine where the data
                      files reside
                    </strong>
                    , and then return to this page.
                  </em>
                </Typography>
              </Grid>
            </Grid>
          </>
        }
      />
    );
  }
);

export default StepDownload;
