import React from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { react } from '@appbaseio/reactivesearch/lib/types';
import get from 'lodash/get';
import moment, { Moment } from 'moment';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import FilterTitle from '@extensions/components/search-core/FilterTitle';
import DatePicker from '@dapclient/components/core/date-picker';
import { DateRange } from '@dapclient/components/core/date-picker/DatePicker';
import isEqual from 'lodash/isEqual';

interface IAttachedDatePickerProps {
  minDate: Moment | null;
  maxDate: Moment | null;
  setQuery: ({ query, value }: { query: any; value: string | null }) => void;
  value: string | null;
  startDateField: string;
  endDateField: string;
  hidden?: boolean;
}
@observer
class AttachedDatePicker extends React.Component<IAttachedDatePickerProps> {
  @observable currentDateRange: DateRange = {
    startDate: null,
    endDate: null,
  };

  constructor(props: IAttachedDatePickerProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.updateDateRangeUsingValue();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.updateDateRangeUsingValue();
    }
  }

  updateDateRangeUsingValue = () => {
    if (this.props.value) {
      const [startDateStr, endDateStr] = this.props.value.split(' to ');
      this.handleDateChange({
        startDate: moment(startDateStr, 'YYYY-MM-DD').utc(),
        endDate: moment(endDateStr, 'YYYY-MM-DD').utc(),
      });
    } else {
      this.handleDateChange({
        startDate: null,
        endDate: null,
      });
    }
  };

  @action
  handleDateChange = (newRange: DateRange) => {
    const { startDateField, endDateField } = this.props;
    this.currentDateRange = newRange;
    if (newRange.startDate && newRange.endDate) {
      const completedRange = newRange as { startDate: Moment; endDate: Moment };
      this.props.setQuery({
        query: {
          bool: {
            must: [
              {
                exists: {
                  field: startDateField,
                },
              },
              {
                bool: {
                  must_not: [
                    {
                      range: {
                        [startDateField]: {
                          gt: completedRange.endDate.endOf('day').valueOf(),
                          format: 'epoch_millis',
                        },
                      },
                    },
                    {
                      range: {
                        [endDateField]: {
                          lt: completedRange.startDate.startOf('day').valueOf(),
                          format: 'epoch_millis',
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        value: `${completedRange.startDate.format(
          'YYYY-MM-DD'
        )} to ${completedRange.endDate.format('YYYY-MM-DD')}`,
      });
    } else {
      this.props.setQuery({
        query: {
          match_all: {},
        },
        value: null,
      });
    }
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return (
      <DatePicker
        startDate={this.currentDateRange.startDate}
        endDate={this.currentDateRange.endDate}
        onDatesChange={this.handleDateChange}
        oldestAllowed={this.props.minDate}
        newestAllowed={this.props.maxDate}
        startDateId="date-search-start"
        endDateId="date-search-end"
      />
    );
  }
}

export const DATE_RANGE_REACTIVE_ID = 'DateRangeFilter';

export default class DateRangeFilter extends React.Component<{
  startDateField: string;
  endDateField: string;
  react?: react;
}> {
  render = () => {
    const { startDateField, endDateField } = this.props;
    return (
      <>
        <ReactiveComponent
          componentId={DATE_RANGE_REACTIVE_ID}
          defaultQuery={() => ({
            size: 0,
            aggs: {
              maxDate: { max: { field: endDateField } },
              minDate: { min: { field: startDateField } },
            },
          })}
          react={this.props.react}
          showFilter={true}
          filterLabel="Dates"
          URLParams={true}
          render={({ aggregations, setQuery, value, loading, error }) => {
            const rawMaxDate = get(aggregations, 'maxDate.value', null);
            const rawMinDate = get(aggregations, 'minDate.value', null);
            const hidden = loading || error || !rawMaxDate || !rawMinDate;
            return (
              <div className="data-filter">
                <FilterTitle hidden={hidden}>Date Range</FilterTitle>
                <AttachedDatePicker
                  maxDate={rawMaxDate && moment(rawMaxDate).utc()}
                  minDate={rawMinDate && moment(rawMinDate).utc()}
                  setQuery={setQuery}
                  value={value}
                  startDateField={startDateField}
                  endDateField={endDateField}
                  hidden={hidden}
                />
              </div>
            );
          }}
        />
      </>
    );
  };
}
