import {
  useState,
  useCallback,
  useRef,
  useMemo
} from 'react';
import moment, { Moment } from 'moment';
import { inject, observer } from 'mobx-react';

import { map } from 'lodash';
import chroma from 'chroma-js';
import { styled } from '@mui/material/styles';
import { ArrowDownward } from '@mui/icons-material';
import { Popover, Button, Tooltip } from '@mui/material';

import { ISecurityService } from '@extensions/services/ISecurityService';

import theme from '@extensions/services/Theme';
import { formatBytes } from '@extensions/utils/format';
import {
  ViewValue,
  ViewStat,
} from '@extensions/components/dataset/TimelineController';
import FileList from '@extensions/components/dataset/FileList';
import ImageBrowser from '@extensions/components/dataset/ImageBrowser';
import Dataset, { DatasetRawStats, StatSegment } from '@extensions/models/Dataset';

interface ITimelineProps {
  dataset: Dataset;
  title?: React.ReactChild;
  beg?: Moment;
  end?: Moment;
  data: DatasetRawStats;
  view?: [ViewValue | null, ViewStat | null];
  height?: number;
  isFirstInSet: boolean;
  isLastInSet: boolean;
  securityService?: ISecurityService;
}

// Todo - styled with backtick is not compatible in MUI 5
// Todo - Need to replace styled('div')`` with styled('div')(({theme})=>({}))
const Wrapper = styled('div')`
  width: 100%;
  margin-bottom: 2px;
  & code {
    font-family: monospace;
  }
`;

const Container = styled('div')`
  width: 100%;
  background-color: #f3f3f3;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
`;

const Blocks = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Block = styled('div')`
  position: absolute;
  height: 100%;
  opacity: 50%;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;

const Overlay = styled('div')`
  position: absolute;
  height: 10px;
  border-radius: 3px;
  top: calc(50% - 5px);
  background-color: rgba(0, 0, 0, 0.25);
`;

const Label = styled('div')`
  position: absolute;
  display: flex;
  padding-left: 4px;
  justify-content: center;
  left: 0;
`;

const DateRange = styled('div')`
  text-align: center;
  font-size: 120%;
`;

const StatsPop = styled('div')`
  padding: 0.75rem 1rem;
`;

const StatsLine = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 0.4rem;
  margin-top: 0.4rem;
  border-top: 1px solid #eee;
`;

const SummaryValue = styled('div')`
  font-weight: bold;
  text-align: center;
  & small {
    font-weight: normal;
    font-size: 0.75rem;
  }
`;

const StatsLabel = styled('div')`
  flex: 1;
  font-size: 0.75rem;
  color: #aaa;
  background-color: #eee;
  border-radius: 3px;
  padding: 3px 8px;
  text-align: center;
  font-style: italic;
`;

const Stats = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const Stat = styled('div')`
  flex: 1;
  padding: 5px 8px;
  border-right: 1px solid #eee;
  text-align: center;
  white-space: nowrap;
  font-size: 0.9rem;
  &:last-child {
    border-right: none;
  }
`;

const StatLabel = styled('div')`
  font-size: 60%;
  color: #aaa;
  text-transform: uppercase;
`;

const StatValue = styled('div')`
  font-size: 80%;
`;

const Timeline = inject('securityService')(
  observer(
    ({
      dataset,
      title,
      beg,
      end,
      data,
      view,
      height,
      isFirstInSet,
      isLastInSet,
      securityService,
    }: ITimelineProps) => {
      const tipRef = useRef({
        x: 0,
        y: 0,
      });
      const popperRef = useRef<any>(null);
      const containerRef = useRef<HTMLDivElement>(null);
      const anchorRef = useRef({
        clientWidth: 0,
        clientHeight: 0,
        getBoundingClientRect: () =>
          new DOMRect(
            tipRef.current.x,
            containerRef.current
              ? containerRef.current.getBoundingClientRect().y
              : 0,
            0,
            0
          ),
      });

      const [tipValue, setTipValue] = useState('');

      const [anchor, setAnchor] = useState({
        el: null as HTMLElement | null,
        data: null as StatSegment | null,
        x: 0 as number,
        y0: 0 as number,
        y1: 0 as number,
      });

      // const initialZoom = useRef([beg, end])

      // useEffect(() => {
      //   if (initialZoom.current[0] === undefined) {
      //     initialZoom.current[0] = beg
      //   }
      //   if (initialZoom.current[1] === undefined) {
      //     initialZoom.current[1] = end
      //   }
      // }, [beg, end])

      const [listSegment, setListSegment] = useState(
        undefined as StatSegment | undefined
      );
      const [overlayRange, setOverlayRange] = useState(
        undefined as [Moment, Moment] | undefined
      );
      const [showFileList, setShowFileList] = useState(false);
      const [showImages, setShowImages] = useState(false);
      const handleClose = useCallback(() => {
        setAnchor({ el: null, data: null, x: 0, y0: 0, y1: 0 });
      }, [setAnchor]);

      const range: [Moment, Moment] = useMemo(() => {
        if (beg !== undefined && end !== undefined) {
          return [beg.clone(), end.clone()];
        }
        // const z = initialZoom.current
        // if (z[0] !== undefined && z[1] !== undefined) {
        //   return [z[0].clone(), z[1].clone()]
        // }
        let b: Moment | undefined = undefined;
        let e: Moment | undefined = undefined;
        for (let block of data.timeline) {
          if (b === undefined || block.beg.isBefore(b)) {
            b = block.beg;
          }
          if (e === undefined || block.end.isAfter(e)) {
            e = block.end;
          }
        }
        return [b ? b.clone() : moment(), e ? e.clone() : moment()];
      }, [data.timeline, /*initialZoom,*/ beg, end]);

      const span: number = useMemo(() => {
        return moment.duration(range[1].diff(range[0])).asSeconds();
      }, [range]);

      const blocks = useMemo(() => {
        return map(data.timeline, (block, i) => {
          return [
            (moment.duration(block.beg.diff(range[0])).asSeconds() / span) *
            100,
            (moment.duration(block.end.diff(range[0])).asSeconds() / span) *
            100,
            i,
          ];
        });
      }, [data.timeline, span, range]);

      const overlay = useMemo(() => {
        if (overlayRange === undefined) {
          return undefined;
        }
        return [
          (moment.duration(overlayRange[0].diff(range[0])).asSeconds() / span) *
          100,
          (moment.duration(overlayRange[1].diff(range[0])).asSeconds() / span) *
          100,
        ];
      }, [overlayRange, span, range]);

      const onNavigate = useCallback(
        (range) => setOverlayRange(range),
        [setOverlayRange]
      );

      // This improves performance but blocks don't look right
      // let scale = 1
      // let offset = 0
      // if (beg !== undefined && end !== undefined) {
      //   const zoomed = end.diff(beg, 'seconds')
      //   scale = span / zoomed
      //   offset = range[0].diff(beg, 'seconds') / zoomed
      // }

      const fileType = data.extension.toLowerCase().split('.').pop() || '';
      const isImageType = ['png', 'jpg', 'jpeg', 'gif'].indexOf(fileType) >= 0;
      const tlColor = isImageType
        ? theme.palette.timeline.secondary
        : theme.palette.timeline.primary;

      const colors: [string, string][] = useMemo(() => {
        if (view === undefined || view[0] === null || view[1] === null) {
          const bg = chroma(tlColor).alpha(0.6).hex();
          const bgHover = chroma(tlColor).alpha(0.9).hex();
          return map(data.timeline, (_) => [bg, bgHover]);
        }
        let typeKey = view[0] === ViewValue.Count ? 'fileCount' : 'fileSize';
        const values = map(
          data.timeline,
          (block) => block[typeKey].stDev / block[typeKey].mean
        );
        const scale = theme.palette.timeline.chromaGradientScale;
        return map(values, (c) => {
          const scaled = scale(c);
          return [scaled.alpha(0.6).hex(), scaled.alpha(0.9).hex()];
        });
      }, [view, data.timeline, tlColor]);

      height = height || 20;
      const fontSize = Math.max(height - 8, 8);

      let segBeg = moment();
      let segEnd = moment();
      let segCount = 0;
      let segSize = 0;
      let segGuess = false;
      if (anchor.data) {
        segGuess =
          (beg && beg.isAfter(anchor.data.beg)) ||
            (end && end.isBefore(anchor.data.end))
            ? true
            : false;
        segBeg = beg && beg.isAfter(anchor.data.beg) ? beg : anchor.data.beg;
        segEnd = end && end.isBefore(anchor.data.end) ? end : anchor.data.end;
        segCount = segGuess
          ? anchor.data.fileCount.mean * segEnd.diff(segBeg, 'days')
          : anchor.data.fileCount.sum;
        segSize = segGuess
          ? anchor.data.fileSize.mean * segEnd.diff(segBeg, 'days')
          : anchor.data.fileSize.sum;
      }

      let borderRadius = [0, 0, 0, 0];
      if (isFirstInSet) {
        borderRadius[0] = borderRadius[1] = 3;
      }
      if (isLastInSet) {
        borderRadius[2] = borderRadius[3] = 3;
      }

      const isLoggedIn =
        securityService !== undefined && securityService.userIsLoggedIn;

      return (
        <Wrapper>
          <Tooltip
            title={tipValue}
            placement="top"
            arrow
            PopperProps={{
              popperRef,
              anchorEl: anchorRef.current,
            }}
          >
            <Container
              style={{
                height: height,
                borderRadius: borderRadius.map((v) => `${v}px`).join(' '),
              }}
              ref={containerRef}
              onMouseMove={(e) => {
                tipRef.current = { x: e.clientX, y: e.clientY };
                if (popperRef.current !== null) {
                  popperRef.current.update();
                }
                if (!beg || !end) {
                  return;
                }
                const pos = e.currentTarget.getBoundingClientRect();
                const pct = (e.clientX - pos.x) / pos.width;
                const ndays = end.diff(beg, 'days');
                setTipValue(
                  beg
                    .clone()
                    .add(Math.round(pct * ndays), 'days')
                    .format('YYYY MM DD')
                );
              }}
            >
              <Label style={{ fontSize: `${fontSize}px` }}>
                <code>{title || `*.${data.extension}`}</code>
              </Label>
              <Blocks
                style={
                  {
                    // This got close but just wasn't right
                    // transformOrigin: 'left',
                    // transform: `translateX(${offset * 100}%) scaleX(${scale})`,
                  }
                }
              >
                {blocks
                  .filter((block, i) => {
                    return !(block[1] < 0 || block[0] > 100);
                  })
                  .map((block) => {
                    const index = block[2];
                    const isSel =
                      anchor.data &&
                      index === data.timeline.indexOf(anchor.data);
                    const bg = isSel ? colors[index][1] : colors[index][0];
                    return (
                      <Block
                        key={index}
                        sx={{
                          left: `${block[0]}%`,
                          width: `${block[1] - block[0]}%`,
                          backgroundColor: `${bg}`,
                          '&:hover': {
                            backgroundColor: `${colors[index][1]}`
                          }
                        }}
                        onClick={(e) => {
                          const rect = e.currentTarget.getBoundingClientRect();
                          setAnchor({
                            el: e.currentTarget,
                            x: e.clientX,
                            y0: rect.top - 5,
                            y1: rect.top + rect.height + 5,
                            data: data.timeline[index],
                          });
                        }}
                      />
                    );
                  })}
                {overlay && (
                  <Overlay
                    sx={{
                      left: `${overlay[0]}%`,
                      width: `${overlay[1] - overlay[0]}%`
                    }}
                  />
                )}
              </Blocks>
            </Container>
          </Tooltip>
          {listSegment !== undefined && showFileList && (
            <FileList
              dataset={dataset}
              extension={data.extension}
              timeline={data.timeline}
              timelineIndex={data.timeline.indexOf(listSegment)}
              absoluteRange={beg && end ? [beg, end] : undefined}
              onClose={() => setShowFileList(false)}
            />
          )}
          {listSegment !== undefined && showImages && (
            <ImageBrowser
              dataset={dataset}
              extension={data.extension}
              timeline={data.timeline}
              timelineIndex={data.timeline.indexOf(listSegment)}
              absoluteRange={beg && end ? [beg, end] : undefined}
              onNavigate={onNavigate}
              onClose={() => {
                setShowImages(false);
                setOverlayRange(undefined);
              }}
            />
          )}

          {anchor.el !== null && anchor.data !== null && (
            <>
              <Popover
                open={true}
                anchorEl={anchor.el}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorReference="anchorPosition"
                anchorPosition={{ left: anchor.x, top: anchor.y0 }}
                transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                sx={{ fontFamily: theme.openSansFontFamily }}
              >
                <StatsPop>
                  <DateRange>
                    <code>{segBeg.format('YYYY MM DD')}</code>
                    <br />
                    <code>
                      {segEnd.clone().subtract(1, 'days').format('YYYY MM DD')}
                    </code>
                  </DateRange>
                  <StatsLine>
                    <SummaryValue>
                      {Math.round(
                        moment.duration(segEnd.diff(segBeg)).asDays()
                      ).toLocaleString()}
                      &nbsp;
                      <small>day(s)</small>
                    </SummaryValue>
                  </StatsLine>
                  <StatsLine>
                    <SummaryValue>
                      {segGuess && '~ '}
                      {(segGuess
                        ? Math.round(segCount)
                        : segCount
                      ).toLocaleString()}
                      &nbsp;
                      <small>file(s)</small>
                    </SummaryValue>
                    <StatsLabel>file count per day</StatsLabel>
                    <Stats>
                      <Stat>
                        <StatLabel>min</StatLabel>
                        <StatValue>{anchor.data.fileCount.min}</StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>mean</StatLabel>
                        <StatValue>
                          {anchor.data.fileCount.mean.toFixed(1)}
                        </StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>median</StatLabel>
                        <StatValue>{anchor.data.fileCount.median}</StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>st dev</StatLabel>
                        <StatValue>
                          {anchor.data.fileCount.stDev.toFixed(1)}
                        </StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>max</StatLabel>
                        <StatValue>{anchor.data.fileCount.max}</StatValue>
                      </Stat>
                    </Stats>
                  </StatsLine>
                  <StatsLine>
                    <SummaryValue>
                      {segGuess && '~ '}
                      {formatBytes(segSize)}
                    </SummaryValue>
                    <StatsLabel>total size per day</StatsLabel>
                    <Stats>
                      <Stat>
                        <StatLabel>min</StatLabel>
                        <StatValue>
                          {formatBytes(anchor.data.fileSize.min)}
                        </StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>mean</StatLabel>
                        <StatValue>
                          {formatBytes(Math.round(anchor.data.fileSize.mean))}
                        </StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>median</StatLabel>
                        <StatValue>
                          {formatBytes(anchor.data.fileSize.median)}
                        </StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>st dev</StatLabel>
                        <StatValue>
                          {formatBytes(Math.round(anchor.data.fileSize.stDev))}
                        </StatValue>
                      </Stat>
                      <Stat>
                        <StatLabel>max</StatLabel>
                        <StatValue>
                          {formatBytes(anchor.data.fileSize.max)}
                        </StatValue>
                      </Stat>
                    </Stats>
                  </StatsLine>
                </StatsPop>
              </Popover>
              <Popover
                open={true}
                onClose={handleClose}
                anchorEl={anchor.el}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                anchorReference="anchorPosition"
                anchorPosition={{ left: anchor.x, top: anchor.y1 }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                sx={{ fontFamily: theme.openSansFontFamily }}
              >
                <div style={{ padding: '0.5rem 1rem' }}>
                  {isImageType && (
                    <Tooltip
                      title={
                        isLoggedIn ? '' : 'Must be logged in to browse images'
                      }
                    >
                      <span>
                        <Button
                          size="small"
                          startIcon={<ArrowDownward />}
                          disabled={!isLoggedIn}
                          onClick={() => {
                            setListSegment(anchor.data || undefined);
                            setShowImages(true);
                            handleClose();
                          }}
                        >
                          Show Images
                        </Button>
                        &nbsp;|&nbsp;
                      </span>
                    </Tooltip>
                  )}
                  <Button
                    size="small"
                    startIcon={<ArrowDownward />}
                    onClick={() => {
                      setListSegment(anchor.data || undefined);
                      setShowFileList(true);
                      handleClose();
                    }}
                  >
                    Show Files
                  </Button>
                </div>
              </Popover>
            </>
          )}
        </Wrapper>
      );
    }
  )
);

export default Timeline;
