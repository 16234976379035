import BaseUser from '@dapclient/models/User';
import UserTool from '@extensions/models/UserTool';
import { makeObservable, observable } from 'mobx';

export default class User extends BaseUser {
  @observable tools: UserTool[] | null = null;
  @observable affiliation: string | undefined | null;
  @observable usCitizen: boolean | undefined | null;
  @observable employmentLevel: string | undefined | null;
  @observable country: string | undefined | null;
  sshkey: string;

  constructor({ affiliation, us_citizen, employment_level, country, sshkey, ...data }) {
    super(data);
    this.affiliation = affiliation;
    this.usCitizen = us_citizen;
    this.employmentLevel = employment_level;
    this.country = country;
    this.sshkey = sshkey;
    makeObservable(this);
  }

  static getGuestUser(): User {
    return new User({
      username: '',
      name_given: '',
      name_family: '',
      email: '',
      orcId: '',
      justification: '',
      sshkey: '',
      api_umbrella_key: '',
      agreed: false,
      approved: false,
      verified: false,
      mfa_enabled: false,
      mfa: false,
      affiliation: '',
      us_citizen: undefined,
      employment_level: undefined,
      country: undefined,
    });
  }
}
