import React from 'react';
import { inject, observer } from 'mobx-react';
import { Typography, TypographyProps } from '@mui/material';

import theme from '@extensions/services/Theme';

export interface ILabelProps {
  className?: string;
  children: React.ReactNode;
  typographyProps?: TypographyProps;
}

export interface ILabelState { }

@observer
export class Label extends React.Component<
  ILabelProps,
  ILabelState
> {
  render() {
    const { children, typographyProps } = this.props;
    if (!children) {
      return null;
    }

    return (
      <Typography
        {...typographyProps}
        sx={{
          color: theme.palette.secondary.main,
          '&&': {
            marginBottom: '0.125rem',
          },
        }}
      >
        {children}
      </Typography>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(Label);
