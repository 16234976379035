import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Button, Typography, Paper } from '@mui/material';
import { computed, action, observable, reaction, makeObservable } from 'mobx';

import theme from '@extensions/services/Theme';
import { IMetadataService } from '@extensions/services/IMetadataService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { INotificationService } from '@extensions/services/INotificationService';

import Link from '@extensions/components/core/Link';
import ProvideMetadata, {
  FormMode,
} from '@extensions/components/metadata/ProvideMetadata';
import MetaDocument from '@extensions/models/MetaDocument';
import MetadataDest from '@extensions/models/MetadataDest';
import ConfirmButton from '@extensions/components/core/ConfirmButton';
import SplitView from '@extensions/components/metadata/review/SplitView';
import BackToLandingPage from '@extensions/components/metadata/BackToLandingPage';

const StyledRootDiv = styled('div')(({}));

const StyledSplitView = styled(SplitView)(({
  marginBottom: '2rem'
}));

const StyledPaper = styled(Paper)(({
  padding: '1rem',
  marginBottom: '1rem',
}));

const StyledButtonsDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'flex-end',
  '& button:not(:nth-last-of-type(1))': {
    marginRight: '1rem',
  },
}));

const StyledProvdeMetadata = styled(ProvideMetadata)(({
  margin: '1rem 0'
}));

const StyledConfirmButton = styled(ConfirmButton)(({
  color: theme.palette.error.dark,
  '&:hover': {
    backgroundColor: '#ffebee',
  },
}));

export interface ISubmittedProps {
  className?: string;
  document: MetaDocument;
  destination: MetadataDest | null;
  metadataService?: IMetadataService;
  securityService?: ISecurityService;
  notificationService?: INotificationService;
}

export interface ISubmittedState { }

@observer
export class Submitted extends React.Component<
  ISubmittedProps,
  ISubmittedState
> {
  @observable
  metadataBackup: MetaDocument['metadata'] = null;
  @observable
  isEditing: boolean = false;

  constructor(props: ISubmittedProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get subtitleText(): React.ReactNode {
    const { document } = this.props;
    if (document.can.publish) {
      return `Please review the metadata for ${document.datasetName}.`;
    }
    return (
      <>
        This metadata is awaiting approval. In the meantime, you can{' '}
        <Link to={`/ds/${document.datasetName}/upload`}>upload data</Link>.
      </>
    );
  }

  componentDidMount() {
    this.backupMetadata();
    reaction(
      () => {
        const { document } = this.props;
        // If the document changes or metadata loads then update backup
        return [document.id, document.metadataLoaded];
      },
      this.backupMetadata,
      { fireImmediately: true }
    );
  }

  @action
  backupMetadata = () => {
    this.metadataBackup = this.props.document.metadata;
  };

  @action
  cancelEdit = () => {
    const { document } = this.props;
    if (this.metadataBackup) {
      document.setMetadata(this.metadataBackup);
    }
    this.toggleEditing();
  };

  @action
  saveEdit = () => {
    const { document } = this.props;

    document.save({
      onSuccess: () => {
        // Update in memory backup to store new version
        this.backupMetadata();
        this.toggleEditing();
      },
    });
  };

  @action
  toggleEditing = () => {
    this.isEditing = !this.isEditing;
    window.scrollTo(0, 0);
  };

  @computed
  get body() {
    const { document, destination, metadataService } = this.props;

    if (this.isEditing) {
      return (
        <StyledProvdeMetadata
          document={document}
          // If they are editing, they can admin, and there is a destination
          destination={destination as MetadataDest}
          cancel={this.cancelEdit}
          next={this.saveEdit}
          previous={() => { }}
          mode={FormMode.REVIEWER}
        />
      );
    }

    return (
      <>
        <StyledSplitView
          document={document}
          schema={destination?.schema || null}
        />

        <StyledButtonsDiv>
          {document.can.delete && (
            <StyledConfirmButton
              color="primary"
              confirmOptions={{
                confirmationText: 'Yes, delete',
                cancellationText: 'No',
              }}
              onClick={() => metadataService?.deleteDocument(document)}
              disabled={!document.can.delete}
            >
              Delete
            </StyledConfirmButton>
          )}
          {document.can.write && (
            <Button
              variant="outlined"
              onClick={this.toggleEditing}
              disabled={!document.can.write}
            >
              Edit
            </Button>
          )}
          {document.isSubmitted && document.can.publish && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => metadataService?.publish(document)}
              disabled={!document.can.publish}
            >
              Publish
            </Button>
          )}
        </StyledButtonsDiv>
      </>
    );
  }

  render() {
    const { document } = this.props;
    return (
      <StyledRootDiv>
        <BackToLandingPage />
        <Typography variant="h1" sx={{ fontSize: '48px !important' }}>
          Metadata for {document.datasetName}
        </Typography>
        <Typography sx={{ marginBottom: '1rem' }}>
          {this.subtitleText}
        </Typography>
        <StyledPaper>{this.body}</StyledPaper>
      </StyledRootDiv >
    );
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(Submitted);
