import AccessLevel from '@extensions/models/AccessLevel';
import IControlledAccess, { Type } from '@extensions/models/IControlledAccess';
import Permission from '@extensions/models/Permission';
import AccessRestriction from '@extensions/models/AccessRestriction';
import ContactPoint from '@extensions/models/ContactPoint';
import Organization from '@extensions/models/Organization';
import Quality from '@extensions/models/Quality';
import DatasetMetrics from '@extensions/models/DatasetMetrics';
import DistributionType from '@extensions/models/DistributionType';
import Distribution from '@extensions/models/Distribution';
import FileMetadataSchema, {
  DisplayType,
  RANGE_TYPE_FILE_METADATA,
} from '@extensions/models/FileMetadataSchema';
import LatLong from '@extensions/models/LatLong';
import Reference from '@extensions/models/Reference';
import ViewData from '@extensions/models/ViewData';
import RealTimeData from '@extensions/models/RealTimeData';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { action, computed, observable, makeObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { DownloadStat } from '@extensions/models/Metrics';
import IHasMetrics from '@extensions/models/IHasMetrics';
import DataRelevantEvent from '@extensions/models/DataRelevantEvent';
import Publication from './Publication';

// App specific imports

export enum FilterType {
  AUTOCOMPLETE = 'autocomplete',
  DEFAULT = 'default',
  HIDE = 'hide',
}

export interface IFileExtension {
  label: string;
  valuesInUse: Set<string>;
  filterType?: FilterType;
}

export interface DatasetStats {
  fileCount: number;
  byteCount: number;
  startDate: Moment | null;
  endDate: Moment | null;
}

export interface DatasetRawStats {
  datasetName: string;
  extension: string;
  updated: Moment;
  summary: Stats;
  timeline: StatSegment[];
}

export interface StatSegment {
  beg: Moment;
  end: Moment;
  fileSize: Stats;
  fileCount: Stats;
}

export interface Stats {
  count: number;
  min: number;
  max: number;
  mean: number;
  median: number;
  stDev: number;
  sum: number;
}

export interface Downloadable {
  url: string;
  name: string;
  date: Moment;
  downloaded: boolean;
}

export interface Gap {
  ndays: number;
  date: Moment;
}
export default class Dataset implements IControlledAccess, IHasMetrics {
  name: string;
  title: string;
  shortName: string;
  description: string;
  viewData: ViewData | null;
  realtimeData?: RealTimeData | null | undefined;
  keywords: string[];
  contacts: ContactPoint[] = [];
  references: Reference[] = [];
  timeline: {
    start: Moment;
    end: Moment;
  } | null = null;
  latLongs: LatLong[] = [];
  distribution: Distribution[] = [];
  accessMethods: string[] = [];
  restriction: AccessRestriction;
  permissions: Permission[] = [];
  permissionLabel: string;
  accessLevel: string;
  projectName: string;
  participatingOrganizations: Organization[] = [];
  citation: string | null | undefined;
  quality: Quality | null = null;
  datasetMetrics: DatasetMetrics | null = null;
  events: DataRelevantEvent[] | null = [];
  timezoneOffset: number | null = null;
  externalSource: string | null;
  generalUseReady: boolean | null;
  isPublic: boolean;
  isMfaRestricted: boolean = false;
  @observable dynamoFileCount: number | null = null;
  @observable dynamoTotalFileSize: number | null = null;
  @observable dynamoLastModified: number | null = null;
  @observable dynamoDataBegins: Date | null = null;
  @observable dynamoDataEnds: Date | null = null;
  @observable dynamoFullExtensions: string[] | null = null;
  @observable dynamoRangeMinMax: { min: number; max: number } | null = null;
  @observable dynamoFileExtensions: IFileExtension[] = [];
  @observable dynamoFileTypes: string[] | null = null;
  @observable imagesDatasetName: string | null = null;
  @observable orderFileCount: number | null = null;
  @observable orderByteCount: number | null = null;
  @observable orderCount: number | null = null;
  @observable doi?: string;
  @observable views: number = 0;
  @observable abilities: string[] | null = null;
  @observable downloadStats: DownloadStat[] = [];
  @observable publications: Publication[] = [];

  constructor(response) {
    makeObservable(this);
    const data = response.meta || response;
    this.name = response.name || data.identifier;
    this.doi = data.doiName;
    this.projectName = response.project_name;
    this.participatingOrganizations = response.participatingOrganization
      ? response.participatingOrganizations.map((org) => new Organization(org))
      : [];
    this.title = data.title || this.name;
    this.shortName = data.shortName;
    this.description = data.description;
    this.keywords = data.keyword;
    this.externalSource = data.externalSourceIdentifier || null;
    this.imagesDatasetName = data.imagesDataset || null;

    if (data.events) {
      this.events = data.events;
    }

    if (data.timezoneOffset) {
      this.timezoneOffset = data.timezoneOffset;
    }

    if (data.realtimeData) {
      this.realtimeData = data.realtimeData;
    }

    if (data.viewData) {
      this.viewData = data.viewData;
    } else {
      this.viewData = null;
    }

    this.generalUseReady = data.generalUseReady ?? null;

    if (data.temporal) {
      const parts = (data.temporal as string).split('/');
      if (parts.length === 2) {
        this.timeline = {
          start: moment(parts[0]),
          end: moment(parts[1]),
        };
      } else {
        console.warn(
          `bad "temporal" in metadata for ${this.name}: ${data.temporal}`
        );
      }
    }
    this.citation = data.citation;

    if (
      data.accessLevel === AccessLevel.Public ||
      data.accessLevel === AccessLevel.NonPublic ||
      data.accessLevel === AccessLevel.RestrictedPublic
    ) {
      this.accessLevel = data.accessLevel;
    } else {
      this.accessLevel = AccessLevel.Invalid;
    }

    this.isPublic = (this.accessLevel === AccessLevel.Public);

    if (data.accessRestriction) {
      this.restriction = find(
        AccessRestriction.restrictions,
        (res: AccessRestriction) =>
          res.restrictionType === data.accessRestriction
      ) as AccessRestriction; // Can't ever be undefined?
      this.isMfaRestricted = AccessRestriction.requiresMfa(this.restriction);
    } else {
      this.restriction = AccessRestriction.restrictions.none;
    }
    this.permissions = AccessRestriction.getPermissions(
      this.restriction,
      this.getDatasetOnlyName(),
      this.projectName
    );
    this.permissionLabel = AccessRestriction.getPermissionLabel(
      this.restriction,
      this.getDatasetOnlyName(),
      this.projectName
    );

    // some datasets might have contacts as a single element (old schema), not an array so test for that
    if (data.contactPoint && Array.isArray(data.contactPoint)) {
      this.contacts = data.contactPoint.map(
        (contact) => new ContactPoint(contact)
      );
    } else if (data.contactPoint) {
      this.contacts = [];
      this.contacts.push(new ContactPoint(data.contactPoint));
    }
    if (data.spatial) {
      this.latLongs = data.spatial.map((point) => new LatLong(point));
    }
    if (data.distribution) {
      // todo rename to distributions (plural)
      this.distribution = data.distribution.map(
        (dist) => new Distribution(dist)
      );

      const downloadDistro: Distribution = this.getDownloadDistribution();

      // add to the 'download-livewire' distro the dynamo field names using the index of the identifierSchema's attributes
      if (downloadDistro) {
        let extCount: number = 1;
        downloadDistro.fileMetadataSchema.forEach(
          (schema: FileMetadataSchema, index: number) => {
            // the last entry in the FileMetadataSchema list will always be the file type, no matter the name, and will map to
            // the dynamo field name of "file_type"
            if (index === downloadDistro.fileMetadataSchema.length - 1) {
              schema.setDynamoFieldName('file_type');
            } else if (schema.name === RANGE_TYPE_FILE_METADATA.name) {
              extCount++;
            } else if (schema.dynamoFieldName === '') {
              schema.setDynamoFieldName(`ext${extCount}`);
              extCount++;
            }
          }
        );
      }
    }
    if (data.references) {
      this.references = data.references;
    }

    if (data.dapFileSummary) {
      this.setDynamoFileTypes(data.dapFileSummary.types);
      this.setDynamoFileCount(data.dapFileSummary.count);
      this.setDynamoFullExtensions(data);
      this.setDynamoTotalFileSize(data.dapFileSummary.size);
      this.setDynamoLastModified(data.dapFileSummary.updated);
      if (data.dapFileSummary.begins) {
        this.setDynamoDataBegins(data.dapFileSummary.begins);
      }
      if (data.dapFileSummary.ends) {
        this.setDynamoDataEnds(data.dapFileSummary.ends);
      }
    }
  }

  @computed
  get hasRange(): boolean {
    const downloadDistribution = this.getDownloadDistribution();
    return (
      downloadDistribution &&
      downloadDistribution.fileMetadataSchema.some(
        (schema) => schema.name === RANGE_TYPE_FILE_METADATA.name
      )
    );
  }

  @action setAccessMethods(methods: string[]): void {
    this.accessMethods = methods;
  }

  @action setDynamoFileTypes(types: string[]): void {
    this.dynamoFileTypes = types;
  }
  @action setDynamoFileCount(count: number): void {
    this.dynamoFileCount = count;
  }
  @action setDynamoTotalFileSize(size: number): void {
    this.dynamoTotalFileSize = size;
  }
  @action setDynamoLastModified(epoch: number): void {
    this.dynamoLastModified = epoch;
  }
  dynamoValueToDate(value: string | number | null): Date | null {
    switch (typeof value) {
      case 'number':
        if (value === 0 || value === 999999999) {
          return null;
        }
        return moment(value).toDate();
      case 'string':
        return moment(value, 'YYYYMMDD').toDate();
      default:
        return null;
    }
  }

  @action setDynamoDataBegins(date: string | number | null): void {
    this.dynamoDataBegins = this.dynamoValueToDate(date);
  }
  @action setDynamoDataEnds(date: string | number | null): void {
    this.dynamoDataEnds = this.dynamoValueToDate(date);
  }
  @action setDynamoFullExtensions(data: any): void {
    const formalized = [] as IFileExtension[];
    if (data.dapFileSummary) {
        data.dapFileSummary.extensions?.forEach(fullExt => {
          fullExt.split('.').slice(0, -1).forEach((ext, i) => {
            if (formalized.length === i) {
              formalized.push({
                label: `Extension ${i+1}`,
                filterType: FilterType.DEFAULT,
                valuesInUse: new Set(),
              });
            }
            formalized[i].valuesInUse.add(ext);
          });
        });
        const dapDistro = this.getDownloadDistribution()
        if (dapDistro) {
          let index = 0;
          dapDistro.fileMetadataSchema.slice(0, -1).forEach(schema => {
            if (index >= formalized.length) {
              return;
            }
            switch (schema.downloadDisplay) {
              case DisplayType.DATE:
              case DisplayType.TIME:
                case undefined:
                  return;
              case DisplayType.RANGE:
              case DisplayType.NONE:
                formalized[index].filterType = FilterType.HIDE;
                break;
              case DisplayType.AUTOCOMPLETE:
                formalized[index].label = schema.label;
                formalized[index].filterType = FilterType.AUTOCOMPLETE;
                break;
              default:
                formalized[index].label = schema.label;
                break;
            }
            index++;
          });
        } else { //this handles the case where a user is not logged in
            let index = 0
            if (index >= formalized.length) {
              return;
            }
            if (data.fileExtensions) {
              data.fileExtensions.slice(0, -1).forEach(schema => {
                switch(schema.downloadDisplay) {
                  case DisplayType.DATE:
                  case DisplayType.TIME:
                    case undefined:
                      return;
                  case DisplayType.RANGE:
                  case DisplayType.NONE:
                    formalized[index].filterType = FilterType.HIDE;
                    break;
                  case DisplayType.AUTOCOMPLETE:
                    formalized[index].filterType = FilterType.AUTOCOMPLETE;
                    break;
                  default:
                    break;
                }
                index++;
              })
            }
          }
      this.dynamoFullExtensions = data.dapFileSummary.extensions || [];
      this.dynamoFileExtensions = formalized;
    }
  }
  @action setDynamoRangeMinMax(
    minMax: { min: number; max: number } | null
  ): void {
    this.dynamoRangeMinMax = minMax;
  }

  getDynamoFileCount(): number | null {
    return this.dynamoFileCount;
  }
  getDynamoTotalFileSize(): number | null {
    return this.dynamoTotalFileSize;
  }
  getDownloadDistribution(): Distribution {
    return filter(this.distribution, {
      distributionType: DistributionType.DownloadDap,
    })[0]; // should only ever be one
  }

  static extractProjectName(fullDatasetName: string): string {
    const projectName = fullDatasetName.indexOf('/')
      ? fullDatasetName.split('/')[0]
      : '';
    return projectName;
  }

  static stripProjectName(fullDatasetName: string): string {
    const datasetName = fullDatasetName.indexOf('/')
      ? fullDatasetName.split('/')[1]
      : fullDatasetName;
    return datasetName;
  }

  // what to call this method?...:-(
  getDatasetOnlyName(): string {
    return Dataset.stripProjectName(this.name);
  }

  getPermissionLabel(): string {
    return this.permissionLabel;
  }
  getIdentifier(): string {
    return this.name;
  }
  getRestriction(): AccessRestriction {
    return this.restriction;
  }
  getPermissions(): Permission[] {
    return this.permissions;
  }
  getType(): Type {
    return Type.DATASET;
  }

  @action setViews(views: number): void {
    this.views = views;
  }

  @action setChildCount(count: number): void {
    // this.datasetCount = count;
    // for now do nothing
    //maybe eventually we can show number of distributions as a stat for datasets?
  }
  getChildCount(): number | null {
    return null;
  }

  getDownloadStats(): DownloadStat[] {
    return this.downloadStats;
  }
  getOrderFileCount(): number | null {
    return this.orderFileCount;
  }
  getOrderByteCount(): number | null {
    return this.orderByteCount;
  }
  getOrderCount(): number | null {
    return this.orderCount;
  }

  getViews(): number {
    return this.views;
  }
  getAbilities(): string[] | null {
    return this.abilities;
  }

  get isDataQualityAffected(): boolean {
    return (this.events || []).some(e => e.affectsDataQuality);
  }

  get isGeneralUseReady(): boolean {
    return this.generalUseReady || false;
  }

  get epochBegins(): number | undefined {
    return this.dynamoDataBegins
      ? moment(this.dynamoDataBegins).unix()
      : undefined;
  }

  get epochEnds(): number | undefined {
    return this.dynamoDataEnds
      ? moment(this.dynamoDataEnds).unix()
      : undefined;
  }

  get epochUpdated(): number {
    return moment(this.dynamoLastModified).unix();
  }

  get updated() {
    return moment.unix(this.epochUpdated);
  }

  get fileCount(): number {
    return this.dynamoFileCount || 0;
  }

  get fileTypes(): string[] {
    return this.dynamoFileTypes || [];
  }

  get extensions(): string[] {
    return this.dynamoFullExtensions || [];
  }

  get totalSize(): number {
    return this.dynamoTotalFileSize || 0;
  }

  get imagesExtensions(): string[] {
    return [];
  }

  get isTimeCentric(): boolean {
    return (
      this.dynamoDataBegins !== undefined &&
      this.dynamoDataEnds !== undefined
    );
  }

  @computed
  get lastUpdated(): string {
    const dateFormat = 'YYYY/MM/DD';
    if (!this.dynamoLastModified) {
      return 'N/A';
    }

    let lastModifiedMoment;
    switch (typeof this.dynamoLastModified) {
      case 'string':
        lastModifiedMoment = moment(this.dynamoLastModified);
        break;
      case 'number':
        // check to see if the number is greater than "now" in seconds since it shouldn't ever be a future time
        // if so the precision is in miliseonds, otherwise seconds
        if (this.dynamoLastModified > new Date().getTime() / 1000) {
          lastModifiedMoment = moment(this.dynamoLastModified);
        } else {
          lastModifiedMoment = moment.unix(this.dynamoLastModified);
        }
        break;
      default:
        return 'N/A';
    }
    return moment(lastModifiedMoment, dateFormat).calendar();
  }
}
