import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

import AddIcon from '@extensions/utils/AddIcon';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export interface ILinkProps
  extends Omit<MuiLinkProps, 'component'>,
    Omit<RouterLinkProps, keyof MuiLinkProps | 'to'> {
  /** If not given will be rendered as a button */
  to?: RouterLinkProps['to'];
  ignoreTarget?: boolean;
}
class Link extends React.Component<ILinkProps> {
  isAbsoluteUrl = (url: RouterLinkProps['to']): boolean => {
    return typeof url === 'string' ? /^(?:[a-z]+:)?\/\//.test(url) : false;
  };
  isApiUrl = (url: RouterLinkProps['to']): boolean => {
    return typeof url === 'string' ? url.startsWith('/api/') : false;
  };
  isMailTo = (url: RouterLinkProps['to']): boolean => {
    if (typeof url === 'string') {
      return url.startsWith('mailto');
    }
    return false;
  };
  render() {
    const { classes, className, to, ignoreTarget, ...otherProps } = this.props;
    const isInternal = to && !this.isAbsoluteUrl(to) && !this.isApiUrl(to);
    let component:
      | React.ComponentType<RouterLinkProps>
      | 'a'
      | 'button'
      | undefined;
    let href: string | undefined;
    if (to) {
      if (this.isMailTo(to)) {
        component = 'a';
        href = to as string;
      } else if (isInternal) {
        component = RouterLink;
        href = undefined;
      } else {
        component = 'a';
        href = to as string;
      }
    } else {
      component = 'button';
      href = undefined;
    }
    return (
      <>
        <MuiLink
          underline="hover"
          component={component}
          className={className}
          href={href}
          to={to}
          {...otherProps}
        />
        {!Boolean(ignoreTarget) &&
          Boolean(otherProps.target) &&
          otherProps.target === '_blank' && (
            <>
              &nbsp;
              <AddIcon icon={faExternalLinkAlt} size="xs" />
            </>
          )}
      </>
    );
  }
}

export default Link;
