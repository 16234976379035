import { useEffect, useRef } from 'react';
import { FieldProps } from '@rjsf/utils';

// Must be a functional component to work with library due to bad TS types
export default function InvisibleFieldFunction(props: FieldProps) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      const row = ref.current.closest('.MuiGrid-item') as HTMLElement;
      if (row) {
        row.style.display = 'none';
      }
    }
  });
  return <div ref={ref} />;
}
