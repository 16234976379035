import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import User from '@extensions/models/User';

const getInstructions = (user: User, externalSource: string | null): JSX.Element => {
  if (externalSource === null) {
    return <></>;
  }
  switch (externalSource) {
    case 'arm':
      return (
        <Alert severity='warning'>
          <AlertTitle>
            <em>In order to access ARM data, you will need to first:</em>
          </AlertTitle>
          <ol>
            <li>
              <strong>
                <a target="_blank" href="https://adc.arm.gov/armuserreg/#/new" rel="noreferrer">
                  Create an ARM account
                </a>
              </strong>
              &nbsp; using this email address: <code>{user.email}</code>.
            </li>
            <li>
              <strong>
                <a target="_blank" href="https://adc.arm.gov/armlive/register" rel="noreferrer">
                  Initialize your ARM API Access Token
                </a>
              </strong>
              &nbsp; (logging in with the credentials you created in the previous step).
            </li>
            <li>
              Refresh this page.
            </li>
          </ol>
        </Alert>
      );
  }

  return (
    <>Unknown source</>
  );
}

export default getInstructions;
