import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { inject } from 'mobx-react';
import { styled } from '@mui/material/styles';

import {
    ProjectPublications as DapProjectPublications,
} from '@dapclient/components/project-search/ProjectPublications';
import Link from '@dapclient/components/core/Link';

const StyledTableHead = styled(TableHead)(({
    '& th': {
        whiteSpace: 'noWrap'
    }
}));

type TableContainerExtraProps = {
    component: React.ElementType;
};

const StyledTableContainer = styled(TableContainer, {
    shouldForwardProp: (props) => props !== 'isStacked'
})<TableContainerExtraProps & { isStacked: boolean }>(({ isStacked }) => {
    if (isStacked) {
        return {
            maxHeight: '500px',
            marginTop: '24px'
        }
    } else {
        return { maxHeight: '500px' }
    }
});
export class ProjectPublications extends DapProjectPublications {
    renderAdditionalReferences = (isStacked: boolean) => {
        const { tableSize, project } = this.props;
        const size = tableSize ? tableSize : 'medium';
        if (project.references && project.references.length) {
            return (
                <StyledTableContainer component={Paper} isStacked={isStacked}>
                    <Table size={size} stickyHeader>
                        <StyledTableHead>
                            <TableRow>
                                <TableCell>
                                    Reference Title <small>({project.references.length})</small>
                                </TableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                                project.references
                                    .slice()
                                    .map((reference) => {
                                        return (
                                            <TableRow key={reference.referenceURL}>
                                                <TableCell>
                                                    <Link to={reference.referenceURL}>{reference.referenceTitle}</Link>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            );
        } else return null;
    }
}

export default inject((store: any) => ({
    securityService: store.securityService,
    notificationService: store.notificationService,
    membershipService: store.membershipService,
}))(ProjectPublications);
