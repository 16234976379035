import { times } from 'lodash';
import debounce from 'debounce';
import moment, { Moment } from 'moment';
import { useState, useRef, useEffect, useCallback } from 'react';

import {
  ArrowDropDown,
  SettingsEthernet as ZoomPresets,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';
import { Select, Menu, MenuItem, Button } from '@mui/material';

import TimelineSlider from '@extensions/components/dataset/TimelineSlider';

export interface IDataset {
  name: string;
  epochBegins: number;
  epochEnds: number;
  epochUpdated: number;
  fileCount: number;
  totalSize: number;
  fileTypes: string[];
}

export enum ViewValue {
  Size = 'size',
  Count = 'count',
}

export enum ViewStat {
  Consistency = 'consistency',
}

enum ZoomPreset {
  Reset = 'reset',
  LastTwoWeeks = 'twoweeks',
  LastThreeMonths = 'threemonths',
  CurrentMonth = 'thisMonth',
  PreviousMonth = 'prevMonth',
  CurrentYear = 'thisYear',
  PreviousYear = 'prevYear',
}

export interface ITimelineControllerProps {
  beg: Moment;
  end: Moment;
  zoom?: [Moment, Moment];
  viewValue?: ViewValue;
  viewStat?: ViewStat;
  handleZoom: (range: [Moment, Moment]) => void;
  handleViewChange: (value: ViewValue | null, stat: ViewStat | null) => void;
}

const Wrapper = styled('div')`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  z-index: 100;
  background-color: #ccc;
  border-radius: 3px;
  margin: 1rem 0;
  padding: 10px;
`;

const Marker = styled('div')(() => {
  return {
    backgroundColor: theme.palette.timeline.secondary,
    height: '7px',
    cursor: 'pointer',
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > svg': {
      height: '7px',
      width: '7px',
      position: 'relative',
      top: '-7px',
    },
    '& > svg > polygon': {
      fill: 'rgb(0, 169, 224)',
    },
  };
});

const Bar = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: '8pt',
    padding: 0,
    border: '2px solid rgb(0, 169, 224)',
    borderWidth: '2px 0 0 0',
    borderRadius: '0 0 3px 3px',
    '& > .beg, & > .end': {
      backgroundColor: 'rgb(0, 169, 224)',
      padding: '0 8px',
      borderRadius: '0 0 3px 3px',
      color: '#fff',
    },
  };
});

const Controls = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
  margin-bottom: 0;
`;

const ViewSelectWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const Gradient = styled('div')(
  {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',
    '& > .label': {
      whiteSpace: 'nowrap',
    },
    '& > :first-of-type': {
      // transform: 'translateX(-100%)',
      paddingRight: '10px',
    },
    '& > :last-child': {
      // transform: 'translate(100%)',
      paddingLeft: '10px',
    },
    '& > .gradient': {
      height: '15px',
      width: '100%',
      borderRadius: '5px',
      display: 'flex',
      overflow: 'hidden',
      backgroundColor: '#f3f3f3',
    },
  },
  (props) => ({})
);

const Legend = styled('div')`
  display: flex;
  & > * {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    & > :first-of-type {
      border-radius: 5px;
      height: 0.5rem;
      width: 2rem;
      margin-right: 0.25rem;
    }
  }
`;

const StyledMenuItemDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}));

const TimelineController = ({
  beg,
  end,
  zoom,
  viewValue,
  viewStat,
  handleZoom,
  handleViewChange,
}: ITimelineControllerProps) => {
  const [dragStart, setDragStart] = useState(undefined as number | undefined);
  const [dragDays, setDragDays] = useState(0);
  const [barWidth, setBarWidth] = useState(0);
  const [zoomNow, setZoomNow] = useState<[Moment, Moment]>(zoom || [beg, end]);
  const [zoomAnchorEl, setZoomAnchorEl] = useState<null | HTMLElement>(null);

  const initialZoom = useRef(zoomNow);

  useEffect(() => {
    if (zoom !== undefined) {
      setZoomNow(zoom);
    }
  }, [zoom]);

  const barEl = useRef<HTMLDivElement>(null);

  const debouncedZoomHandler = useRef(debounce(handleZoom, 500));

  const updateZoom = useCallback(
    (newZoom: [Moment, Moment], debounce: boolean = false) => {
      setZoomNow(newZoom);
      if (debounce) {
        debouncedZoomHandler.current(newZoom);
      } else {
        handleZoom(newZoom);
      }
    },
    [setZoomNow, debouncedZoomHandler, handleZoom]
  );

  const onMouseUp = useCallback(() => setDragStart(undefined), [setDragStart]);

  const onMouseMove = useCallback(
    (e) => {
      if (dragStart === undefined || barWidth === 0 || dragDays === 0) {
        return;
      }
      const diff = (e.pageX - dragStart) / barWidth;
      const days = Math.round(end.diff(beg, 'days') * diff);
      if (days !== 0) {
        let newZoom = [
          zoomNow[0].clone().add(days, 'days'),
          zoomNow[1].clone().add(days, 'days'),
        ];
        if (newZoom[0].isBefore(beg)) {
          newZoom[0] = beg.clone();
          newZoom[1] = beg.clone().add(dragDays, 'days');
        } else if (newZoom[1].isAfter(end)) {
          newZoom[1] = end.clone();
          newZoom[0] = end.clone().subtract(dragDays, 'days');
        }
        setDragStart(e.pageX);
        updateZoom(newZoom as [Moment, Moment], true);
      }
    },
    [updateZoom, beg, end, dragStart, dragDays, zoomNow, barWidth]
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseUp, onMouseMove]);

  const handleZoomPreset = useCallback(
    (preset) => {
      let d: Moment = moment();
      switch (preset) {
        case ZoomPreset.Reset:
          updateZoom(initialZoom.current || [beg, end]);
          break;
        case ZoomPreset.LastTwoWeeks:
          updateZoom([moment().subtract(14, 'days'), moment()]);
          break;
        case ZoomPreset.LastThreeMonths:
          updateZoom([moment().subtract(90, 'days'), moment()]);
          break;
        case ZoomPreset.CurrentMonth:
          updateZoom([moment().startOf('month'), moment()]);
          break;
        case ZoomPreset.PreviousMonth:
          d = d.clone().startOf('month').subtract(1, 'month');
          updateZoom([d, d.clone().endOf('month')]);
          break;
        case ZoomPreset.CurrentYear:
          updateZoom([moment().startOf('year'), moment()]);
          break;
        case ZoomPreset.PreviousYear:
          d = d.clone().startOf('year').subtract(1, 'year');
          updateZoom([d, d.clone().endOf('year')]);
          break;
      }
      setZoomAnchorEl(null);
    },
    [beg, end, updateZoom]
  );

  const width = (zoomNow[1].diff(zoomNow[0]) / end.diff(beg)) * 100;
  const offset = (zoomNow[0].diff(beg) / end.diff(beg)) * 100;

  return (
    <Wrapper>
      <TimelineSlider
        beg={beg}
        end={end}
        initialZoom={zoomNow}
        handleZoom={(zoom) => updateZoom(zoom, true)}
      />
      <Marker
        style={{
          width: `${Math.min(width, 100)}%`,
          marginLeft: `${Math.max(Math.min(offset, 100), 0)}%`,
        }}
        onMouseDown={(e) => {
          setDragStart(e.pageX);
          setDragDays(Math.round(zoomNow[1].diff(zoomNow[0], 'days')));
          setBarWidth(
            barEl.current === null
              ? 0
              : barEl.current.getBoundingClientRect().width
          );
        }}
      >
        <PointerLeft />
        <PointerRight />
      </Marker>
      <Bar ref={barEl}>
        <span className="beg">{zoomNow[0].format('YYYY MM DD')}</span>
        <Controls>
          <ViewSelectWrapper>
            <Select
              value={
                viewValue !== undefined && viewStat !== undefined
                  ? `${viewValue}-${viewStat}`
                  : ''
              }
              variant="outlined"
              fullWidth
              displayEmpty
              sx={{
                '& .MuiSelect-select': {
                  padding: '5px 32px 4px 10px',
                  fontSize: "80%",
                }
              }}
              onChange={(e) => {
                const value = e.target.value as string;
                if (value === '') {
                  handleViewChange(null, null);
                  return;
                }
                const [view, stat] = value.split('-');
                handleViewChange(view as ViewValue, stat as ViewStat);
              }}
            >
              <MenuItem value="">View &mdash; Daily Availability</MenuItem>
              <MenuItem value="size-consistency">
                View &mdash; Daily File Size Consistency
              </MenuItem>
              <MenuItem value="count-consistency">
                View &mdash; Daily File Count Consistency
              </MenuItem>
            </Select>
            {viewValue === undefined && (
              <Legend>
                <div>
                  <div
                    style={{ backgroundColor: theme.palette.timeline.primary }}
                  ></div>
                  <div>Data</div>
                </div>
                <div>
                  <div
                    style={{
                      backgroundColor: theme.palette.timeline.secondary,
                    }}
                  ></div>
                  <div>Images</div>
                </div>
              </Legend>
            )}
            {viewValue !== undefined && (
              <Gradient>
                <span className="label">Less Variable</span>
                <span className="gradient">
                  {times(10).map((i) => (
                    <span
                      key={i}
                      style={{
                        width: '10%',
                        backgroundColor: theme.palette.timeline
                          .chromaGradientScale((i + 1) / 10)
                          .alpha(0.6)
                          .hex(),
                      }}
                    ></span>
                  ))}
                </span>
                <span className="label">More Variable</span>
              </Gradient>
            )}
          </ViewSelectWrapper>
          <Button
            size="small"
            sx={{
              paddingRight: '0',
              marginLeft: '0.5rem',
              color: (theme) => theme.palette.common.black
            }}
            onClick={(e) => setZoomAnchorEl(e.currentTarget)}
          >
            <ZoomPresets />
            <ArrowDropDown />
          </Button>
          <Menu
            open={Boolean(zoomAnchorEl)}
            anchorEl={zoomAnchorEl}
            onClose={() => setZoomAnchorEl(null)}
          >
            <StyledMenuItemDiv>
              <MenuItem onClick={() => handleZoomPreset(ZoomPreset.Reset)}>
                Zoom &gt; Reset
              </MenuItem>
              <MenuItem onClick={() => handleZoomPreset(ZoomPreset.LastTwoWeeks)}>
                Zoom &gt; Last 14 Days
              </MenuItem>
              <MenuItem
                onClick={() => handleZoomPreset(ZoomPreset.LastThreeMonths)}
              >
                Zoom &gt; Last 90 Days
              </MenuItem>
              <MenuItem onClick={() => handleZoomPreset(ZoomPreset.CurrentMonth)}>
                Zoom &gt; Current Month
              </MenuItem>
              <MenuItem
                onClick={() => handleZoomPreset(ZoomPreset.PreviousMonth)}
              >
                Zoom &gt; Previous Month
              </MenuItem>
              <MenuItem onClick={() => handleZoomPreset(ZoomPreset.CurrentYear)}>
                Zoom &gt; Current Year
              </MenuItem>
              <MenuItem onClick={() => handleZoomPreset(ZoomPreset.PreviousYear)}>
                Zoom &gt; Previous Year
              </MenuItem>
            </StyledMenuItemDiv>
          </Menu>
        </Controls>
        <span className="end">{zoomNow[1].format('YYYY MM DD')}</span>
      </Bar>
    </Wrapper>
  );
};

const PointerLeft = () => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <polygon points="0 0, 100 100, 0 100" />
  </svg>
);

const PointerRight = () => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <polygon points="100 0, 100 100, 0 100" />
  </svg>
);

export default TimelineController;
