import React from 'react';
import { Diff } from 'deep-diff';
import Form from '@rjsf/mui';
import { UiSchema } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';

import SummaryContext, {
  HighlightOption,
  SummaryContextValue,
} from '@extensions/components/metadata/review/SummaryContext';
import { getFileUploadUrls } from '@extensions/utils/metadata';

import HasEmailWidget from '@extensions/components/metadata/review/HasEmailWidget';
import SelectWidget from '@extensions/components/metadata/review/SelectWidget';
import TextareaWidget from '@extensions/components/metadata/review/TextareaWidget';
import CheckboxWidget from '@extensions/components/metadata/review/CheckboxWidget';

import InvisibleField from '@extensions/components/metadata/InvisibleField';
import FileUploadField from '@extensions/components/metadata/review/FileUploadField';
import MeasurementField from '@extensions/components/metadata/review/MeasurementField';

import FieldTemplate from '@extensions/components/metadata/review/FieldTemplate';
import ArrayFieldTemplate from '@extensions/components/metadata/review/ArrayFieldTemplate';
import ObjectFieldTemplate from '@extensions/components/metadata/review/ObjectFieldTemplate';

import validator from '@rjsf/validator-ajv8';
import BaseInputTemplate from '@extensions/components/metadata/review/BaseInputTemplate';
import ExpandableDescriptionTypography from './ExpandableDescriptionTypography';

const StyledRootDiv = styled('div')(({}));

const StyledHeaderDiv = styled('div')(({
  marginBottom: '1.25rem',
}));

export interface ISummaryPanelProps extends IStepParams {
  className?: string;
  dataSchema: JSONSchema7 | undefined;
  uiSchema: UiSchema | undefined;
  data: Record<string, any> | null;
  fileUploadUrls: ReturnType<typeof getFileUploadUrls>;
  changes: Diff<Record<string, any>>[] | undefined;
  kindsToHighlight: HighlightOption[];
  title?: React.ReactNode;
  canPublish: boolean;
}

export interface ISummaryPanelState { }

@observer
export class SummaryPanel extends React.Component<
  ISummaryPanelProps,
  ISummaryPanelState
> {
  getContextValue = (): SummaryContextValue => {
    const { changes, kindsToHighlight } = this.props;
    return {
      changes: changes,
      kindsToHighlight: kindsToHighlight,
    };
  };

  render() {
    const {
      title,
      dataSchema,
      uiSchema,
      data,
      fileUploadUrls,
      canPublish
    } = this.props;
    const adjustedUiSchema = {
      ...uiSchema,
      fileExtensions: {
        'ui:field': 'invisible'
      },
    };
    return (
      <StyledRootDiv>
        {title && <StyledHeaderDiv>{title}</StyledHeaderDiv>}
        <SummaryContext.Provider value={this.getContextValue()}>
          {data && dataSchema && uiSchema && (
            <Form
              validator={validator}
              schema={dataSchema}
              uiSchema={!canPublish ? adjustedUiSchema : uiSchema}
              formData={data}
              widgets={{
                hasemail: HasEmailWidget,
                select: SelectWidget,
                checkbox: CheckboxWidget,
                textarea: TextareaWidget,
                expandableDescription: ExpandableDescriptionTypography,
              }}
              fields={{
                invisible: InvisibleField,
                fileupload: FileUploadField,
                measurement: MeasurementField,
              }}
              templates={{
                FieldTemplate: FieldTemplate,
                ArrayFieldTemplate: ArrayFieldTemplate,
                ObjectFieldTemplate: ObjectFieldTemplate,
                BaseInputTemplate: BaseInputTemplate,
              }}
              formContext={{
                fileUploadUrls,
              }}
            >
              <div />
            </Form>
          )}
        </SummaryContext.Provider>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(SummaryPanel);
