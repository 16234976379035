import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';

import AddIcon from '@extensions/utils/AddIcon';

export interface IButtonOptions {
  isDefault?: boolean;
  value: string;
  buttonProps: ButtonProps;
}

export interface ISplitButtonProps {
  className?: string;
  options: IButtonOptions[];
  onClick: (value: string) => void;
  tooltipTitle?: string;
  styleObject?: any;
}

@observer
class SplitButton extends React.Component<ISplitButtonProps> {
  @observable
  anchorElement: null | HTMLElement = null;

  @action
  setAnchorElement = (newAnchor: null | HTMLElement): void => {
    this.anchorElement = newAnchor;
  };

  @action
  handleDropDownClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setAnchorElement(event.currentTarget);
  };

  @action
  handleMenuClose = (): void => {
    this.setAnchorElement(null);
  };

  getDefaultOption = (): IButtonOptions => {
    const { options } = this.props;
    if (options.length === 0) {
      return {
        value: '',
        buttonProps: {},
      };
    }
    const userSpecifiedDefault = options.find(option => option.isDefault);
    return userSpecifiedDefault || options[0];
  };

  constructor(props: ISplitButtonProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { onClick, options, tooltipTitle, styleObject } = this.props;
    const defaultOption = this.getDefaultOption();
    const {
      children,
      startIcon,
      endIcon,
      ...styleButtonProps
    } = defaultOption.buttonProps;

    const buttons = (
      <Button
        aria-label="Select Button Action"
        onClick={this.handleDropDownClick}
        sx={{
          ...styleObject.root,
          ...styleObject.mainButton,
          ...styleObject.buttonPadding,
          backgroundColor: '#e0e0e0',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          }
        }}
        startIcon={<AddIcon icon={faChevronDown} />}
        {...styleButtonProps}
      >
        Script
      </Button>
    );
    return (
      <>
        {tooltipTitle ? (
          <Tooltip title={tooltipTitle}>{buttons}</Tooltip>
        ) : (
          buttons
        )}
        <Menu
          id="action-menu"
          anchorEl={this.anchorElement}
          keepMounted
          open={Boolean(this.anchorElement)}
          onClose={this.handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {options.map(({ buttonProps, value }) => (
            <MenuItem
              key={value}
              onClick={() => {
                onClick(value);
                this.handleMenuClose();
              }}
            >
              {buttonProps.children}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default SplitButton;
