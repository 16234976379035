import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Typography, Grid, Divider } from '@mui/material';

import {
  DownloadOption,
  reasonCantUseOption,
} from '@extensions/models/FileOrder';
import { OrderStatus } from '@extensions/models/FileOrder';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import ZipOption from '@extensions/components/order/ZipOption';
import ApiOption from '@extensions/components/order/ApiOption';
import FeedbackForm from '@extensions/components/core/FeedbackForm';
import ScriptOption from '@extensions/components/order/ScriptOption';
import FileLinksOption from '@extensions/components/order/FileLinksOption';

const StyledGrid = styled(Grid)(({
  width: '100%',
  '&&': {
    margin: '-20px'
  }
}));

const StyledRoot = styled('div')(({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

const StyledDownloadHeader = styled(Grid)(({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  alignSelf: 'stretch',
  marginTop: theme.spacing(2),
}));

export interface IOrderOptionsProps {
  order: FileOrderGroup;
}

export interface IOrderOptionsState { }

@observer
export class OrderOptions extends React.Component<
  IOrderOptionsProps,
  IOrderOptionsState
> {
  render() {
    const { order } = this.props;
    const orderNotRead = order.status === OrderStatus.PENDING;
    if (orderNotRead) {
      return (
        <StyledRoot>
          <Alert severity="info">
            <AlertTitle>Order Pending</AlertTitle>
            Your order is being retrieved from long term storage. You will be
            emailed when it is ready to download.
          </Alert>
        </StyledRoot>
      );
    }
    return (
      <StyledGrid container spacing={5}>
        <StyledDownloadHeader item xs={12} >
          <Typography variant="h2">
            How would you like to download the data?
          </Typography>
          <StyledDivider
            variant="fullWidth"
          />
        </StyledDownloadHeader>
        <Grid item xs={12} lg={3}>
          <FileLinksOption order={order} />
          {Boolean(reasonCantUseOption(DownloadOption.LINKS, order)) && (
            <Alert severity="info">
              {reasonCantUseOption(DownloadOption.LINKS, order)}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <ZipOption order={order} />
          {Boolean(reasonCantUseOption(DownloadOption.ZIP, order)) && (
            <Alert severity="info">
              {reasonCantUseOption(DownloadOption.ZIP, order)}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <ScriptOption order={order} />
          {Boolean(reasonCantUseOption(DownloadOption.SCRIPT, order)) && (
            <Alert severity="info">
              {reasonCantUseOption(DownloadOption.SCRIPT, order)}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} lg={3}>
          <ApiOption order={order} />
        </Grid>
        <StyledDownloadHeader item xs={12} >
          <Grid>
            <FeedbackForm />
          </Grid>
        </StyledDownloadHeader>
      </StyledGrid>
    );
  }
}

export default inject((store: any) => ({
  // Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(OrderOptions);
