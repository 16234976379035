import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';

import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import { RouteComponentProps } from 'react-router-dom';

import { ICachingService } from '@extensions/services/ICachingService';

import MarkdownLink from '@extensions/components/core/MarkdownLink';
import MarkdownImage from '@extensions/components/core/MarkdownImage';
import { Typography } from '@mui/material';

export interface IStaticPageProps extends RouteComponentProps {
  className?: string;
  cachingService: ICachingService;
}

export interface IStaticPageState { }

@observer
export class StaticPage extends React.Component<
  IStaticPageProps,
  IStaticPageState
> {
  content: string | null = null;
  show404: boolean = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      content: observable,
      show404: observable,
    });
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.fetchContent();
    }
  }

  fetchContent = async () => {
    const { cachingService, location } = this.props;
    try {
      const staticPage = await cachingService.getStaticPage(location.pathname);
      runInAction(() => (this.content = staticPage));
    } catch (error: any) {
      if (error.response.notFound) {
        runInAction(() => (this.show404 = true));
      } else {
        throw error;
      }
    }
  };

  render() {
    let body: React.ReactNode;
    if (this.show404) {
      body = (
        <>
          <Typography variant="h1">Page Not Found</Typography>
          <Typography>Please contact us if you require assistance.</Typography>
        </>
      );
    }
    if (this.content) {
      body = (
        <ReactMarkdown
          children={this.content}
          linkTarget={(url) => (url.startsWith('http') ? '_blank' : '')}
          components={{
            image: MarkdownImage,
            a: MarkdownLink,
            h1: ({...props}) => <Typography variant='h1' sx={{ fontWeight: '500', marginBlockEnd: '11px', fontSize: '1.5em', lineHeight: '1.6' }} {...props} />,
            h2: ({...props}) => <Typography variant='h2' sx={{ fontWeight: '500', fontSize: '1.5em', marginBlockEnd: '0' }} {...props} />,
            p: ({...props}) => <Typography variant='body1' sx={{ marginBlockEnd: '0', color: 'rgb(92, 92, 92)', lineHeight: 1.6 }} {...props}/>,
            ul: ({...props}) => {
              const modifedProps = {...props, ordered: props.ordered.toString()}
              return <Typography variant='body1' component='ul' sx={{ marginBlockStart: '1em', lineHeight: 1.6 }} {...modifedProps} />
            },
            li: ({...props}) => {
              const modifedProps = {...props, ordered: props.ordered.toString()}
              return <Typography variant='body1' component={'li'} sx={{ marginBlockEnd: '0', color: 'rgb(92, 92, 92)', lineHeight: 1.6 }} {...modifedProps} />
            },
          }}
          rehypePlugins={[rehypeRaw]}
        />
      );
    }
    return <div>{body}</div>;
  }
}

export default inject((store: any) => ({
  cachingService: store.cachingService,
}))(StaticPage);
