import React from 'react';

import btnBg from '@extensions/resources/btn_bg.jpg';

import { Link, styled } from '@mui/material';
import theme from '@extensions/services/Theme';

export interface IHeaderMenuItemProps {
  label: string;
  dst: string;
  className?: any;
  menuItemContentMobileStyle?: { fontWeight: number, lineHeight: number };
}
const StyledRootLi = styled('li')(({
  textTransform: 'uppercase',
  display: 'inline-block',
  background: theme.palette.secondary.light,
  marginRight: '1px'
}));

class HeaderMenuItem extends React.Component<IHeaderMenuItemProps> {
  render() {
    const {
      label,
      dst,
      menuItemContentMobileStyle
    } = this.props;
    return (
      <StyledRootLi key={label}>
        <Link
          href={dst}
          sx={{
            fontWeight: menuItemContentMobileStyle && Object.keys(menuItemContentMobileStyle).length !== 0 ? menuItemContentMobileStyle.fontWeight : 700,
            lineHeight: menuItemContentMobileStyle && Object.keys(menuItemContentMobileStyle).length !== 0 ? menuItemContentMobileStyle.lineHeight : 1,
            padding: '0.5rem 1.3rem',
            fontSize: '1.2rem',
            textAlign: 'left',
            width: '100%',
            backgroundImage: btnBg,
            color: theme.palette.common.white,
            display: 'inline-block',
            background: theme.palette.secondary.light,
            textDecoration: 'none',
            '&:hover': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.secondary.main,
              backgroundImage: 'none',
              textDecoration: 'none',
            },
          }}
        >
          {label}
        </Link>
      </StyledRootLi>
    );
  }
}

export default HeaderMenuItem;
