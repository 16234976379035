import { useState } from 'react'
import { Tooltip, CircularProgress } from '@mui/material';
import { Moment } from 'moment'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import {
  faLink,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons'

import { getDownloadUrl } from '@extensions/services/DatasetService'
import { ISecurityService } from '@extensions/services/ISecurityService'
import { formatBytes } from '@extensions/utils/format'
import { useIsMounted } from '@dapclient/hooks/useIsMounted'
import AddIcon from '@extensions/utils/AddIcon'


interface IFileInListProps {
  datasetName: string
  file: IFile
  securityService?: ISecurityService
}

export interface IFile {
  name: string
  date: Moment
  iteration: number
  received: Moment
  signature: string
  size: number
}

const Row = styled.div`
  padding: 3px 5px;
  display: flex;
  justify-content: space-between;
  font-family: monospace;
  font-size: 10pt;
  & .button {
    margin-right: 0.5rem;
  }
  & .button svg {
    transform: scale(0.8);
  }
  & .button.disabled {
    cursor: default;
    color: #aaa;
  }
`

const Hash = styled.div`
  font-family: monospace;
  font-size: 80%;
  color: #aaa;
`

const Error = styled.div`
  font-family: monospace;
  color: red;
  font-size: 6pt;
`

const FileInList = inject('securityService')(observer(
  ({ datasetName, file, securityService }: IFileInListProps) => {

    const isMounted = useIsMounted()

    const [url, setUrl] = useState<string|null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string|null>(null)

    const isLoggedIn = securityService !== undefined && securityService.userIsLoggedIn

    return (
      <Row>
        <div>
          {
            loading && (
              <CircularProgress size={12} style={{ marginRight: '0.5rem'}} />
            )
          }
          {
            isLoggedIn && !loading && (
              <Tooltip title={url === null ? "Generate download link (makes file name clickable)" : "Regenerate download link"}>
                <a href="#nothing" className="button" onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setLoading(true)
                  getDownloadUrl(datasetName, file.name)
                    .then(d => {
                      if (isMounted.current) {
                        setUrl(
                          file.name in d.body.urls
                            ? d.body.urls[file.name]
                            : null
                        )
                      }
                    })
                    .catch((err: any) => {
                      if (isMounted.current) {
                        setError(err.toString())
                      }
                    })
                    .finally(() => {
                      if (isMounted.current) {
                        setLoading(false)
                      }
                    })
                }}>
                  <AddIcon icon={faLink} />
                </a>
              </Tooltip>
            )
          }
          {
            !isLoggedIn && (
              <Tooltip title="Log in to generate download link">
                <span className="button disabled">
                  <AddIcon icon={faUnlink} />
                </span>
              </Tooltip>
            )
          }
          <span style={{ paddingRight: '0.5rem' }}>
            {
              url !== null ? (
                <a href={url} rel="noreferrer" download={file.name}>
                  {file.name}
                </a>
              ) : file.name
            }
          </span>
          {
            url !== null && Boolean(file.signature) && (
              <Hash>
                SHA: {file.signature}
              </Hash>
            )
          }
          {
            error !== null && (
              <Error>{error}</Error>
            )
          }
        </div>
        <div>{formatBytes(file.size)}</div>
      </Row>
    )
  }
))

export default FileInList
