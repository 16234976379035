import React from 'react';
import { inject, observer } from 'mobx-react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { styled } from '@mui/material/styles';

import AddIcon from '@extensions/utils/AddIcon';

const StyledAddIcon = styled(AddIcon)(({
  alignSelf: 'center',
  display: 'inline-block',
  margin: '0 0.25rem',
  color: '#F39C12',
}));

export interface IDiffDotProps {
  className?: string;
}

export interface IDiffDotState { }

@observer
export class DiffDot extends React.Component<
  IDiffDotProps,
  IDiffDotState
> {
  render() {
    return (
      <StyledAddIcon
        icon={faCircle}
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(DiffDot);
