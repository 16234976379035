import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import OptionButton from '@extensions/components/order/OptionButton';
import FileLinksDialog from '@extensions/components/order/FileLinksDialog';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import { DownloadOption } from '@extensions/models/FileOrder';
import AddIcon from '@extensions/utils/AddIcon';

export interface IFileLinksOptionProps {
  className?: string;
  order: FileOrderGroup;
}

@observer
class FileLinksOption extends React.Component<IFileLinksOptionProps> {
  @observable
  showDialog = false;

  @action
  toggleShowDialog = (): void => {
    this.showDialog = !this.showDialog;
  };

  constructor(props: IFileLinksOptionProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { className, order } = this.props;
    return (
      <>
        <OptionButton
          className={clsx(className)}
          startIcon={<AddIcon icon={faLink} />}
          onClick={this.toggleShowDialog}
          order={order}
          option={DownloadOption.LINKS}
        >
          File Links
        </OptionButton>
        <FileLinksDialog
          open={this.showDialog}
          onClose={this.toggleShowDialog}
          order={order}
        />
      </>
    );
  }
}

export default (FileLinksOption);
