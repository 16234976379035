import React from 'react';
import { observer } from 'mobx-react';
import { Button, Tooltip } from '@mui/material';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';
import AddIcon from '@extensions/utils/AddIcon';

const StyledButton = styled(Button)(({
  width: '100%',
  height: '50.25px',
  paddingTop: '.75rem',
  paddingBottom: '.75rem',
  backgroundColor: '#e0e0e0 !important',
  color: 'black !important',
}));

@observer
class ApiOption extends React.Component {
  openChatInNewTab = () => {
    window.open('https://github.com/DAP-platform/dap-py/blob/master/docs/doe_dap_dl.md', '_blank');
  };

  render() {
    const button = (
      <Tooltip title={'Use API to download data'}>
        <StyledButton
          onClick={this.openChatInNewTab}
          size="large"
          variant="contained"
          startIcon={<AddIcon icon={faPlug} />}
        >  
          API
        </StyledButton>
      </Tooltip>
    );
    return button;
  }
}

export default (ApiOption);
