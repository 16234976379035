import { ArrayFieldTemplateProps } from '@rjsf/utils';
import BaseArrayFieldTemplate from '@extensions/components/metadata/review/BaseArrayFieldTemplate';

export default function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  const augmentedProps = {
    ...props,
    canAdd: false,
    items:
      props.items &&
      props.items.map((item) => ({ ...item, hasToolbar: false })),
  };
  return <BaseArrayFieldTemplate {...augmentedProps} />;
}
