import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Typography, TextField } from '@mui/material';
import { observable, action, makeObservable } from 'mobx';

import { IMetadataService } from '@extensions/services/IMetadataService';

import MetadataDest from '@extensions/models/MetadataDest';
import VirtualizedAutocomplete from '@extensions/components/core/VirtualizedAutocomplete';

const StyledRootDiv = styled('div')(({}));

const StyledDraftText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  paddingLeft: '5px'
}));

export interface IDestSearchSelectProps {
  className?: string;
  options: MetadataDest[];
  initialDataset: string | null;
  // New dataset name
  onChange: (newVal: string | null) => void;
  metadataService?: IMetadataService;
}

export interface IDestSearchSelectState { }

@observer
export class DestSearchSelect extends React.Component<
  IDestSearchSelectProps,
  IDestSearchSelectState
> {
  @observable
  selectedDest: MetadataDest | null;

  constructor(props) {
    super(props);
    makeObservable(this);
    const { initialDataset } = props;
    this.selectedDest = initialDataset
      ? ({
        // Because I'm desperate
        datasetName: initialDataset,
      } as unknown as MetadataDest)
      : null;
  }

  @action
  handleDestChange = (event: any, newValue: MetadataDest | null) => {
    this.selectedDest = newValue;
    this.props.onChange(this.selectedDest?.datasetName || null);
  };

  renderOption = (props, option) => {
    const { metadataService } = this.props;
    if (!metadataService) {
      return null;
    }
    let draftText = <></>;
    if (option.hasDocument) {
      const draft = metadataService.getDocumentByDataset(option.datasetName);
      if (draft) {
        draftText = (
          <StyledDraftText
            variant="subtitle2"
            component='span'
          >
            (Draft created by {draft.author.firstName} {draft.author.lastName})
          </StyledDraftText>
        );
      }
    }
    return (
      <div >
        <Typography {...props} key={option.datasetName}>{option.datasetName}{draftText}</Typography>
      </div>
    );
  };

  render() {
    const { options } = this.props;
    return (
      <StyledRootDiv>
        <VirtualizedAutocomplete
          id="dataset-selector"
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Dataset Name"
              placeholder="Search Datasets"
            />
          )}
          renderOption={this.renderOption}
          getOptionLabel={(option) => option.datasetName}
          onChange={this.handleDestChange}
          value={this.selectedDest}
          // The value in this function might be a vanilla JS object
          // which just contains the dataset name... remember I'm desperate
          isOptionEqualToValue={(option, value) =>
            option.datasetName === value.datasetName
          }
        />
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
}))(DestSearchSelect);
