import { ReactNode } from 'react';
import { inject } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { TableCell, TableRow } from '@mui/material';

import { IHistoryService } from '@extensions/services/IHistoryService';

import {
  DataOrders as DapDataOrders,
} from '@dapclient/components/user/DataOrders';
import Link from '@extensions/components/core/Link';
import FileOrder from '@extensions/models/FileOrder';

const StyledHiddenDiv = styled('div')(({
  width: 0,
  height: 0,
  display: 'block',
  overflow: 'hidden',
}));

const StyledHiddenLink = styled(Link)(({
  width: 0,
  height: 0,
  display: 'block',
  overflow: 'hidden',
}));

const StyledHiddenCell = styled(TableCell)(({
  '&&': {
    padding: 0,
    display: 'none',
  },
}));

const StyledRow = styled(TableRow)(({
  cursor: 'pointer',
}));

export interface A2eDataOrdersProps {
  historyService?: IHistoryService;
}

export class DataOrders extends DapDataOrders<A2eDataOrdersProps> {
  getBodyRow: (order: FileOrder) => ReactNode = (order) => {
    const { historyService } = this.props;
    const detailsUrl = `/profile/orders/${order.id}`;
    return (
      <StyledRow
        key={order.id}
        hover={true}
        onClick={() =>
          historyService && historyService.history.push(detailsUrl)
        }
      >
        <TableCell component="th" scope="row">
          {order.datasetName}
        </TableCell>
        <TableCell>{this.getFileCountContent(order)}</TableCell>
        <TableCell>{this.getTotalSizeContent(order)}</TableCell>
        <TableCell>{this.getCreatedContent(order)}</TableCell>
        <StyledHiddenCell>
          <StyledHiddenLink to={`/profile/orders/${order.id}`} >
            Details
          </StyledHiddenLink>
        </StyledHiddenCell>
      </StyledRow>
    );
  };
  getHeaderRow: () => ReactNode = () => {
    return (
      <TableRow>
        <TableCell>Dataset</TableCell>
        <TableCell>File Count</TableCell>
        <TableCell>Total Size</TableCell>
        <TableCell>Created</TableCell>
        <StyledHiddenCell>
          <StyledHiddenDiv >Links</StyledHiddenDiv>
        </StyledHiddenCell>
      </TableRow>
    );
  };
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  historyService: store.historyService,
}))(DataOrders);
