import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import {
  MultiList,
  SEARCH_BAR_REACTIVE_ID,
  FilterSectionHeader
} from '@extensions/components/search-core';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';
import DateFilter from '@extensions/components/publications/DateFilter';
import { ISecurityService } from '@extensions/services/ISecurityService';

const StyledMultiList = styled(MultiList)(({theme}) => ({
    margin: '1rem 0',
    wordBreak: 'break-all',
}));

const StyledDateFilter = styled(DateFilter)(({theme}) => ({
    margin: '1rem 0',
    wordBreak: 'break-all',
}));

export interface ISearchFiltersProps {
  className?: string;
  securityService?: ISecurityService;
}

export interface ISearchFiltersState {}

const STATUS_ID = 'STATUS';
const PROJECT_ID = 'PROJECT';
const DATASET_ID = 'DATASET';
const DATE_ID = 'DATE';
const TYPE_ID = 'TYPE';
const KEYWORD_ID = 'KEYWORDS';
const AUTHOR_ID = 'AUTHORS';

export const FILTER_REACTIVE_IDS = [
  STATUS_ID,
  PROJECT_ID,
  DATE_ID,
  TYPE_ID,
  KEYWORD_ID,
  AUTHOR_ID,
  SEARCH_BAR_REACTIVE_ID,
  DATASET_ID,
];

const allReactiveIdsExcept = getAllReactiveIdsExcept([
  ...FILTER_REACTIVE_IDS,
  SEARCH_BAR_REACTIVE_ID,
]);

@observer
export class SearchFilters extends React.Component<
  ISearchFiltersProps,
  ISearchFiltersState
> {
  render() {
    const { securityService } = this.props;
    const isAdminMode = securityService?.user?.canAdminPubs;
    return (
      <div>
        <Typography
          variant="h6"
          component="h2"
          color="textPrimary"
          sx={{
            borderBottom: (theme)=> `1px solid ${theme.palette.grey['400']}`,
            marginBottom: (theme) => theme.spacing(3)
          }}
        >
          FILTERS
        </Typography>
        <FilterSectionHeader variant="h5" component="h3">
          Publication Criteria
        </FilterSectionHeader>
        <StyledMultiList
          // dataField="prettyPublicationType.keyword" // Todo - This breaks A2e Publication Type filter since A2e does not have 'type' attribute, it uses 'prettyPublicationType'
          dataField="referenceTypePretty.keyword"
          componentId={TYPE_ID}
          title="Publication Type"
          react={{
            and: allReactiveIdsExcept(TYPE_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Type"
        />
        {isAdminMode && (
          <StyledMultiList
            dataField="status.keyword"
            componentId={STATUS_ID}
            title="Status"
            react={{
              and: allReactiveIdsExcept(STATUS_ID),
            }}
            maxLabelWidth="none"
            URLParams
            filterLabel="Status"
          />
        )}
        <StyledMultiList
          dataField="projects.keyword"
          componentId={PROJECT_ID}
          title="Project"
          react={{
            and: allReactiveIdsExcept(PROJECT_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Project"
        />
        <StyledMultiList
          dataField="datasets.keyword"
          componentId={DATASET_ID}
          title="Dataset"
          react={{
            and: allReactiveIdsExcept(DATASET_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Dataset"
        />
        <StyledDateFilter
          componentId={DATE_ID}
          react={{
            and: allReactiveIdsExcept(DATE_ID),
          }}
          dataField="publicationDate"
          URLParams
        />
        <MultiList
          dataField="keywords.keyword"
          componentId={KEYWORD_ID}
          title="Keyword"
          react={{
            and: allReactiveIdsExcept(KEYWORD_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Keyword"
        />
        <StyledMultiList
          dataField="authorNames.keyword"
          componentId={AUTHOR_ID}
          title="Author"
          react={{
            and: allReactiveIdsExcept(AUTHOR_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Author"
        />
      </div>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
}))(SearchFilters);