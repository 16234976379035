import { inject } from 'mobx-react';
import {
  Dataset as DapDataset,
} from '@dapclient/components/page/Dataset';

export class Dataset extends DapDataset {
  getBrowseDataButton = () => null;
  renderDataOrderModal = () => null;
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  notificationService: store.notificationService,
  historyService: store.historyService,
  projectService: store.projectService,
}))(Dataset);
