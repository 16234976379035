import React from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import get from 'lodash/get';
import { react } from '@appbaseio/reactivesearch/lib/types';
import isEqual from 'lodash/isEqual';
import { CheckboxGrid } from '@dapclient/components/search-core';
import FilterTitle from '@extensions/components/search-core/FilterTitle';

const NETCDF_LABEL = 'Standardized NetCDF';

type Bucket = {
  key: any;
  key_as_string: string;
  doc_count: number;
};

interface IStandardizationCheckboxProps {
  checked: boolean;
  setQuery: ({ value, query }: { value: string | null; query: any }) => void;
  numStandardized: number;
  hidden?: boolean;
}

class StandardizationCheckbox extends React.Component<IStandardizationCheckboxProps> {
  componentDidMount() {
    if (this.props.checked) {
      this.setCheckedQuery();
    }
  }
  componentDidUpdate(prevProps: IStandardizationCheckboxProps) {
    if (!isEqual(prevProps.checked, this.props.checked)) {
      this.setStandardization(this.props.checked);
    }
  }

  setCheckedQuery = () => {
    this.props.setQuery({
      query: {
        term: { isStandardized: true },
      },
      value: NETCDF_LABEL,
    });
  };

  setUncheckedQuery = () => {
    this.props.setQuery({
      query: {
        match_all: {},
      },
      value: null,
    });
  };

  setStandardization = (newChecked: boolean) => {
    if (newChecked) {
      this.setCheckedQuery();
    } else {
      this.setUncheckedQuery();
    }
  };

  render() {
    const { checked, numStandardized, hidden } = this.props;
    if (hidden) {
      return null;
    }

    return (
      <CheckboxGrid
        data={[{ key: NETCDF_LABEL, doc_count: `${numStandardized}` }]}
        value={{ [NETCDF_LABEL]: checked }}
        handleChange={() => this.setStandardization(!checked)}
        checkboxColumnHeader="Type"
        labeledBy="standardization-title"
      />
    );
  }
}

export const STANDARDIZATION_REACTIVE_ID = 'StandardizationFilter';

export default class StandardizationFilter extends React.Component<{
  react?: react;
}> {
  render = () => {
    return (
      <ReactiveComponent
        componentId={STANDARDIZATION_REACTIVE_ID}
        showFilter={true}
        filterLabel="Standardization"
        defaultQuery={() => ({
          size: 0,
          aggs: {
            isStandardized: {
              terms: {
                field: 'isStandardized',
                size: 100,
              },
            },
          },
        })}
        URLParams={true}
        react={this.props.react}
        render={({ loading, error, aggregations, setQuery, value }) => {
          const buckets: Bucket[] = get(
            aggregations,
            'isStandardized.buckets',
            []
          );

          let numNetcdf = 0;
          if (buckets.length > 0) {
            const netcdfBucket = buckets.find((bucket) => bucket.key === 1);
            if (netcdfBucket) {
              numNetcdf = netcdfBucket.doc_count;
            }
          }

          const hidden = loading || error || numNetcdf === 0;
          return (
            <div className="data-filter">
              <FilterTitle hidden={hidden} id="standardization-title">
                Standardization
              </FilterTitle>
              <StandardizationCheckbox
                hidden={hidden}
                checked={!!value}
                numStandardized={numNetcdf}
                setQuery={setQuery}
              />
            </div>
          );
        }}
      />
    );
  };
}
