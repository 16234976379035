import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';

export const RECOMMENDED_REACTIVE_ID = 'RecommendedFilter';

export default class RecommendedFilter extends React.Component<{ react?: react }> {

  render = () => {
    return (
      <MultiList
        className="data-filter"
        componentId={RECOMMENDED_REACTIVE_ID}
        dataField="generalUseReady"
        title="Preferred for General Use"
        filterLabel="Preferred"
        showSearch={false}
        react={this.props.react}
        URLParams={true}
        transformData={(data: { key; doc_count }[]) => {
          return data.filter((d) => d.key === 1).map(({ key, doc_count }) => {
            const prettyKey = {
              1: 'Yes',
            }[key];
            return {
              key: prettyKey,
              doc_count,
            };
          })
        }
        }
        customQuery={function (yesNoValue, props) {
          if (!yesNoValue) {
            return {};
          }

          const trueFalseValueArr = yesNoValue.map((v) => {
            if (v === 'Yes') {
              return true;
            }
            return {};
          })

          if (trueFalseValueArr && trueFalseValueArr.length > 0) {
            return {
              query: {
                terms: {
                  generalUseReady: trueFalseValueArr,
                },
              },

            };
          } else {
            return {
              query: {
                match_all: {}
              }
            }
          }
        }}
      />
    );
  };
}
