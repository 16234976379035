import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import React from 'react';
import filesize from 'filesize';
import { Moment } from 'moment';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import { faTrash, faLock } from '@fortawesome/free-solid-svg-icons';

import theme from '@extensions/services/Theme';
import { ICartService, OrderItem } from '@extensions/services/ICartService';

import AddIcon from '@extensions/utils/AddIcon';
import Link from '@extensions/components/core/Link';
import FileOrder from '@extensions/models/FileOrder';
import QualityWarning from '../dataset/QualityWarning';
import { DatasetStats } from '@extensions/models/Dataset';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledRootDiv = styled('div')(({}));

const StyledActionHeaderDiv = styled('div')(({
  color: 'transparent',
  height: 0,
  width: 0,
  overflow: 'hidden',
}));

const StyledTotalWrapperDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  marginTop: theme.spacing(1),
  '& .MuiTypography-root': {
    marginBottom: '2rem',
  },
}));

const StyledAddIcon = styled(AddIcon)(({
  marginLeft: '2.5px',
  marginRight: '2.5px',
  color: theme.palette.error.dark,
}));

export interface IOrderSummaryProps {
  className?: string;
  cartService?: ICartService;
}

const formatDate = (startDate: Moment): string => {
  return startDate.format('YYYY MM DD');
};

@inject('cartService')
@observer
class OrderSummary extends React.Component<IOrderSummaryProps> {
  getColumns = (): {
    statsKey: keyof DatasetStats;
    align: 'right' | 'left';
    defaultDisplayValue?: string;
    format: (value: any) => React.ReactNode;
    getStyleObject?: (item: OrderItem, value: any) => object;
    heading: string;
  }[] => [
      {
        statsKey: 'fileCount',
        align: 'right',
        heading: 'File Count',
        format: (count: number) => {
          return count.toLocaleString();
        },
      },
      {
        statsKey: 'byteCount',
        align: 'right',
        heading: 'Total Size',
        format: (byteCount: number) => {
          const prettyCount = filesize(byteCount, { round: 0 });
          if (byteCount > FileOrder.maxSizeWithoutApproval) {
            return (
              <span>
                {prettyCount}
                <sup>*</sup>
              </span>
            );
          }
          return prettyCount;
        },
        getStyleObject: (item: OrderItem, value: number) =>
          value > FileOrder.maxSizeWithoutApproval
            ? { color: theme.palette.error.main }
            : {}
      },
      {
        statsKey: 'startDate',
        align: 'right',
        heading: 'Start Date',
        format: formatDate,
        getStyleObject: (item: OrderItem, value: any) => {
          return item.dateRange ? { color: '#aaa' } : {};
        },
      },
      {
        statsKey: 'endDate',
        align: 'right',
        heading: 'End Date',
        format: formatDate,
        getStyleObject: (item: OrderItem, value: any) => {
          return item.dateRange ? { color: '#aaa' } : {};
        },
      },
    ];

  render() {
    const { cartService } = this.props;
    if (!cartService || !cartService.currentStats) {
      return <CenteredCircularProgress />;
    }

    if (cartService.orderItems.length === 0) {
      return (
        <Typography>
          No datasets in cart. Add datasets from the{' '}
          <Link to="/data">search page</Link> or view{' '}
          <Link to="/profile/orders">previous orders</Link>.
        </Typography>
      );
    }
    const datasetByName = cartService.datasetsByName;
    const tableColumns = this.getColumns();
    return (
      <StyledRootDiv>
        <TableContainer component={Paper}>
          <Table aria-label="datasets being ordered">
            <TableHead>
              <TableRow>
                <TableCell>Dataset</TableCell>
                {tableColumns.map((col) => (
                  <TableCell
                    sx={{ whiteSpace: 'nowrap' }}
                    key={col.statsKey}
                    align={col.align}
                  >
                    {col.heading}
                  </TableCell>
                ))}
                <TableCell>
                  <StyledActionHeaderDiv>Actions</StyledActionHeaderDiv>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartService.orderItems.map((item) => (
                <TableRow key={cartService.buildOrderItemKey(item)}>
                  <TableCell sx={{ wordBreak: 'break-word' }} component="th" scope="row">
                    <Link to={`/ds/${item.datasetName}`} title={datasetByName[item.datasetName].title}>
                      {item.datasetName}
                    </Link>
                    {(datasetByName[item.datasetName].events || []).some(e => e.affectsDataQuality) &&
                      <QualityWarning datasetName={item.datasetName} />
                    }
                    {datasetByName[item.datasetName].isMfaRestricted &&
                      <StyledAddIcon
                        icon={faLock}
                        title="Data is [MFA] restricted"
                      />}
                  </TableCell>
                  {tableColumns.map((col) => {
                    const stats =
                      cartService.currentStats &&
                      cartService.currentStats.get(
                        cartService.buildOrderItemKey(item)
                      );
                    const value = stats ? stats[col.statsKey] : undefined;
                    const styleObject = col.getStyleObject ? col.getStyleObject(item, value) : {};
                    let displayValue: React.ReactNode = '—';
                    if (value !== undefined && value !== null) {
                      displayValue = col.format(value);
                    } else if (col.defaultDisplayValue) {
                      displayValue = col.defaultDisplayValue;
                    }
                    return (
                      <TableCell
                        key={col.statsKey}
                        align={col.align}
                        sx={{
                          ...styleObject,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {displayValue}
                      </TableCell>
                    );
                  })}
                  <TableCell align={'right'}>
                    <Tooltip
                      aria-label="remove from cart"
                      title="Remove from cart"
                    >
                      <IconButton
                        size="small"
                        onClick={() =>
                          cartService.removeFromCart(
                            item.datasetName,
                            item.dateRange
                              ? [
                                item.dateRange.startDate,
                                item.dateRange.endDate,
                              ]
                              : undefined
                          )
                        }
                      >
                        <AddIcon icon={faTrash} size="xs" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledTotalWrapperDiv>
          {cartService.unusuallyLarge && (
            <Typography variant="body1"
              sx={{
                color: theme.palette.error.main,
                marginRight: 'auto'
              }}
            >
              <sup>*</sup> Unusually large volume of data
            </Typography>
          )}
          {cartService.total && cartService.orderItems.length > 0 && (
            <Typography variant="body1" sx={{
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.grey[700],
              }}>
              Total: {filesize(cartService.total.byteCount)} and{' '}
              {cartService.total.fileCount} files
            </Typography>
          )}
        </StyledTotalWrapperDiv>
      </StyledRootDiv>
    );
  }
}

export default OrderSummary;
