import React from 'react';
import { inject, observer } from 'mobx-react';
import ReactMarkdown, { Options } from 'react-markdown';

import MarkdownLink from '@extensions/components/core/MarkdownLink';
import MarkdownImage from '@extensions/components/core/MarkdownImage';

export interface IMarkdownTextProps extends Omit<Options, 'children'> {
  className?: string;
  rawMarkdown: string;
}

export interface IMarkdownTextState { }

@observer
export class MarkdownText extends React.Component<
  IMarkdownTextProps,
  IMarkdownTextState
> {
  render() {
    const {
      rawMarkdown,
      components,
      linkTarget,
      ...otherProps
    } = this.props;
    const defaultComponents = { img: MarkdownImage, a: MarkdownLink };
    const defaultLinkTarget = (url) => (url.startsWith('http') ? '_blank' : '');
    return (
      <ReactMarkdown
        {...otherProps}
        children={rawMarkdown}
        linkTarget={linkTarget ?? defaultLinkTarget}
        components={{ ...defaultComponents, ...components }}
      />
    );
  }
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(MarkdownText);
