import React from 'react';
import moment from 'moment';
import { inject } from 'mobx-react';

import {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

import Link from '@extensions/components/core/Link';
import DatasetModel from '@extensions/models/Dataset';
import { formatBytes } from '@extensions/utils/format';
import AccessLevel from '@extensions/models/AccessLevel';
import MarkdownText from '@extensions/components/core/MarkdownText';
import CitationButton from '@extensions/components/core/CitationButton';
import DimensionsList from '@extensions/components/dataset/DimensionsList';
import MeasurementsList from '@extensions/components/dataset/MeasurementsList';
import TimelineContainerSingle from '@extensions/components/dataset/TimelineContainerSingle';
import { DatasetSummary as DapDatasetSummary } from '@dapclient/components/dataset/DatasetSummary';

const StyledTable = styled(Table)(() => ({
  '& td:first-of-type': {
    textAlign: 'right',
    color: theme.palette.grey[500],
  },
}));

export class DatasetSummary extends DapDatasetSummary<
  typeof StyledTable
> {
  getDataSummaryPanel(dataset: DatasetModel | null): React.ReactNode {
    if (!dataset) {
      return null;
    }
    const body = (
      <TableContainer>
        <StyledTable size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                DOI
              </TableCell>
              <TableCell>
                <code>
                  {dataset.doi || 'N/A'}
                </code>
              </TableCell>
            </TableRow>
            {dataset.accessLevel === AccessLevel.Public && (
              <TableRow>
                <TableCell>
                  License
                </TableCell>
                <TableCell>
                  <Link to="/license">CC0 Public DomLinkin Dedication</Link>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                File Type(s)
              </TableCell>
              <TableCell>
                <code>
                  {dataset.dynamoFileTypes && dataset.dynamoFileTypes.length > 0
                    ? dataset.dynamoFileTypes.join(', ')
                    : 'N/A'}
                </code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                File Count
              </TableCell>
              <TableCell>
                <code>
                  {dataset.dynamoFileCount
                    ? dataset.dynamoFileCount?.toLocaleString()
                    : 'N/A'}
                </code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Total Size
              </TableCell>
              <TableCell>
                <code>
                  {dataset.dynamoTotalFileSize
                    ? formatBytes(dataset.dynamoTotalFileSize)
                    : 'N/A'}
                </code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Start Date
              </TableCell>
              <TableCell>
                <code>
                  {dataset.dynamoDataBegins
                    ? moment(dataset.dynamoDataBegins).format('YYYY-MM-DD')
                    : 'N/A'}
                </code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                End Date
              </TableCell>
              <TableCell>
                <code>
                  {dataset.dynamoDataEnds
                    ? moment(dataset.dynamoDataEnds).format('YYYY-MM-DD')
                    : 'N/A'}
                </code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Time Zone
              </TableCell>
              <TableCell>
                <code>
                  {dataset.timezoneOffset
                    ? (dataset.timezoneOffset > 0) ? `UTC+${dataset.timezoneOffset}` : `UTC${dataset.timezoneOffset}`
                    : "UTC+0"}
                </code>
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </TableContainer>
    );
    return this.getPanel({
      ariaControls: 'data-summary-content',
      id: 'data-summary',
      title: 'Data Summary',
      body,
    });
  }

  getDimensionsPanel(dataset: DatasetModel | null): React.ReactNode {
    if (!dataset?.dimensions) {
      return null;
    }

    const body = <DimensionsList dimensions={dataset.dimensions} />;
    return this.getPanel({
      ariaControls: 'dimensions-list-content',
      id: 'dimensions-list',
      title: `Dimensions (${dataset.dimensions.length})`,
      body,
    });
  }

  getMeasurementsPanel(dataset: DatasetModel | null): React.ReactNode {
    if (!dataset?.measurements) {
      return null;
    }

    const body = <MeasurementsList measurements={dataset.measurements} />;
    return this.getPanel({
      ariaControls: 'measurements-list-content',
      id: 'measurements-list',
      title: `Measurements (${dataset.measurements.length})`,
      body,
    });
  }

  getUncertaintyPanel(dataset: DatasetModel | null): React.ReactNode {
    if (!dataset?.uncertaintyDescription) {
      return null;
    }

    const body =
      <MarkdownText
        rawMarkdown={dataset.uncertaintyDescription}
        components={{
          p: ({...props}) => <Typography variant='body1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', color: '#000', fontSize: '14px' }} {...props} />,
        }}
      />;
    return this.getPanel({
      ariaControls: 'uncertainty-description-content',
      id: 'uncertainty-description',
      title: 'Uncertainty',
      body,
    });
  }

  getConstraintsPanel(dataset: DatasetModel | null): React.ReactNode {
    if (!dataset?.constraintsDescription) {
      return null;
    }

    const body =
      <MarkdownText
        rawMarkdown={dataset.constraintsDescription}
        components={{
          p: ({...props}) => <Typography variant='body1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', color: '#000', fontSize: '14px' }} {...props} />,
        }}
      />;
    return this.getPanel({
      ariaControls: 'constraints-description-content',
      id: 'constraints-description',
      title: 'Constraints',
      body,
    });
  }

  getQualityDescriptionPanel(dataset: DatasetModel | null): React.ReactNode {
    if (!dataset?.dataQualityDescription) {
      return null;
    }

    const body =
      <MarkdownText
        rawMarkdown={dataset.dataQualityDescription}
        components={{
          p: ({...props}) => <Typography variant='body1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', color: '#000', fontSize: '14px' }} {...props} />,
        }}
      />;
    return this.getPanel({
      ariaControls: 'data-quality-description-content',
      id: 'data-quality-description',
      title: 'Data Quality',
      body,
    });
  }

  getDataDetailsPanel(dataset: DatasetModel | null): React.ReactNode {
    if (!dataset?.dataDetails) {
      return null;
    }

    const body =
      <MarkdownText
        rawMarkdown={dataset.dataDetails}
        components={{
          p: ({...props}) => <Typography variant='body1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', color: '#000', fontSize: '14px' }} {...props} />,
        }}
      />;
    return this.getPanel({
      ariaControls: 'data-details-content',
      id: 'data-details',
      title: 'Details',
      body,
    });
  }

  getCitationButton(dataset: DatasetModel | null) {
    if (dataset && this.props.securityService.userIsLoggedIn) {
      return (
        <CitationButton
          citation={dataset.getCitationWithAccessDate(moment())}
        />
      );
    }
    return null;
  }

  getAdditionalPanels(dataset: DatasetModel | null): React.ReactNode {
    return (
      <>
        {this.getDataDetailsPanel(dataset)}
        {this.getQualityDescriptionPanel(dataset)}
        {this.getConstraintsPanel(dataset)}
        {this.getUncertaintyPanel(dataset)}
        {this.getMeasurementsPanel(dataset)}
        {this.getDimensionsPanel(dataset)}
        {this.getDataSummaryPanel(dataset)}
      </>
    );
  }

  getTimelines(dataset: DatasetModel | null): React.ReactNode {
    if (dataset?.dynamoFileCount && dataset?.dynamoDataBegins) {
      return (
        <div style={{ marginTop: '2rem' }}>
          <TimelineContainerSingle dataset={dataset} />
        </div>
      );
    }
    return null;
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  membershipService: store.membershipService,
  notificationService: store.notificationService,
  projectService: store.projectService,
  metricsService: store.metricsService,
}))(DatasetSummary);
