import { WidgetProps } from '@rjsf/utils';
import TextWidget from '@extensions/components/metadata/review/TextWidget';
import { DEFAULT_VALUE } from '@extensions/components/metadata/review/utils';

// @rjsf/* doesn't like class components anymore, need to use functional components to avoid errors.
export default function SelectWidget(props: WidgetProps) {
  const getLabelForValue = (value: any): string => {
    const {
      options: { enumOptions },
    } = props;
    const matchingEntry = (enumOptions as any[]).find(
      ({ value: optionValue }) => optionValue === value
    );
    if (matchingEntry === undefined || matchingEntry === null) {
      return DEFAULT_VALUE
    }
    return matchingEntry.label
  };

  const getDisplayValue = (): string => {
    const { value: rawValue, multiple } = props;

    if (multiple && Array.isArray(rawValue)) {
      return rawValue.map(val => getLabelForValue(val)).join(', ')
    }
    return getLabelForValue(rawValue)
  };
  return (
    <div>
      <TextWidget {...props} value={getDisplayValue()} />
    </div>
  )
}

