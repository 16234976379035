import React from 'react';
import { FieldProps } from '@rjsf/utils';
import { Box, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';

import MetadataService from '@extensions/services/MetadataService';

import Measurement from '@extensions/models/Measurement';
import Label from '@extensions/components/metadata/review/Label';
import { DEFAULT_VALUE } from '@extensions/components/metadata/review/utils';

export interface IMeasurementFieldProps extends FieldProps<Measurement> {
  className?: string;
  metadataService?: MetadataService;
}

export interface IMeasurementFieldState { }

@observer
export class MeasurementField extends React.Component<
  IMeasurementFieldProps,
  IMeasurementFieldState
> {
  getId = (field: keyof Measurement) =>
    `${this.props.idSchema['$id']}-${field}`;
  getChangeHandler = (
    field: keyof Measurement,
    getValFromArgs: (...args) => any = (event) => event.target.value
  ) => {
    const { formData, onChange } = this.props;
    return (...args) => {
      if (formData) {
        onChange({ ...formData, [field]: getValFromArgs(...args) })
      }
    }
  };
  render() {
    const { formData, metadataService } = this.props;
    if (!metadataService || !metadataService.loaded) {
      return null;
    }
    return (
      <section>
        <Box sx={{ width: '100%', maxWidth: '600px', overflow: 'hidden' }}>
          <Label sx={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }} typographyProps={{ variant: 'h6' }}>
            <Box sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              {formData && formData.specificName} ({formData && formData.unit})
            </Box>
          </Label>
        </Box>
        <Box sx={{ width: '100%', maxWidth: '600px', overflow: 'hidden' }}>
          <Typography sx={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>{formData && formData.description}</Typography>
        </Box>

        <Label>Primary Measurement</Label>
        <Typography>{(formData && formData.isPrimary) ? 'Yes' : 'No'}</Typography>

        <Label>Standard Name</Label>
        <Typography>
          {(formData && formData.standardName)
            ? formData.standardName.displayName
            : DEFAULT_VALUE}
        </Typography>

        <Label>Standard Unit</Label>
        <Typography>
          {(formData && formData.standardUnit)
            ? formData.standardUnit.displayName
            : DEFAULT_VALUE}
        </Typography>
      </section>
    );
  }
}

const Wrapped = inject((store: any) => ({
  metadataService: store.metadataService,
}))(MeasurementField);

export default function MeasurementFieldFunc(props: FieldProps) {
  return <Wrapped {...props} />;
}
