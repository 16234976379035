import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material';
import { Typography, Stepper, Step, StepLabel } from '@mui/material';

import theme from '@extensions/services/Theme';

import EditorStep from '@extensions/models/EditorStep';

const StyledTitleBarDiv = styled('div')(({
  backgroundColor: '#f8f8f8',
  padding: '1rem 2.5rem',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

const StyledSubLabelDiv = styled('div')(({
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.text.primary,
  marginTop: '0.5rem',
  maxWidth: '100%',
  overflowX: 'auto',
  textTransform: 'initial',
  letterSpacing: 'normal',
}));

export interface IProgressTrackerProps {
  className?: string;
  steps: EditorStep[];
  activeStep: number;
  setActiveStep: (index: number) => void;
}

export interface IProgressTrackerState { }

@observer
export class ProgressTracker extends React.Component<
  IProgressTrackerProps,
  IProgressTrackerState
> {
  getOptionalNotice = () => (
    <Typography variant="caption" sx={{
      lineHeight: 1,
      fontSize: '0.75rem !important',
    }}
    >
      Optional
    </Typography>
  );

  render() {
    const { steps, activeStep } = this.props;
    return (
      <div>
        <StyledTitleBarDiv>
          <Typography variant="h2" sx={{ fontSize: '1.9375rem !important' }}>
            Steps
          </Typography>
        </StyledTitleBarDiv>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ padding: '1rem 2.5rem' }}
        >
          {steps.map(({ label, optional, subLabel }, index) => (
            <Step key={label}>
              <StepLabel
                optional={optional ? this.getOptionalNotice() : undefined}
                sx={{
                  '& span': {
                    '&.MuiStepLabel-label': {
                      color: theme.palette.secondary.main,
                      lineHeight: 1,
                      textTransform: 'uppercase',
                      letterSpacing: '0.1em',
                      fontSize: '1rem',
                    }
                  }
                }}
              >
                {label}
                {subLabel && <StyledSubLabelDiv>{subLabel}</StyledSubLabelDiv>}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

export default inject((store: any) => ({
}))(ProgressTracker);
