import {
  Chip,
  Grid,
  Tooltip,
  styled,
  ScopedCssBaseline,
  Link,
} from '@mui/material'
import moment from 'moment'
import {
  CallSplit,
  CheckCircle,
  Error,
  GetApp,
  LocalOffer,
  People
} from '@mui/icons-material'
import { css } from '@emotion/css'
import { Line, LineChart, ResponsiveContainer } from 'recharts'

import {
  FiltersWrapper,
  MultiList,
  SEARCH_BAR_REACTIVE_ID,
  SearchBar,
  SearchProvider,
  SelectedFilters,
} from '@extensions/components/search-core'
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils'
import SearchResults, { ResultStats } from '@extensions/components/search-core/SearchResults'
import theme from '@extensions/services/Theme'

interface RepoHit {
  id: number;
  name: string;
  full_name: string;
  description: string;
  updated_at: string;
  homepage: string | null;
  stargazers_count: number;
  forks_count: number;
  default_branch: string;
  parent: string;
  url: string;
  license: string;
  license_url: string;
  commit_activity: number[];
  languages: string[];
  badges: Badge[];
  contributors_count: number;
  watchers_count: number;
  current_release: string | null;
  current_release_date: string | null;
  releases_count: number;
  issues_open_count: number;
  issues_closed_count: number;
  pull_requests_open_count: number;
  pull_requests_closed_count: number;
  downloads_last_day: number | undefined;
  downloads_last_week: number | undefined;
  downloads_last_month: number | undefined;
  _indexed: string;
}

interface Badge {
  image_url: string;
  image_link: string;
}

const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));

const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledMultiList = styled(MultiList)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const REACTIVE_LICENSE_ID = 'LicenseFilter'
const REACTIVE_LANGUAGE_ID = 'LanguageFilter'
const REACTIVE_ORG_ID = 'OrgFilter'
const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  REACTIVE_LICENSE_ID,
  REACTIVE_LANGUAGE_ID,
  REACTIVE_ORG_ID,
]

const allReactiveIdsExcept = getAllReactiveIdsExcept(REACTIVE_IDS)

const getStatsDisplayString = (stats?: ResultStats) => {
  if (stats) {
    const { currentPage, numberOfPages, numberOfResults } = stats;
    return `${numberOfResults} repositor${numberOfResults === 1 ? 'y' : 'ies'
      } found (page ${currentPage + 1} of ${numberOfPages})`;
  }
  return '';
};

export interface IRepoProps {
  className?: string;
}

const Repo = () => {
  return (
    <ScopedCssBaseline sx={{ backgroundColor: theme.palette.grey[50] }}>
      <SearchProvider
        elasticIndex="codehub/repo"
        apiPrefix="api"
        theme={{
          colors: {
            primaryColor: '#53b03f',
          },
        }}
      >
        <StyledSearchBar
          dataField={[
            'name',
            'full_name',
            'keyword',
            'description',
            'parent',
            'project_name',
            'languages',
            'url',
          ]}
          autosuggest={false}
          URLParams={true}
          queryFormat="and"
        />
        <StyledSelectedFilters />
        <Grid container spacing={6} sx={{ paddingTop: '10px' }}>
          <Grid item sm={3} md={4} sx={{ width: '100%' }}>
            <FiltersWrapper>
              <StyledMultiList
                componentId={REACTIVE_ORG_ID}
                title="Organization"
                dataField="parent.raw"
                react={{
                  and: allReactiveIdsExcept(REACTIVE_ORG_ID),
                }}
                maxLabelWidth="none"
                showBottomBorder
                URLParams
                filterLabel="Org"
              />
              <StyledMultiList
                componentId={REACTIVE_LICENSE_ID}
                title="License"
                dataField="license.keyword"
                react={{
                  and: allReactiveIdsExcept(REACTIVE_LICENSE_ID),
                }}
                maxLabelWidth="none"
                showBottomBorder
                URLParams
                filterLabel="License"
              />
              <StyledMultiList
                componentId={REACTIVE_LANGUAGE_ID}
                title="Language"
                dataField="languages.keyword"
                react={{
                  and: allReactiveIdsExcept(REACTIVE_LANGUAGE_ID),
                }}
                maxLabelWidth="none"
                showBottomBorder
                URLParams
                filterLabel="Language"
              />
              {/* <SingleRange
                componentId={REACTIVE_RELEASE_ID}
                title="Latest Release"
                dataField="current_release_date"
                showRadio={true}
                innerClass={{
                  title: classes.filterTitle,
                }}
                data={[
                  {
                    start: moment().subtract(6, 'months').valueOf(),
                    end: moment().valueOf(),
                    label: 'Past 6 months',
                  },
                  {
                    start: moment().subtract(1, 'years').valueOf(),
                    end: moment().subtract(6, 'momths').valueOf(),
                    label: '6 months to 1 year ago',
                  },
                  {
                    start: moment().subtract(30, 'years').valueOf(),
                    end: moment().subtract(1, 'years').valueOf(),
                    label: '1+ year ago',
                  },
                ]}
              /> */}
            </FiltersWrapper>
          </Grid>
          <Grid item sm={9} md={8}>
            <SearchResults
              dataField="name.keyword"
              sortBy="asc"
              react={{
                and: [SEARCH_BAR_REACTIVE_ID, ...REACTIVE_IDS],
              }}
              renderItem={(hit: RepoHit) => (
                <Hit key={hit.id} data={hit} />
              )}
              renderNoResults={() => {
                return "No results found."
              }}
              renderResultStats={(stats: ResultStats) => (
                <div
                  aria-hidden
                  className={css`
                    && {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      margin-right: 0.5rem;
                      align-items: center;
                      font-size: 90%;
                      color: #999;
                    }
                  `}
                >
                  {getStatsDisplayString(stats)}
                </div>
              )}
              sortOptions={[
                {
                  label: 'Most Recently Updated',
                  dataField: 'updated_at',
                  sortBy: 'desc',
                },
                {
                  label: 'Repository (A-Z)',
                  dataField: 'name.keyword',
                  sortBy: 'asc',
                },
                {
                  label: 'Repository (Z-A)',
                  dataField: 'name.keyword',
                  sortBy: 'desc',
                },
                {
                  label: 'Organization (A-Z)',
                  dataField: 'full_name.keyword',
                  sortBy: 'asc',
                },
                {
                  label: 'Organization (Z-A)',
                  dataField: 'full_name.keyword',
                  sortBy: 'desc',
                },
                {
                  label: 'Open Issues Count (Asc)',
                  dataField: 'issues_open_count',
                  sortBy: 'asc',
                },
                {
                  label: 'Open Issues Count (Desc)',
                  dataField: 'issues_open_count',
                  sortBy: 'desc',
                },
                {
                  label: 'Contributor Count',
                  dataField: 'contributors_count',
                  sortBy: 'desc',
                },
                {
                  label: 'Fork Count',
                  dataField: 'forks_count',
                  sortBy: 'desc',
                },
                {
                  label: 'PyPI Downloads Count',
                  dataField: 'downloads_last_month',
                  sortBy: 'desc',
                },
              ]}
            />
          </Grid>
        </Grid>
      </SearchProvider>
    </ScopedCssBaseline>
  )
}

interface IHitProps {
  data: RepoHit
}

const HitWrapper = styled('li')`
  margin: 1rem 0;

  .details {
    padding-right: 1rem;
  }

  .details .title {
    font-weight: bold;
  }

  .description, .license {
    font-size: 80%;
    line-height: 1;
  }

  .stats {
    background-color: #efefef;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 0;
    font-size: 80%;

    .title {
      padding: 0.3rem 0.55rem;
      border-bottom: 1px solid #ddd;
    }

    .body {
      padding: 0.5rem;

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      a > svg {
        margin-right: 0.25rem;
      }
    }
  }
`

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const Hit = ({ data }: IHitProps) => {
  const chartData = data.commit_activity.map(v => ({
    val: v,
  }))
  return (
    <HitWrapper>
      <Grid container direction="row">
        <Grid item className="details" lg={4}>
          <Grid container direction="column" spacing={1}>
            <Grid item className="title">
              {data.parent} /&nbsp;
              <StyledLink variant='a' href={data.url} target="_blank" rel="noreferrer">
                {data.name}
              </StyledLink>
            </Grid>
            <Grid item className="description">
              {data.description || <em className="none">[ No description ]</em>}
            </Grid>
            <Grid item className="license">
              <strong>License:</strong>&nbsp;
              {Boolean(data.license_url) && (
                <StyledLink variant='a' href={data.license_url} target="_blank" rel="noreferrer">
                  {data.license}
                </StyledLink>
              )}
              {!Boolean(data.license_url) && data.license}
            </Grid>
            <Grid item className="languages">
              <Grid container spacing={1}>
                {data.languages.map(lang => (
                  <Grid item key={lang}>
                    <Chip size="small" key={lang} label={lang} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="stats" lg={8}>
          <Grid container direction="column">
            <Grid item className="title">
              <Grid container>
                <Grid item xs={3}>
                  <StyledLink variant='a' href={`${data.url}/pulse`} target="_blank" rel="noreferrer">
                    Activity
                  </StyledLink>
                </Grid>
                <Grid item style={{ textAlign: 'right' }} xs={9}>
                  <small>Updated: {moment(data.updated_at).fromNow()}</small>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="body">
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  Issues
                  <Grid container spacing={1}>
                    <Grid item>
                      <Tooltip title="Open Issues">
                        <StyledLink variant='a' href={`${data.url}/issues`} target="_blank" rel="noreferrer">
                          <Error fontSize="small" />
                          {data.issues_open_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Closed Issues">
                        <StyledLink variant='a' href={`${data.url}/issues?q=is%3Aissue+is%3Aclosed`} target="_blank" rel="noreferrer">
                          <CheckCircle fontSize="small" />
                          {data.issues_closed_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  Pull Requests
                  <Grid container spacing={1}>
                    <Grid item>
                      <Tooltip title="Open Pull Requests">
                        <StyledLink variant='a' href={`${data.url}/pulls`} target="_blank" rel="noreferrer">
                          <Error fontSize="small" />
                          {data.pull_requests_open_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Closed Pull Requests">
                        <StyledLink variant='a' href={`${data.url}/pulls?q=is%3Apr+is%3Aclosed`} target="_blank" rel="noreferrer">
                          <CheckCircle fontSize="small" />
                          {data.pull_requests_closed_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  Contributors<br />
                  <StyledLink variant='a' href={`${data.url}/contributors`} target="_blank" rel="noreferrer">
                    <People fontSize="small" />
                    {data.contributors_count}
                  </StyledLink>
                </Grid>
                <Grid item xs={8}>
                  Releases
                  <Grid container spacing={1}>
                    {Boolean(data.current_release) && (
                      <Grid item>
                        <StyledLink variant='a' href={`${data.url}/releases`} target="_blank" rel="noreferrer">
                          <LocalOffer fontSize="small" />
                          {data.current_release} ({moment(data.current_release_date).fromNow()})
                        </StyledLink>
                      </Grid>
                    )}
                    <Grid item>
                      <StyledLink variant='a' href={`${data.url}/releases`} target="_blank" rel="noreferrer">
                        <LocalOffer fontSize="small" />
                        {data.releases_count}
                      </StyledLink>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  Forks
                  <StyledLink variant='a' href={`${data.url}/forks`} target="_blank" rel="noreferrer">
                    <CallSplit fontSize="small" />
                    {data.forks_count}
                  </StyledLink>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title="Past Year of Commit Activity">
                    <div>
                      <ResponsiveContainer height={50}>
                        <LineChart data={chartData}>
                          <Line
                            type="monotone"
                            dataKey="val"
                            stroke="#888"
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </Tooltip>
                </Grid>
                {data.downloads_last_month !== undefined && (
                  <Grid item xs={12}>
                    PyPI Downloads
                    <StyledLink variant='a' href={`https://pypistats.org/packages/${data.name.toLowerCase()}`}>
                      <GetApp fontSize="small" />
                      {data.downloads_last_day || 0} (day),&nbsp;
                      {data.downloads_last_week || 0} (week),&nbsp;
                      {data.downloads_last_month || 0} (month)
                    </StyledLink>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {data.badges.map(b => (
                      <Grid key={`${b.image_link}${b.image_url}`} item>
                        <a href={b.image_link} target="_blank" rel="noreferrer">
                          <img src={b.image_url} alt="Badge" />
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HitWrapper>
  )
}

export default Repo;