import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { observable, computed, action, reaction, makeObservable } from 'mobx';

import { styled } from '@mui/material';
import { Typography, Alert } from '@mui/material';

import { DocAction } from '@extensions/services/IMetadataService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { IMetadataService } from '@extensions/services/IMetadataService';

import Link from '@extensions/components/core/Link';
import MetaDocument from '@extensions/models/MetaDocument';
import MetadataDest from '@extensions/models/MetadataDest';
import Editor from '@extensions/components/metadata/Editor';
import Submitted from '@extensions/components/metadata/Submitted';
import ReadOnlyDoc from '@extensions/components/metadata/ReadOnlyDoc';
import BackToLandingPage from '@extensions/components/metadata/BackToLandingPage';

import { ISecurityService } from '@extensions/services/ISecurityService';
import MustSignInAlert from '@extensions/components/core/MustSignInAlert';

const StyledRootDiv = styled('div')(({
  width: '100%',
  marginBottom: '2rem',
}));

const StyledEditor = styled(Editor)(({
  width: '100%',
  marginBottom: '2rem',
}));

const StyledHeadDiv = styled('div')(({
  width: '100%',
}));

export interface IDocumentDetailsProps
  extends RouteComponentProps<{ id: string }> {
  className?: string;
  metadataService?: IMetadataService;
  historyService?: IHistoryService;
  securityService?: ISecurityService;
}

export interface IDocumentDetailsState { }

@observer
export class DocumentDetails extends React.Component<
  IDocumentDetailsProps,
  IDocumentDetailsState
> {
  @observable
  document: MetaDocument | null = null;
  @observable
  documentNotFound: boolean = false;

  constructor(props: IDocumentDetailsProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get destination(): MetadataDest | null {
    const { metadataService } = this.props;
    if (!this.document || !this.document.datasetName) {
      return null;
    }
    return metadataService?.getDestination(this.document.datasetName) || null;
  }

  componentDidMount() {
    if (this.props.metadataService) {
      this.props.metadataService.load();
    }
    reaction(
      () => {
        const { match, metadataService } = this.props;
        return [metadataService?.loaded, match.params['id']];
      },
      this.setDocument,
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.destination,
      () => this.destination?.loadSchema(),
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.document,
      () => this.document?.loadDetails(),
      {
        fireImmediately: true,
      }
    );
  }

  @action
  setDocument = () => {
    const { metadataService, match } = this.props;
    if (!metadataService?.loaded) {
      return;
    }
    let documentId: string | number = +match.params['id'];
    if (isNaN(documentId)) {
      documentId = match.params['id'];
    }
    let doc;
    if (metadataService.selectedDocAction === DocAction.PublishedEditing) {
      doc = metadataService.getPublishedDocument(documentId);
      doc.saveDraft();
    } else {
      doc = metadataService.getDocument(documentId);
    }
    if (!doc) {
      this.documentNotFound = true;
    } else {
      this.document = doc;
    }

    //for the case where users go directly to editing link or refresh the page:
    if (metadataService?.selectedDocAction === undefined) {
      if (match.path.includes('metadata/edit')) {
        metadataService?.setSelectedDocAndAction(doc, false, true);
      } else {
        metadataService?.setSelectedDocAndAction(doc, true, false);
      }
    }
  };

  render() {
    const { metadataService, historyService } = this.props;
    const docAction = metadataService?.selectedDocAction;

    if (this.documentNotFound) {
      return <Alert severity="warning">Document not found. Go to <Link to="/metadata">Metadata Submission</Link> page.</Alert>;
    }

    if (!this.document) {
      if (this.props.securityService
        && this.props.securityService.user
        && this.props.securityService.user.authenticated
      ) {
        if (this.props.securityService.user.canEdit) {
          return <Alert severity="warning">
            Document is not loaded properly, try <Link style={{ verticalAlign: 'baseline' }} onClick={() => window.location.reload()}>refresh </Link> to load again.
          </Alert>
        } else {
          return <Alert severity="warning">
            You don't have permission to edit Metadata. Please email{' '}<a href="mailto:dapteam@pnnl.gov">our team</a> for permission.
          </Alert>
        }
      }
      return <MustSignInAlert actionDescription={"edit metadata document"} />
    }
    const readOnly = <ReadOnlyDoc document={this.document} />;

    // * Click on pending's pencil icon
    const submitted = (
      <Submitted document={this.document} destination={this.destination} />
    );

    // * Click on draft's pencil icon
    const editMetadata = (
      <StyledRootDiv>
        <BackToLandingPage />
        <StyledHeadDiv>
          <Typography variant="h1" sx={{ mb: '0.5rem', fontSize: '3rem !important' }}>
            Edit Metadata
          </Typography>
        </StyledHeadDiv>
        <StyledEditor
          document={this.document}
          destination={this.destination || undefined}
          onCancel={() => {
            if (!this.document?.deleteOnCancel && this.document?.isPublished) {
              metadataService?.deleteDocument(this.document as MetaDocument);
            } else {
              historyService?.history.push('/metadata');
            }
          }}
        />
      </StyledRootDiv>
    );

    if (docAction === DocAction.DraftReviewingReadOnly) {
      return readOnly;
    } else if (
      docAction === DocAction.PendingReviewing ||
      docAction === DocAction.PendingEditing
    ) {
      return submitted;
    }
    return editMetadata;
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
  historyService: store.historyService,
  securityService: store.securityService,
}))(DocumentDetails);
