import { useCallback, useState } from 'react';
import { Alert, Box, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

const AuthCallback = () => {
  const [copied, setCopied] = useState(false);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const code = params.get('code');

  const select = useCallback(() => {
    if (code) {
      navigator.clipboard.writeText(code).then(() => {
        setCopied(true);
      });
    }
  }, [code]);

  return (
    <>
      <h1>Authorization Code</h1>
      <Box gap={1} display="flex" flexDirection="column">
        <code style={{ overflowWrap: 'break-word' }}>{params.get('code')}</code>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={select}
        >
          Copy to Clipboard
        </Button>
        {copied && <Alert severity="success">Copied to clipboard!</Alert>}
      </Box>
    </>
  );
};

export default AuthCallback;
