import moment, { Moment } from 'moment'

import { Slider, Tooltip } from '@mui/material'
import { SliderValueLabelProps } from '@mui/material/Slider'

interface ITimelineSliderProps {
  beg: Moment
  end: Moment
  initialZoom: [Moment, Moment] | undefined
  handleZoom: (zoom: [Moment, Moment]) => void
}

const m2n = (d: Moment) => {
  return d.unix()
}

const n2m = (n: number) => {
  return moment.unix(n)
}

// const countDays = (d0: Moment, d1:Moment) => {
//   return Math.round(duration(d1.diff(d0)).asDays())
// }
function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={n2m(value).format('YYYY MM DD')}>
      {children}
    </Tooltip>
  );
}

const TimelineSlider = ({ beg, end, initialZoom, handleZoom }: ITimelineSliderProps) => {
  return (
    <Slider
      valueLabelDisplay="auto"
      sx={{ display: 'block' }}
      size="small"
      min={m2n(beg)}
      max={m2n(end)}
      value={[
        initialZoom ? m2n(initialZoom[0]) : m2n(beg),
        initialZoom ? m2n(initialZoom[1]) : m2n(end),
      ]}
      step={86400}
      onChange={(e, value) => handleZoom([n2m(value[0]), n2m(value[1])])}
      slots={{
        valueLabel: ValueLabelComponent,
      }}
    />
  )
}

export default TimelineSlider
