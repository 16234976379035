import clsx from 'clsx';
import { WidgetProps } from '@rjsf/utils';
import { Typography } from '@mui/material';
import Label from '@extensions/components/metadata/review/Label';
import { DEFAULT_VALUE } from '@extensions/components/metadata/review/utils';

export default function TextWidget(props: WidgetProps) {
  const { className, value, label } = props;

  return (
    <div className={clsx(className)}>
      <Label>{label}</Label>
      <Typography>{value === undefined || value === null ? DEFAULT_VALUE : value}</Typography>
    </div>
  )
}
