import React from 'react';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import {
  DownloadOption,
  reasonCantUseOption,
} from '@extensions/models/FileOrder';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '50.25px',
  paddingTop: '.75rem',
  paddingBottom: '.75rem',
  backgroundColor: '#e0e0e0 !important',
}));

export interface IOptionButtonProps
  extends Omit<ButtonProps, 'classes' | 'disabled'> {
  option: DownloadOption;
  order: FileOrderGroup;
}

class OptionButton extends React.Component<IOptionButtonProps> {
  render() {
    const { order, option, ...otherProps } = this.props;
    const notAllowedTooltip = reasonCantUseOption(option, order);
    const disabled = notAllowedTooltip !== null;
    const button = (
      <StyledButton
        sx={{backgroundColor: '#e0e0e0'}}
        size="large"
        variant="contained"
        disabled={disabled}
        {...otherProps}
      />
    );
    if (disabled) {
      return (
        <Tooltip title={notAllowedTooltip as string}>
          <span tabIndex={0}>{button}</span>
        </Tooltip>
      );
    }
    return button;
  }
}

export default (OptionButton);
