import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';

export const MEASUREMENT_REACTIVE_ID = 'MeasurementFilter';

export default class MeasurementFilter extends React.Component<{
  react?: react;
}> {
  render = () => {
    return (
      <MultiList
        className="data-filter"
        componentId={MEASUREMENT_REACTIVE_ID}
        dataField="measurements.standardName.displayName.keyword"
        title="Standard Measurement"
        filterLabel="Measurement"
        showSearch={false}
        react={this.props.react}
        URLParams={true}
      />
    );
  };
}
