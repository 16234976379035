import MetaDocument from '@extensions/models/MetaDocument';
import { JSONSchema7 } from 'json-schema';
import { UiSchema } from '@rjsf/core';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import request from 'superagent';

export function getFileUploadUrls({ document }: { document: MetaDocument }) {
  return {
    uploadRefFile: `/api/datadocs/${document.id}/files`,
    listRefFiles: `/api/datadocs/${document.id}/files`,
    downloadRefFile: `/api/datadocs/${document.datasetName}/files`,
    deleteRefFile: `/api/datadocs/${document.datasetName}/files`,
  };
}

export function getTitle({
  schema,
  uiSchema,
}: {
  schema: JSONSchema7;
  uiSchema: UiSchema;
}) {
  return schema.title || uiSchema['ui:title'] || uiSchema['ui:options']?.title;
}

export function getDescription({
  schema,
  uiSchema,
}: {
  schema: JSONSchema7;
  uiSchema: UiSchema;
}) {
  return (
    schema.description ||
    uiSchema['ui:description'] ||
    uiSchema['ui:options']?.description
  );
}

export function withApiPrefix(path: string): string {
  const prefix = '/api/datadocs';
  if (path.startsWith('/') || path.length === 0) {
    return `${prefix}${path}`;
  }
  return `${prefix}/${path}`;
}

export async function fetchSchema({
  projectName,
  type,
}: {
  projectName: string;
  type: string;
}): Promise<Record<string, any>> {
  const getConfig = (
    configVariant: 'schema' | 'config'
  ): request.SuperAgentRequest => {
    const url = withApiPrefix(`${projectName}/${configVariant}/${type}`);
    return DapApiAgent.agent.get(url);
  };
  const [dataSchemaResp, uiSchemaResp] = await Promise.all([
    getConfig('schema'),
    getConfig('config'),
  ]);
  return {
    dataSchema: dataSchemaResp.body,
    uiSchema: uiSchemaResp.body,
  };
}
