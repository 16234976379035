import { Box, Typography } from '@mui/material';
import { BaseInputTemplateProps } from '@rjsf/utils';
import Label from '@extensions/components/metadata/review/Label';

export default function BaseInputTemplate(props: BaseInputTemplateProps) {
    const { label, value } = props;
    return (
        <Box sx={{ width: '100%', maxWidth: '600px', overflow: 'hidden' }}>
            <Label>{label}</Label>
            <Typography sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>{value}</Typography>
        </Box>
    )
}
