import React from 'react';
import { inject } from 'mobx-react';
import { Typography } from '@mui/material';

import {
  DocumentLanding as DapDocumentLanding
} from '@dapclient/components/page/DocumentLanding';
import Link from '@extensions/components/core/Link';
import theme from '@extensions/services/Theme';
import HeadManager from './HeadManager';

export class DocumentLanding extends DapDocumentLanding {
  getLinkToUpload = (): React.ReactNode => {
    return (
      <>
        <HeadManager
          title="A2e: Atmosphere to Electrons Metadata"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
        <Typography
          sx={{
            color: theme.palette.grey[700]
          }}
        >
          Need to upload data? Go to the{' '}
          <Link to="/upload">data upload page</Link> to get started.
        </Typography>
      </>
    );
  };
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
  historyService: store.historyService,
}))(DocumentLanding);
