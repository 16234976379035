import {
  action,
  IReactionDisposer,
  observable,
  reaction,
  makeObservable,
  computed
} from 'mobx';
import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@mui/material';
import uniq from 'lodash/uniq';
import fileSize from 'filesize';
import unzip from 'lodash/unzip';
import debounce from 'lodash/debounce';
import { styled } from '@mui/material/styles';

import { ICartService } from '@extensions/services/ICartService';
import { ICachingService } from '@extensions/services/ICachingService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { INotificationService } from '@extensions/services/INotificationService';

import FileMetadataSchema, {
  DisplayType,
} from '@extensions/models/FileMetadataSchema';
import guessOS from '@extensions/utils/guessOS';
import Dataset, { DatasetStats } from '@extensions/models/Dataset';
import { DateRange } from '@extensions/components/core/date-picker';
import CenteredCircularProgress from '../core/CenteredCircularProgress';
import AttributeFilter from '@extensions/components/dataset/large-orders/AttributeFilter';

const StyledAttributeFilter = styled(AttributeFilter)(({
  marginTop: '0.5rem'
}));

const StyledButtonBarDiv = styled('div')(({
  position: 'absolute',
  right: '1.5rem',
  bottom: '1.5rem',
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
}));

export interface ILargeDataOrderProps {
  className?: string;
  datasetService?: IDatasetService;
  securityService?: ISecurityService;
  notificationService?: INotificationService;
  cachingService?: ICachingService;
  onOrderCancel: (event: any) => void;
  visible: boolean;
  cartService?: ICartService;
}

export interface ILargeDataOrderState { }

export interface AppliedFilter {
  attribute: FileMetadataSchema;
  value: [number, number] | string[] | DateRange | null;
}

@observer
export class LargeDataOrder extends React.Component<
  ILargeDataOrderProps,
  ILargeDataOrderState
> {
  notificationId = 'LargeDataOrderNotification';
  reactions: IReactionDisposer[] = [];
  @observable
  filterableAttributes: FileMetadataSchema[] = [];
  @observable
  filters: Record<string, AppliedFilter> = {};
  @observable
  stats: DatasetStats | null = null;
  @computed
  get extensionValsByIndex() {
    const { datasetService } = this.props;
    const fullExtensions = datasetService?.dataset?.dynamoFullExtensions || [];
    const extensionsSplit = fullExtensions.map((ext) => ext.split('.'));
    let updatedResults = unzip(extensionsSplit).map((vals) => uniq(vals));
    this.filterableAttributes.forEach((fa) => {
      if (fa.downloadDisplay === DisplayType.DATE) {
        updatedResults.unshift([DisplayType.DATE])
      }
    })
    return updatedResults;
  }
  @computed
  get searchesQuery(): object | null {
    const { datasetService } = this.props;
    if (!datasetService || !datasetService.dataset) {
      return null;
    }

    const query = {
      Dataset: datasetService.dataset.name,
      latest: true,
    };
    Object.values(this.filters).forEach((filter) => {
      const { value, attribute } = filter;
      if (value == null) {
        return;
      }
      switch (attribute.downloadDisplay) {
        case DisplayType.RANGE:
          query[attribute.dynamoFieldName] = {
            between: value,
          };
          break;
        case DisplayType.LIST:
          if ((value as string[]).length > 0) {
            query[attribute.dynamoFieldName] = value;
          }
          break;
        case DisplayType.DATE:
          const { startDate, endDate } = value as DateRange;
          if (startDate !== null && endDate !== null) {
            query[attribute.dynamoFieldName] = {
              between: [
                startDate.format('YYYYMMDD'),
                endDate.format('YYYYMMDD'),
              ],
            };
          }
          break;
        default:
          console.log(`Bad filter! ${attribute.label} with value ${value}`);
          break;
      }
    });
    return query;
  }

  constructor(props) {
    super(props);
    this.updateStats = debounce(this.updateStats, 300);
    makeObservable(this);
  }

  componentDidMount() {
    const { datasetService } = this.props;

    const updateDataDisposer = reaction(
      () => datasetService?.dataset,
      this.updateData,
      { fireImmediately: true }
    );
    this.reactions.push(updateDataDisposer);
  }

  componentWillUnmount() {
    this.reactions.forEach((disposer) => disposer());
  }

  @action
  updateData = (dataset: Dataset | null | undefined) => {
    this.filterableAttributes = [];
    this.filters = {};
    if (!dataset) {
      return;
    }

    const downloadSection = dataset.getDownloadDistribution();
    this.filterableAttributes = [...downloadSection.fileMetadataSchema];
    this.updateStats();
  };

  @action
  updateFilter = (filter: AppliedFilter) => {
    this.filters[filter.attribute.label] = filter;
    this.updateStats();
  };

  @action
  setStats = (newStats: DatasetStats | null) => {
    this.stats = newStats;
  };

  placeOrder = () => {
    const os = guessOS();
    const { cartService, datasetService } = this.props;

    if (cartService && os && datasetService?.dataset) {
      cartService.placeOrderNotInCart(os, this.filters, datasetService.dataset);
    }
  };

  updateStats = () => {
    // NOTE: Known race condition. If the user filters, then pauses,
    // and then filters again, it could be that:
    // 1. The update stats function fires twice
    // 2. The second API call returns first, and the stats in this component
    //    are updated
    // 3. The first API call then returns, and the correct stats in this component
    //    are overwritten.
    // The consequences are minimal so we're not fixing it for now.

    const { cachingService } = this.props;
    if (!cachingService) {
      return;
    }
    this.setStats(null);
    if (this.searchesQuery) {
      cachingService.getStats(this.searchesQuery).then((fetchedStats) => {
        this.setStats(fetchedStats);
      });
    }
  };

  renderOrderStatistics = (stats: DatasetStats | null): React.ReactNode => {
    if (stats === null) {
      return <CenteredCircularProgress />;
    }
    return (
      <Typography>
        {stats.fileCount} Files and {fileSize(stats.byteCount)} of Data
      </Typography>
    );
  };

  render() {
    const { visible, onOrderCancel, datasetService } =
      this.props;
    if (!datasetService || !datasetService.dataset) {
      return null;
    }
    const dataset = datasetService.dataset;

    return (
      <Dialog
        open={visible}
        onClose={onOrderCancel}
        maxWidth="sm"
        fullWidth
        aria-label="order data"
        sx={{
          '.MuiPaper-root': {
            overflow: 'visible'
          }
        }}
      >
        <DialogTitle
          sx={{ pt: '1.5rem' }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: '1.7rem !important' }}
          >
            Order {dataset.shortName} Files
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'inline-block',
            paddingBottom: '1.5rem',
            overflow: 'visible',
          }}
        >
          <div>
            <Typography
              sx={{
                marginBottom: '0.5rem',
                fontSize: '0.875rem !important'
              }}
              variant="h3"
              component="h2"
            >
              Filters
            </Typography>
            {this.filterableAttributes.map((attribute, index) => {
              return (
                <StyledAttributeFilter
                  key={attribute.label}
                  attribute={attribute}
                  dataset={dataset}
                  options={this.extensionValsByIndex[index]}
                  min={dataset?.dynamoRangeMinMax?.min}
                  max={dataset?.dynamoRangeMinMax?.max}
                  value={this.filters[attribute.label]?.value || null}
                  onChange={(newVal) => {
                    this.updateFilter({ attribute, value: newVal });
                  }}
                />
              )
            })}
            <Typography
              variant="h3"
              sx={{
                marginTop: '1.5rem',
                marginBottom: '0.5rem',
                fontSize: '0.875rem !important'
              }}
            >
              Order Summary
            </Typography>
            {this.renderOrderStatistics(this.stats)}

            <StyledButtonBarDiv>
              <Button variant="outlined" onClick={onOrderCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  this.placeOrder();
                  onOrderCancel(e);
                }}
                sx={{ marginLeft: '1rem' }}
              >
                Order Files
              </Button>
            </StyledButtonBarDiv>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  notificationService: store.notificationService,
  cachingService: store.cachingService,
  cartService: store.cartService,
}))(LargeDataOrder);
