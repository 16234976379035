import clsx from 'clsx';
import { WidgetProps } from '@rjsf/utils';
import ReactMarkdown from 'react-markdown';

import Label from '@extensions/components/metadata/review/Label';
import MarkdownLink from '@extensions/components/core/MarkdownLink';
import MarkdownImage from '@extensions/components/core/MarkdownImage';

import {
  DEFAULT_VALUE
} from '@extensions/components/metadata/review/utils';

export interface ITextareaWidgetProps extends WidgetProps {
  className?: string;
}

export default function TextareaWidget(props: ITextareaWidgetProps) {
  const { className, value, label } = props;
  return (
    <div className={clsx(className)} style={{ width: '100%', maxWidth: '600px', overflow: 'hidden' }}>
      <Label style={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>{label}</Label>
      <div style={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
        {value === undefined || value === null ? (
          DEFAULT_VALUE
        ) : (
          <ReactMarkdown
            children={value}
            components={{ image: MarkdownImage, a: MarkdownLink }}
          />
        )}
      </div>
    </div>
  );
}
