import React from 'react';
import { inject, observer } from 'mobx-react';

import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

import sortBy from 'lodash/sortBy';

import { ICartService } from '@extensions/services/ICartService';

import { FilterType } from '@dapclient/models/Dataset';
import { FilterTitle } from '@extensions/components/search-core';
import DefaultExtensionFilter from '@extensions/components/cart/DefaultExtensionFilter';
import AutocompleteExtensionFilter from '@extensions/components/cart/AutocompleteExtensionFilter';

const StyledAutocompleteExtensionFilter = styled(AutocompleteExtensionFilter)(({
  marginTop: '0.5rem',
}));

export interface IExtensionsFiltersProps {
  className?: string;
  cartService?: ICartService;
  styleObject?: any;
}

@inject('cartService')
@observer
class ExtensionsFilters extends React.Component<IExtensionsFiltersProps> {
  render() {
    const { cartService, styleObject } = this.props;
    if (!cartService || !cartService.datasets) {
      return null;
    }
    const combinedExtensions = new Map<
      string,
      { values: Set<string>; filterType?: FilterType }
    >();
    for (const dataset of cartService.datasets) {
      for (const fileExtension of dataset.dynamoFileExtensions) {
        const label = fileExtension.label;
        const currentEntry = combinedExtensions.get(label) || {
          values: new Set(),
        };
        if (fileExtension.filterType) {
          if (fileExtension.filterType === FilterType.HIDE) {
            continue;
          }
          currentEntry.filterType = fileExtension.filterType;
        }
        for (const newValue of fileExtension.valuesInUse.values()) {
          currentEntry.values.add(newValue);
        }

        combinedExtensions.set(label, currentEntry);
      }
    }

    const sortedExtensions = sortBy([...combinedExtensions.entries()], '0');
    return (
      <div>
        {sortedExtensions.map(([label, { values, filterType }]) => {
          const filterProps = {
            toggle: value => cartService.toggleExtensionValue({ label, value }),
            selected: cartService.extensionsFilter.get(label) || new Set(),
            values: [...values.values()].sort((a: string, b: string) =>
              a.localeCompare(b, undefined, { numeric: true })
            ),
          };
          let filter: React.ReactNode;
          switch (filterType as FilterType) {
            case FilterType.AUTOCOMPLETE:
              filter = (
                <StyledAutocompleteExtensionFilter
                  {...filterProps}
                  label={label}
                />
              );
              break;
            case FilterType.DEFAULT:
            default:
              filter = (
                <DefaultExtensionFilter
                  {...filterProps}
                  styleObject={styleObject.optionsList && Object.keys(styleObject.optionsList).length === 0
                    ? {}
                    : { ...styleObject.optionsList }}
                />
              );
          }
          return (
            <FormControl
              component="div"
              sx={
                styleObject && Object.keys(styleObject.filterBlock).length === 0
                  ? { width: '100%' }
                  : { ...styleObject.filterBlock, width: '100%' }
              }
              key={label}
            >
              <FilterTitle>{label}</FilterTitle>
              {filter}
            </FormControl>
          );
        })}
      </div>
    );
  }
}

export default ExtensionsFilters;
