import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { observable, action, makeObservable } from 'mobx';

import { styled } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { Typography, Button, TextField, Link, Alert } from '@mui/material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import { IUploadService } from '@extensions/services/IUploadService';
import { IContactUsService } from '@extensions/services/IContactUsService';
import theme from '@extensions/services/Theme';

import VirtualizedAutocomplete from '@extensions/components/core/VirtualizedAutocomplete';

const StyledRootDiv = styled('div')(({
  marginBottom: '2rem',
  width: '100%',
}));

const StyledChoicesDiv = styled('div')(({
  position: 'relative',
  display: 'flex',
  width: '100%',
  marginTop: '2rem',
}));

const StyledChoiceContainerDiv = styled('div')(({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 4rem',
}));

const StyledChoiceExplanation = styled('div')(({
  backgroundColor: '#eee',
  borderRadius: '5px',
  padding: '1rem',
  width: '91%',
}));

const StyledMilldeOrDiv = styled('div')(({ theme }) => (({
  position: 'absolute',
  top: 'calc(50% - 25px)',
  left: 'calc(50% - 25px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50px',
  height: '50px',
  backgroundColor: '#fafafa',
  color: theme.palette.grey[700],
  fontWeight: 'bold',
  fontSize: '24pt',
})));

const StyledVirtualizedAutocomplete = styled(VirtualizedAutocomplete)(({
  width: '100%',
  marginBottom: '1rem',
  flex: '1 1 auto',
}));

const StyledTextField = styled(TextField)(({
  '& > div': {
    backgroundColor: '#fff',
  },
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700]
}));

const StyledListItem = styled('li')(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const StyledTypography = styled(Typography)(({
  'p&': {
    marginBottom: '0rem',
  }
}));

const NOTIFICATION_ID = 'upload-page';

export interface IUploadDataProps extends RouteComponentProps {
  className?: string;
  uploadService?: IUploadService;
  notificationService?: INotificationService;
  contactUsService?: IContactUsService;
}

export interface IUploadDataState { }

@observer
export class UploadData extends React.Component<
  IUploadDataProps,
  IUploadDataState
> {
  @observable
  selectedDataset: string | null = null;
  @observable
  selectedProject: string | null = null;

  constructor(props: IUploadDataProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    if (this.props.uploadService) {
      this.props.uploadService.load();
    }
  }

  @action
  handleDatasetChange = (event, newSelection: string | null) => {
    this.selectedDataset = newSelection;
  };

  @action
  handleProjectChange = (event, newSelection: string | null) => {
    this.selectedProject = newSelection;
  };

  @action
  handleBeginConfigure = event => {
    const { notificationService } = this.props;
    event.preventDefault();

    if (!this.selectedProject) {
      notificationService?.addNotification(
        NOTIFICATION_ID,
        Status.Error,
        'Project Name Required',
        'Please Select a Project Name',
        true,
        true
      );
    } else {
      this.props.history.push(`/uploaders/${this.selectedProject}`);
    }
  };

  handleBeginUpload = event => {
    const { notificationService } = this.props;
    event.preventDefault();

    if (!this.selectedDataset) {
      notificationService?.addNotification(
        NOTIFICATION_ID,
        Status.Error,
        'Dataset Name Required',
        'Please Select a Dataset Name',
        true,
        true
      );
    } else {
      this.props.history.push(`/ds/${this.selectedDataset}/upload`);
    }
  };

  handleContactUs = event => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.contactUsService) {
      this.props.contactUsService.openModal();
    }
  };

  render() {
    const { uploadService } = this.props;
    if (!uploadService) {
      return null;
    }

    return (
      <StyledRootDiv>
        <Typography variant='h1' sx={{ mb: '1rem' }}>
          Submit Data
        </Typography>
        <StyledDescription>
          Use one of the options below to upload data.
        </StyledDescription>
        <StyledDescription>
          <em>If the project or dataset you are looking for is not in the respective list,
            please <Link underline='hover' component="a" onClick={this.handleContactUs} sx={{ cursor: 'pointer' }}>let us know</Link>.</em>
        </StyledDescription>
        <StyledChoicesDiv>
          <StyledChoiceContainerDiv sx={{ borderRight: '1px solid #ccc' }}>
            <OpenInBrowserIcon sx={{ fontSize: `150px !important`, color: theme.palette.grey[700] }} />
            <Typography variant="h2" sx={{ mb: '1rem' }}>
              Drag and Drop
            </Typography>
            <StyledChoiceExplanation>
              <Typography component='strong' sx={{ fontWeight: 'bold', color: theme.palette.grey[800] }}>
                Ideal for files that are:
              </Typography>
              <ul style={{ marginTop: '0.5rem' }}>
                <StyledListItem>
                  <StyledTypography
                    variant='body1'
                  >
                    small (in size <em>and</em> quantity),
                  </StyledTypography>
                </StyledListItem>
                <StyledListItem>
                  <StyledTypography variant='body1'>
                    on this computer, <em>and</em>
                  </StyledTypography>
                </StyledListItem>
                <StyledListItem>
                  <StyledTypography variant='body1'>
                    a static set.
                  </StyledTypography>
                </StyledListItem>
              </ul>
              {uploadService.datasetNames.length === 0 && (
                <Alert severity="warning" sx={{ mb: '1rem' }}>
                  It looks like you don't have upload access to any datasets.
                  Please <Link component="a" onClick={this.handleContactUs}>let us know</Link> what you need.
                </Alert>
              )}
              <form onSubmit={this.handleBeginUpload}>
                <StyledVirtualizedAutocomplete
                  options={uploadService.datasetNames}
                  value={this.selectedDataset}
                  onChange={this.handleDatasetChange}
                  renderInput={params => (
                    <StyledTextField
                      {...params}
                      label="Select Dataset ..."
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation={true}
                  disabled={this.selectedDataset === null}
                  startIcon={<ChevronRight />}
                >
                  Get Started
                </Button>
              </form>
            </StyledChoiceExplanation>
          </StyledChoiceContainerDiv>
          <StyledChoiceContainerDiv>
            <SettingsApplicationsIcon sx={{ fontSize: `150px !important`, color: theme.palette.grey[700] }} />
            <Typography variant="h2" sx={{ mb: '1rem' }}>
              Uploader Client
            </Typography>
            <StyledChoiceExplanation>
              <Typography component='strong' sx={{ fontWeight: 'bold', color: theme.palette.grey[800] }}>
                Ideal for files that are:
              </Typography>
              <ul style={{ marginTop: '0.5rem' }}>
                <StyledListItem>
                  <StyledTypography
                    variant='body1'
                  >
                    large (in size <em>or</em> quantity),
                  </StyledTypography>
                </StyledListItem>
                <StyledListItem>
                  <StyledTypography
                    variant='body1'
                  >
                    remote (reside on another computer), <em>or</em>
                  </StyledTypography>
                </StyledListItem>
                <StyledListItem>
                  <StyledTypography
                    variant='body1'
                  >
                    routine / ongoing.
                  </StyledTypography>
                </StyledListItem>
              </ul>
              {uploadService.projectNames.length === 0 && (
                <Alert severity="warning" sx={{ mb: '1rem' }}>
                  It looks like you don't have upload access to any projects.
                  Please <Link component="a" onClick={this.handleContactUs}>let us know</Link> what you need.
                </Alert>
              )}
              <form onSubmit={this.handleBeginConfigure}>
                <StyledVirtualizedAutocomplete
                  options={uploadService.projectNames}
                  value={this.selectedProject}
                  onChange={this.handleProjectChange}
                  renderInput={params => (
                    <StyledTextField
                      {...params}
                      label="Select Project ..."
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation={true}
                  disabled={this.selectedProject === null}
                  startIcon={<ChevronRight />}
                >
                  Get Started
                </Button>
              </form>
            </StyledChoiceExplanation>
          </StyledChoiceContainerDiv>
          <StyledMilldeOrDiv>
            OR
          </StyledMilldeOrDiv>
        </StyledChoicesDiv>
      </StyledRootDiv>
    )
  }
}

export default inject((store: any) => ({
  uploadService: store.uploadService,
  notificationService: store.notificationService,
  contactUsService: store.contactUsService,
}))(withRouter(UploadData));
