import {
  useState,
  useCallback,
  useMemo
} from 'react';
import Ajv from 'ajv';
import {
  Alert,
  Tab,
  Tabs,
} from '@mui/material';
import addFormats from 'ajv-formats';
import { JsonEditor } from 'jsoneditor-react';

import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/cobalt';
import 'jsoneditor-react/es/editor.min.css';

import MetaDocument from '@extensions/models/MetaDocument';
import MetadataSchema from '@extensions/models/MetadataSchema';

import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';

const StyledVerticalCenterDiv = styled('div')(({
  display: 'flex',
  alignItems: 'center',
}));

interface TabPanelProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode
  value: number
  index: number
}

const TabPanel = ({ children, index, value, ...rest }: TabPanelProps) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...rest}>
      {value === index && children}
    </div>
  )
}

const stripKeys = (
  obj: Record<string, any>,
  callback: (key: string, val: any) => boolean,
  sortByKeys: string[] | null = null
) => {
  let newObj = {} as Record<string, any>
  let hidden = [] as string[]
  for (const key in obj) {
    if (callback(key, obj[key])) {
      newObj[key] = obj[key]
      continue
    }
    hidden.push(key)
  }
  return [sortKeys(newObj, sortByKeys), hidden]
}

const sortKeys = (obj: Record<string, any>, sortedKeys: string[] | null = null) => {
  return Object.keys(obj).sort(sortedKeys === null ? undefined : (a, b) => {
    return sortedKeys.indexOf(a) - sortedKeys.indexOf(b)
  }).reduce(
    (newObj, key) => {
      newObj[key] = obj[key]
      return newObj
    }, {} as Record<string, any>
  )
}

interface Props {
  document: MetaDocument
  schema: MetadataSchema
  handleErrorChange: (err: string | null) => void
  handleMetadataChange: (metadata: Record<string, any>) => void
}

const hidesKey = (uiSchemaItem) => {
  return (
    (uiSchemaItem['ui:field'] || '') === 'invisible' ||
    (uiSchemaItem['ui:readonly'] || false)
  );
}

const PreFillJson = (({ document, schema, handleErrorChange, handleMetadataChange }: Props) => {

  const { dataSchema, uiSchema } = schema

  const [tab, setTab] = useState(0)

  const validate = useMemo(
    () => {
      const ajv = addFormats(new Ajv());
      return ajv.compile(dataSchema);
    },
    [dataSchema]
  )

  const metadata = document.metadata as Record<string, any>

  const visibleKeys = useMemo(
    () => {
      let keys = [...(uiSchema['ui:order'] || [])]
      for (const key in uiSchema) {
        if (key === 'ui:order') {
          continue
        }
        const i = keys.indexOf(key)
        if (hidesKey(uiSchema[key])) {
          if (i >= 0) {
            keys.splice(i, 1);
          }
          continue
        }
        if (i < 0) {
          keys.push(key)
        }
      }
      return keys
    },
    [uiSchema]
  )

  const [visibleMetadata,] = useMemo(
    () => {
      return stripKeys(metadata, (key: string, val: any) => {
        if ((uiSchema["ui:order"] || []).indexOf(key) < 0) {
          return false
        }
        if (key in uiSchema) {
          if (hidesKey(uiSchema[key])) {
            return false
          }
        }
        return true
      }, uiSchema['ui:order'] || null)
    },
    [metadata, uiSchema]
  )

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue)
    },
    [setTab]
  )

  const handleJsonChange = useCallback(
    (jsonObject) => {
      const [stripped,] = stripKeys(jsonObject, (key: string, val: any) => visibleKeys.indexOf(key) >= 0)
      const restoredMetadata = { ...metadata, ...stripped }
      if (validate(restoredMetadata)) {
        handleMetadataChange(restoredMetadata)
        handleErrorChange(null)
      }
      return validate.errors
        ? validate.errors.map(err => {
          let path = err.instancePath.split('/')
          if (path[0] === '') {
            path.shift()
          }
          return {
            path,
            message: err.message,
          }
        })
        : null
    },
    [
      metadata,
      visibleKeys,
      validate,
      handleMetadataChange,
      handleErrorChange,
    ]
  )

  return (
    <>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="JSON" />
        <Tab label="JSON Schema" />
      </Tabs>
      <TabPanel value={tab} index={0} style={{ marginBottom: '1rem' }}>
        <Alert
          severity="info"
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
        >
          Use the box below to input JSON-formatted text from which to pre-fill.<br />
          <em><strong>NOTE:</strong> Defaults to existing dataset metadata.</em>
        </Alert>
        <JsonEditor
          mode="code"
          value={visibleMetadata}
          onValidate={handleJsonChange}
          onValidationError={errors => handleErrorChange(
            errors.length
              ? errors.map(err => `${err.dataPath}: ${err.message}`).join(", ")
              : null
          )}
          ace={ace}
          theme="ace/theme/cobalt"
          htmlElementProps={{ style: { height: '800px' } }}
        />
      </TabPanel>
      <TabPanel value={tab} index={1} style={{ marginBottom: '1rem' }}>
        <Alert
          severity="info"
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
        >
          <StyledVerticalCenterDiv>
            <a href="https://json-schema.org/specification.html" target="_blank" rel="noreferrer">
              JSON Schema documentation
            </a>&nbsp;<LaunchIcon sx={{ fontSize: '1rem' }} />
          </StyledVerticalCenterDiv>
        </Alert>
        <JsonEditor
          mainMenuBar={false}
          mode="code"
          value={dataSchema}
          ace={ace}
          theme="ace/theme/cobalt"
          htmlElementProps={{ style: { height: '800px' } }}
          onEditable={() => false}
        />
      </TabPanel>
    </>
  )
})

export default PreFillJson
