import { useState, useEffect, useCallback, useRef } from 'react'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { inject, observer } from 'mobx-react'
import moment, { Moment } from 'moment'
import styled from '@emotion/styled'
import { map } from 'lodash'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'debounce'
import { FixedSizeList as ListWindow, ListOnScrollProps } from 'react-window'

import { nextRange } from '@extensions/utils/fileList'
import { getDownloadUrls } from '@extensions/services/DatasetService'
import { ISecurityService } from '@extensions/services/ISecurityService'
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress'
import ImageModal from '@extensions/components/dataset/ImageModal'
import Dataset, { StatSegment, Downloadable, Gap } from '@extensions/models/Dataset'
import { useIsMounted } from '@dapclient/hooks/useIsMounted'
import { useExternalSource } from '@extensions/hooks/useExternalSource'


interface IBrowserProps {
  dataset: Dataset
  extension: string
  timeline: StatSegment[]
  timelineIndex: number
  absoluteRange?: [Moment, Moment]
  filesPerRequest?: number
  onNavigate: (range: [Moment, Moment]) => void
  onClose: () => void
  securityService?: ISecurityService
}

const Outer = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  border: 1px solid #333;
  border-width: 1px 0;
  & > button {
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    transform: scale(0.5);
    position: absolute;
    top: 0;
    right: 0;
  }
  & > button:hover {
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }
`

const ListWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: auto;
  background-color: #ddd;
  color: #777;
  & > button {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  & > .left {
    transform: rotate:(90deg);
    left: 0;
  }
  & > .right {
    transform: rotate(-90deg);
    right: 0;
  }
`

const ListItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: ltr;
  & > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  & > .date-stamp {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.1rem 0.15rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-family: monospace;
    font-size: 70%;
    text-align: center;
  }
`

const Error = styled.div`
  font-family: monospace;
  color: red;
  font-size: 8pt;
`

const preloadImage = (callback: (width: number, height: number) => void, url: string) => {
  const img = new Image()
  img.src = url
  img.onload = () => {
    callback(img.width, img.height)
  }
  img.onerror = () => {
    callback(0, 0)
  }
}

const getGap = (i1: Downloadable, i2: Downloadable) => {
  return Math.abs(i1.date.diff(i2.date, 'days'))
}

const ImageBrowser = inject('securityService')(observer(
  ({
    securityService, dataset, extension, timeline, timelineIndex,
    absoluteRange, filesPerRequest, onNavigate, onClose,
  }: IBrowserProps) => {

    const isMounted = useIsMounted()

    const [xLoading, , xInstructions] = useExternalSource(dataset.externalSource)

    const [images, setImages] = useState(undefined as (Downloadable | Gap)[] | undefined)
    const [err, setErr] = useState(undefined as string | undefined)
    const [loading, setLoading] = useState(false)
    const [currentRightIndex, setCurrentRightIndex] = useState(timelineIndex)
    const [currentLeftIndex, setCurrentLeftIndex] = useState(timelineIndex)
    const [currentRange, setCurrentRange] = useState(
      [moment(), moment()] as [Moment, Moment]
    )
    const [imageDims, setImageDims] = useState({width: 0, height: 0})
    const [selectedImage, setSelectedImage] = useState(null as Downloadable | null)

    const listRef = useRef<HTMLDivElement>(null)
    const windowRef = useRef<HTMLDivElement>(null)
    const absoluteRangeRef = useRef(absoluteRange)

    const allExtensions = dataset.dynamoFullExtensions || []

    const datasetName = allExtensions.indexOf(extension) < 0
      ? (dataset.imagesDatasetName || dataset.name)
      : dataset.name

    const queryImages = useCallback(
      (beg: Moment, end: Moment, after: boolean) => {
        setLoading(true)
        getDownloadUrls(datasetName, [extension], [beg, end])
          .then(d => {
            if (!isMounted.current) {
              return
            }
            const byName = d.body.urls
            const toAdd = map(Object.keys(byName) as string[], name => {
              const parts = name.split('.')
              const extParts = extension.split('.')
              const date = parts[parts.length - extParts.length - 2]
              return  {
                name: name,
                date: moment(date, 'YYYYMMDD'),
                url: byName[name],
              } as Downloadable
            }).sort((a, b) => {
              if (a.name > b.name) {
                return -1
              }
              if (a.name < b.name) {
                return 1
              }
              return 0
            })
            if (imageDims.width === 0 || imageDims.height === 0) {
              preloadImage((width: number, height: number) => {
                if (width === 0 || height === 0 || listRef.current === null) {
                  return
                }
                setImageDims({
                  width: width * (listRef.current.clientHeight / height),
                  height: listRef.current.clientHeight,
                })
              }, toAdd[0].url)
            }
            let i = 1
            let withGaps = [] as (Downloadable | Gap)[]
            let last = images && images[images.length-1] as Downloadable
            let first = images && images[0] as Downloadable
            let gap = 0
            if (after && last && (gap = getGap(toAdd[0], last)) > 1) {
              withGaps.push({ndays: gap-1, date: toAdd[0].date.clone().add(1, 'days')})
            }
            while (i < toAdd.length) {
              withGaps.push(toAdd[i-1])
              if ((gap = getGap(toAdd[i], toAdd[i-1])) > 1) {
                withGaps.push({ndays: gap-1, date: toAdd[i].date.clone().add(1, 'days')})
              }
              i++
            }
            withGaps.push(toAdd[toAdd.length-1])
            if (!after && first && (gap = getGap(toAdd[toAdd.length-1], first)) > 1) {
              withGaps.push({ndays: gap-1, date: first.date.clone().add(1, 'days')})
            }
            if (after) {
              setImages([...(images || []), ...withGaps])
            } else {
              setImages([...withGaps, ...(images || [])])
            }
          })
          .catch((err: any) => {
            if (isMounted.current) {
              setErr(err.toString())
            }
          })
          .finally(() => {
            if (isMounted.current) {
              setLoading(false)
            }
          })
      },
      [datasetName, extension, listRef, images, isMounted, imageDims, setImageDims],
    )

    const handleScroll = useCallback(
      (props: ListOnScrollProps) => {
        if (listRef.current === null || imageDims.width === 0 || images === undefined) {
          return
        }
        const window = listRef.current.clientWidth
        const width = imageDims.width * (images.length + 2)
        const index = [
          Math.min(images.length-1, Math.floor(((props.scrollOffset + window) / width) * (images.length+2)) - 1),
          Math.max(0, Math.floor((props.scrollOffset / width) * (images.length+2)) - 1),
        ]
        let item0 = images[index[0]]
        let item1 = images[index[1]]
        let ndays = 'ndays' in item1 ? item1.ndays : 1
        onNavigate([
          item0.date.clone(),
          item1.date.clone().add(ndays, 'days'),
        ])
        // range = [
        //   'date' in beg ? beg.date.format('YYYY MM DD') : "-",
        //   'date' in end ? end.date.format('YYYY MM DD') : "-"
        // ]
      },
      [listRef, imageDims, images, onNavigate]
    )

    const handleImageLoaded = useCallback(
      (img: Downloadable) => img.downloaded = true,
      []
    )

    const handleModalClose = useCallback(
      () => setSelectedImage(null),
      [setSelectedImage]
    )

    const handleImageClick = useCallback(
      (img: Downloadable) => setSelectedImage(img),
      [setSelectedImage]
    )

    const loadRight = useCallback(
      () => {
        const [beg, end, index] = nextRange(
          true, timeline, currentRightIndex, currentRange,
          absoluteRange, filesPerRequest
        )
        if (beg && end) {
          setCurrentRightIndex(index)
          setCurrentRange([currentRange[0], end])

          queryImages(beg, end, false)
        }
      },
      [timeline, currentRightIndex, currentRange, filesPerRequest, absoluteRange, queryImages]
    )
    const noRight = (
      (
        currentRightIndex >= timeline.length-1 &&
        currentRange[1].isSameOrAfter(timeline[timeline.length-1].end)
      ) || (
        absoluteRange && absoluteRange[1].isSameOrBefore(currentRange[1])
      )
    )

    const loadLeft = useCallback(
      () => {
        const [beg, end, index] = nextRange(
          false, timeline, currentLeftIndex, currentRange,
          absoluteRange, filesPerRequest
        )
        if (beg && end) {
          setCurrentLeftIndex(index)
          setCurrentRange([beg, currentRange[1]])

          queryImages(beg, end, true)
        }
      },
      [timeline, currentLeftIndex, currentRange, filesPerRequest, absoluteRange, queryImages]
    )
    const noLeft = (
      (
        currentLeftIndex <= 0 &&
        currentRange[0].isSameOrBefore(timeline[0].beg)
      ) || (
        absoluteRange && absoluteRange[0].isSameOrAfter(currentRange[0])
      )
    )

    // Reset the image list when the range or index changes but not on first render
    const first = useRef(true)
    const reset = useRef(debounce(() => setImages(undefined), 1000))
    useEffect(() => {
      if (first.current) {
        first.current = false
        return
      }
      if (
        absoluteRange &&
        absoluteRangeRef.current &&
        absoluteRangeRef.current[0].isSame(absoluteRange[0]) &&
        absoluteRangeRef.current[1].isSame(absoluteRange[1])
      ) {
        return
      }
      absoluteRangeRef.current = absoluteRange
      reset.current()

    }, [timelineIndex, absoluteRange])

    useEffect(() => {
      if (images !== undefined || xLoading) {
        return
      }

      const [beg, end, nextIndex] = nextRange(
        false, timeline, timelineIndex, undefined, absoluteRange, filesPerRequest
      )

      if (beg && end) {
        setCurrentLeftIndex(nextIndex)
        setCurrentRange([beg, end])

        queryImages(beg, end, true)
      }

    }, [
      timeline,
      timelineIndex,
      queryImages,
      filesPerRequest,
      images,
      absoluteRange,
      xLoading,
    ])

    useEffect(() => {
      if (imageDims.width === 0 || imageDims.height === 0 || windowRef.current === null) {
        return
      }
      if (windowRef.current.scrollLeft === 0) {
        handleScroll({scrollDirection: "forward", scrollOffset: 0, scrollUpdateWasRequested: false})
      }
    }, [imageDims, handleScroll, windowRef])

    return (
      <Outer>
      {
        ((images === undefined && loading) || xLoading) && (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
            <CenteredCircularProgress size={12} />
          </div>
        )
      }
      {
        err && (
          <Error>[ {err} ]</Error>
        )
      }
      {
        Boolean(xInstructions) && xInstructions
      }
      { images && images.length > 0 && (
        <ListWrapper ref={listRef}>
          <ListWindow
            layout="horizontal"
            direction="rtl"
            itemCount={images.length+2}
            itemSize={imageDims.width || 150}
            height={listRef.current?.clientHeight || 0}
            width={listRef.current?.clientWidth || 0}
            outerRef={windowRef}
            onScroll={handleScroll}
          >
            {
              ({ index, style }) => {
                if (index === 0) {
                  return (
                    <ListItem style={style}>
                      <Button
                        disabled={noRight}
                        size="small"
                        endIcon={<ArrowForwardIcon />}
                        onClick={loadRight}
                      >Load More</Button>
                    </ListItem>
                  )
                }
                if (index > images.length) {
                  return (
                    <ListItem style={style}>
                      <Button
                        disabled={noLeft}
                        size="small"
                        startIcon={<ArrowBackIcon />}
                        onClick={loadLeft}
                      >Load More</Button>
                    </ListItem>
                  )
                }
                let item = images[index-1]
                if ('url' in item) {
                  let image = item as Downloadable
                  return (
                    <ListItem style={style} onClick={e => handleImageClick(image)}>
                      <span className="date-stamp">{image.date.format('YYYY MM DD')}</span>
                      {imageDims.width > 0 && (
                        <img alt={image.name} src={image.url} onLoad={e => handleImageLoaded(image)} />
                      )}
                    </ListItem>
                  )
                }
                let gap = item as Gap
                return (
                  <ListItem style={style}>
                    <em>{gap.ndays}-day gap</em>
                  </ListItem>
                )
              }
            }
          </ListWindow>
        </ListWrapper>
      )}
        <IconButton
          size="small"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <ImageModal
          open={selectedImage !== null}
          images={images ? images.filter(img => 'url' in img) as Downloadable[]: []}
          startImage={selectedImage}
          imageSizeRatio={imageDims.width / (imageDims.height || 1)}
          handleClose={handleModalClose}
        />
      </Outer>
    )
  }
))

export default ImageBrowser
