import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { AppBar, Tab, Tabs, Toolbar, Typography } from '@mui/material';

import { useCachingService } from '@extensions/hooks/useService'
import MarkdownText from '@extensions/components/core/MarkdownText'
import Repo from '@extensions/components/code-search/Repo'
import Code from '@extensions/components/code-search/Code'

import theme from '@extensions/services/Theme';

interface ICodeHubProps extends RouteComponentProps<{view: string}> {
}

const tabs = [
  {
    route: 'repo',
    label: 'Code Repository Search',
    component: Repo,
  },
  {
    route: 'search',
    label: 'Code Search',
    component: Code,
  },
]

const CodeHub = ({ match }: ICodeHubProps) => {
  const cache = useCachingService()
  const history = useHistory()
  const location = useLocation()
  const currentRoute = match.params['view']

  const [intro, setIntro] = useState('')

  const onTabChange = useCallback(route => {
    history.push(
      location.pathname.replace(
        new RegExp(`/${currentRoute}(/|$)`),
        `/${route}$1`
      )
    );
  }, [history, location, currentRoute])

  const tab = currentRoute ? tabs.find(
    (t, i) => t.route === currentRoute || (!currentRoute && i === 0)
  ) : tabs[0]

  useEffect(() => {
    if (!currentRoute && tab) {
      history.replace(`${location.pathname}/${tab.route}`)
    }
  })

  useEffect(() => {
    if (Boolean(intro)) {
      return
    }
    cache.getCodeHubIntro().then(md => setIntro(md))
  }, [intro, cache])

  if (!tab) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <>
      {Boolean(intro) && (
        <em>
          <MarkdownText
            rawMarkdown={intro}
            components={{
              p: ({ ...props }) => (
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.palette.grey[700], lineHeight: 1.6 }}
                  {...props}
                />
              ),
            }}
          />
        </em>
      )}
      <AppBar position="sticky" sx={{ marginBottom: (theme) => theme.spacing(2.2) }}>
        <Toolbar>
          <Tabs
            value={tab.route}
            onChange={(event, val) => onTabChange(val)}
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.grey[800],
              },
            }}
            sx={{
              '& .MuiTabs-indicator': {
                '@media (max-width: 500px)': {
                  display: 'none'
                },
              },
              '& .MuiTabs-flexContainer': {
                flexWrap: 'wrap',
                justifyContent: 'center',
              },
            }}
          >
            {tabs.map(t => (
              <Tab 
                key={t.route} 
                value={t.route} 
                label={t.label} 
                sx={{
                    color: theme.palette.common.black,
                    opacity: 0.7,
                    '& .MuiTabs-flexContainer': {
                      flexWrap: 'wrap'
                    },
                    '&.Mui-selected': {
                      color: 'black',
                      '@media (max-width: 500px)': {
                        textDecoration: 'solid underline 2px',
                        textUnderlineOffset: '15px'
                      },
                    },
                  }}
              />
            ))}
          </Tabs>
        </Toolbar>
      </AppBar>
      {React.createElement(tab.component, {})}
    </>
  )
}

export default CodeHub
