import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Dimension from '@extensions/models/Dimension';

const CustomTableContainer = ({ ...props }) => (
  <TableContainer {...props} />
);
const StyledContainer = styled(CustomTableContainer)(({
  maxHeight: '500px',
}));

const StyledTable = styled(Table)(({
  width: '100%',
  '& .MuiTableCell-root': {
    fontSize: '80% !important',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  '& td': {
    paddingLeft: '2rem',
    fontStyle: 'italic',
    color: theme.palette.grey[700],
  },
  '& svg': {
    height: '15px',
    width: '15px',
    position: 'relative',
    top: '-2px',
    marginRight: '2px',
  },
}));

export interface IDimensionsListProps {
  className?: string;
  dimensions: Dimension[];
}

export interface IDimensionsListState {}

@observer
export class DimensionsList extends React.Component<
  IDimensionsListProps,
  IDimensionsListState
> {
  render() {
    const { dimensions } = this.props;
    return (
      <StyledContainer
        component={Paper}
      >
        <StyledTable aria-label="measurements" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Interval</TableCell>
              <TableCell>Bin Placement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dimensions.map((dimension, index) => (
              <React.Fragment key={`${dimension.unit}-${index}`}>
                <TableRow>
                  <TableCell>{dimension.type}</TableCell>
                  <TableCell component="th" scope="row">
                    {dimension.unit}
                  </TableCell>
                  <TableCell>{dimension.averagingInterval ?? 'N/A'}</TableCell>
                  <TableCell>
                    {dimension.averagingBinPlacement ?? 'N/A'}
                  </TableCell>
                </TableRow>
                {/*
                    Screen readers can't communicate to the user that this
                    row is really part of the one above (it will read it off
                    as only having a cell for the first column). To make the
                    table accessible, the description is in the top row
                    for screen readers, but hidden from sighted users.
                  */}
                <StyledTableRow aria-hidden >
                  <TableCell colSpan={4}>
                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                      <SubdirectoryArrowRightIcon fontSize="small" />
                      {dimension.description ?? 'No Description Provided'}
                    </div>
                  </TableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </StyledContainer>
    );
  }
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))((DimensionsList));
