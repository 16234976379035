import React from 'react';
import { inject } from 'mobx-react';
import removeMd from 'remove-markdown';
import { Typography } from '@mui/material';

import Project from '@extensions/models/Project';
import {
  ProjectResult as DapProjectResult,
} from '@dapclient/components/project-search/ProjectResult';


export class ProjectResult extends DapProjectResult {
  getParticipatingOrgsPanel: (hit: Project) => React.ReactNode = () => null;
  getExpandableText: (hit: Project) => React.ReactNode = (hit) => {
    const splitedDescription = removeMd(hit.description.replace(/([#]+(.*))/g, '')).split(' ');
    let slicedDescription = splitedDescription.slice(0, 50).join(' ');
    if (splitedDescription.length > 50) {
      slicedDescription = slicedDescription + ' ...';
    }
    return (
      <Typography variant='body1'>
        {slicedDescription}
      </Typography>
    )
  }
}


export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(ProjectResult);
