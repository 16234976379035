import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IconButtonProps } from '@rjsf/utils';

export default function AddButton(props: IconButtonProps) {
    const { className, disabled, onClick } = props;
    return (
        <Button className={className} disabled={disabled} onClick={onClick} color="secondary">
            <AddIcon /> Add Item
        </Button>
    );
}
