import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';

export const DATA_LEVEL_REACTIVE_ID = 'DataLevelFilter';

export default class DataLevelFilter extends React.Component<{
  react?: react;
}> {
  render = () => {
    return (
      <MultiList
        className="data-filter"
        componentId={DATA_LEVEL_REACTIVE_ID}
        dataField="dataLevel.keyword"
        title="Data Level"
        filterLabel="Level"
        showSearch={false}
        react={this.props.react}
        URLParams={true}
      />
    );
  };
}
