import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

import MetaDocument from '@extensions/models/MetaDocument';
import SplitView from '@extensions/components/metadata/review/SplitView';
import BackToLandingPage from '@extensions/components/metadata/BackToLandingPage';

const StyledRootDiv = styled('div')(({}));

export interface IReadOnlyDocProps {
  className?: string;
  document: MetaDocument;
}

export interface IReadOnlyDocState { }

@observer
export class ReadOnlyDoc extends React.Component<
  IReadOnlyDocProps,
  IReadOnlyDocState
> {
  render() {
    const { document } = this.props;
    return (
      <StyledRootDiv>
        <BackToLandingPage />
        <Typography variant="h1" sx={{ fontSize: '3rem !important' }}>
          Metadata for {document.datasetName}
        </Typography>
        <Typography sx={{ marginBottom: '1rem' }}>
          You do not have permission to edit this metadata. If you need to make
          edits, please contact us.
        </Typography>
        <Paper sx={{
          padding: '1rem',
          marginBottom: '1rem'
        }}>
          <SplitView document={document} />
        </Paper>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({}))(ReadOnlyDoc);
