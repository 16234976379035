import React from 'react';
import { observer } from 'mobx-react';
import {
  faLockOpen,
  faLock,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';

import { styled } from '@mui/material';

import theme from '@extensions/services/Theme';
import { useSecurityService } from '@extensions/hooks/useService';

import AddIcon from '@extensions/utils/AddIcon';
import Link from '@dapclient/components/core/Link';
import CartLink from '@extensions/components/nav/CartLink';
import SignInLink from '@dapclient/components/core/SignInLink';

const StyledRootDiv = styled('div')(({
  padding: '0.5rem 1rem',
  background: theme.palette.secondary.light,
  color: 'rgb(238, 238, 238)',
  fontWeight: 100,
  lineHeight: 1.5,
}));

const StyledButton = styled('button')(({
  border: 0,
  backgroundColor: 'transparent',
  padding: 0,
  cursor: 'pointer',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.common.white,
  },
  '&:visited': {
    color: '#eee',
  },
}));

const StyledSepSpan = styled('span')(({
  display: 'inline-block',
  padding: '0 0.5rem',
  color: theme.palette.secondary.contrastText,
}));

export interface ISignInAndRegisterProps {
  className?: string;
}

const SignInAndRegister = () => {

  const securityService = useSecurityService();

  const { user } = securityService;

  const loggedIn = Boolean(user && user.authenticated);
  const withMfa = Boolean(loggedIn && user?.authenticatedMfa);

  const firstName = user !== null ? user.firstname : "";
  const lastName = user !== null ? user.lastname : "";

  const LinkStyleObject = {
    fontWeight: theme.typography.fontWeightBold,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&:visited': {
      color: '#eee',
    },
  }

  const sep = (
    <StyledSepSpan>&bull;</StyledSepSpan>
  )

  return (
    <StyledRootDiv>
      {loggedIn && (
        <>
          Welcome,&nbsp;
          <Link to="/profile" sx={LinkStyleObject}>
            {`${firstName} ${lastName} `}
            <AddIcon
              icon={withMfa ? faLock : faLockOpen}
              title={withMfa ? "Logged in with MFA token" : "NOT logged in with MFA token"}
            />
          </Link>
          {sep}
          <Link
            onClick={async () => {
              await securityService!.logout();
              window.location.href = '/';
            }}
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.common.white,
              cursor: 'pointer',
            }}
          >
            Sign Out <AddIcon icon={faSignOutAlt} />
          </Link>
        </>
      )}
      {!loggedIn && (
        <>
          <SignInLink styleObject={LinkStyleObject}>
            Sign In
          </SignInLink>
          {sep}
          <Link to="/register" sx={LinkStyleObject}>
            Register
          </Link>
        </>
      )}
      {sep}
      <CartLink styleObject={{ color: '#ffffff' }} />
    </StyledRootDiv>
  );
};

export default observer(SignInAndRegister);
