import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';

import AddIcon from '@extensions/utils/AddIcon';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

const StyledAddIcon = styled(AddIcon)(({
  alignSelf: 'center',
  display: 'inline-block',
  margin: '0 0.25rem',
  color: '#2ECC71',
}))

export interface IDiffPlusProps {
  className?: string;
}

export interface IDiffPlusState { }

@observer
export class DiffPlus extends React.Component<
  IDiffPlusProps,
  IDiffPlusState
> {
  render() {
    return (
      <StyledAddIcon
        icon={faPlus}
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(DiffPlus);
