import React from 'react';
import { inject } from 'mobx-react';
import {
  MustSignInAlert as DapMustSignInAlert,
} from '@dapclient/components/core/MustSignInAlert';
import Link from '@extensions/components/core/Link';
import SignInLink from '@extensions/components/core/SignInLink';

export class MustSignInAlert extends DapMustSignInAlert {
  renderSignInLink = (): React.ReactNode => {
    return <SignInLink styleObject={{ display: 'contents' }}>Sign In</SignInLink>;
  };

  renderRegisterLink = (): React.ReactNode => {
    return <Link to="/register">Sign Up</Link>;
  };
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(MustSignInAlert);
