import React from 'react';
import { observer, inject } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Typography, Chip } from '@mui/material';
import { chipClasses } from '@mui/material/Chip';

import theme from '@extensions/services/Theme';
import { ICartService } from '@extensions/services/ICartService';

import { FilterTitle } from '@extensions/components/search-core';

const StyledRootDiv = styled('div')(({theme}) => (({
  marginBottom: theme.spacing(2),
})));

const StyledChip = styled(Chip)(({
  [`&.${chipClasses.filled}`]: {
    backgroundColor: "#e0e0e0",
    borderRadius: '16px'
  },
  "&.MuiChip-root": {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    maxWidth: '100%',
  },
  "&.MuiChip-label": {
    flex: '0 1 auto'
  },
  "&.MuiSvgIcon-root": {
    flex: '0 0 auto'
  }
}));

export interface IAppliedFiltersProps {
  className?: string;
  cartService?: ICartService;
}

@inject('cartService')
@observer
class AppliedFilters extends React.Component<IAppliedFiltersProps> {
  render() {
    const { cartService } = this.props;
    if (!cartService) {
      return null;
    }
    const filters: { label: string; onClear: () => void }[] = [];
    if (cartService.dateRangeFilterStr) {
      filters.push({
        label: cartService.dateRangeFilterStr,
        onClear: () => {
          cartService.setDateRangeFilter(null);
        },
      });
    }

    for (const { displayStr, label } of cartService.extensionsFilterStrs) {
      filters.push({
        label: `${label}: ${displayStr}`,
        onClear: () => cartService.clearExtensionFilter(label),
      });
    }

    if (cartService.fileTypesFilterStr) {
      filters.push({
        label: cartService.fileTypesFilterStr,
        onClear: cartService.clearFileTypesFilter,
      });
    }

    let body: React.ReactNode;
    if (filters.length === 0) {
      body = <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>No Applied Filters</Typography>;
    } else {
      body = filters.map(filter => (
        <StyledChip
          onDelete={filter.onClear}
          key={filter.label}
          label={filter.label}
        />
      ));
    }
    return (
      <StyledRootDiv>
        <FilterTitle>Applied Filters</FilterTitle>
        {body}
      </StyledRootDiv>
    );
  }
}

export default AppliedFilters;
