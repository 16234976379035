import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
import { inject, observer } from 'mobx-react';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { styled } from '@mui/material/styles';
import Measurement from '@extensions/models/Measurement';

const CustomTableContainer = ({ ...props }) => (
  <TableContainer {...props} />
);
const StyledContainer = styled(CustomTableContainer)(({
  maxHeight: '500px',
}));

const StyledTable = styled(Table)(({
  width: '100%',
  '& .MuiTableCell-root': {
    fontSize: '80% !important',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  '& td': {
    paddingLeft: '2rem',
    fontStyle: 'italic',
    color: theme.palette.grey[700],
  },
  '& svg': {
    height: '15px',
    width: '15px',
    position: 'relative',
    top: '-2px',
    marginRight: '2px',
  },
}));

const StyledDiv = styled('div')(({
  height: '1rem',
}));

export interface IMeasurementsListProps {
  className?: string;
  measurements: Measurement[];
}

export interface IMeasurementsListState {}

@observer
export class MeasurementsList extends React.Component<
  IMeasurementsListProps,
  IMeasurementsListState
> {

  renderTable(measurements: Measurement[]) {
    return (
      <StyledContainer
        component={Paper}
      >
        <StyledTable aria-label="measurements" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name (Unit)</TableCell>
              <TableCell>Standardized (Unit)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measurements.map((measurement, index) => (
              <React.Fragment key={`${measurement.specificName}-${index}`}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>
                      <code>
                        {measurement.specificName} ({measurement.unit || 'N/A'})
                      </code>
                    </strong>
                  </TableCell>
                  <TableCell>
                    {measurement.standardName
                      ? measurement.standardName.displayName
                      : 'N/A'
                    }{' '}
                    {
                      Boolean(measurement.standardUnit && measurement.standardUnit.displayName) && (
                        <code>
                          ({measurement.standardUnit?.displayName})
                        </code>
                      )
                    }
                  </TableCell>
                </TableRow>
                {/*
                    Screen readers can't communicate to the user that this
                    row is really part of the one above (it will read it off
                    as only having a cell for the first column). To make the
                    table accessible, the description is in the top row
                    for screen readers, but hidden from sighted users.
                  */}
                <StyledTableRow aria-hidden>
                  <TableCell colSpan={2}>
                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                      <SubdirectoryArrowRightIcon fontSize="small" />
                      {measurement.description ?? 'No Description Provided'}
                    </div>
                  </TableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </StyledContainer>
    );
  }

  render() {
    const { measurements } = this.props;
    const primary = measurements.filter(m => m.isPrimary);
    const nonPrimary = measurements.filter(m => !m.isPrimary);
    return (
      <div>
        {primary.length > 0 && (
          <div>
            <Typography variant='h3' sx={{ fontSize: '16px', paddingBottom: '10px' }}>Primary Measurements</Typography>
            <div>
              {this.renderTable(primary)}
            </div>
          </div>
        )}
        {primary.length > 0 && nonPrimary.length > 0 && (
          <StyledDiv></StyledDiv>
        )}
        {nonPrimary.length > 0 && (
          <div>
            <Typography variant='h3' sx={{ fontSize: '16px', paddingBottom: '10px' }}>Ancillary Measurements</Typography>
            <div>
              {this.renderTable(nonPrimary)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(MeasurementsList);
