import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material';

import AddIcon from '@extensions/utils/AddIcon';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Link, { ILinkProps } from '@extensions/components/core/Link';

const StyledRootDiv = styled('div')(({
  display: 'flex',
  alignItems: 'center',
}));

const StyledLink = styled(Link)(({
  fontSize: '1.25rem',
}));

const StyledAddIcon = styled(AddIcon)(({
  marginRight: '0.5rem',
}));

export interface IBreadcrumbProps {
  className?: string;
  to: ILinkProps['to'];
  name: string;
}

export interface IBreadcrumbState { }

@observer
export class Breadcrumb extends React.Component<
  IBreadcrumbProps,
  IBreadcrumbState
> {
  render() {
    const { to, name } = this.props;
    return (
      <StyledRootDiv>
        <StyledAddIcon
          icon={faChevronLeft}
          size="sm"
        />
        <StyledLink to={to}>
          {name}
        </StyledLink>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(Breadcrumb);
