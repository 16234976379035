import React from 'react';
import { inject, observer } from 'mobx-react';

import { Typography, Alert } from '@mui/material';

import { ISecurityService } from '@extensions/services/ISecurityService';

import MustSignInAlert from '@extensions/components/core/MustSignInAlert';
import AwaitApprovalAlert from '@extensions/components/core/AwaitApprovalAlert';
import MustVerifyEmailAlert from '@extensions/components/core/MustVerifyEmailAlert';

export enum GuardResult {
  ALLOWED,
  NOT_AUTHENTICATED,
  NOT_VERIFIED,
  NOT_APPROVED,
  DEFAULT_NOT_AUTHORIZED,
}

export interface IMetadataGuardProps {
  children: React.ReactNode;
  securityService?: ISecurityService;
}

export interface IMetadataGuardState { }

@observer
export class MetadataGuard extends React.Component<
  IMetadataGuardProps,
  IMetadataGuardState
> {
  check = (): GuardResult => {
    throw new Error('NOT IMPLEMENTED');
  };

  renderNotAuthenticated = (): React.ReactNode => {
    return <MustSignInAlert actionDescription="manage metadata" />;
  };

  renderNotVerified = (): React.ReactNode => {
    return <MustVerifyEmailAlert />;
  };

  renderNotApproved = (): React.ReactNode => {
    return <AwaitApprovalAlert />;
  };

  renderDefaultNotAuthorized = (): React.ReactNode => {
    return (
      <Alert severity="error">You are not allowed to view this page</Alert>
    );
  };

  render() {
    const { children } = this.props;
    const result = this.check();
    if (result === GuardResult.ALLOWED) {
      return children;
    }

    let body: React.ReactNode;
    switch (result) {
      case GuardResult.NOT_AUTHENTICATED:
        body = this.renderNotAuthenticated();
        break;
      case GuardResult.NOT_VERIFIED:
        body = this.renderNotVerified();
        break;
      case GuardResult.NOT_APPROVED:
        body = this.renderNotApproved();
        break;
      case GuardResult.DEFAULT_NOT_AUTHORIZED:
        body = this.renderDefaultNotAuthorized();
        break;
    }
    return (
      <>
        <Typography variant="h1" sx={{ marginBottom: '1rem' }}>
          Not Authorized
        </Typography>
        {body}
      </>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
}))(MetadataGuard);
