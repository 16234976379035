import React from 'react';
import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';

import cityGraphic from '@extensions/resources/CityGraphic.png';

const PaddedContent = styled('div')`
  font-family: 'Open Sans',sans-serif;
  background-color: 'rgba(84, 95, 102, 1)';
  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }
  color: white;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: white;
  }
  .MuiLink-button,
  a {
    color: navajowhite;
  }

  .MuiLink-button:hover,
  a:hover {
    color: #fbdeca;
  }
`;

const ImageTextContainer = styled.div`
  position: relative;
  text-align: left;
  color: white;
  h2 {
    color: white;
    font-weight: bold;
  }
`;

const CityImage = styled.img`
  float: left;
  padding-top: 40px;
  padding-left: 10px;
  width: 750px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;
const ImageText = styled.div`
  @media only screen and (min-width: 751px) {
    position: absolute;
  }
`;

const AboutText = styled(ImageText)`
  h2 {
    font-size: 21px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  @media only screen and (min-width: 751px) {
    top: 33px;
    left: 0px;
    h2 {
      width: 450px;
      font-weight: 700;
    }
    p {
      width: 365px;
      font-feature-settings: "tnum";
      margin-top: 0;
      font-feature-settings: "tnum", "tnum";
      font-variant-alternates: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: tabular-nums;
      font-variant-position: normal;
      text-align: left;
      text-size-adjust: 100%;
    }
  }
`;
const DataTypeText = styled(ImageText)`
  h2 {
    font-size: 21px;
    font-weight: 700;
  }
  p, ul, li {
    font-size: 14px;
    line-height: 1.6;
  }
  @media only screen and (min-width: 751px) {
    top: 405px;
    left: 430px;
    width: 465px;
    h2 {
      width: 450px;
      font-size: 21px;
      font-weight: 700;
    }
    p, ul, li {
      width: 365px;
      font-size: 14px;
      line-height: 1.6;
      font-feature-settings: "tnum";
      margin-top: 0;
      font-feature-settings: "tnum", "tnum";
      font-variant-alternates: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: tabular-nums;
      font-variant-position: normal;
      text-align: left;
      text-size-adjust: 100%;
    }
  }
`;
const DataUseText = styled(ImageText)`
  h2 {
    font-size: 21px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  @media only screen and (min-width: 751px) {
    top: 822px;
    left: 0px;
    width: 304px;
    h2 {
      width: 450px;
    }
    p {
      width: 365px;
      font-feature-settings: "tnum";
      margin-top: 0;
      font-feature-settings: "tnum", "tnum";
      font-variant-alternates: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: tabular-nums;
      font-variant-position: normal;
      text-align: left;
      text-size-adjust: 100%;
    }
  }
`;
const ProvideDataText = styled(ImageText)`
  h2 {
    font-size: 21px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  @media only screen and (min-width: 751px) {
    top: 1022px;
    left: 430px;
    width: 304px;
    h2 {
      width: 450px;
    }
    p {
      width: 365px;
      font-feature-settings: "tnum";
      margin-top: 0;
      font-feature-settings: "tnum", "tnum";
      font-variant-alternates: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: tabular-nums;
      font-variant-position: normal;
      text-align: left;
      text-size-adjust: 100%;
    }
  }
`;

export interface IHomeProps {
  className?: string;
}

export interface IHomeState { }

@observer
export class Home extends React.Component<IHomeProps, IHomeState> {
  renderContent() {
    return (
      <PaddedContent>
        <ImageTextContainer>
          <CityImage src={cityGraphic} alt="city" />
          <AboutText>
            <h2>What is the Livewire Data Platform?</h2>
            <p>
              The Livewire Data Platform collects, preserves, curates, and disseminates a catalog of
              transportation and mobility-related projects. It allows transportation researchers,
              industry, and academic partners to increase the visibility of their projects
              within the research community, securely share and preserve data, and leverage
              datasets from other projects. The Livewire Data Platform supports the U.S.
              Department of Energy’s Office of Energy Efficiency and Renewable
              Energy’s (EERE) goal of providing an affordable, efficient, safe, and
              accessible transportation future where mobility is decoupled from energy consumption.
            </p>
          </AboutText>
          <DataTypeText>
            <h2>What types of data are there?</h2>
            <p>
              The Livewire Data Platform includes behavioral,
              experimental, modeled, analytical, and raw data
              at the vehicle, traveler, and system level to
              support research into:
            </p>
            <ul>
              <li>Urban Science</li>
              <li>Connected and Automated Vehicles</li>
              <li>Alternative Fueling Infrastructure</li>
              <li>Mobility Decision Science</li>
              <li>Multimodal Transportation</li>
              <li>Vehicle Efficiency</li>
            </ul>
          </DataTypeText>
          <DataUseText>
            <h2>How can I use the data?</h2>
            <p>
              The Livewire Data Platform houses data to support your
              transportation projects and decision-making efforts.
              Information housed in Livewire (including data, metadata, reference
              documents and publications studying the impact of a variety of
              advanced technologies on transportation energy consumption) helps
              communities incorporate energy efficiency into mobility planning.
            </p>
          </DataUseText>
          <ProvideDataText>
            <h2>CAN I UPLOAD DATA TO LIVEWIRE?</h2>
            <p>
              Livewire is open to the public and lets any user upload
              data directly to the platform where it will be stored
              and maintained by the Livewire team at no cost to users.
              After setting up an account and working with the Livewire team to
              create a project page, data owners can upload their own data, as
              well as reference documents and publications. For more information
              about this feature, contact the Livewire team at livewirecontact@lyris.pnnl.gov.
            </p>
          </ProvideDataText>
        </ImageTextContainer>
      </PaddedContent>
    );
  }
  render() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(Home);
