import {
    FormContextType,
    ObjectFieldTemplateProps,
    RJSFSchema,
    StrictRJSFSchema,
    descriptionId,
    getTemplate,
    getUiOptions,
    titleId,
} from '@rjsf/utils';
import Grid from '@mui/material/Grid';

/** The `ObjectFieldTemplate` is the template to use to render all the inner properties of an object along with the
 * title and description if available. If the object is expandable, then an `AddButton` is also rendered after all
 * the properties.
 * Tweak: `AddButton` is not needed due to this ObjectFieldTemplate is being used in the SummaryPanel.tsx, where content is not editable.
 * @param props - The `ObjectFieldTemplateProps` for this component
 */
export default function ObjectFieldTemplate<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: ObjectFieldTemplateProps<T, S, F>) {
    const {
        description,
        title,
        properties,
        required,
        uiSchema,
        idSchema,
        schema,
        registry,
    } = props;
    const uiOptions = getUiOptions<T, S, F>(uiSchema);
    const TitleFieldTemplate = getTemplate<'TitleFieldTemplate', T, S, F>('TitleFieldTemplate', registry, uiOptions);
    const DescriptionFieldTemplate = getTemplate<'DescriptionFieldTemplate', T, S, F>(
        'DescriptionFieldTemplate',
        registry,
        uiOptions
    );
    return (
        <>
            {title && (
                <TitleFieldTemplate
                    id={titleId<T>(idSchema)}
                    title={title}
                    required={required}
                    schema={schema}
                    uiSchema={uiSchema}
                    registry={registry}
                />
            )}
            {description && (
                <DescriptionFieldTemplate
                    id={descriptionId<T>(idSchema)}
                    description={description}
                    schema={schema}
                    uiSchema={uiSchema}
                    registry={registry}
                />
            )}
            <Grid container={true} spacing={1} style={{ marginTop: '10px' }}>
                {properties.map((element, index) => {// Remove the <Grid> if the inner element is hidden as the <Grid>
                    // itself would otherwise still take up space.
                    // element.content.props.readonly = true;
                    // element.content.props.disabled = true;
                    return element.hidden ? (
                        element.content
                    ) : (
                        <Grid item={true} xs={12} key={index} style={{ marginBottom: '10px' }}>
                            {element.content}
                        </Grid>
                    )
                }
                )}
            </Grid>
        </>
    );
}
