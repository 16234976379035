import React from 'react';
import { FieldProps } from '@rjsf/utils';
import { inject, observer } from 'mobx-react';

import { default as BaseFileUploadField } from '@extensions/components/metadata/FileUploadField';

export interface IFileUploadFieldProps extends FieldProps {
  className?: string;
}

export interface IFileUploadFieldState { }

@observer
export class FileUploadField extends React.Component<
  IFileUploadFieldProps,
  IFileUploadFieldState
> {
  render() {
    return <BaseFileUploadField {...this.props} inReview />;
  }
}

const Wrapped = inject((store: any) => ({
  // Add services here
}))(FileUploadField);

export default function FileUploadFieldFunc(props: FieldProps) {
  return <Wrapped {...props} />;
}
