import React from 'react';
import { inject, observer } from 'mobx-react';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

import { styled } from '@mui/material/styles';

import AddIcon from '@extensions/utils/AddIcon';

const StyledAddIcon = styled(AddIcon)(({
  alignSelf: 'center',
  display: 'inline-block',
  margin: '0 0.25rem',
  color: '#E74C3C',
}))

export interface IDiffMinusProps {
  className?: string;
}

export interface IDiffMinusState { }

@observer
export class DiffMinus extends React.Component<
  IDiffMinusProps,
  IDiffMinusState
> {
  render() {
    return (
      <StyledAddIcon
        icon={faMinus}
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(DiffMinus);
