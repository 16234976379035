/* eslint-disable @typescript-eslint/no-useless-constructor */
import { default as BaseSecurityService } from '@dapclient/services/SecurityService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { INotificationService } from '@extensions/services/INotificationService';
import { ITokenService } from '@extensions/services/ITokenService';
import { makeObservable, action, runInAction, override } from 'mobx';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import UserTool from '@extensions/models/UserTool';

export default class SecurityService extends BaseSecurityService {
  constructor(
    historyService: IHistoryService,
    notificationService: INotificationService,
    tokenService: ITokenService
  ) {
    super(historyService, notificationService, tokenService);
    makeObservable(this);
  }

  @action
  loadUserTools = () => {
    if (!this.userIsLoggedIn || !this.user) {
      return;
    }
    const user = this.user;
    const load = async () => {
      const tools = [
        new UserTool({
          url: '/profile',
          label: 'Profile',
          icon: 'vcard',
        }),
        new UserTool({
          url: '/profile/orders',
          label: 'Data Orders',
          icon: 'shopping-basket',
        }),
      ];
      if (this.user && this.user.canEdit) {
        tools.push(
          new UserTool({
            url: '/metadata',
            label: 'Submit Metadata',
            icon: 'edit',
          })
        );
        tools.push(
          new UserTool({
            url: '/upload',
            label: 'Submit Data',
            icon: 'cloud-upload',
          })
        );
      }
      const resp = await DapApiAgent.agent.get('/api/external-apps');
      const externalTools = resp.body;
      tools.push(
        ...externalTools.map(
          (tool) =>
            new UserTool({
              url: tool.url,
              label: tool.title,
              icon: 'tasks',
              external: true,
            })
        )
      );

      runInAction(() => (user.tools = tools));
    };
    this.notificationService.showStateInUI({
      pending: load(),
      notificationId: 'LOAD_USER_TOOLS',
      errorMessage: 'Could not load external tools',
      errorDescription: 'Please contact us if this issue continues',
    });
  };

  @override
  updateAuthenticatedUser(userData: any): void {
    super.updateAuthenticatedUser(userData);
    this.loadUserTools();
  }
}
