import React from 'react';
import { styled } from '@mui/material';
import { FieldProps } from '@rjsf/utils';
import { inject, observer } from 'mobx-react';
import { Card, CardContent, Typography, Divider } from '@mui/material';
import { action, runInAction, observable, makeObservable } from 'mobx';

import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';

import Reference from '@extensions/models/Reference';
import RefFile from '@extensions/models/RefFileUpload';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import FileUpload from '@extensions/components/core/FileUpload';
import { getTitle, getDescription } from '@extensions/utils/metadata';
import RefReviewTable from '@extensions/components/metadata/RefReviewTable';
import RefFileSelector from '@extensions/components/metadata/RefFileSelector';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledRootCard = styled(Card)(({}));

const StyledTitleBarDiv = styled('div')(({
  margin: '5px 0',
}));

const StyledSubheadTypography = styled(Typography)(({
  marginBottom: '0.5rem',
}));

export interface IFileUploadFieldProps extends FieldProps {
  className?: string;
  notificationService?: INotificationService;
  inReview?: boolean;
}

export interface IFileUploadFieldState { }

@observer
export class FileUploadField extends React.Component<
  IFileUploadFieldProps,
  IFileUploadFieldState
> {
  private notificationId = 'FileUploadField';
  @observable
  files: RefFile[] | null = null;
  @observable
  selectedFiles: Set<string> = new Set();

  constructor(props: IFileUploadFieldProps) {
    super(props);
    makeObservable(this);
  }

  get urls() {
    const { formContext } = this.props;
    return formContext.fileUploadUrls;
  }
  get uploadConfig() {
    const { uploadRefFile } = this.urls;
    return {
      postUrl: uploadRefFile,
    };
  }
  djsConfig = {};

  componentDidMount() {
    this.loadFiles();
  }

  @action
  loadFiles = async () => {
    this.files = null;
    try {
      const resp = await DapApiAgent.agent.get(this.urls.listRefFiles);
      runInAction(() => (this.files = resp.body));
    } catch {
      this.props.notificationService?.addNotification(
        this.notificationId,
        Status.Error,
        'Failed to Load Files',
        'Could not load files for display in form'
      );
    }
  };

  @action
  toggleFile = (name: string) => {
    if (this.selectedFiles.has(name)) {
      this.selectedFiles.delete(name);
    } else {
      this.selectedFiles.add(name);
    }
  };

  @action
  successCallback = () => {
    this.loadFiles();
  };

  getRefsFromProps = (): Reference[] | undefined => {
    const { formData } = this.props;
    if (!formData) {
      return undefined;
    }

    return formData.map((refItem) => {
      const output = { ...refItem };
      if (
        refItem.fileExtension != null &&
        refItem.fileType != null &&
        refItem.fileSize != null
      ) {
        // Badly formatted due to previous bug
        output.custom = {
          fileSize: refItem.size,
          fileType: refItem.mime,
          fileExtension: refItem.extension,
        };
      }
      return output;
    });
  };

  render() {
    const { schema, uiSchema, inReview } = this.props;

    const step2Id = 'select-files-subheader';
    const references = this.getRefsFromProps();
    let body: React.ReactNode;
    if (inReview) {
      if (references && references.length > 0) {
        body = <RefReviewTable references={references} labeledBy={step2Id} />;
      }
    } else {
      let fileList: React.ReactNode;
      if (this.files === null) {
        fileList = <CenteredCircularProgress />;
      } else if (this.files.length === 0) {
        fileList = <Typography>No files found.</Typography>;
      } else {
        fileList = (
          <RefFileSelector
            references={this.getRefsFromProps()}
            uploadedFiles={this.files}
            onReferencesChange={this.props.onChange}
            labeledBy={step2Id}
          />
        );
      }
      body = (
        <>
          <StyledSubheadTypography variant="h6" id={step2Id}>
            Step 1 (optional): Upload an Attachment
          </StyledSubheadTypography>
          <FileUpload
            config={this.uploadConfig}
            djsConfig={this.djsConfig}
            eventHandlers={{
              success: this.successCallback,
            }}
          />

          <StyledSubheadTypography variant="h6" >
            Step 2: Select Uploaded Files to Attach
          </StyledSubheadTypography>
          {fileList}
        </>
      );
    }

    return (
      <StyledRootCard>
        <CardContent>
          <StyledTitleBarDiv>
            <Typography variant="h5">
              {getTitle({ schema, uiSchema })}
            </Typography>
            <Divider />
          </StyledTitleBarDiv>

          <Typography variant="subtitle2" sx={{ mb: '1rem' }}>
            {getDescription({ schema, uiSchema })}
          </Typography>

          {body}
        </CardContent>
      </StyledRootCard>
    );
  }
}

const Wrappped = inject((store: any) => ({
  notificationService: store.notificationService,
}))(FileUploadField);

export default function FileUploadFieldFunc(props: FieldProps) {
  return <Wrappped {...props} />;
}
