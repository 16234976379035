import React, { ReactElement } from 'react';

import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  Bar,
  Tooltip,
  XAxis,
} from 'recharts';
import filesize from 'filesize';
import { Typography, styled } from '@mui/material';
import { faCloud, faFile, faChartBar } from '@fortawesome/free-solid-svg-icons';

import {
  StatsBar as DapStatsBar
} from '@dapclient/components/metrics/StatsBar';
import dapTheme from '@extensions/services/Theme';
import Stat from '@extensions/components/metrics/Stat';
import MetricsUtils from '@extensions/utils/MetricsUtils';

const StyledTypography = styled(Typography)(({theme}) => (({
  color: theme.palette.grey[700]
})));

class ProjectStatsBar extends DapStatsBar {
  renderTotalDynamoSizesStats = () => {
    const files = this.props.item.getDynamoFileCount();
    const sizes = this.props.item.getDynamoTotalFileSize();
    const childCount = this.props.item.getChildCount();
    let fileCountStat: ReactElement = <></>;
    let fileSizesStat: ReactElement = <></>;
    let childCountStat: ReactElement = <></>;
    childCountStat = (
      <Stat
        variant="beside"
        value={childCount ? childCount.toLocaleString() : '-'}
        description="Datasets"
        icon={faChartBar}
        iconSize={'2x'}
        fixedWidth
      />
    );
    fileCountStat = (
      <Stat
        variant="beside"
        value={files ? files.toLocaleString() : '-'}
        description="Files Stored"
        icon={faFile}
        iconSize={'2x'}
        fixedWidth
      />
    );
    fileSizesStat = (
      <Stat
        variant="beside"
        value={sizes ? `${filesize(sizes)}` : '-'}
        description="Stored"
        icon={faCloud}
        iconSize={'2x'}
        fixedWidth
      />
    );
    return (
      <>
        {childCountStat}
        {fileSizesStat}
        {fileCountStat}
      </>
    );
  };

  getXAxis = (xAxisInterval) => {
    return (
      <XAxis
        mirror={true}
        // hide
        dataKey="month"
        tickFormatter={(value) =>
          MetricsUtils.formatXAxisTick(value, xAxisInterval)
        }
        tick={false}
        interval={xAxisInterval}
      />
    );
  };

  renderDownloadHistoryCharts = () => {
    const stats = this.props.item.getDownloadStats();

    if (stats.length === 0) {
      return null;
    }

    // currently not showing ticks so most of this code is moot, leaving for
    // now to demo with and without ticks easily
    let xAxisInterval = 11;
    if (stats.length < 12) {
      // otherwise if there is less than 1 year of stats show every tick
      xAxisInterval = 0;
    } else if (stats.length < 12 * 3) {
      // otherwise if there is less than 3 years of stats show the tick every other month
      xAxisInterval = 2;
    } else if (stats.length < 12 * 4) {
      // otherwise if there is less than 5 years of stats show the tick every 6 months
      xAxisInterval = 5;
    }
    const barColor = MetricsUtils.getBarColor(dapTheme);
    const xAxis = this.getXAxis(xAxisInterval);
    let downloadDateRange = `(${MetricsUtils.formatDownloadsTooltipLabel(
      stats[0].month
    )}`;
    if (stats.length > 1) {
      downloadDateRange += ` - ${MetricsUtils.formatDownloadsTooltipLabel(
        stats[stats.length - 1].month
      )}`;
    }
    downloadDateRange += ')';

    return (
      <div>
        <ResponsiveContainer height={150} width="100%">
          <BarChart data={stats}>
            {xAxis}
            <YAxis
              mirror={true}
              hide
              tickFormatter={MetricsUtils.formatYAxisFileSizesTick}
              scale={stats.length > 1 ? "log" : "auto"}
              domain={['auto', 'auto']}
            />
            <Tooltip
              labelFormatter={MetricsUtils.formatDownloadsTooltipLabel}
              formatter={MetricsUtils.formatFileSizesTooltip}
            />
            <Bar dataKey="size" fill={barColor} />
          </BarChart>
        </ResponsiveContainer>
        <StyledTypography variant="caption">
          Bytes Requested {downloadDateRange}
        </StyledTypography>
        <ResponsiveContainer height={150} width="100%">
          <BarChart data={stats}>
            {xAxis}
            <YAxis
              mirror={true}
              hide
              tickFormatter={MetricsUtils.formatYAxisTick}
              scale={stats.length > 1 ? "log" : "auto"}
              domain={['auto', 'auto']}
            />
            <Tooltip
              labelFormatter={MetricsUtils.formatDownloadsTooltipLabel}
              formatter={MetricsUtils.formatFileCountsTooltip}
            />
            <Bar dataKey="file_count" fill={barColor} />
          </BarChart>
        </ResponsiveContainer>
        <StyledTypography variant="caption">
          Files Requested {downloadDateRange}
        </StyledTypography>
      </div>
    );
  };

  renderStats = (): React.ReactNode => {
    return (
      <>
        {this.renderViewStats()}
        {this.renderDownloadStats()}
        {this.renderTotalDynamoSizesStats()}
        {this.renderDownloadHistoryCharts()}
      </>
    );
  };
}

export default ProjectStatsBar;
