import React from 'react';
import { inject } from 'mobx-react';
import { Typography } from '@mui/material';

import {
    NewPublication as DapNewPublication,
} from '@dapclient/components/page/NewPublication';
import HeadManager from './HeadManager';

export class NewPublication extends DapNewPublication {
    renderTitle = () => {
        return (
            <>
                <HeadManager
                    title="A2e: Atmosphere to Electrons Add Publication"
                    description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
                        Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
                />
                <Typography variant="h1">Add a Publication</Typography>
            </>
        )
    }
}

export default inject((store: any) => ({
    // TODO: Put any services you need here
    // EXAMPLE => metricsService: store.metricsService
}))(NewPublication);
