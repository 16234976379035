import { WidgetProps } from '@rjsf/utils';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({
  '& .MuiIconButton-root': {
    padding: '2px',
  }
}))

export default function HasEmailWidget(props: WidgetProps) {
  return (
    <StyledTextField
      error={!props.value}
      helperText={!props.value && "Email is a required property."}
      id={props.id}
      label="Email"
      value={stripMailto(props.value)}
      onChange={(e) => props.onChange(addMailto(e.target.value))}
      variant="outlined"
      fullWidth
      required
    />
  );
}

const mailtoRegex = /^mailto:/;

function stripMailto(hasEmail: string) {
  return hasEmail ? hasEmail.replace(mailtoRegex, '') : hasEmail;
}

function addMailto(email: string) {
  if (email && !mailtoRegex.test(email)) {
    return `mailto:${email}`;
  }
  return email;
}
