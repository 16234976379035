import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import theme from '@extensions/services/Theme';

const StyledContent = styled('div')(({
  paddingTop: '20px!important',
  paddingBottom: '50px!important',
  textAlign: 'left',
  '& > h2': {
    fontWeight: 'bold'
  }
}));

const StyledTypography = styled(Typography)(({
  color: theme.palette.grey[700],
  marginBottom: '1rem'
}));

const Agreement: React.FunctionComponent<{}> = () => {
  return (
    <StyledContent>
      <StyledTypography variant='body1' gutterBottom>
        <em>Privacy and Security</em>
      </StyledTypography>
      <StyledTypography>
        The Livewire Data Platform is provided as a resource to benefit users
        and visitors seeking data related to transportation and mobility. The
        Livewire Data Platform supports the U.S. Department of Energy’s Office
        of Energy Efficiency and Renewable Energy’s Energy Efficient Mobility
        Systems (EEMS) Program goal of providing an affordable, efficient, safe,
        and accessible transportation future where mobility is decoupled from
        energy consumption.
      </StyledTypography>
      <StyledTypography>
        Unless otherwise noted, the following policies apply to all pages and
        sites in the livewire.energy.gov domain.
      </StyledTypography>
      <StyledTypography variant='body1' gutterBottom>
        <em>Information Collected and Stored Automatically</em>
      </StyledTypography>
      <StyledTypography>
        We store all information provided via the website. This may include some
        personal data about our site visitors (hereafter “you”) to the extent
        effectively provided to us by the website. Example information includes:
        your name, Internet Protocol (IP) address, user identification (ID), and
        e-mail address. Your data may be stored in our databases until you
        request removal of said information.
      </StyledTypography>
      <StyledTypography>
        Information collected by Livewire Data Platform is NOT shared with
        anyone beyond the support staff for this website and other appropriate
        staff—except when required by computer security, law enforcement
        investigation or other authorized legal purpose. The information also
        may be used as a source of anonymous statistical information. You cannot
        be identified on the basis of this statistical information.
      </StyledTypography>
      <StyledTypography variant='body1' gutterBottom>
        <em>Cookies</em>
      </StyledTypography>
      <StyledTypography>
        Any cookies in the livewire.energy.gov domain are non-persistent
        (per-session) cookies only available during an active browser session.
        Hence, they do not permanently record data and are not stored on your
        computer’s hard drive. These cookies are erased as soon as you leave the
        Livewire Data Platform website.
      </StyledTypography>
      <StyledTypography variant='body1' gutterBottom>
        <em>Personal Information</em>
      </StyledTypography>
      <StyledTypography>
        Due to the nature of mission, you may elect to provide personal
        information via the website for registration or request for information.
        Said information is collected merely to meet the needs of its stated
        purposes, e.g., establishing a user account to acquire or share project
        data.
      </StyledTypography>
      <StyledTypography>
        Livewire Data Platform does not collect information for commercial
        marketing purposes.
      </StyledTypography>
      <StyledTypography>
        You also have the right to access, correct, and/or remove all personal
        data stored by Livewire Data Platform. For more information, please
        contact Livewirecontact@lyris.pnnl.gov.
      </StyledTypography>
      <StyledTypography variant='body1' gutterBottom>
        <em>Spam</em>
      </StyledTypography>
      <StyledTypography>
        To curtail incidents of spam, Livewire Data Platform employs reCAPTCHA®,
        which protects websites from bots. Any personal data you share via the
        Livewire Data Platform also may be assessed to determine if your message
        constitutes spam. Livewire Data Platform will never provide your email
        address to third parties and will never send any spam to you.
      </StyledTypography>
      <StyledTypography variant='body1' gutterBottom>
        <em>External Links</em>
      </StyledTypography>
      <StyledTypography>
        The Livewire Data Platform may include links to websites created and
        maintained by other public and/or private organizations. These links are
        solely for your information and convenience. By clicking away from
        Livewire Data Platform to an outside website, you are subject to the
        privacy and security policies indicated by the owner(s) and sponsor(s)
        of those external sites.
      </StyledTypography>
      <StyledTypography>
        Livewire Data Platform is not responsible for website content created
        and maintained by other public and/or private organizations.
      </StyledTypography>
      <StyledTypography variant='body1' gutterBottom>
        <em>Site Revisions</em>
      </StyledTypography>
      <StyledTypography>
        On occasion, Livewire Data Platform may have a need to amend its
        services, which could require an update to our privacy and security
        policies. Continued use of the Livewire Data Platform website following
        the posting of any changes to our privacy and security policy means you
        agree to and accept these changes.
      </StyledTypography>
      <StyledTypography variant='body1' gutterBottom>
        <em>Disclaimer</em>
      </StyledTypography>
      <StyledTypography>
        This material was prepared as an account of work sponsored by an agency
        of the United States Government. Neither the United States Government
        nor the United States Department of Energy, nor the Contractor, nor any
        or their employees, nor any jurisdiction or organization that has
        cooperated in the development of these materials, makes any warranty,
        express or implied, or assumes any legal liability or responsibility for
        the accuracy, completeness, or usefulness or any information, apparatus,
        product, software, or process disclosed, or represents that its use
        would not infringe privately owned rights.
      </StyledTypography>
      <StyledTypography>
        Reference herein to any specific commercial product, process, or service
        by trade name, trademark, manufacturer, or otherwise does not
        necessarily constitute or imply its endorsement, recommendation, or
        favoring by the United States Government or any agency thereof, or the
        Contractor. The views and opinions of authors expressed herein do not
        necessarily state or reflect those of the United States Government or
        any agency thereof.
      </StyledTypography>
    </StyledContent>
  );
};

export default Agreement;
