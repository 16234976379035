import { useState, useEffect, useCallback, useRef } from 'react';
import { Button, IconButton } from '@mui/material';
import { inject, observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import styled from '@emotion/styled';
import { map } from 'lodash';
import { ArrowUpward, ArrowDownward, Close } from '@mui/icons-material';

import { debounce } from 'debounce';

import { nextRange } from '@extensions/utils/fileList';
import FileInList, { IFile } from '@extensions/components/dataset/FileInList';
import { getFileList } from '@extensions/services/DatasetService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';
import Dataset, { StatSegment } from '@dapclient/models/Dataset';
import { useIsMounted } from '@dapclient/hooks/useIsMounted';

interface IFileListProps {
  dataset: Dataset;
  extension: string;
  timeline: StatSegment[];
  timelineIndex: number;
  absoluteRange?: [Moment, Moment];
  filesPerRequest?: number;
  onClose: () => void;
  securityService?: ISecurityService;
}

const Outer = styled.div`
  position: relative;
  min-height: 100px;
  border: 1px solid #333;
  border-width: 1px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding: 0.5rem 0;
  width: 100%;
`;

const List = styled.div`
  margin: 5px 0 5px 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

const ListItem = styled.div`
  & > .spacer {
    height: 2px;
    border-top: 2px dashed #ddd;
  }
  & > .gap {
    color: #777;
    background-color: #ddd;
    text-align: center;
    font-size: 70%;
    font-style: italic;
  }
`;

const Error = styled.div`
  font-family: monospace;
  color: red;
  font-size: 8pt;
`;

const hasGap = (f1: IFile, f2: IFile) => {
  return f1.date.diff(f2.date, 'days') > 1;
};

const isNextDay = (f1: IFile, f2: IFile) => {
  return f1.date.diff(f2.date, 'days') === 1;
};

const FileList = inject('securityService')(
  observer(
    ({
      dataset,
      extension,
      timeline,
      timelineIndex,
      absoluteRange,
      filesPerRequest,
      onClose,
    }: IFileListProps) => {
      const isMounted = useIsMounted();

      const [files, setFiles] = useState(undefined as IFile[] | undefined);
      const [err, setErr] = useState(undefined as string | undefined);
      const [loading, setLoading] = useState(false);
      const [currentUpIndex, setCurrentUpIndex] = useState(timelineIndex);
      const [currentDownIndex, setCurrentDownIndex] = useState(timelineIndex);
      const [currentRange, setCurrentRange] = useState([moment(), moment()] as [
        Moment,
        Moment
      ]);

      const absoluteRangeRef = useRef(absoluteRange);
      const timelineIndexRef = useRef(timelineIndex);

      const queryFiles = useCallback(
        (beg: Moment, end: Moment, after: boolean) => {
          setLoading(true);
          getFileList(dataset.name, [extension], [beg, end])
            .then((d) => {
              if (!isMounted.current) {
                return;
              }
              const toAdd = d.sort((a, b) => {
                if (a.name > b.name) {
                  return -1;
                }
                if (a.name < b.name) {
                  return 1;
                }
                return 0;
              });
              if (after) {
                setFiles([...(files || []), ...toAdd]);
              } else {
                setFiles([...toAdd, ...(files || [])]);
              }
            })
            .catch((err: any) => {
              if (isMounted.current) {
                setErr(err.toString());
              }
            })
            .finally(() => {
              if (isMounted.current) {
                setLoading(false);
              }
            });
        },
        [dataset, extension, files, isMounted]
      );

      const loadUp = useCallback(() => {
        const [beg, end, index] = nextRange(
          true,
          timeline,
          currentUpIndex,
          currentRange,
          absoluteRange,
          filesPerRequest
        );
        if (beg && end) {
          setCurrentUpIndex(index);
          setCurrentRange([currentRange[0], end]);

          queryFiles(beg, end, false);
        }
      }, [
        timeline,
        currentUpIndex,
        currentRange,
        filesPerRequest,
        absoluteRange,
        queryFiles,
      ]);
      const noUp =
        (currentUpIndex >= timeline.length - 1 &&
          currentRange[1].isSameOrAfter(timeline[timeline.length - 1].end)) ||
        (absoluteRange && absoluteRange[1].isSameOrBefore(currentRange[1]));

      const loadDown = useCallback(() => {
        const [beg, end, index] = nextRange(
          false,
          timeline,
          currentDownIndex,
          currentRange,
          absoluteRange,
          filesPerRequest
        );
        if (beg && end) {
          setCurrentDownIndex(index);
          setCurrentRange([beg, currentRange[1]]);

          queryFiles(beg, end, true);
        }
      }, [
        timeline,
        currentDownIndex,
        currentRange,
        filesPerRequest,
        absoluteRange,
        queryFiles,
      ]);
      const noDown =
        (currentDownIndex <= 0 &&
          currentRange[0].isSameOrBefore(timeline[0].beg)) ||
        (absoluteRange && absoluteRange[0].isSameOrAfter(currentRange[0]));

      // Reset the file list when the range or index changes but not on first render
      const first = useRef(true);
      const reset = useRef(debounce(() => setFiles(undefined), 1000));
      useEffect(() => {
        if (first.current) {
          first.current = false;
          return;
        }
        if (
          absoluteRange &&
          absoluteRangeRef.current &&
          absoluteRangeRef.current[0].isSame(absoluteRange[0]) &&
          absoluteRangeRef.current[1].isSame(absoluteRange[1]) &&
          timelineIndexRef.current === timelineIndex
        ) {
          return;
        }
        absoluteRangeRef.current = absoluteRange;
        timelineIndexRef.current = timelineIndex;
        setCurrentUpIndex(timelineIndex);
        setCurrentDownIndex(timelineIndex);
        reset.current();
      }, [timelineIndex, absoluteRange]);

      useEffect(() => {
        if (files !== undefined) {
          return;
        }

        const [beg, end, nextIndex] = nextRange(
          false,
          timeline,
          timelineIndex,
          undefined,
          absoluteRange,
          filesPerRequest
        );

        if (beg && end) {
          setCurrentDownIndex(nextIndex);
          setCurrentRange([beg, end]);

          queryFiles(beg, end, true);
        }
      }, [
        timeline,
        timelineIndex,
        queryFiles,
        filesPerRequest,
        files,
        absoluteRange,
      ]);

      return (
        <Outer>
          {files === undefined && loading && (
            <CenteredCircularProgress size={12} />
          )}
          {err && <Error>[ {err} ]</Error>}
          {files && files.length > 0 && (
            <ListWrapper>
              {loading && <CenteredCircularProgress size={12} />}
              {!loading && (
                <Button
                  size="small"
                  disabled={noUp}
                  startIcon={<ArrowUpward />}
                  onClick={loadUp}
                >
                  Load More
                </Button>
              )}
              <List>
                {map(files, (f, i) => {
                  return (
                    <ListItem key={f.name}>
                      <FileInList datasetName={dataset.name} file={f} />
                      {i < files.length - 1 && isNextDay(f, files[i + 1]) && (
                        <div className="spacer"></div>
                      )}
                      {i < files.length - 1 && hasGap(f, files[i + 1]) && (
                        <div className="gap">
                          {f.date.diff(files[i + 1].date, 'days')}-day gap
                        </div>
                      )}
                    </ListItem>
                  );
                })}
              </List>
              {loading && <CenteredCircularProgress size={12} />}
              {!loading && (
                <Button
                  size="small"
                  disabled={noDown}
                  startIcon={<ArrowDownward />}
                  onClick={loadDown}
                >
                  Load More
                </Button>
              )}
            </ListWrapper>
          )}
          <IconButton
            size="small"
            style={{ position: 'absolute', top: '3px', right: '3px' }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Outer>
      );
    }
  )
);

export default FileList;
