import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Button, Typography, Alert } from '@mui/material';
import { observable, action, makeObservable } from 'mobx';

import { IHistoryService } from '@extensions/services/IHistoryService';
import { IMetadataService } from '@extensions/services/IMetadataService';

import { IStepParams } from '@extensions/models/EditorStep';
import CancelBtn from '@extensions/components/metadata/CancelBtn';
import DestSearchSelect from '@extensions/components/metadata/DestSearchSelect';

const StyledRootDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

const StyledButtonBarDiv = styled('div')(({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
  '& button:not(:nth-last-of-type(1))': {
    marginRight: '1rem',
  }
}));

export interface IChooseDatasetProps extends IStepParams {
  className?: string;
  metadataService?: IMetadataService;
  historyService?: IHistoryService;
}

export interface IChooseDatasetState { }

@observer
export class ChooseDataset extends React.Component<
  IChooseDatasetProps,
  IChooseDatasetState
> {
  @observable
  selectedDataset: string | null = this.props.document.datasetName;
  @observable
  error: Error | null = null;
  @action
  setSelectedDataset = (newVal: string | null) =>
    (this.selectedDataset = newVal);
  @action
  clearError = () => (this.error = null);

  @action
  handleNext = () => {
    const { next, document, metadataService, historyService } = this.props;
    const doc = metadataService?.documents.find(
      (doc) =>
        doc.id !== document.id && doc.datasetName === this.selectedDataset
    );
    if (!this.selectedDataset) {
      this.error = new Error('Please select a dataset to continue');
    } else if (doc) {
      metadataService?.setSelectedDocAndAction(doc, false, true);
      historyService?.history.push(`/metadata/edit/${doc.id}`);
      next(false); //no need to save for this case as we're just loading the existing draft for the first time
    } else {
      const succeeded = document.setDataset({ name: this.selectedDataset });
      if (succeeded) {
        next(true);
      }
    }
  };

  constructor(props: IChooseDatasetProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { metadataService, cancel } = this.props;
    if (!metadataService) {
      return null;
    }

    return (
      <StyledRootDiv>
        <div>
          <Typography variant="h2" sx={{ marginBottom: '1rem', fontSize: '1.9735rem !important' }}>
            Select Dataset
          </Typography>
          <Typography sx={{ marginBottom: '0.75rem' }}>
            What dataset do you want to add metadata to
          </Typography>
          <DestSearchSelect
            initialDataset={this.selectedDataset}
            onChange={this.setSelectedDataset}
            options={metadataService.destinations}
          />
        </div>
        <div>
          {this.error && (
            <Alert severity="error" onClose={this.clearError}>
              {this.error.message}
            </Alert>
          )}

          <StyledButtonBarDiv>
            <CancelBtn onClick={cancel} />
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleNext}
            >
              Next
            </Button>
          </StyledButtonBarDiv>
        </div>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
  historyService: store.historyService,
}))(ChooseDataset);
