import { WidgetProps } from '@rjsf/utils';
import { TextField } from '@mui/material';
import { useState, useEffect } from 'react';
 
const DescriptionWidget = ({
  id,
  placeholder,
  value,
  required,
  disabled,
  readonly,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options
}: WidgetProps) => {
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {
    if (value && value.length > 2000) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [value]);
 
  const _onChange = ({ target: { value } }) => {
    if (value.length > 2000) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    onChange(value === '' ? options.emptyValue : value);
  };
 
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);
 
  return (
    <TextField
      id={id}
      label="Description"
      variant="outlined"
      multiline
      fullWidth
      required={required}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      placeholder={placeholder}
      value={value || ''}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      error={isError}
      helperText={isError ? 'Character limit exceeded. Maximum is 2000 characters.' : ''}
      inputProps={{
        style: {
          resize: 'vertical',
          overflow: 'auto',
          minHeight: '4em',
        },
      }}
      InputProps={{
        inputComponent: 'textarea',
      }}
    />
  );
};
 
export default DescriptionWidget;