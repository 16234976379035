import { Location } from 'history';
import { inject } from 'mobx-react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import theme from '@extensions/services/Theme';

import {
  App as DapApp
} from '@dapclient/components/App';
import FAQ from '@dapclient/components/page/FAQ';
import Cart from '@extensions/components/page/Cart';
import Upload from '@extensions/components/page/Upload';
import SignIn from '@extensions/components/page/SignIn';
import Static from '@extensions/components/page/Static';
import Dataset from '@extensions/components/page/Dataset';
import Profile from '@extensions/components/page/Profile';
import Metrics from '@extensions/components/page/Metrics';
import Project from '@extensions/components/page/Project';
import CodeHub from '@extensions/components/page/CodeHub';
import Register from '@extensions/components/page/Register';
import Uploaders from '@extensions/components/page/Uploaders';
import ExternalApp from '@extensions/components/page/ExternalApp';
import Acknowledge from '@extensions/components/page/Acknowledge';
import FeedbackTool from '@extensions/components/core/FeedbackTool';
import RequiresAuth from '@extensions/components/core/RequiresAuth';
import OrderDetails from '@extensions/components/page/OrderDetails';
import ProjectSearch from '@extensions/components/page/ProjectSearch';
import DatasetSearch from '@extensions/components/page/DatasetSearch';
import NewPublication from '@extensions/components/page/NewPublication';
import DocumentDetails from '@extensions/components/page/DocumentDetails';
import DocumentLanding from '@extensions/components/page/DocumentLanding';
import EditPublication from '@extensions/components/page/EditPublication';
import PublicationDetails from '@extensions/components/page/PublicationDetails';
import PublicationsSearch from '@extensions/components/page/PublicationsSearch';

export class App extends DapApp {

  getLayoutContentStyle = (location): Object => {
    const isProjectRoute = location.pathname === '/projects';
    const isDataRoute = location.pathname === '/data';
    var layoutContent = this.props.layoutService && this.props.layoutService.fullWidth
      ? {
        width: '100%',
        maxWidth: '100%',
        minWidth: theme.contentWidth.min,
        padding: '20px',
      } : {
        maxWidth: theme.contentWidth.max,
        minWidth: theme.contentWidth.min,
        marginRight: 'auto',
        marginLeft: 'auto',
        padding: isProjectRoute ? '40px 0 2rem 0' : isDataRoute ? '4px 0 2rem 0' : '20px 0 2rem 0',
        width: '100%',
        '@media (max-width: 1000px)': {
          minWidth: '0%',
          maxWidth: '90%',
        }
      }
    return {
      ...super.getLayoutContentStyle(location),
      ...layoutContent,
    }
  };


  getLayoutStyle = (location): Object => {
    const isHomePage = this.props.location.pathname === '/';
    const backgroundColor = isHomePage
      ? theme.palette.background.default
      : theme.palette.grey[50];
    return {
      ...super.getLayoutStyle(location),
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      lineHeight: 1.6,
      fontVariant: 'initial',
      fontFeatureSettings: 'initial',
      WebkitFontSmoothing: 'antialiased',
      'h1, h2, h3, h4, h5, h6 ': {
        color: theme.palette.secondary.main,
      },
      backgroundColor: `${backgroundColor}`,
    }
  };

  getBackgroundColor = (location: Location): string => {
    return theme.palette.background.default;
  };

  getFeedbackTool(): React.ReactNode | null {
    return <FeedbackTool />;
  };

  renderRoutes() {
    return (
      <Switch>
        <Redirect exact path="/" to="/home" />
        <Redirect exact path="login" to="signIn" />
        <Redirect
          path="/data/:project/:dataset/:section?"
          to="/ds/:project/:dataset/:section?"
        />
        <Redirect path="/user/orders/:id" to="/profile/orders/:id" />
        <Redirect path="/user/orders" to="/profile/orders" />
        <Redirect path="/user/cart" to="/profile/cart" />
        <Redirect path="/user" to="/profile" />
        <Route exact path="/signIn" component={SignIn} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/password/reset" component={Register} />
        <Route exact path="/data" component={DatasetSearch} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/metrics" component={Metrics} />
        <Route exact path="/acknowledge" component={Acknowledge} />
        <Route exact path="/publication/new" component={NewPublication} />
        <Route
          exact
          path="/publication/edit/:id(\d+)"
          component={EditPublication}
        />
        <Route
          exact
          path="/publication/:id(\d+)"
          component={PublicationDetails}
        />
        <Route path="/publications" component={PublicationsSearch} />
        <Route path="/projects" component={ProjectSearch} />
        <Route path="/project" component={Project} />
        <Route exact path="/profile/orders/:id" component={OrderDetails} />
        <Route path="/profile/orders" component={Profile} />
        <Route exact path="/profile/cart" component={Cart} />
        <Route path="/profile" component={Profile} />
        <Route path="/metadata/edit/:id" component={DocumentDetails} />
        <Route path="/metadata/:id" component={DocumentDetails} />
        <Route exact path="/metadata" component={DocumentLanding} />
        <Route
          path="/upload"
          render={() => (
            <RequiresAuth>
              <Upload />
            </RequiresAuth>
          )}
        />
        <Route
          path="/uploaders/:project"
          render={(props) => (
            <RequiresAuth>
              <Uploaders {...props} />
            </RequiresAuth>
          )}
        />
        <Route path="/ds" component={Dataset} />
        <Route
          path="/code"
          component={CodeHub}
          exact
        />
        <Route
          path="/code/:view"
          component={CodeHub}
        />
        <Route path="/external-app" component={ExternalApp} />
        <Route component={Static} />
      </Switch>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
  notificationService: store.notificationService,
  layoutService: store.layoutService,
}))(withRouter(App));
