import React from 'react';
import fileSize from 'filesize';
import { inject, observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ICartService } from '@extensions/services/ICartService';
import { ISecurityService } from '@extensions/services/ISecurityService';

const StyledRootDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export interface IOrderButtonProps {
  className?: string;
  securityService?: ISecurityService;
  cartService?: ICartService;
}

@inject('cartService', 'securityService')
@observer
class OrderButton extends React.Component<IOrderButtonProps> {
  @observable
  showConfirmationDialog = false;

  @action
  toggleConfirmationDialog = () => {
    this.showConfirmationDialog = !this.showConfirmationDialog;
  };

  constructor(props: IOrderButtonProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { securityService, cartService } = this.props;

    if (!securityService || !cartService) {
      return null;
    }

    if (cartService.orderItems.length === 0) {
      return null;
    }

    let button: React.ReactNode;
    if (securityService.userIsLoggedIn) {
      button = (
        <>
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#e0e0e0 !important',
            }}
            variant="contained"
            disabled={cartService.orderItems.length === 0}
            onClick={
              cartService.unusuallyLarge
                ? this.toggleConfirmationDialog
                : cartService.placeOrder
            }
          >
            Place order
          </Button>
          <Dialog
            open={this.showConfirmationDialog}
            onClose={this.toggleConfirmationDialog}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
          >
            <DialogTitle id="confirmation-dialog-title">
              Are you sure you want to order{' '}
              {cartService.total
                ? `${fileSize(cartService.total.byteCount)} of data`
                : 'this much data'}
              ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Large quantities of data may take a long time to download.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.toggleConfirmationDialog}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  cartService.placeOrder();
                  this.toggleConfirmationDialog();
                }}
                variant="contained"
                autoFocus
              >
                Confirm Order
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      button = (
        <Button
          component="a"
          href="/signIn"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#e0e0e0',
          }}
          variant="contained"
        >
          Log in to place order
        </Button>
      );
    }

    return <StyledRootDiv>{button}</StyledRootDiv>;
  }
}

export default OrderButton;
