import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(Autocomplete)(({
  '& input:focus': {
    //handled by the wrapper element MaterialUI creates
    outline: 'none',
  },
}));

export interface IAutocompleteExtensionFilterProps {
  className?: string;
  values: string[];
  selected: Set<string>;
  toggle: (value: string) => void;
  label: string;
}

class AutocompleteExtensionFilter extends React.Component<
  IAutocompleteExtensionFilterProps
> {
  render() {
    const { className, values, selected, label, toggle } = this.props;
    return (
      <StyledRoot
        multiple
        className={clsx(className)}
        options={values}
        value={[...selected.values()]}
        onChange={(event, newValues, reason, details) => {
          if (details && details.option) {
            toggle(details.option);
          } else if (reason === 'clear') {
            selected.forEach(value => toggle(value));
          }
        }}
        filterSelectedOptions
        renderInput={params => (
          <TextField {...params} variant="outlined" label={label} />
        )}
      />
    );
  }
}

export default (AutocompleteExtensionFilter);
