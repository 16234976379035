import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

export const INSTRUMENT_REACTIVE_ID = 'InstrumentFilter';

interface InstrumentFilterProps {
  react?: react;
}

interface InstrumentFilterState {
  sortAlphabetically: boolean;
  anchorEl: null | HTMLElement;
  isVisible: boolean;
  selectedItems: string[];
}

@observer
export default class InstrumentFilter extends React.Component<InstrumentFilterProps, InstrumentFilterState> {
  @observable sortAlphabetically: boolean = false;
  @observable anchorEl: null | HTMLElement = null;
  @observable isVisible: boolean = true;
  @observable selectedItems: string[] = [];
  
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    this.anchorEl = event.currentTarget;
  }

  @action
  handleMenuClose() {
    this.anchorEl = null;
  }

  @action
  handleSort(sortAlphabetically: boolean) {
    this.sortAlphabetically = sortAlphabetically;
    this.handleMenuClose();
  }

  @action
  handleSelectionChange(selectedValues: string[]) {
    this.selectedItems = selectedValues;
  }

  @computed
  get sortedItems() {
    const { sortAlphabetically } = this;
    return (items) => {
      if (sortAlphabetically) {
        return items.sort((a, b) => a.key.localeCompare(b.key));
      } else {
        return items.sort((a, b) => b.doc_count - a.doc_count);
      }
    };
  }

  render() {
    const { sortAlphabetically, anchorEl, isVisible, selectedItems } = this;
    return (
      <>
        {isVisible && (
          <MultiList
            className="data-filter"
            componentId={INSTRUMENT_REACTIVE_ID}
            dataField="instrument.keyword"
            title={
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>Instrument</span>
                <FilterAltIcon onClick={(e) => this.handleMenuOpen(e)} style={{ cursor: 'pointer' }} />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => this.handleMenuClose()}
                >
                  <MenuItem onClick={() => this.handleSort(false)}>Sort by Count</MenuItem>
                  <MenuItem onClick={() => this.handleSort(true)}>Sort Alphabetically</MenuItem>
                </Menu>
              </div>
            }
            filterLabel="Instrument"
            showSearch={false}
            react={this.props.react}
            URLParams={true}
            sortBy={sortAlphabetically ? 'asc': 'count'}
            onChange={(values) => this.handleSelectionChange(values)}
            value={selectedItems}
            transformData={this.sortedItems}
          />
        )}
      </>
    );
  };
}