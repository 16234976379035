import React from 'react';

import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';

import FooterMenu from './FooterMenu';
import doeIcon from '@extensions/resources/doe.png';

const WHO_OWNS_US = `
Atmosphere to Electrons is a resource of the Department of Energy's Wind
Energy Technologies Office.
`;

const StyledRootDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
  background: theme.palette.primary.main,
  alignItems: 'center',
}));

const StyledImg = styled('img')(({
  height: '60px',
}));

const StyledA = styled('a')(({
  marginBottom: '0.625rem',
}));

const StyledP = styled('p')(({
  fontSize: '0.8rem',
  marginBottom: '1.1rem',
  color: theme.palette.common.white,
  marginBlockEnd: theme.spacing(0.6),
  marginBlockStart: theme.spacing(0),
}));

class Footer extends React.Component {
  render() {
    return (
      <StyledRootDiv>
        <StyledA
          href="http://energy.gov/eere"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledImg
            src={doeIcon}
            alt="U.S. Department of Energy"
          />
        </StyledA>
        <StyledP>{WHO_OWNS_US}</StyledP>
        <FooterMenu />
      </StyledRootDiv>
    );
  }
}

export default Footer;
