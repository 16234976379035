import React from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

const StyledLabel = styled('span')(({
  fontWeight: theme.typography.fontWeightBold,
}));

export interface IFileSummaryItemProps {
  className?: string;
  label: string;
  value: string | number;
}

class FileSummaryItem extends React.Component<IFileSummaryItemProps> {
  render() {
    const { className, label, value } = this.props;
    return (
      <Typography className={clsx(className)}>
        <StyledLabel>{label}:</StyledLabel> {value}
      </Typography>
    );
  }
}

export default (FileSummaryItem);
