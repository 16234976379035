import React from 'react';
import { Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import filesize from 'filesize';

import FileOrderGroup from '@extensions/models/FileOrderGroup';
import FileSummaryItem from '@extensions/components/order/FileSummaryItem';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

const StyledHeading = styled(Typography)(({
  marginBottom: theme.spacing(2)
}));

export interface IFileSummaryProps {
  className?: string;
  order: FileOrderGroup;
}

class FileSummary extends React.Component<IFileSummaryProps> {
  render() {
    const { className, order } = this.props;
    return (
      <Paper className={clsx(className)}>
        <StyledHeading variant="h2" sx={{ fontSize: '1.9375rem' }}>
          File Summary
        </StyledHeading>
        {order.fileTypes.length > 0 && (
          <FileSummaryItem
            label="File Types"
            value={order.fileTypes.join(', ')}
          />
        )}
        {order.dateRange.startDate && order.dateRange.endDate && (
          <FileSummaryItem
            label="Date Range"
            value={`${order.dateRange.startDate.format(
              'YYYY-MM-DD'
            )} to ${order.dateRange.endDate.format('YYYY-MM-DD')}`}
          />
        )}
        <FileSummaryItem label="Total Size" value={filesize(order.totalSize)} />
        <FileSummaryItem label="File Count" value={order.fileCount} />
      </Paper>
    );
  }
}

export default (FileSummary);
