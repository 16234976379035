import React from 'react';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';
import FileOrderGroup from '@extensions/models/FileOrderGroup';

const CustomList = ({ ...props }) => (
  <List {...props} />
);
const StyledList = styled(CustomList)(({
  maxHeight: '15rem',
  overflow: 'auto',
}));

const CustomListItem = ({ ...props }) => (
  <ListItem {...props} />
);
const StyledListItem = styled(CustomListItem)(({
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

const StyledLi = styled('li')(({
  width: '100%',
}));

const StyledIcon = styled(ListItemIcon)(({
  minWidth: 'auto',
  marginRight: theme.spacing(2),
}));

export interface IDatasetListProps {
  className?: string;
  order: FileOrderGroup;
}

class DatasetList extends React.Component<IDatasetListProps> {
  render() {
    const { className, order } = this.props;
    const headerId = 'datasets-header';
    return (
      <Paper className={clsx(className)}>
        <Typography variant="h2" sx={{ fontSize: '1.9375rem' }} id={headerId}>
          Datasets
        </Typography>
        <StyledList
          aria-labelledby={headerId}
          component="ul"
        >
          {order.datasetNames.map(datasetName => (
            <StyledLi key={datasetName}>
              <StyledListItem
                button
                component="a"
                href={`/data/${datasetName}`}
              >
                <StyledIcon >
                  <AddIcon icon={faDatabase} size="lg" />
                </StyledIcon>
                <ListItemText primary={datasetName} />
              </StyledListItem>
            </StyledLi>
          ))}
        </StyledList>
      </Paper>
    );
  }
}

export default (DatasetList);
