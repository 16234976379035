import {
    Radio,
    FormControlLabel,
} from '@mui/material';
import { inject } from 'mobx-react';

import {
    TypeField as DapTypeField,
} from '@dapclient/components/publications/TypeField';
import { PublicationType } from '@dapclient/models/Publication';
import theme from '@extensions/services/Theme';

export class TypeField extends DapTypeField {
    renderRadioGroup = () => {
        const { publicationService } = this.props;
        return Object.values(PublicationType).slice(0, 2).map((type, idx) => {
            return <FormControlLabel
                key={idx}
                value={type}
                control={<Radio />}
                label={publicationService?.prettyPublicationType(type)}
                sx={{
                    '& .MuiFormControlLabel-label': {
                        color: theme.palette.grey[700]
                    }
                }}
            />
        })
    }
}

export default inject((store: any) => ({
    publicationService: store.publicationService,
}))(TypeField);
