import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material';

import Breadcrumb from '@extensions/components/core/Breadcrumb';

const StyledBreadcrumb = styled(Breadcrumb)(({
  marginBottom: '0.5rem',
}));

export interface IBackToLandingPageProps {
  className?: string;
}

export interface IBackToLandingPageState { }

@observer
export class BackToLandingPage extends React.Component<
  IBackToLandingPageProps,
  IBackToLandingPageState
> {
  render() {
    return (
      <StyledBreadcrumb
        to="/metadata"
        name="Metadata Submissions"
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(BackToLandingPage);
