import React from 'react';
import { observer, inject } from 'mobx-react';

import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import ILayoutService from '@extensions/services/ILayoutService';

import HeaderMenu from './HeaderMenu';
import ResponsiveSR from './ResponsiveSR';
import SignInAndRegister from './SignInAndRegister';
import a2eHeaderLogo from '@extensions/resources/a2e_header_logo.png';


const StyledRootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: theme.palette.primary.main,
}));

const StyledTopBarDiv = styled('div', {
  shouldForwardProp: (props) => props !== 'fullWidth'
})<{ fullWidth: boolean }>(({ fullWidth }) => {
  if (fullWidth) {
    return {
      width: '100%',
      maxWidth: '100%',
      minWidth: theme.contentWidth.min,
      '@media (max-width: 1000px)': {
        minWidth: '0%',
      },
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      '@media (min-width: 1000px)': {
        position: 'relative',
        '& > :last-child': {
          position: 'absolute',
          top: 0,
          right: 0,
        }
      },
    }
  } else {
    return {
      width: '100%',
      maxWidth: theme.contentWidth.max,
      minWidth: theme.contentWidth.min,
      '@media (max-width: 1000px)': {
        minWidth: '0%',
        maxWidth: '100%',
      },
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      '@media (min-width: 1000px)': {
        position: 'relative',
        '& > :last-child': {
          position: 'absolute',
          top: 0,
          right: 0,
        }
      },
    }
  }
});

const StyledMenuWrapperDiv = styled('div', {
  shouldForwardProp: (props) => props !== 'fullWidth'
})<{ fullWidth: boolean }>(({ fullWidth }) => {
  if (fullWidth) {
    return {
      background: theme.palette.secondary.light,
      width: '100%',
    }
  } else {
    return {
      display: 'flex',
      background: theme.palette.secondary.light,
      width: '100%',
      justifyContent: 'center',
    }
  }
});

const StyledHeaderMenuDiv = styled('div', {
  shouldForwardProp: (props) => props !== 'fullWidth'
})<{ fullWidth: boolean }>(({ fullWidth }) => {
  if (fullWidth) {
    return {
      width: '100%',
      '@media (min-width: 1000px)': {
        minWidth: theme.contentWidth.min,
      }
    }
  } else {
    return {
      width: '100%',
      '@media (min-width: 1000px)': {
        maxWidth: theme.contentWidth.max,
        minWidth: theme.contentWidth.min,
      }
    }
  }
});

const StyledA2eATag = styled('a')(({
  maxWidth: '100%',
  height: '120px',
}));

const StyledImg = styled('img')(({
  maxWidth: '100%'
}));

interface IHeaderProps {
  layoutService?: ILayoutService;
}

@observer
class Header extends React.Component<IHeaderProps> {
  render() {
    const { layoutService } = this.props;
    return (
      <StyledRootDiv>
        <StyledTopBarDiv
          fullWidth={layoutService && layoutService.fullWidth ? true : false}
        >
          <StyledA2eATag href="/">
            <StyledImg alt="A2e Logo" src={a2eHeaderLogo} />
          </StyledA2eATag>
          <ResponsiveSR desktop={<SignInAndRegister />} mobile={<></>} />
        </StyledTopBarDiv>
        <StyledMenuWrapperDiv
          fullWidth={layoutService && layoutService.fullWidth ? true : false}
        >
          <StyledHeaderMenuDiv
            fullWidth={layoutService && layoutService.fullWidth ? true : false}
          >
            <HeaderMenu />
          </StyledHeaderMenuDiv>
        </StyledMenuWrapperDiv>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  layoutService: store.layoutService,
}))(Header);
