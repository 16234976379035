import React from 'react';
import { inject, observer } from 'mobx-react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TextField, InputAdornment, IconButton } from '@mui/material';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';

export interface ISearchBoxProps {
  className?: string;
  value: string;
  onChange: (newValue: string) => void;
  onSearch?: () => void;
  label: string;
}

export interface ISearchBoxState { }

@observer
export class SearchBox extends React.Component<
  ISearchBoxProps,
  ISearchBoxState
> {
  render() {
    const { value, onChange, label } = this.props;
    return (
      <TextField
        sx={{ width: '70%' }}
        variant="outlined"
        size="small"
        label={label}
        value={value}
        onChange={event => onChange(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="search" size="small" color="secondary">
                <AddIcon icon={faSearch} style={{ color: theme.palette.secondary.main }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(SearchBox);
