import React from 'react';
import { IconButton } from '@mui/material';
import { inject, observer } from 'mobx-react';

import {
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import AddIcon from '@extensions/utils/AddIcon';

export interface IDownloadIconButtonProps {
  className?: string;
  url: string;
  label?: string;
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
}

export interface IDownloadIconButtonState { }

@observer
export class DownloadIconButton extends React.Component<
  IDownloadIconButtonProps,
  IDownloadIconButtonState
> {
  render() {
    const {
      url,
      label = 'download',
      iconProps,
    } = this.props;
    return (
      <IconButton
        aria-label={label}
        component="a"
        href={url}
        download
      >
        <AddIcon {...iconProps} icon={faFileDownload} />
      </IconButton>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(DownloadIconButton);
