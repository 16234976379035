import React from 'react';
import Tabs from '@mui/material/Tabs';
import { observer, inject } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import HeaderMenuSR from './HeaderMenuSR';
import UserToolMenu from './UserToolMenu';
import ResponsiveMenu from '@extensions/components/nav/ResponsiveMenu';
import { ISecurityService } from '@dapclient/services/ISecurityService';
import HeaderMenuItem, { IHeaderMenuItemProps } from './HeaderMenuItem';

import { styled } from '@mui/material';
import theme from '@extensions/services/Theme';

const HEADER_ITEMS: IHeaderMenuItemProps[] = [
  {
    label: 'Projects',
    dst: '/projects',
  },
  {
    label: 'Data',
    dst: '/data',
  },
  {
    label: 'Publications',
    dst: '/publications',
  },
  {
    label: 'Code',
    dst: '/code',
  },
  {
    label: 'Metrics',
    dst: '/metrics',
  },
  {
    label: 'FAQ',
    dst: '/faq',
  },
];

export interface IHeaderMenuProps extends RouteComponentProps{
  securityService?: ISecurityService;
}

const StyledTabs = styled(Tabs)(({
  minHeight: 'unset',
  '& .MuiTab-root': {
    height: 45,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    minWidth: 'auto',
    padding: '0px 16px',
    opacity: 'unset',
    minHeight: 'unset',
    '&.Mui-selected': {
      background: theme.palette.secondary.main,
    },
  },
}));

const StyledMobileTabs = styled(Tabs)(({
  minHeight: '100%',
  background: theme.palette.secondary.light,
  '& .MuiTab-root': {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      background: theme.palette.secondary.main,
    },
  },
  fontFamily: theme.typography.fontFamily,
}));

const StyledNavRoot = styled('nav')(({
  '@media (max-width: 959px)': {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}));

const StyleUlItemList = styled('ul')(({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

@inject('securityService')
@observer
class HeaderMenu extends React.Component<IHeaderMenuProps> {
  render() {
    const { securityService: nullableSecurityService } = this.props;
    const securityService = nullableSecurityService as ISecurityService;

    const innerMenu = HEADER_ITEMS.map((headerItem) => (
      <HeaderMenuItem
        key={headerItem.dst}
        {...headerItem}
      />
    ));

    const innerMenuMobile = [
      <HeaderMenuSR key="headerMenu" />, // Place HeaderMenuSR as the first item
      ...HEADER_ITEMS.map((headerItem) => (
        <HeaderMenuItem
          key={headerItem.dst}
          {...headerItem}
          menuItemContentMobileStyle={{
            fontWeight: 1700,
            lineHeight: 2,
          }}
        />
      ))
    ];

    // user profile page has inner nav to /profile or /profile/orders so let either pathname show the user button as selected
    const webMenu = (
      <>
        <StyledTabs value={false} key={this.props.location.key}>
          {innerMenu}
        </StyledTabs>
      </>
    );

    //mobileMenu doesn't have any of the styling to make the tabs look like they're a part of the black app bar, it uses MUI default styling
    const mobileMenu = (
      <>
        <StyledMobileTabs orientation="vertical" value={false}>
          {innerMenuMobile}
        </StyledMobileTabs>
      </>
    );

    return (
      <StyledNavRoot>
        <StyleUlItemList>
          <ResponsiveMenu desktop={webMenu} mobile={mobileMenu} />
          {securityService.user && securityService.user.authenticated && (
            <UserToolMenu
              tools={securityService.user.tools}
            />
          )}
        </StyleUlItemList>
      </StyledNavRoot>
    );
  }
}

export default withRouter(HeaderMenu);
