import React from 'react';
import { Diff } from 'deep-diff';
import { SummaryContextValue } from '@extensions/components/metadata/review/SummaryContext';
import DiffDot from '@extensions/components/metadata/review/DiffDot';
import DiffMinus from '@extensions/components/metadata/review/DiffMinus';
import DiffPlus from '@extensions/components/metadata/review/DiffPlus';

export const DEFAULT_VALUE = 'N/A';

export function getFieldPath({
  id,
  prefix,
}: {
  id: string;
  prefix: string;
}): string {
  const fullPrefix = `${prefix}_`;
  const pathSnakeCase = id.slice(fullPrefix.length);
  return pathSnakeCase.split('_').join('.');
}

export function getBooleanDisplay(val: boolean | null | undefined) {
  return val ? 'Yes' : 'No';
}

export function getDiffStyles({
  path,
  summaryContextValue,
}: {
  summaryContextValue: SummaryContextValue;
  path: string;
}): { adornment: React.ReactNode; className: string } | undefined {
  const { changes, kindsToHighlight, classes } = summaryContextValue;
  const change = getChange(changes, path);

  if (change !== undefined) {
    const kind = change.kind === 'A' ? change.item.kind : change.kind;
    if (kindsToHighlight.includes(kind)) {
      return {
        N: {
          adornment: <DiffPlus />,
          className: classes.addedValue,
        },
        E: {
          adornment: <DiffDot />,
          className: classes.editedValue,
        },
        D: {
          adornment: <DiffMinus />,
          className: classes.deletedValue,
        },
      }[kind];
    }
  }

  return undefined;
}

export function getChange(
  changes: Diff<Record<string, any>>[] | undefined,
  path: string
): Diff<Record<string, any>> | undefined {
  if (changes === undefined) {
    return undefined;
  }

  return changes.find((diff) => {
    let completePath = diff.path ? [...diff.path] : [];
    let kind = diff.kind;
    if (diff.kind === 'A') {
      completePath.push(diff.index);
      kind = diff.item.kind;
    }

    const completePathStr = completePath.join('.');
    const pathMatches =
      kind === 'D'
        ? path.startsWith(completePathStr)
        : completePathStr === path;
    return pathMatches;
  });
}
