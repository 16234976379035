import * as yup from 'yup';
import { debounce } from 'lodash';
import { observer, inject } from 'mobx-react';
import { ReactNode, useRef, useState, useMemo, useEffect } from 'react';
import { Field, FieldArray, FieldProps, Formik, useField } from 'formik';

import {
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  TextFieldProps,
  Tooltip,
  Button,
  MenuList,
  MenuItem,
  Paper,
  ClickAwayListener,
  Popover,
  FormControlLabel,
  Checkbox,
  Select,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  AlertTitle,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TimerIcon from '@mui/icons-material/Timer';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';

import {
  IUploadService,
  IDatasetConfig,
  IUiConfig,
  IOption,
} from '@extensions/services/IUploadService';
import theme from '@extensions/services/Theme';

import Link from '@extensions/components/core/Link';
import { default as DapAddIcon } from '@extensions/utils/AddIcon';
import { useUploadService } from '@extensions/hooks/useService';

const UNITS_EXPORT = {
  seconds: (n) => `${n}s`,
  minutes: (n) => `${n}m`,
  hours: (n) => `${n}h`,
  days: (n) => `${n * 24}h`,
};

const UNITS_MULT = {
  seconds: 1,
  minutes: 1 / 60,
  hours: 1 / (60 * 60),
  days: 1 / (60 * 60 * 24),
};

const UNITS_IMPORT = (val: string): TimeUnit => {
  let num = parseInt(val.slice(0, -1));
  let unit = val.slice(-1);
  switch (unit) {
    case 's':
      unit = 'seconds';
      break;
    case 'm':
      unit = 'minutes';
      break;
    case 'h':
      unit = 'hours';
      if (num % 24 === 0) {
        unit = 'days';
        num = num / 24;
      }
      break;
  }
  return {
    value: num,
    unit,
  };
};

const UNITS_TRANSLATE = (val: TimeUnit, unit: string): TimeUnit => {
  const multFrom = UNITS_MULT[val.unit];
  const multTo = UNITS_MULT[unit];
  return {
    value: (val.value / multFrom) * multTo,
    unit,
  };
};

const MAX_DELAY = {
  value: 180,
  unit: 'days',
};

const DEFAULT_DELAY = {
  value: 7,
  unit: 'days',
};

const DEFAULT_BIN_MB = 10;

const DEFAULT_MIN_AGE = '1m';

const DEFAULT_SCAN_INTERVAL = '30s';

const DEFAULT_ZIP_LEVEL = 0;

// Do this right sometime...
const INPUT_HEIGHT = 40;

const defaultPathMapping = () => {
  return {
    cleanupEnabled: false,
    cleanupDelay: { ...DEFAULT_DELAY },
    pattern: '',
    template: '',
  };
};

type FormConfig = {
  rootPath: string;
  settings: FormSettings;
  paths: FormPathConfig[];
};

type FormSettings = {
  binMb: number;
  minAge: TimeUnit;
  scanInterval: TimeUnit;
  zipLevel: number;
};

type FormPathConfig = {
  cleanupEnabled: boolean;
  cleanupDelay: TimeUnit;
  pattern: string;
  template: string;
};

type TimeUnit = {
  value: number;
  unit: string;
};

const configToForm = (config?: IUiConfig): FormConfig => {
  if (!config) {
    return {
      rootPath: '',
      settings: {
        binMb: DEFAULT_BIN_MB,
        minAge: UNITS_IMPORT(DEFAULT_MIN_AGE),
        scanInterval: UNITS_IMPORT(DEFAULT_SCAN_INTERVAL),
        zipLevel: DEFAULT_ZIP_LEVEL,
      },
      paths: [defaultPathMapping()],
    };
  }
  const form = {
    rootPath: config.root_path,
    settings: {
      binMb: config.settings
        ? parseInt(config.settings.bin_size.replace(/^(\d+)/, '$1'))
        : DEFAULT_BIN_MB,
      minAge: UNITS_IMPORT(
        config.settings ? config.settings.min_age : DEFAULT_MIN_AGE
      ),
      scanInterval: UNITS_IMPORT(
        config.settings ? config.settings.scan_delay : DEFAULT_SCAN_INTERVAL
      ),
      zipLevel:
        config.settings && config.settings.compress !== undefined
          ? config.settings.compress
          : DEFAULT_ZIP_LEVEL,
    },
    paths: (config.rename || []).map((r): FormPathConfig => {
      return {
        pattern: r.pattern,
        template: r.output_name,
        cleanupEnabled: false,
        cleanupDelay: DEFAULT_DELAY,
      };
    }),
  };
  for (const opt of config.options) {
    for (const rename of form.paths) {
      if (rename.pattern === opt.pattern) {
        rename.cleanupEnabled = opt.delete === true;
        if (opt.delete && opt.delete_delay) {
          rename.cleanupDelay = UNITS_IMPORT(opt.delete_delay);
        }
        break;
      }
    }
  }
  return form;
};

const formToConfig = (form: FormConfig): IUiConfig => {
  return {
    root_path: form.rootPath,
    settings: {
      bin_size: `${form.settings.binMb}MB`,
      min_age: UNITS_EXPORT[form.settings.minAge.unit](
        form.settings.minAge.value
      ),
      scan_delay: UNITS_EXPORT[form.settings.scanInterval.unit](
        form.settings.scanInterval.value
      ),
      compress: form.settings.zipLevel,
    },
    options: form.paths.map((p) => {
      const opt = {
        pattern: p.pattern,
        delete: p.cleanupEnabled,
      } as IOption;
      if (opt.delete && p.cleanupDelay) {
        opt.delete_delay = UNITS_EXPORT[p.cleanupDelay.unit](
          p.cleanupDelay.value
        );
      }
      return opt;
    }),
    rename: form.paths.map((p) => {
      return {
        pattern: p.pattern,
        output_name: p.template,
      };
    }),
  };
};

const validationSchema = yup.object({
  rootPath: yup.string().trim().min(1).required('Root file path is required.'),
  settings: yup.object({
    binMb: yup
      .number()
      .required('Bin zize (in MBs) is required.')
      .typeError('Time value must be a number.')
      .test(
        'bin-positive',
        'Must be greater than or equal to zero.',
        function (value) {
          return value !== undefined && value > 0;
        }
      ),
    minAge: yup.object({
      unit: yup
        .string()
        .trim()
        .oneOf(Object.keys(UNITS_EXPORT))
        .required('Unit is required.'),
      value: yup
        .number()
        .typeError('Time value must be a number.')
        .test(
          'time-positive',
          'Must be greater than or equal to zero.',
          function (value) {
            return value !== undefined && value > 0;
          }
        )
        .required('Time value is required.'),
    }),
    scanInterval: yup.object({
      unit: yup
        .string()
        .trim()
        .oneOf(Object.keys(UNITS_EXPORT))
        .required('Unit is required.'),
      value: yup
        .number()
        .typeError('Time value must be a number.')
        .test(
          'time-positive',
          'Must be greater than or equal to zero.',
          function (value) {
            return value !== undefined && value > 0;
          }
        )
        .required('Time value is required.'),
    }),
  }),
  paths: yup
    .array()
    .of(
      yup.object({
        cleanupEnabled: yup
          .boolean()
          .strict(true)
          .required('Specifying file cleanup on source is required.'),
        cleanupDelay: yup.object().when('cleanupEnabled', {
          is: true,
          then: yup.object({
            unit: yup
              .string()
              .trim()
              .oneOf(Object.keys(UNITS_EXPORT))
              .required('Unit is required.'),
            value: yup
              .number()
              .typeError('Time value must be a number.')
              .test(
                'time-positive',
                'Must be greater than or equal to zero.',
                function (value) {
                  return value !== undefined && value > 0;
                }
              )
              .test(
                'time-within-reason',
                `Maximum amount of time is ${MAX_DELAY.value} ${MAX_DELAY.unit}.`,
                function (value) {
                  let t = UNITS_TRANSLATE(
                    UNITS_IMPORT(UNITS_EXPORT[this.parent.unit](value)),
                    MAX_DELAY.unit
                  );
                  return t.value <= MAX_DELAY.value;
                }
              )
              .required('Time value is required.'),
          }),
        }),
        pattern: yup
          .string()
          .trim()
          .matches(RegExp(`^[\x00-\x7F]+$`), 'Only ASCII characters allowed')
          .required('File name pattern is required.')
          .test(
            'valid-pattern',
            ({ value }) => {
              try {
                translateRegex(value || '');
              } catch (error) {
                return `${error}`;
              }
              return '';
            },
            function (value) {
              try {
                translateRegex(value || '');
              } catch (error) {
                return false;
              }
              return true;
            }
          ),
        template: yup
          .string()
          .trim()
          .matches(RegExp(`^[\x00-\x7F]+$`), 'Only ASCII characters allowed')
          .required('File name template is required.'),
      })
    )
    .required('At least one path must be configured.'),
});

const BGCOLOR = '#efefef';

const Container = styled('div')({
  backgroundColor: `${BGCOLOR}`,
  width: '100%',
  padding: '1rem',
  borderRadius: '3px',
});

type IConfigureDatasetProps = {
  uploadService?: IUploadService;
  config: IDatasetConfig;
  onStatusChange: (edited: boolean) => void;
  onForceClose: () => void;
};

const ConfigureDataset = inject('uploadService')(
  observer(
    ({
      uploadService,
      config,
      onStatusChange,
      onForceClose,
    }: IConfigureDatasetProps) => {
      const testRef = useRef<HTMLButtonElement>(null);
      const [testOpen, setTestOpen] = useState(false);
      const [testPath, setTestPath] = useState('');
      const [dialogOpen, setDialogOpen] = useState(false);

      return (
        <Formik
          initialValues={configToForm(config.ui_conf)}
          validateOnMount={true}
          validationSchema={validationSchema}
          onSubmit={async (form, formikBag) => {
            if (!uploadService) {
              return;
            }
            let newConfig = { ...config, ui_conf: formToConfig(form) };
            if ((await uploadService.saveDatasetConfig(newConfig)) !== null) {
              formikBag.setStatus({ success: 'Saved' });
              formikBag.resetForm({ values: form });
            }
            formikBag.setSubmitting(false);
          }}
        >
          {({ dirty, values, handleSubmit, setStatus, isValid }) => {
            let paths = values.paths;

            const Listener = (props) => {
              const { dirty, onStatusChange } = props;
              useEffect(() => {
                onStatusChange(dirty);
              }, [dirty, onStatusChange]);
              return null;
            };

            return (
              <Container>
                <Listener dirty={dirty} onStatusChange={onStatusChange} />
                <Grid container spacing={2} style={{ paddingTop: '1rem' }}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item sx={{ flexGrow: 1 }}>
                        <ConfigInputField
                          name="rootPath"
                          placeholder="C:\Base\Data\Directory ..."
                          label="Root Directory"
                          requireTouchBeforeError={true}
                          fullWidth
                          help={
                            <>
                              <Typography>
                                The local client path(s) to rename (specified
                                below) will be relative to the directory
                                specified here. For example, on Windows it might
                                be <code>C:\DATA</code>. On Linux or MacOS it
                                might be <code>/data</code>.
                              </Typography>
                              <Typography>
                                <strong>
                                  The entered path must be absolute
                                </strong>
                                . On Linux and MacOS, this means the path must
                                start with <code>/</code>. On Windows it means
                                the file path must start with a volume or drive
                                letter followed by a <code>:</code>, e.g.{' '}
                                <code>C:\</code>.
                              </Typography>
                            </>
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="stretch"
                          sx={{ height: `${INPUT_HEIGHT}px` }}
                        >
                          <Settings />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <FieldArray name="paths">
                        {(arrayHelpers) =>
                          paths.map((path, index) => {
                            return (
                              <PathMapping
                                key={index}
                                index={index}
                                deleteDisabled={paths.length === 1}
                                datasetName={config.name}
                                {...path}
                                onAdd={() =>
                                  arrayHelpers.insert(
                                    index + 1,
                                    defaultPathMapping()
                                  )
                                }
                                onDelete={() => arrayHelpers.remove(index)}
                                onMoveUp={() =>
                                  arrayHelpers.move(
                                    index,
                                    index > 0 ? index - 1 : paths.length - 1
                                  )
                                }
                                onMoveDown={() =>
                                  arrayHelpers.move(
                                    index,
                                    index < paths.length - 1 ? index + 1 : 0
                                  )
                                }
                              />
                            );
                          })
                        }
                      </FieldArray>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#ccc',
                      marginLeft: '1rem',
                    }}
                  ></div>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Tooltip
                          title={testOpen ? '' : 'Test Example File Path'}
                        >
                          <Button
                            color="secondary"
                            variant={testOpen ? 'contained' : 'text'}
                            disableElevation
                            ref={testRef}
                            onClick={() => setTestOpen((prev) => !prev)}
                          >
                            {testOpen ? <CloseIcon /> : <ThumbsUpDownIcon />}
                          </Button>
                        </Tooltip>
                        <Popover
                          open={testOpen}
                          anchorEl={testRef.current}
                          onClose={() => setTestOpen(false)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: -3,
                            horizontal: 'left',
                          }}
                        >
                          <PopoverBody>
                            <Tester
                              datasetName={config.name}
                              initialTestPath={testPath}
                              rememberTestPath={setTestPath}
                              config={values}
                            />
                          </PopoverBody>
                        </Popover>
                        <span style={{ color: '#aaa' }}>{' | '}</span>
                        <Tooltip title="Permanently Delete Configuration">
                          <Button onClick={() => setDialogOpen(true)}>
                            <DeleteForeverIcon />
                          </Button>
                        </Tooltip>
                        <Dialog
                          open={dialogOpen}
                          onClose={() => setDialogOpen(false)}
                        >
                          <DialogTitle>Are you sure?</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Deleting the dataset configuration cannot be
                              undone.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => {
                                setDialogOpen(false);
                              }}
                            >
                              No, Cancel
                            </Button>
                            <Button
                              onClick={async () => {
                                if (uploadService) {
                                  await uploadService.deleteDatasetConfig(
                                    config.name
                                  );
                                }
                                // No need to close the dialog because deleting the
                                // dataset will cause this whole component to go away
                              }}
                            >
                              Yes, Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent="flex-end" spacing={1}>
                          <Grid item>
                            <Button onClick={onForceClose}>
                              {dirty ? 'Cancel' : 'Close'}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color="primary"
                              disabled={dirty === false || isValid === false}
                              variant="contained"
                              disableElevation
                              type="submit"
                              onClick={() => {
                                setStatus({ success: null });
                                handleSubmit();
                              }}
                            >
                              {dirty ? (
                                <>
                                  <SaveIcon />
                                  &nbsp;Save
                                </>
                              ) : (
                                <>
                                  <CheckIcon />
                                  &nbsp;Saved
                                </>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            );
          }}
        </Formik>
      );
    }
  )
);

type ConfigInputProps = {
  help?: ReactNode;
} & TextFieldProps;

const MonoTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontFamily: 'monospace',
  },
  '& legend': {
    color: theme.palette.text.primary,
  },
}));

const ConfigInput = ({ help, ...rest }: ConfigInputProps) => {
  const helpRef = useRef<HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [helpOpen, setHelpOpen] = useState(false);

  const inputElem = inputRef.current;

  const width = useMemo(
    () => (inputElem ? `${inputElem.clientWidth}px` : '100%'),
    [inputElem]
  );

  return (
    <>
      <MonoTextField
        {...rest}
        size="small"
        inputRef={inputRef}
        InputProps={
          help
            ? {
                ref: helpRef,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="help"
                      size="small"
                      color="secondary"
                      onClick={() => setHelpOpen((prevOpen) => !prevOpen)}
                    >
                      <DapAddIcon icon={faQuestionCircle} />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : rest.InputProps
        }
      />
      <Popover
        open={helpOpen}
        anchorEl={helpRef.current}
        onClose={() => setHelpOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper sx={{ padding: '1rem', width }}>
          <IconButton
            size="small"
            onClick={() => setHelpOpen(false)}
            sx={{ float: 'right' }}
          >
            <CloseIcon />
          </IconButton>
          {help}
        </Paper>
      </Popover>
    </>
  );
};

type ConfigInputFieldProps = {
  requireTouchBeforeError?: boolean;
} & ConfigInputProps;

const ConfigInputField = ({
  name,
  requireTouchBeforeError,
  ...rest
}: ConfigInputFieldProps) => {
  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }: FieldProps) => {
        const showError =
          (!Boolean(requireTouchBeforeError) || touched) && Boolean(error);

        return (
          <ConfigInput
            {...field}
            {...rest}
            error={showError}
            helperText={showError ? error : ''}
          />
        );
      }}
    </Field>
  );
};

const PopoverBody = styled(Paper)({
  padding: '1rem',
});

const SubText = styled(Typography)({
  display: 'block',
  lineHeight: 1,
  fontSize: '80%',
  fontStyle: 'italic',
  padding: '0.25rem 0 0 0.25rem',
  color: '#666',
});

const Settings = () => {
  const openRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const [, binMbMeta] = useField('settings.binMb');
  const [, minAgeMeta] = useField('settings.minAge');
  const [, scanIntervalMeta] = useField('settings.scanInterval');

  const hasError =
    (Boolean(binMbMeta) && Boolean(binMbMeta.error)) ||
    (Boolean(minAgeMeta) && Boolean(minAgeMeta.error)) ||
    (Boolean(scanIntervalMeta) && Boolean(scanIntervalMeta.error));

  return (
    <>
      <Tooltip title="Tweak Default Settings">
        <Button
          ref={openRef}
          fullWidth
          color="secondary"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          <SettingsIcon />
        </Button>
      </Tooltip>
      <Popover
        open={open || hasError}
        anchorEl={openRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverBody>
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{ maxWidth: '300px' }}
          >
            <Grid item>
              <Typography variant="h3">Settings</Typography>
            </Grid>
            <Grid item>
              <TimeUnitField
                fieldName="settings.minAge"
                inputLabel="Minimum File Age"
              />
              <SubText>
                File must have a modified timestamp of "now" minus the above age
                (or older) in order to be sent.
              </SubText>
            </Grid>
            <Grid item>
              <TimeUnitField
                fieldName="settings.scanInterval"
                inputLabel="Scan Interval"
              />
              <SubText>
                Controls how long to wait between root directory scans.
              </SubText>
            </Grid>
            <Grid item>
              <ConfigInputField
                name="settings.binMb"
                label="Chunk Size"
                type="number"
                style={{ width: '10rem' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disableTypography={true}>
                      MB
                    </InputAdornment>
                  ),
                }}
              />
              <SubText>
                Indicates the maximum amount of data that can be sent in a
                single HTTP request.
              </SubText>
            </Grid>
            <Grid item>
              <ConfigInputField
                name="settings.zipLevel"
                label="Compression Level"
                type="number"
                style={{ width: '10rem' }}
                inputProps={{
                  min: 0,
                  max: 9,
                }}
              />
              <SubText>
                The level of compression to use for data transfer: 0 = no
                compression, 9 = best compression but slowest to compute (for
                reference, gzip default is 6).
              </SubText>
            </Grid>
          </Grid>
        </PopoverBody>
      </Popover>
    </>
  );
};

type ITimeUnitFieldProps = {
  fieldName: string;
  inputLabel: string;
  disabled?: boolean;
};

const TimeUnitField = ({
  fieldName,
  inputLabel,
  disabled,
}: ITimeUnitFieldProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <ConfigInputField
          name={`${fieldName}.value`}
          disabled={disabled}
          label={inputLabel}
          type="number"
          style={{ width: '10rem' }}
        />
      </Grid>
      <Grid item>
        <Field name={`${fieldName}.unit`}>
          {({ field }) => (
            <Select {...field} disabled={disabled} size="small">
              {Object.keys(UNITS_EXPORT).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          )}
        </Field>
      </Grid>
    </Grid>
  );
};

type IPathMappingProps = {
  datasetName: string;
  index: number;
  pattern: string;
  template: string;
  cleanupEnabled: boolean;
  cleanupDelay: TimeUnit;
  deleteDisabled?: boolean;
  onAdd: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onDelete: () => void;
};

const PathMapping = ({
  datasetName,
  index,
  cleanupEnabled,
  deleteDisabled,
  onAdd,
  onDelete,
  onMoveUp,
  onMoveDown,
}: IPathMappingProps) => {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const cleanupRef = useRef<HTMLButtonElement>(null);
  const [cleanupOpen, setCleanupOpen] = useState(false);

  const [, cleanupDelayMeta] = useField(`paths[${index}].cleanupDelay`);

  const cleanupHasError =
    Boolean(cleanupDelayMeta) && Boolean(cleanupDelayMeta.error);

  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        direction="column"
        sx={{ paddingBottom: '1rem' }}
      >
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <IconButton
                size="small"
                ref={menuRef}
                onClick={() => setMenuOpen((prevOpen) => !prevOpen)}
                sx={{ height: `${INPUT_HEIGHT}px` }}
              >
                <MoreVertIcon />
              </IconButton>
            </Grid>
            <Popover
              open={menuOpen}
              anchorEl={menuRef.current}
              onClose={() => setMenuOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        onMoveUp();
                        setMenuOpen(false);
                      }}
                      disabled={deleteDisabled}
                    >
                      <ArrowUpwardIcon fontSize="small" /> &nbsp;Move Up
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        onMoveDown();
                        setMenuOpen(false);
                      }}
                      disabled={deleteDisabled}
                    >
                      <ArrowDownwardIcon fontSize="small" /> &nbsp;Move Down
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        onDelete();
                        setMenuOpen(false);
                      }}
                      disabled={deleteDisabled}
                    >
                      <DeleteIcon fontSize="small" /> &nbsp;Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popover>
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item sx={{ flexGrow: 1 }}>
                  <ConfigInputField
                    name={`paths[${index}].pattern`}
                    placeholder="^Instrument\\(?P<YMD>\d{4})/(?P<HMS>\d{6})\.(?P<ext>.*)"
                    label="File Name Pattern to Match"
                    required={true}
                    fullWidth
                    help={
                      <>
                        <Typography>
                          The regular expression to match file paths starting
                          just after the root path specified above. Use{' '}
                          <Link
                            to="https://github.com/google/re2/wiki/Syntax"
                            target="_blank"
                          >
                            this reference
                          </Link>
                          &nbsp; for syntax help or{' '}
                          <Link to="https://regex101.com" target="_blank">
                            this great tool
                          </Link>
                          &nbsp; (use <strong>Golang</strong> flavor) to
                          dynamically test your pattern.
                        </Typography>
                        <Typography>
                          Of particular utility is the named capturing group:{' '}
                          <code>{'(?P<name>matchme)'}</code>
                        </Typography>
                        <Typography>
                          <strong>Example:</strong>
                        </Typography>
                        <Typography>
                          <code>
                            {
                              '^Instrument\\\\(?P<YMD>\\d{4})\\\\(?P<HMS>\\d{6})\\.(?P<ext>.*)'
                            }
                          </code>
                        </Typography>
                        <Typography>
                          <strong>NOTE:&nbsp;</strong>
                          <em>
                            For multiple patterns, each path will be checked in
                            the order defined. Make sure earlier patterns are
                            specific enough to not match what was meant for a
                            later pattern.
                          </em>
                        </Typography>
                      </>
                    }
                    style={{ backgroundColor: '#f9fad2' }}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems="stretch"
                    sx={{ height: `${INPUT_HEIGHT}px` }}
                  >
                    <Tooltip title="Control Local File Cleanup">
                      <Button
                        ref={cleanupRef}
                        fullWidth
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setCleanupOpen((prev) => !prev);
                        }}
                      >
                        {cleanupEnabled ? <TimerIcon /> : <TimerOffIcon />}
                      </Button>
                    </Tooltip>
                    <Popover
                      open={cleanupOpen || cleanupHasError}
                      anchorEl={cleanupRef.current}
                      onClose={() => setCleanupOpen(false)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <PopoverBody>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Field name={`paths[${index}].cleanupEnabled`}>
                              {({ field }) => (
                                <FormControlLabel
                                  sx={{ color: '#000' }}
                                  label="Delete local source files post transfer?"
                                  control={
                                    <Checkbox
                                      sx={{ color: theme.palette.text.primary }}
                                      checked={field.value}
                                      {...field}
                                    />
                                  }
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item>
                            <TimeUnitField
                              fieldName={`paths[${index}].cleanupDelay`}
                              inputLabel="After ..."
                              disabled={cleanupEnabled === false}
                            />
                          </Grid>
                        </Grid>
                      </PopoverBody>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ paddingLeft: '3rem' }} spacing={1}>
            <SubdirectoryArrowRightIcon />
            <Grid item sx={{ flexGrow: 1 }}>
              <ConfigInputField
                name={`paths[${index}].template`}
                placeholder={`${datasetName}.{{.YMD}}.{{.HMS}}.{{.ext}}`}
                label="Standardized Name Template for Rename"
                required={true}
                fullWidth
                help={
                  <>
                    <Typography>
                      The template for renaming the matched local path to a
                      standardized name. The two most important things to
                      remember:
                    </Typography>
                    <Typography component="div">
                      <ol>
                        <li>
                          The name must end up beginning with the dataset name:{' '}
                          <code>{datasetName}</code>. A built-in variable{' '}
                          <code>__source</code> can be used to reference the
                          dataset name automatically.
                        </li>
                        <li>
                          The syntax for referencing a named capture group from
                          the corresponding pattern: <code>{'{{.name}}'}</code>
                        </li>
                      </ol>
                    </Typography>
                    <Typography>
                      In some cases, there needs to be a translation from the
                      pattern within the template. Below are two examples where
                      a simple reference is not sufficient:
                    </Typography>
                    <Typography component="div">
                      <ul>
                        <li>
                          <code>
                            {'{{ parseDayOfYear .Y .YDAY | formatDate "Ymd" }}'}
                          </code>
                        </li>
                        <li>
                          <code>
                            {'{{ parseJulianDate .JDAY | formatDate "Ymd" }}'}
                          </code>
                        </li>
                      </ul>
                    </Typography>
                  </>
                }
              />
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="stretch"
                sx={{ height: `${INPUT_HEIGHT}px` }}
              >
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={() => onAdd()}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

type TesterProps = {
  datasetName: string;
  initialTestPath: string;
  rememberTestPath: (testPath: string) => void;
  config: FormConfig;
};

const translateRegex = (golangRegex: string): RegExp => {
  return new RegExp(golangRegex.replace(/\(\?P</g, '(?<'));
};

const Tester = ({
  datasetName,
  initialTestPath,
  rememberTestPath,
  config,
}: TesterProps) => {
  const uploadService = useUploadService();

  const sep = config.rootPath.startsWith('/') ? '/' : '\\';

  const [filePath, setFilePath] = useState(
    initialTestPath || config.rootPath + sep
  );
  const [fileName, setFileName] = useState(null as string | null);
  const [checking, setChecking] = useState(false);

  const isValid = fileName !== null && fileName.startsWith(datasetName);

  const debouncedTesting = useMemo(() => {
    return debounce((testPath: string) => {
      rememberTestPath(testPath);
      if (!uploadService) {
        setFileName(null);
      }
      setChecking(true);
      uploadService
        .testDatasetMapping(
          testPath,
          datasetName,
          config.rootPath,
          config.paths
        )
        .then(setFileName)
        .finally(() => setChecking(false));
    }, 1000);
  }, [
    uploadService,
    rememberTestPath,
    config.rootPath,
    config.paths,
    datasetName,
    setFileName,
  ]);

  useEffect(() => {
    if (initialTestPath) {
      debouncedTesting(initialTestPath);
    }
  }, [initialTestPath, debouncedTesting]);

  return (
    <Grid container direction="column" sx={{ minWidth: '500px' }} spacing={1}>
      <Grid item>
        <MonoTextField
          fullWidth
          spellCheck={false}
          label="Absolute Data File Path to Test"
          placeholder={`${config.rootPath}${sep}...`}
          value={filePath}
          onChange={(e) => {
            const testPath = e.currentTarget.value;
            setFilePath(testPath);
            debouncedTesting(testPath);
          }}
        />
      </Grid>
      {Boolean(filePath) && filePath.length > config.rootPath.length + 1 && (
        <Grid item>
          <Alert severity={isValid ? 'success' : checking ? 'info' : 'error'}>
            <AlertTitle>
              {checking && 'Checking ...'}
              {!checking && isValid && 'Matched!'}
              {!checking && !isValid && fileName === null && 'No Match'}
              {!checking && !isValid && fileName !== null && (
                <>
                  Must start with <strong>{datasetName}</strong>
                </>
              )}
            </AlertTitle>
            <code>{Boolean(fileName) && fileName}</code>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfigureDataset;
