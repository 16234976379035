import React from 'react';
import { inject } from 'mobx-react';
import { styled } from '@mui/material/styles';

import Project from '@extensions/models/Project';
import {
  ProjectOverview as DapProjectOverview
} from '@dapclient/components/project/ProjectOverview';
import ProjectStatsBar from '@extensions/components/project/ProjectStatsBar';

const StyledProjectStatsBar = styled(ProjectStatsBar)(({ theme }) => ({
  gridTemplateColumns: 'auto',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'auto auto',
  },
  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: 'auto',
  },
}));

class ProjectOverview extends DapProjectOverview {
  getUsageStats = (project: Project): React.ReactNode | null => {
    return (
      <StyledProjectStatsBar item={project} />
    );
  };
}

export default inject((store: any) => ({
  projectService: store.projectService,
  datasetService: store.datasetService,
  metricsService: store.metricsService,
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(ProjectOverview);
