import moment, { Moment } from 'moment'

import { StatSegment } from '@dapclient/models/Dataset'

export const nextRange = (
    goingUp: boolean,
    timeline: StatSegment[],
    currentIndex: number,
    currentRange?: [Moment, Moment],
    absoluteRange?: [Moment, Moment],
    filesPerRequest?: number,
  ): [Moment | undefined, Moment | undefined, number] => {
    let index = currentIndex
    let segment = timeline[index]
    let perDay = segment.fileCount.median
    let nFiles = filesPerRequest || 100
    let nDays = nFiles / perDay
    let beg = moment()
    let end = moment()
    let unit: moment.unitOfTime.DurationConstructor = 'days'
    let nUnit = Math.max(Math.round(nDays), 1)
    let perUnit = perDay
    nFiles = nUnit * perUnit
    // This could work but since we only know stats for an entire day, there can
    // be chunks of the day where there are no files and that will mess with the
    // visual component logic of building the list ...
    // if (nUnit < 1) {
    //   nUnit = Math.round(nDays * 24);
    //   perUnit = Math.round(perDay / 24);
    //   unit = 'hours';
    //   if (nUnit < 1) {
    //     nUnit = Math.round(nDays * 1440);
    //     perUnit = Math.round(perDay / 1440);
    //     unit = 'minutes';
    //   }
    // }
    const begBound = absoluteRange ? absoluteRange[0] : moment()
    const endBound = absoluteRange ? absoluteRange[1] : moment()
    const beforeBound = (d: Moment): boolean => {
      return absoluteRange !== undefined && d.isBefore(absoluteRange[0])
    }
    const afterBound = (d: Moment): boolean => {
      return absoluteRange !== undefined && d.isAfter(absoluteRange[1])
    }
    if (goingUp) {
      if (index === timeline.length-1 && currentRange && currentRange[1].isSameOrAfter(segment.end)) {
        return [undefined, undefined, index]
      }
      if (currentRange && currentRange[1].isBefore(segment.end)) {
        beg = currentRange[1].clone()
      } else {
        segment = timeline[++index]
        beg = segment.beg
      }
      beg = beforeBound(beg) ? begBound : beg
      end = beg.clone().add(nUnit, unit)
      if (afterBound(end)) {
        end = endBound
      } else if (end.isAfter(segment.end)) {
        end = segment.end
        let nRemainingFiles = nFiles - (end.diff(beg, unit)) * perUnit
        while (index+1 < timeline.length && nRemainingFiles > 0) {
          segment = timeline[++index]
          nUnit = nRemainingFiles / perUnit
          end = segment.beg.clone().add(nUnit, unit)
          if (afterBound(end)) {
            end = endBound
            break
          }
          if (end.isAfter(segment.end)) {
            nRemainingFiles -= (segment.end.diff(segment.beg, unit)) * perUnit
            continue
          }
          break
        }
      }
    } else {
      if (index === 0 && currentRange && currentRange[0].isSameOrBefore(segment.beg)) {
        return [undefined, undefined, index]
      }
      if (currentRange) {
        if (currentRange[0].isAfter(segment.beg)) {
          end = currentRange[0].clone()
        } else {
          segment = timeline[--index]
          end = segment.end
        }
      } else {
        end = segment.end
      }
      end = afterBound(end) ? endBound : end
      beg = end.clone().subtract(nUnit, unit)
      if (beforeBound(beg)) {
        beg = begBound
      } else if (beg.isBefore(segment.beg)) {
        beg = segment.beg
        let nRemainingFiles = nFiles - (end.diff(beg, unit)) * perUnit
        while (index-1 > 0 && nRemainingFiles > 0) {
          segment = timeline[--index]
          nUnit = nRemainingFiles / perUnit
          beg = segment.end.clone().subtract(nUnit, unit)
          if (beforeBound(beg)) {
            beg = begBound
            break
          }
          if (beg.isBefore(segment.beg)) {
            nRemainingFiles -= (segment.end.diff(segment.beg, unit)) * perUnit
            continue
          }
          break
        }
      }
    }
    if (beg.isAfter(end)) {
      // Not sure if this can happen really
      return [end, beg, index]
    }
    return [beg, end, index]
  }
