import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';

export const FILE_TYPE_REACTIVE_ID = 'FileTypeFilter';

export default class FileTypeFilter extends React.Component<{
  react?: react;
}> {
  render = () => {
    return (
      <MultiList
        className="data-filter"
        componentId={FILE_TYPE_REACTIVE_ID}
        dataField="dapFileSummary.types.keyword"
        title="File Type"
        filterLabel="File Type"
        showSearch={false}
        react={this.props.react}
        URLParams={true}
      />
    );
  };
}
