import { WidgetProps } from '@rjsf/utils';
import TextWidget from '@extensions/components/metadata/review/TextWidget';

export default function HasEmailWidget(
  props: WidgetProps
) {
  return <TextWidget {...props} value={stripMailto(props.value)} />;
}

const mailtoRegex = /^mailto:/;

function stripMailto(hasEmail: string) {
  return hasEmail ? hasEmail.replace(mailtoRegex, '') : hasEmail;
}
