import { JSONSchema7 } from 'json-schema';
import { UiSchema } from '@rjsf/core';

export default class MetadataSchema {
  dataSchema: JSONSchema7;
  uiSchema: UiSchema;
  constructor(data) {
    this.dataSchema = data.dataSchema;
    this.uiSchema = data.uiSchema;
  }
}
