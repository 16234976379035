import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';

const StyledRootDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'space-between',
  '& button:nth-last-of-type(n+2)': {
    marginRight: '1rem',
  },
}));

export interface IButtonBarProps {
  className?: string;
  children: React.ReactNode;
}

export interface IButtonBarState { }

@observer
export class ButtonBar extends React.Component<
  IButtonBarProps,
  IButtonBarState
> {
  render() {
    const { children } = this.props;
    return <StyledRootDiv>{children}</StyledRootDiv>;
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(ButtonBar);
