import { MouseEvent as ReactMouseEvent } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { useConfirm, ConfirmOptions } from 'material-ui-confirm';

const DEFAULT_OPTIONS: ConfirmOptions = {
  confirmationText: 'Yes',
  cancellationText: 'No',
};

export interface IConfirmIconButtonProps extends IconButtonProps {
  confirmOptions?: ConfirmOptions;
  beforeConfirm?: (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

export default function ConfirmIconButton(props: IConfirmIconButtonProps) {
  const {
    onClick = () => { },
    confirmOptions,
    beforeConfirm,
    ...otherProps
  } = props;
  const confirm = useConfirm();
  const onClickWithConfirm = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    beforeConfirm && beforeConfirm(event);
    confirm({ ...DEFAULT_OPTIONS, ...confirmOptions }).then(() =>
      onClick(event)
    );
  };
  return <IconButton {...otherProps} onClick={onClickWithConfirm} />;
}
