import { useState, useCallback, useEffect } from 'react'
import { useIsMounted } from './useIsMounted'
import { useCartService, useSecurityService } from './useService'

import getInstructions from '@extensions/components/dataset/externalSourceInstructions'

export function useExternalSource(src: string | null) {
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(src === null)
    const [instructions, setInstructions] = useState(null as null | JSX.Element)

    const isMounted = useIsMounted()

    const cartService = useCartService()
    const securityService = useSecurityService()

    const onReady = useCallback(ready => {
        if (!isMounted.current) {
            return
        }
        setReady(ready)
        setLoading(false)
        setInstructions(ready || !securityService.user
            ? null
            : getInstructions(securityService.user, src)
        )
    }, [src, isMounted, securityService.user])

    useEffect(() => {
        if (src === null || loading) {
            return
        }
        if (securityService && !securityService.user) {
            return
        }
        setLoading(true)
        cartService.isExternalSourceReady(src, onReady)
    }, [cartService, securityService, src, loading, onReady])

    return [loading, ready, instructions] as [boolean, boolean, JSX.Element | null]
}
