import { css } from '@emotion/css'
import { useMemo, useState, Fragment } from 'react'
import { Grid, ScopedCssBaseline, styled, Link } from '@mui/material'

import {
  FiltersWrapper,
  MultiList,
  SEARCH_BAR_REACTIVE_ID,
  SearchBar,
  SearchProvider,
  SelectedFilters,
} from '@extensions/components/search-core'
import { escapeHtml } from '@extensions/utils/format'
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils'
import SearchResults, { ResultStats } from '@extensions/components/search-core/SearchResults'
import theme from '@extensions/services/Theme'

interface CodeHit {
  contributors: string[];
  file_content: string;
  file_extension: string;
  file_is_binary: boolean;
  file_url: string;
  file_name: string;
  file_path: string;
  file_type: string;
  last_commit: string;
  last_commit_date: string;
  node_id: string;
  repo_full_name: string;
  repo_name: string;
  repo_parent: string;
  repo_url: string;
  highlight: Highlight;
  _indexed: string;
}

interface Highlight {
  'attachment.content'?: string[];
}

const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));

const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledMultiList = styled(MultiList)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const REACTIVE_FILE_TYPE_ID = 'FileTypeFilter'
const REACTIVE_FILE_EXT_ID = 'FileExtFilter'
const REACTIVE_REPO_ID = 'RepoFilter'
const REACTIVE_CONTRIB_ID = 'ContributorFilter'
const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  REACTIVE_FILE_TYPE_ID,
  REACTIVE_FILE_EXT_ID,
  REACTIVE_REPO_ID,
  REACTIVE_CONTRIB_ID,
]

const allReactiveIdsExcept = getAllReactiveIdsExcept(REACTIVE_IDS)

const getStatsDisplayString = (stats?: ResultStats) => {
  if (stats) {
    const { currentPage, numberOfPages, numberOfResults } = stats;
    return `${numberOfResults.toLocaleString()} file${numberOfResults === 1 ? '' : 's'
      } found (page ${currentPage + 1} of ${numberOfPages})`;
  }
  return '';
};

export interface IRepoProps {
  className?: string;
}

const Code = () => {
  return (
    <ScopedCssBaseline sx={{ backgroundColor: theme.palette.grey[50] }}>
      <SearchProvider
        elasticIndex="codehub/code"
        apiPrefix="api"
        theme={{
          colors: {
            primaryColor: '#53b03f',
          },
        }}
      >
        <StyledSearchBar
          dataField={['file_path']}
          autosuggest={false}
          URLParams={true}
          queryFormat="and"
          customQuery={(value, props) => {
            const query = value
            value = value.toLowerCase()
            if (!value) {
              return {};
            }
            return {
              query: {
                bool: {
                  should: [
                    { wildcard: { 'file_path.keyword': { value, boost: 15 } } },
                    { wildcard: { 'file_name': { value, boost: 15 } } },
                    { wildcard: { 'repo_name': { value, boost: 15 } } },
                    {
                      match_phrase: {
                        'attachment.content': {
                          query,
                          analyzer: 'special_char_analyzer',
                          boost: 5,
                        },
                      },
                    },
                  ],
                  minimum_should_match: 1,
                },
              },
            };
          }}
          highlight
          customHighlight={() => ({
            highlight: {
              fields: {
                'attachment.content': {
                  type: 'unified',
                  fragment_size: 0,
                  number_of_fragments: 100,
                },
              },
            },
          })}
        />
        <StyledSelectedFilters />
        <Grid container spacing={6}>
          <Grid item sm={3} md={4} sx={{ width: '100%' }}>
            <FiltersWrapper >
              <StyledMultiList
                componentId={REACTIVE_FILE_TYPE_ID}
                title="File Type"
                dataField="file_type.keyword"
                react={{
                  and: allReactiveIdsExcept(REACTIVE_FILE_TYPE_ID),
                }}
                maxLabelWidth="none"
                showBottomBorder
                URLParams
                filterLabel="File Type"
              />
              <StyledMultiList
                componentId={REACTIVE_FILE_EXT_ID}
                title="File Extension"
                dataField="file_extension.keyword"
                react={{
                  and: allReactiveIdsExcept(REACTIVE_FILE_EXT_ID),
                }}
                maxLabelWidth="none"
                showBottomBorder
                URLParams
                filterLabel="File Extension"
              />
              <StyledMultiList
                componentId={REACTIVE_REPO_ID}
                title="Repository"
                dataField="repo_name.keyword"
                react={{
                  and: allReactiveIdsExcept(REACTIVE_REPO_ID),
                }}
                maxLabelWidth="none"
                showBottomBorder
                URLParams
                filterLabel="Repository"
              />
              <StyledMultiList
                componentId={REACTIVE_CONTRIB_ID}
                title="Contributor"
                dataField="contributors.keyword"
                react={{
                  and: allReactiveIdsExcept(REACTIVE_CONTRIB_ID),
                }}
                maxLabelWidth="none"
                showBottomBorder
                URLParams
                filterLabel="Contributor"
              />
            </FiltersWrapper>
          </Grid>
          <Grid item sm={9} md={8}>
            <SearchResults
              dataField="name.keyword"
              sortBy="asc"
              react={{
                and: [SEARCH_BAR_REACTIVE_ID, ...REACTIVE_IDS],
              }}
              renderItem={(hit: CodeHit) => (
                <Hit key={hit.node_id} data={hit} />
              )}
              renderNoResults={() => {
                return "No results found."
              }}
              renderResultStats={(stats: ResultStats) => (
                <div
                  aria-hidden
                  className={css`
                    && {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      margin-right: 0.5rem;
                      align-items: center;
                      font-size: 90%;
                      color: #999;
                    }
                  `}
                >
                  {getStatsDisplayString(stats)}
                </div>
              )}
              sortOptions={[
                {
                  label: 'Relevance',
                  dataField: '_score',
                  sortBy: 'desc',
                },
                {
                  label: 'Repository',
                  dataField: 'repo_name.keyword',
                  sortBy: 'asc',
                },
                {
                  label: 'File Name',
                  dataField: 'file_name.keyword',
                  sortBy: 'asc',
                },
              ]}
              excludeFields={['attachment', 'file_content']}
            />
          </Grid>
        </Grid>
      </SearchProvider>
    </ScopedCssBaseline>
  )
}

interface IHitProps {
  data: CodeHit
}

const HitWrapper = styled('li')`
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;

  .path > :first-of-type, .repo > :first-of-type {
    color: #aaa;
  }
`

const Hit = ({ data }: IHitProps) => {
  const dir = data.file_path.split('/').slice(0, -1).join(' / ')
  return (
    <HitWrapper>
      <StyledLink variant='a' className="path" href={data.file_url} target="_blank" rel="noreferrer">
        {data.file_name}
      </StyledLink>
      <HighlightViewer data={data.highlight} />
      <div className="path">
        <small>
          Path:&nbsp;
        </small>
        <small>
          /&nbsp;{dir}
        </small>
      </div>
      <div className="repo">
        <small>
          Repository:&nbsp;
        </small>
        <small>
          <StyledLink variant='a' href={data.repo_url} target="_blank" rel="noreferrer">
            {data.repo_full_name}
          </StyledLink>
        </small>
      </div>
    </HitWrapper>
  )
}

interface IHighlightProps {
  data: Highlight
}

const CodeBlock = styled('div')`
  font-family: monospace;
  display: grid;
  grid-template-columns: minmax(0, min-content) auto;
  border: 1px solid #ddd;
  border-radius: 3px;
  max-height: 500px;
  overflow: auto;

  .gutter {
    text-align: right;
    background-color: #eee;
    border-right: 1px solid #ccc;
    color: #777;
  }

  .content {
    overflow-x: auto;
    background-color: #f4f4f4;

    .code {
      white-space: pre;
    }

    em {
      font-weight: bold;
      font-style: normal;
      background-color: rgba(255, 255, 0, 0.5);
    }
  }

  .gutter, .content {
    padding: 3px 5px;
    font-size: 10px;

    & > div {
      min-height: 16px;
    }
  }

  .gap {
    min-height: 10px !important;
    height: 10px !important;
    margin: 3px -5px;
  }

  .content .gap {
    background-color: #fff;
    border: 0 solid #ccc;
    border-width: 1px 0;
  }

  button {
    text-align: center;
    cursor: pointer;
    border-radius: 15px;
    border: none;
    background-color: #fff;
    padding: 2px 5px;
  }
  button:hover {
    background-color: #aaa;
    color: #fff;
  }
  button.spacer {
    visibility: hidden;
  }
  button > span {
    display: inline-block;
    rotate: 90deg;
  }
`

interface Section {
  lines: Line[]
  hl: boolean
  show: boolean
}

interface Line {
  num: number
  txt: string
}

const HighlightViewer = ({ data }: IHighlightProps) => {
  const hl = data['attachment.content'] || null
  const lines = useMemo(() => {
    return (hl || []).map(b => b.split("\n")).flat()
      .map(line => {
        const m = line.match(/^(?:(\d+)\s?)?(.*)$/)
        return m ? { num: +m[1] || 0, txt: m[2] || '' } : { num: 0, txt: '' }
      })
  }, [hl])
  const sections = useMemo(() => {
    return lines.reduce((sections, line, i) => {
      if (line.num === 0) {
        if (lines.length > i + 1) {
          line.num = lines[i + 1].num - 1
        }
      }
      const hl = line.txt.search(/<em>[^<]+<\/em>/) >= 0
      const s = sections[sections.length - 1] || null
      if (s === null) {
        return [{
          lines: [line],
          show: hl,
          hl,
        }]
      }
      if (s.hl === hl) {
        s.lines.push(line)
        return sections
      }
      sections.push({
        lines: [line],
        show: hl,
        hl,
      })
      return sections
    }, [] as Section[])
  }, [lines])
  const renderGapIfRelevant = (section, lineIndex) => {
    const s = section
    const i = lineIndex
    const line = section.lines[i]
    if (i > 0 && s.lines[i - 1].num >= 0 && (line.num - s.lines[i - 1].num) > 1) {
      return (<div className="gap"></div>)
    }
    return null
  }
  const [toggled, setToggled] = useState([] as number[])
  return sections.length ? (
    <CodeBlock>
      <div className="gutter">
        {sections.map((s, si) => (
          <Fragment key={`${si}`}>
            {!s.show && toggled.indexOf(si) < 0 && (
              <button
                onClick={() => setToggled([...toggled, si])}
                title={`Show ${s.lines.length} hidden lines`}
              ><span>&harr;</span></button>
            )}
            {(s.show || toggled.indexOf(si) >= 0) && s.lines.map((line, i) => (
              <Fragment key={`${line.num}-${i}`}>
                {renderGapIfRelevant(s, i)}
                <div className="num">{line.num <= 0 ? '' : line.num}</div>
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
      <div className="content">
        {sections.map((s, si) => (
          <Fragment key={`${si}`}>
            {!s.show && toggled.indexOf(si) < 0 && (
              <button className="spacer">&nbsp;</button>
            )}
            {(s.show || toggled.indexOf(si) >= 0) && s.lines.map((line, i) => (
              <Fragment key={`${i}-${line.num}`}>
                {renderGapIfRelevant(s, i)}
                <div
                  className="code"
                  dangerouslySetInnerHTML={{
                    __html: s.hl ? escapeHtml(line.txt).replace(
                      /&lt;em&gt;([^&]+)&lt;\/em&gt;/g,
                      '<em>$1</em>'
                    ) : escapeHtml(line.txt)
                  }}
                />
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </CodeBlock>
  ) : null
}

export default Code;