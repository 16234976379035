import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import theme from '@extensions/services/Theme';
import { ICartService } from '@extensions/services/ICartService';

import AddIcon from '@extensions/utils/AddIcon';
import Link from '@dapclient/components/core/Link';

const StyledCountDiv = styled('div')(({
  display: 'flex',
  fontSize: '.75rem',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: theme.typography.fontWeightBold,
  position: 'absolute',
  top: '-.5rem',
  left: '.75rem',
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
}));

const StyledTextSpan = styled('span')(({
  marginLeft: '1rem',
}));

export interface ICartLinkProps {
  className?: string;
  cartService?: ICartService;
  children?: any;
  styleObject?: any;
}

@inject('cartService')
@observer
class CartLink extends React.Component<ICartLinkProps> {
  render() {
    const { cartService, children } = this.props;
    if (!cartService) {
      return null;
    }

    const count = cartService.orderItems.length;

    return (
      <Link
        aria-label={`Cart (${count} datasets)`}
        to="/profile/cart"
        underline='hover'
        sx={{
          position: 'relative'
        }}
      >
        <AddIcon
          icon={faShoppingCart}
          aria-hidden
          style={{ ...this.props.styleObject }}
        />
        <StyledCountDiv aria-hidden>
          <span>{count}</span>
        </StyledCountDiv>
        <StyledTextSpan>
          {children}
        </StyledTextSpan>
      </Link>
    );
  }
}

export default CartLink;
