import { Moment } from 'moment';
import { filter } from 'lodash';
import { css } from '@emotion/css';
import { observer, inject } from 'mobx-react';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { Tooltip, Button } from '@mui/material';

import theme from '@extensions/services/Theme';
import { ICartService } from '@extensions/services/ICartService';
import { ICachingService } from '@extensions/services/ICachingService';

import AddIcon from '@extensions/utils/AddIcon';
import { formatBytes } from '@extensions/utils/format';
import {
  ViewValue,
  ViewStat,
} from '@extensions/components/dataset/TimelineController';
import { useIsMounted } from '@dapclient/hooks/useIsMounted';
import Timeline from '@extensions/components/dataset/Timeline';
import Dataset, { DatasetRawStats } from '@extensions/models/Dataset';
import AddToCartIconButton from '@extensions/components/dataset/AddToCartIconButton';
import DatasetResultIcons from '@extensions/components/dataset-search/DatasetResultIcons';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

interface ITimelineSetProps {
  dataset: Dataset;
  beg?: Moment;
  end?: Moment;
  zoom?: [Moment, Moment];
  view?: [ViewValue | null, ViewStat | null];
  handleZoom: (zoom: [Moment, Moment]) => void;
  isSingleContext?: boolean;
  cachingService?: ICachingService;
  cartService?: ICartService;
}

const Heading = styled('div')`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled('span')`
  font-size: 12pt;
  font-weight: bold;
  & button {
    border: none;
    background: none;
    margin-left: 0.25rem;
    visibility: hidden;
    cursor: pointer;
  }
  & button:hover {
    background-color: #eee;
    border-radius: 5px;
  }
  & button svg {
    transform: scale(0.8);
  }
`;

const Wrapper = styled('div')`
  &:hover ${Label} button {
    visibility: visible;
  }
`;

const Stats = styled('span')`
  font-size: 8pt;
  color: ${theme.palette.grey[700]}
`;

const Error = styled('div')`
  font-family: monospace;
  color: red;
  font-size: 8pt;
`;

const NotTime = styled('div')`
  font-size: 8pt;
  font-style: italic;
  color: #aaa;
`;

const StyledTitle = styled('a')`
  text-decoration: none;
  color: ${theme.palette.secondary.main};
`

const MAX_COUNT_FOR_AUTOLOAD = 25;

const TimelineSet = inject(
  'cachingService',
  'cartService'
)(
  observer(
    ({
      beg,
      end,
      zoom,
      handleZoom,
      view,
      dataset,
      isSingleContext,
      cachingService,
      cartService,
    }: ITimelineSetProps) => {
      const autoLoad = dataset.extensions.length <= MAX_COUNT_FOR_AUTOLOAD;

      const [timelines, setTimelines] = useState(
        undefined as DatasetRawStats[] | undefined
      );
      const [imageTimelines, setImageTimelines] = useState(
        undefined as DatasetRawStats[] | undefined
      );
      const [err, setErr] = useState(undefined as string | undefined);
      const [isLoading, setIsLoading] = useState(false);
      const isMounted = useIsMounted();

      const loadData = useCallback(() => {
        if (cachingService === undefined || isLoading || !isMounted.current) {
          return;
        }
        setIsLoading(true);
        let promises = [
          cachingService.getRawStats({
            Dataset: dataset.name,
          }),
        ];
        if (dataset.imagesDatasetName) {
          promises.push(
            cachingService.getRawStats({
              Dataset: dataset.imagesDatasetName,
            })
          );
        }
        Promise.all(promises)
          .then((resolved) => {
            if (isMounted.current) {
              for (let i = 0; i < resolved.length; i++) {
                const tl = resolved[i];
                const result = filter(tl, (t) => {
                  return (
                    t.timeline.length > 0 &&
                    (dataset.fileTypes === undefined ||
                      dataset.fileTypes.length === 0 ||
                      dataset.fileTypes.indexOf(t.extension.split('.').pop() || '') >=
                      0)
                  );
                });
                if (i === 0) {
                  setTimelines(result);
                  continue;
                }
                setImageTimelines(result);
              }
            }
          })
          .catch((err) => {
            if (isMounted.current) {
              setErr(err.toString());
              setTimelines([]);
              setImageTimelines([]);
            }
          })
          .finally(() => {
            if (isMounted.current) {
              setIsLoading(false);
            }
          });
      }, [
        dataset,
        isLoading,
        cachingService,
        isMounted,
      ]);
      const extCount = useMemo(() => {
        if (dataset.fileTypes === undefined || dataset.fileTypes.length === 0) {
          return dataset.extensions.length;
        }
        return filter(
          dataset.extensions,
          (e) => dataset.fileTypes.indexOf(e.split('.').pop() || '') >= 0
        ).length;
      }, [dataset]);
      useEffect(() => {
        if (autoLoad && timelines === undefined) {
          loadData();
        }
      });

      const datasetName = dataset.name;
      const datasetTitle = dataset.title;

      const {
        isGeneralUseReady,
        isPublic,
        isMfaRestricted,
        isDataQualityAffected,
        isTimeCentric,
        fileCount,
        totalSize,
        updated,
      } = dataset;

      const hasImages =
        imageTimelines !== undefined && imageTimelines.length > 0;

      return (
        <Wrapper>
          <Heading>
            <Label style={{}}>
              {isSingleContext ? (
                <>
                  <DatasetResultIcons
                    datasetName={datasetName}
                    isGeneralUseReady={isGeneralUseReady ?? false}
                    isPublic={isPublic ?? false}
                    isMfaRestricted={isMfaRestricted ?? false}
                    isDataQualityAffected={isDataQualityAffected ?? false}
                  />
                  {datasetName.split('/').pop()}
                </>
              ) : (
                <>
                  <StyledTitle href={`/ds/${datasetName}`}>
                    {datasetName.split('/').join(' / ')}
                  </StyledTitle>
                </>
              )}
              {!isSingleContext && isTimeCentric && timelines !== undefined && (
                <Tooltip title="Zoom to this dataset">
                  <button
                    onClick={() => {
                      let beg: Moment | null = null;
                      let end: Moment | null = null;
                      for (const tl of timelines) {
                        if (tl.timeline.length === 0) {
                          continue;
                        }
                        const first = tl.timeline[0];
                        const last = tl.timeline[tl.timeline.length - 1];
                        if (beg === null || first.beg.isBefore(beg)) {
                          beg = first.beg;
                        }
                        if (end === null || last.end.isAfter(end)) {
                          end = last.end;
                        }
                      }
                      if (beg && end) {
                        handleZoom([beg, end]);
                      }
                    }}
                  >
                    <AddIcon icon={faExpand} />
                  </button>
                </Tooltip>
              )}
              {Boolean(datasetTitle) && (
                <div style={{ marginBottom: '0.5rem' }}>
                  {!isSingleContext && (
                    <DatasetResultIcons
                      datasetName={datasetName}
                      isGeneralUseReady={isGeneralUseReady ?? false}
                      isPublic={isPublic ?? false}
                      isMfaRestricted={isMfaRestricted ?? false}
                      isDataQualityAffected={isDataQualityAffected ?? false}
                    />
                  )}
                  <em style={{ fontSize: "70%", fontWeight: 'normal' }}>
                    ( {datasetTitle} )
                  </em>
                </div>
              )}
            </Label>
            <div>
              <Stats>
                {fileCount.toLocaleString()} files,&nbsp;
                {formatBytes(totalSize)},&nbsp; Updated {updated.fromNow()}
              </Stats>
              {!isSingleContext && cartService !== undefined && (
                <>
                  &nbsp;
                  <AddToCartIconButton
                    dataset={dataset}
                    fileCount={fileCount}
                    zoom={zoom}
                  />
                </>
              )}
            </div>
          </Heading>
          {!autoLoad && !isLoading && isTimeCentric && timelines === undefined && (
            <Button size="small" fullWidth onClick={loadData}>
              &mdash; Load&nbsp;<strong>{extCount}</strong>&nbsp;Timelines
              &mdash;
            </Button>
          )}
          {isLoading && (
            <div
              className={css`
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: center;
                align-items: center;
              `}
            >
              <CenteredCircularProgress
                className={css`
                  margin: 1rem;
                `}
                size={10}
                aria-label="loading timeline"
              />
            </div>
          )}
          {timelines !== undefined &&
            timelines.length > 0 &&
            timelines.map((tl, i) => (
              <Timeline
                dataset={dataset}
                key={tl.datasetName + tl.extension}
                view={view}
                beg={zoom ? zoom[0] : beg}
                end={zoom ? zoom[1] : end}
                data={tl}
                isFirstInSet={i === 0}
                isLastInSet={i === timelines.length - 1}
              />
            ))}
          {hasImages && <div style={{ marginTop: '0.5rem' }}></div>}
          {hasImages &&
            imageTimelines.map((tl, i) => (
              <Timeline
                dataset={dataset}
                key={tl.datasetName + tl.extension}
                view={view}
                beg={zoom ? zoom[0] : beg}
                end={zoom ? zoom[1] : end}
                data={tl}
                isFirstInSet={i === 0}
                isLastInSet={i === imageTimelines.length - 1}
                title={
                  <>
                    <em style={{ opacity: 0.7 }}>{tl.datasetName}</em>
                    &nbsp;*.{tl.extension}
                  </>
                }
              />
            ))}
          {err !== undefined && <Error>[ {err} ]</Error>}
          {!isTimeCentric && (
            <NotTime>
              [ Timelines not applicable - not a time-ordered dataset ]
            </NotTime>
          )}
        </Wrapper>
      );
    }
  )
);

export default TimelineSet;
