import clsx from 'clsx';
import { ObjectFieldTemplateProps as BaseObjectFieldTemplateProps } from '@rjsf/utils';
import BaseObjectFieldTemplate from '@extensions/components/metadata/review/BaseObjectFieldTemplate/BaseObjectFieldTemplate';

export interface IObjectFieldTemplateProps
  extends BaseObjectFieldTemplateProps {
  className?: string;
}

export default function ObjectFieldTemplate(props: IObjectFieldTemplateProps) {
  const { className, idSchema, ...otherProps } = props;

  return (
    <div className={clsx(className)}>
      <BaseObjectFieldTemplate {...otherProps} idSchema={idSchema} />
    </div>
  );
}
