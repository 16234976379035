import { Moment } from 'moment';
import { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { IconButton, Tooltip } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

import { ICartService } from '@extensions/services/ICartService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import Dataset from '@extensions/models/Dataset';
import { Modal } from '@extensions/components/core/Modal';
import { useExternalSource } from '@extensions/hooks/useExternalSource';

export interface IAddToCartIconButtonProps {
  className?: string;
  cartService?: ICartService;
  securityService?: ISecurityService;
  dataset: Dataset;
  fileCount?: number;
  zoom?: [Moment, Moment];
  isDataQualityAffected?: boolean;
}

export interface IAddToCartIconButtonState { }

const AddToCartIconButton = observer(({
  cartService, securityService, dataset, zoom, fileCount,
}: IAddToCartIconButtonProps) => {

  const [showModal, setShowModal] = useState(false);
  const [xLoading, xReady, xInstructions] = useExternalSource(dataset.externalSource);

  const xDisabled = xLoading || (!xReady && !xInstructions)

  if (!cartService || !securityService) {
    return null;
  }
  const isInCart = cartService.isInCart(dataset.name, zoom);
  const hasFiles = fileCount && fileCount > 0;
  const CartIconComponent = isInCart ? RemoveShoppingCartIcon : AddShoppingCartIcon;
  let title = '';
  if (!hasFiles) {
    title = "Can't add empty dataset";
  } else if (xLoading) {
    title = "Checking external source readiness...";
  } else if (!xReady) {
    if (xInstructions) {
      title = "See steps needing to be taken before adding to cart";
    } else {
      title = "Log in before adding this external dataset to the cart";
    }
  } else {
    title = isInCart ? 'Remove From Cart' : 'Add to Cart';
  }
  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          aria-label={isInCart ? 'remove from cart' : 'add to cart'}
          size="small"
          onClick={() => {
            if (xInstructions !== null) {
              setShowModal(true);
              return;
            }
            if (isInCart) {
              cartService.removeFromCart(dataset.name, zoom);
            } else {
              cartService.addToCart(dataset.name, zoom);
            }
          }}
          disabled={!hasFiles || xDisabled}
        >
          <CartIconComponent />
        </IconButton>
        {Boolean(dataset.externalSource) && (
          <Modal open={showModal} handleClose={() => setShowModal(false)}>
            {xInstructions ?? <></>}
          </Modal>
        )}
      </span>
    </Tooltip>
  );
});

export default inject((store: any) => ({
  cartService: store.cartService,
  securityService: store.securityService,
}))(AddToCartIconButton);
