import { useState } from 'react';
import styled from '@emotion/styled';
import moment, { Moment } from 'moment';

import { Typography } from '@mui/material';
import TimelineController, {
  ViewValue,
  ViewStat,
} from '@extensions/components/dataset/TimelineController';
import TimelineSet from '@extensions/components/dataset/TimelineSet';

import Dataset from '@extensions/models/Dataset';

export interface ITimelineContainerProps {
  datasets: Dataset[];
  datalessDatasetIds: any[];
  fileTypes?: string[];
  defaultZoom?: [Moment, Moment];
}

const Wrapper = styled.div``;

const DatalessLabel = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: rgb(2, 91, 130);
`;

const DatalessSubLabel = styled(Typography)`
  font-size: 0.75rem;
  padding-left: 4px;
  && {
    margin-bottom: 0.5rem;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
`;

const TimelineContainer = ({
  datasets,
  datalessDatasetIds,
  fileTypes,
  defaultZoom,
}: ITimelineContainerProps) => {
  const [zoom, setZoom] = useState(defaultZoom);
  const [view, setView] = useState([null, null] as [
    ViewValue | null,
    ViewStat | null
  ]);

  let min: number | undefined;
  let max: number | undefined;
  for (let d of datasets) {
    if (d.epochBegins === undefined || d.epochEnds === undefined) {
      continue;
    }
    if (min === undefined || d.epochBegins < min) {
      min = d.epochBegins;
    }
    if (max === undefined || d.epochEnds > max) {
      max = d.epochEnds;
    }
  }

  let beg = min ? moment.unix(min) : moment();
  let end = max ? moment.unix(max).add(1, 'days') : moment();
  if (zoom && zoom[0].isBefore(beg)) {
    beg = zoom[0];
  }
  if (zoom && zoom[1].isAfter(end)) {
    end = zoom[1];
  }

  return (
    <Wrapper>
      {datasets.length > 0 && (
        <TimelineController
          beg={beg}
          end={end}
          zoom={zoom}
          viewStat={view[1] || undefined}
          viewValue={view[0] || undefined}
          handleZoom={([beg, end]) => setZoom([beg, end])}
          handleViewChange={(value, stat) =>
            setView([value || null, stat || null])
          }
        />
      )}
      <List>
        {datasets.map((d) => (
          <li key={d.name}>
            <TimelineSet
              dataset={d}
              beg={beg}
              end={end}
              zoom={zoom}
              view={view}
              handleZoom={(zoom) => setZoom(zoom)}
            />
          </li>
        ))}
        {datalessDatasetIds.map((set, index) => (
          <li key={index}>
            {set.externalDistribution ? (
              <>
                <DatalessLabel href={`/ds/${set.identifier}`}>
                  {set.identifier.split('/').join(' / ')}
                </DatalessLabel>
                <DatalessSubLabel>Data Available via External Link</DatalessSubLabel>
              </>
            ) : (
              <>
                <DatalessLabel href={`/ds/${set.identifier}`}>
                  {set.identifier.split('/').join(' / ')}
                </DatalessLabel>
                <DatalessSubLabel>No Data Available</DatalessSubLabel>
              </>
            )}
          </li>
        ))}
      </List>
    </Wrapper>
  );
};

export default TimelineContainer;
