import React from 'react';
import {
  Pagination,
  Select,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({
  justifyContent: 'center',
  display: 'flex',
  margin: '1rem 0',
  fontFamily: "'Open Sans', sans - serif",
  alignItems: 'center',
  '& > li': {
    fontFamily: "'Open Sans', sans - serif",
  }
}));

export interface ISearchPaginationProps {
  className?: string;
  pageSize: number;
  totalPages: number;
  currentPage: number;
  fragmentName: string;
  setPage: (newPage: number) => void;
  getPageNumbers: (currentPageNumber: number, pageSize: number) => void;
}

export default class SearchPagination extends React.Component<
  ISearchPaginationProps
> {
  rootElementRef: React.RefObject<HTMLDivElement>;
  constructor(props) {
    super(props);
    this.rootElementRef = React.createRef();
  }
  componentDidMount() {
    this.addAria();
  }
  componentDidUpdate() {
    this.addAria();
  }
  onShowSizeChange = (currentPageNumber: number, pageSize: number) => {
    this.props.getPageNumbers(currentPageNumber, pageSize);
  }
  getLinkHref = pageNumber => `?${this.props.fragmentName}=${pageNumber}`;
  linkHandleClick = event => event.preventDefault();
  addAria = () => {
    const rootElement = this.rootElementRef.current;
    if (rootElement) {
      const pageNav = rootElement.querySelector('.ant-pagination');
      if (pageNav) {
        pageNav.setAttribute('role', 'navigation');
        pageNav.setAttribute('aria-label', 'Search Pagination');

        const exactPageListItems = Array.from(
          pageNav.querySelectorAll('li.ant-pagination-item')
        );
        for (const pageListItem of exactPageListItems) {
          const link = pageListItem.querySelector('a');
          if (link && link.textContent) {
            const pageNumber = +link.textContent;
            link.setAttribute('aria-label', `Go To Page ${pageNumber}`);
            link.setAttribute('href', this.getLinkHref(pageNumber));
            link.addEventListener('click', this.linkHandleClick);
          }
        }

        const nextPageListItem = pageNav.querySelector('.ant-pagination-next');
        if (nextPageListItem) {
          const link = nextPageListItem.querySelector('a');
          if (link) {
            link.setAttribute('aria-label', 'Go To Next Page');
            link.setAttribute(
              'href',
              this.getLinkHref(this.props.currentPage + 1)
            );
            link.addEventListener('click', this.linkHandleClick);
            const icons = Array.from(link.querySelectorAll('.anticon'));
            for (const icon of icons) {
              icon.setAttribute('aria-hidden', 'true');
            }
          }
        }

        const prevPageListItem = pageNav.querySelector('.ant-pagination-prev');
        if (prevPageListItem) {
          const link = prevPageListItem.querySelector('a');
          if (link) {
            link.setAttribute('aria-label', 'Go To Prev Page');
            link.setAttribute(
              'href',
              this.getLinkHref(this.props.currentPage + 1)
            );
            link.addEventListener('click', this.linkHandleClick);
            const icons = Array.from(link.querySelectorAll('.anticon'));
            for (const icon of icons) {
              icon.setAttribute('aria-hidden', 'true');
            }
          }
        }
      }
    }
  };
  handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    this.setState({ pageSize: newPageSize });
    this.onShowSizeChange(0, newPageSize);
  };
  render() {
    const {
      currentPage,
      totalPages,
      pageSize,
      setPage,
    } = this.props;
    return (
      <StyledDiv
        ref={this.rootElementRef}
      >
        <Pagination
          count={totalPages}
          page={currentPage + 1}
          onChange={(event, pageNumber) => setPage(pageNumber - 1)}
          boundaryCount={1}
          siblingCount={1}
          shape="rounded"
          variant='outlined'
        />
        <Select
          variant='outlined'
          value={pageSize}
          onChange={this.handlePageSizeChange}
          style={{
            height: '35px',
            marginLeft: '8px'
          }}
        >
          {["10", "20", "50", "70", "100"].map((size) => (
            <MenuItem key={size} value={size}>
              {size + ' / page'}
            </MenuItem>
          ))}
        </Select>
      </StyledDiv>
    );
  }
}
