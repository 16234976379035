import {
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import { action, observable, computed, makeObservable } from 'mobx';

import { IMetadataService } from '@extensions/services/IMetadataService';

import MetadataDest from '@extensions/models/MetadataDest';
import { IStepParams } from '@extensions/models/EditorStep';
import ButtonBar from '@extensions/components/metadata/ButtonBar';
import CancelBtn from '@extensions/components/metadata/CancelBtn';
import PreFillJson from '@extensions/components/metadata/PreFillJson';
import DestSearchSelect from '@extensions/components/metadata/DestSearchSelect';

const StyledRootDiv = styled('div')(({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const StyledFormControl = styled(FormControl)(({
  marginBottom: '1rem'
}));

const StyledDestSearchSelect = styled(DestSearchSelect)(({
  marginBottom: '1rem'
}));

export interface IPreFillProps extends IStepParams {
  className?: string;
  metadataService?: IMetadataService;
}

export interface IPreFillState { }

@observer
export class PreFill extends React.Component<
  IPreFillProps,
  IPreFillState
> {
  @observable
  wantsToPrefill: 'yes-clone' | 'yes-json' | 'no' = Boolean(
    this.props.document.importedFrom
  )
    ? 'yes-clone'
    : 'no';
  @observable
  error: Error | null = null;
  updatedMetadata: Record<string, any> | null = null;

  constructor(props: IPreFillProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get prefillOptions(): MetadataDest[] {
    const { metadataService, destination: selectedDest } = this.props;
    if (!selectedDest) {
      return [];
    }
    return (
      metadataService?.destinations.filter(
        (dest) =>
          dest.projectName === selectedDest.projectName &&
          dest.type === selectedDest.type &&
          (dest.isPublished ||
            metadataService?.datasetsWithDocs.has(dest.datasetName))
      ) || []
    );
  }

  @action
  handlePrefillToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelection = (event.target as HTMLInputElement).value;
    this.wantsToPrefill = newSelection as 'yes-clone' | 'yes-json' | 'no';
    this.clearError();
  };

  @action
  clearError = () => (this.error = null);

  handleMetadataChange = (metadata: Record<string, any>) => {
    this.updatedMetadata = metadata;
  };

  @action
  handleErrorChange = (error: string | null) => {
    this.error = error === null ? null : new Error(error);
  };

  @action
  saveAndContinue = () => {
    const { document, next } = this.props;
    if (this.wantsToPrefill === 'yes-clone' && document.importedFrom === null) {
      this.error = new Error('Please select a dataset to clone.');
      return;
    }
    if (this.wantsToPrefill === 'yes-json') {
      if (this.updatedMetadata === null) {
        this.error = new Error('No JSON text found.');
        return;
      }
      document.setMetadata(this.updatedMetadata);
    }
    if (this.wantsToPrefill === 'no') {
      document.setImportedFrom(null);
    }
    next(true);
  };

  render() {
    const { previous, document, cancel, destination } =
      this.props;
    const questionId = 'pre-fill-question';
    return (
      <StyledRootDiv>
        <div>
          <Typography variant="h2" sx={{ marginBottom: '1rem', fontSize: '1.9375rem !important' }}>
            Import Metadata
          </Typography>
          <Typography
            variant="h3"
            id={questionId}
            sx={{ marginBottom: '0.5rem', fontSize: '1.25rem !important' }}
          >
            Do you want to pre-fill from another dataset or JSON text?
          </Typography>

          <StyledFormControl
            component="fieldset"
            aria-labelledby={questionId}
          >
            <RadioGroup
              name="pre-fill"
              value={this.wantsToPrefill}
              onChange={this.handlePrefillToggle}
            >
              <FormControlLabel
                value="yes-clone"
                control={<Radio />}
                label="Yes, from another dataset"
              />
              <FormControlLabel
                value="yes-json"
                control={<Radio />}
                label="Yes, from JSON-formatted text"
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No, skip"
              />
            </RadioGroup>
          </StyledFormControl>

          {this.wantsToPrefill === 'yes-clone' && (
            <>
              <Typography
                variant="h3"
                sx={{ marginBottom: '0.5rem', fontSize: '1.25rem !important' }}
              >
                Select a dataset to import.
              </Typography>
              <StyledDestSearchSelect
                initialDataset={document.importedFrom || ''}
                onChange={document.setImportedFrom}
                options={this.prefillOptions || []}
              />
            </>
          )}
          {this.wantsToPrefill === 'yes-json' &&
            destination !== undefined &&
            destination.schema !== null && (
              <PreFillJson
                document={document}
                schema={destination.schema}
                handleErrorChange={this.handleErrorChange}
                handleMetadataChange={this.handleMetadataChange}
              />
            )}
        </div>
        <div>
          {this.error && (
            <Alert
              severity="error"
              sx={{ margin: '0 0 1rem 0' }}
            >
              {this.error.message}
            </Alert>
          )}
          <ButtonBar>
            <Button onClick={previous}>Back</Button>
            <div>
              <CancelBtn onClick={cancel} />
              <Button
                variant="contained"
                color="primary"
                onClick={this.saveAndContinue}
                disabled={this.error !== null}
              >
                Next
              </Button>
            </div>
          </ButtonBar>
        </div>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
}))(PreFill);
