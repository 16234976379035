import * as React from 'react';
import * as superagent from 'superagent';
import { inject, observer } from 'mobx-react';

import {
  faChartBar,
  faDatabase,
  faFile,
  faHandshake,
  faNewspaper,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import dapTheme from '@extensions/services/Theme';
import MetricsModel from '@extensions/models/Metrics';
import MetricsUtils from '@dapclient/utils/MetricsUtils';
import MetricCard from '@dapclient/components/metrics/MetricCard';
import { IMetricsService } from '@extensions/services/IMetricsService';

import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import tinycolor from '@ctrl/tinycolor';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';
import { Card, CardContent, Grid, Typography } from '@mui/material';

const StyledRootDiv = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(4)
}));

const StyledDownloadStatContainerSpan = styled('span')(({
  padding: 20
}));

const StyledSmallChartSpan = styled('span')(({
  width: '100%'
}));

export interface IMetricsState { }
export interface IMetricsProps {
  metricsService?: IMetricsService;
}

@observer
export class Metrics extends React.Component<
  IMetricsProps,
  IMetricsState
> {
  styleObject = {
    statNum: {},
    statLabel: {},
    cardContent: {},
    accordionSummary: {},
    detailsText: {},
    expandIcon: {},
    downloadStatContainer: {
      padding: 20,
    },
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.metricsService &&
      this.props.metricsService.loadMetricsIfNeeded();
    this.props.metricsService &&
      this.props.metricsService.loadGATokenIfNeeded();
  }

  getColor = (index: number): string => {
    const COLORS = ['#DC3912', '#3366CC', '#FF9900', '#109618', '#990099'];
    return COLORS[index % COLORS.length];
  };

  getXAxisInterval = (xAxisDataLength) => {
    // default for a2e is to show x axis ticks using 11 intervals (yearly)
    let xAxisInterval = 11;
    if (xAxisDataLength < 12) {
      // otherwise if there is less than 1 year of stats show every tick
      xAxisInterval = 0;
    } else if (xAxisDataLength < 12 * 3) {
      // otherwise if there is less than 3 years of stats show the tick every other month
      xAxisInterval = 2;
    } else if (xAxisDataLength < 12 * 4) {
      // otherwise if there is less than 5 years of stats show the tick every 6 months
      xAxisInterval = 5;
    }
    return xAxisInterval;
  };

  formatLaravelCountsTooltipLabel = (label) =>
    `${label.substring(5)}/${label.substring(0, 4)}`;

  // skip first and last x axis tick as they doesn't render well with recharts when y axis mirrored
  formatLaravelxAxisTicks = (
    tickValue,
    firstDataPointValue,
    lastDataPointValue
  ) => {
    if (tickValue === firstDataPointValue || tickValue === lastDataPointValue) {
      return '';
    }
    return this.formatLaravelCountsTooltipLabel(tickValue);
  };

  getXAxisIntervalForTicks = (dataCount, tickCount) => {
    const mod = dataCount % tickCount;
    return (dataCount - mod) / tickCount;
  };

  getXAxis = (xAxisInterval) => {
    return (
      <XAxis
        dataKey="month"
        tickFormatter={(value) =>
          MetricsUtils.formatXAxisTick(value, xAxisInterval)
        }
        interval={xAxisInterval}
        style={{ fontSize: '14px' }}
      />
    );
  };

  renderDownloadFileSizes = (metrics: MetricsModel) => {
    if (metrics.downloadStats.length === 0) {
      return null;
    }
    const barColor = MetricsUtils.getBarColor(dapTheme);
    const xAxisInterval = this.getXAxisInterval(metrics.downloadStats.length);
    const xAxis = this.getXAxis(xAxisInterval);

    return (
      <StyledDownloadStatContainerSpan>
        <Typography variant="h6" gutterBottom sx={{ color: 'black !important' }}>
          Downloaded Files (total of file sizes)
        </Typography>
        <ResponsiveContainer height={250} width="100%">
          <BarChart
            barSize={6}
            data={metrics.downloadStats}
            margin={{ left: 15, right: 40 }}
          >
            {xAxis}
            <YAxis
              tickFormatter={MetricsUtils.formatYAxisFileSizesTick}
              scale="log"
              domain={['auto', 'auto']}
              style={{ fontSize: '14px' }}
            />
            <Tooltip
              labelFormatter={MetricsUtils.formatDownloadsTooltipLabel}
              formatter={MetricsUtils.formatFileSizesTooltip}
            />
            <Bar dataKey="size" fill={barColor} />
          </BarChart>
        </ResponsiveContainer>
      </StyledDownloadStatContainerSpan>
    );
  };

  renderDownloadFileCounts = (metrics: MetricsModel) => {
    if (metrics.downloadStats.length === 0) {
      return null;
    }

    const barColor = MetricsUtils.getBarColor(dapTheme);
    const xAxisInterval = this.getXAxisInterval(metrics.downloadStats.length);
    const xAxis = this.getXAxis(xAxisInterval);
    return (
      <StyledDownloadStatContainerSpan>
        <Typography variant="h6" gutterBottom sx={{ color: 'black !important' }}>
          Downloaded Files (total file count)
        </Typography>
        <ResponsiveContainer height={250} width="100%">
          <BarChart
            barSize={6}
            data={metrics.downloadStats}
            margin={{ left: 15, right: 40 }}
          >
            {xAxis}
            <YAxis
              tickFormatter={MetricsUtils.formatYAxisTick}
              scale="log"
              domain={['auto', 'auto']}
              style={{ fontSize: '14px' }}
            />
            <Tooltip
              labelFormatter={MetricsUtils.formatDownloadsTooltipLabel}
              formatter={MetricsUtils.formatFileCountsTooltip}
            />
            <Bar dataKey="file_count" fill={barColor} />
          </BarChart>
        </ResponsiveContainer>
      </StyledDownloadStatContainerSpan>
    );
  };

  renderDownloadUserCount = (metrics: MetricsModel) => {
    if (metrics.downloadStats.length === 0) {
      return null;
    }
    const barColor = MetricsUtils.getBarColor(dapTheme);

    const xAxisInterval = this.getXAxisInterval(metrics.downloadStats.length);
    const xAxis = this.getXAxis(xAxisInterval);

    return (
      <StyledDownloadStatContainerSpan>
        <Typography variant="h6" gutterBottom sx={{ color: 'black !important' }}>
          User Downloads (by unique user count)
        </Typography>
        <ResponsiveContainer height={250} width="100%">
          <LineChart data={metrics.downloadStats} margin={{ right: 40 }}>
            {xAxis}
            <YAxis tickFormatter={MetricsUtils.formatYAxisTick} />
            <Line
              dataKey="unique_users"
              stroke={barColor}
              dot={false}
              strokeWidth={2}
            />
            <Tooltip
              labelFormatter={MetricsUtils.formatDownloadsTooltipLabel}
              formatter={(value, name, props) => [value, 'Users']}
            />
          </LineChart>
        </ResponsiveContainer>
      </StyledDownloadStatContainerSpan>
    );
  };

  renderDownloadDatasetCount = (metrics: MetricsModel) => {
    if (metrics.downloadStats.length === 0) {
      return null;
    }
    const barColor = MetricsUtils.getBarColor(dapTheme);

    const xAxisInterval = this.getXAxisInterval(metrics.downloadStats.length);
    const xAxis = this.getXAxis(xAxisInterval);

    return (
      <StyledDownloadStatContainerSpan>
        <Typography variant="h6" gutterBottom sx={{ color: 'black !important' }}>
          Dataset Downloads (by unique dataset count)
        </Typography>
        <ResponsiveContainer height={250} width="100%">
          <LineChart data={metrics.downloadStats} margin={{ right: 40 }}>
            {xAxis}
            <YAxis tickFormatter={(value) => (value === 0 ? '' : value)} />
            <Line
              dataKey="unique_datasets"
              stroke={barColor}
              dot={false}
              strokeWidth={2}
            />
            <Tooltip
              labelFormatter={MetricsUtils.formatDownloadsTooltipLabel}
              formatter={(value, name, props) => [value, 'Datasets']}
            />
          </LineChart>
        </ResponsiveContainer>
      </StyledDownloadStatContainerSpan>
    );
  };

  renderWebsiteUsage = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <div id="sessionsActiveUsersChartContainer" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <div id="pageViewsChartContainer" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  loadGaCharts = async (gapiAccessToken, gapiViewId) => {
    const main = tinycolor(theme.palette.secondary.main).toHex();
    const dark = tinycolor(theme.palette.secondary.light).toHex();

    const gaMetricResult = await superagent
      .post(`https://analyticsdata.googleapis.com/v1beta/${gapiViewId}:runReport`)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${gapiAccessToken}`)
      .send({
        dateRanges: [{ startDate: '30daysAgo', endDate: 'yesterday' }],
        metrics: [
          { name: 'sessions' },
          { name: 'activeUsers' },
          { name: 'screenPageViews' },
        ],
        dimensions: [{ name: 'date' }],
      });

    const sessionsActiveUsersData = [['Date', 'Sessions', 'Active Users']];
    const pageViewsData = [['Date', 'Screen Page Views']];

    const formatDate = (dateStr) => {
      const year = dateStr.substring(0, 4);
      const month = dateStr.substring(4, 6);
      const day = dateStr.substring(6, 8);
      return new Date(`${year}-${month}-${day}`);
    };

    const sortedRows = gaMetricResult.body.rows.sort((a, b) => {
      return formatDate(a.dimensionValues[0].value).getTime() - formatDate(b.dimensionValues[0].value).getTime();
    });

    sortedRows.forEach((row) => {
      const dateStr = row.dimensionValues[0].value;
      const date = formatDate(dateStr).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const sessions = parseInt(row.metricValues[0].value);
      const activeUsers = parseInt(row.metricValues[1].value);
      const screenPageViews = parseInt(row.metricValues[2].value);

      sessionsActiveUsersData.push([date, sessions, activeUsers]);
      pageViewsData.push([date, screenPageViews]);
    });

    google.charts.load('current', { packages: ['corechart', 'line'] });

    google.charts.setOnLoadCallback(() => {
      const data1 = google.visualization.arrayToDataTable(sessionsActiveUsersData);
      const options1 = {
        title: 'Sessions / Users Over Last 30 Days',
        colors: [main, dark],
        width: '100%',
        height: '400',
        legend: {'position': 'bottom'},
        chartArea: {'width': '80%', 'height': '69%'},
        hAxis: { title: 'Date' },
        vAxis: { title: 'Count' },
      };
      const chart1 = new google.visualization.LineChart(document.getElementById('sessionsActiveUsersChartContainer'));
      chart1.draw(data1, options1);

      const data2 = google.visualization.arrayToDataTable(pageViewsData);
      const options2 = {
        title: 'Pageviews Over Last 30 Days',
        colors: [main, dark],
        width: '100%',
        height: '400',
        legend: {'position': 'bottom'},
        chartArea: {'width': '79%', 'height': '69%'},
        hAxis: { title: 'Date' },
        vAxis: { title: 'Page Views' },
      };
      const chart2 = new google.visualization.LineChart(document.getElementById('pageViewsChartContainer'));
      chart2.draw(data2, options2);
    });
  };

  renderProjectCount = (metrics: MetricsModel, styleObject?: {}) => {
    if (metrics.projectCountsByMonth.length === 0) {
      return null;
    }

    const barColor = MetricsUtils.getBarColor(dapTheme);
    const xInterval = this.getXAxisIntervalForTicks(
      metrics.projectCountsByMonth.length,
      5
    );

    return (
      <MetricCard
        icon={faHandshake}
        count={metrics.projectCount}
        countSubtitle={'Projects'}
        styleObject={styleObject && Object.keys(styleObject).length !== 0 ? styleObject : this.styleObject}
      >
        <StyledSmallChartSpan>
          <Typography gutterBottom>Cumulative project count</Typography>
          <ResponsiveContainer height={250} width="100%">
            <LineChart data={metrics.projectCountsByMonth}>
              <XAxis
                mirror={true}
                interval={xInterval}
                dataKey="0"
                tickFormatter={(tickValue) =>
                  this.formatLaravelxAxisTicks(
                    tickValue,
                    metrics.projectCountsByMonth[0][0],
                    metrics.projectCountsByMonth[
                    metrics.projectCountsByMonth.length - 1
                    ][0]
                  )
                }
              />
              <YAxis
                mirror={true}
                tickFormatter={(value) => (value === 0 ? '' : value)}
              />
              <Line dataKey="1" stroke={barColor} dot={false} strokeWidth={2} />
              <Tooltip
                labelFormatter={this.formatLaravelCountsTooltipLabel}
                formatter={(value, name, props) => [value, 'Projects']}
              />
            </LineChart>
          </ResponsiveContainer>
        </StyledSmallChartSpan>
      </MetricCard>
    );
  };

  renderPublicationCount = (metrics: MetricsModel, styleObject?: {}) => {
    const { publicationStats } = metrics;
    if (publicationStats) {
      const pieChartData = Object.entries(publicationStats.pubsCountsByType);
      return (
        <MetricCard
          icon={faNewspaper}
          count={publicationStats.totalNumPubs}
          countSubtitle={'Publications'}
          styleObject={styleObject && Object.keys(styleObject).length !== 0 ? styleObject : this.styleObject}
        >
          <StyledSmallChartSpan>
          <Typography gutterBottom>Publications by Type</Typography>
            <ResponsiveContainer height={400} width="100%">
              <PieChart width={400} height={400}>
                <Pie
                  data={pieChartData}
                  fill="#8884d8"
                  dataKey="1"
                  nameKey="0"
                  label={false}
                  labelLine={false}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={this.getColor(index)} />
                  ))}
                </Pie>
                <Legend />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </StyledSmallChartSpan>
        </MetricCard>
      );
    }
    return null;
  }

  renderDatasetCount = (metrics: MetricsModel, styleObject?: {}) => {
    if (metrics.datasetCountsByMonth.length === 0) {
      return null;
    }

    const barColor = MetricsUtils.getBarColor(dapTheme);
    const xInterval = this.getXAxisIntervalForTicks(
      metrics.datasetCountsByMonth.length,
      5
    );

    return (
      <MetricCard
        icon={faChartBar}
        count={metrics.datasetCount}
        countSubtitle={'Datasets'}
        styleObject={styleObject && Object.keys(styleObject).length !== 0 ? styleObject : this.styleObject}
      >
        <StyledSmallChartSpan>
          <Typography gutterBottom>Cumulative dataset count</Typography>
          <ResponsiveContainer height={250} width="100%">
          <LineChart
              data={metrics.datasetCountsByMonth}
              margin={{ bottom: 45, right: 15 }}
            >
              <XAxis
                mirror={true}
                interval={xInterval}
                dataKey="0"
                tickFormatter={(tickValue) =>
                  this.formatLaravelxAxisTicks(
                    tickValue,
                    metrics.datasetCountsByMonth[0][0],
                    metrics.datasetCountsByMonth[
                    metrics.datasetCountsByMonth.length - 1
                    ][0]
                  )
                }
                angle={30}
                dy={35}
              />
              <YAxis
                mirror={true}
                tickFormatter={(value) => (value === 0 ? '' : value)}
              />
              <Line dataKey="1" stroke={barColor} dot={false} strokeWidth={2} />
              <Tooltip
                labelFormatter={this.formatLaravelCountsTooltipLabel}
                formatter={(value, name, props) => [value, 'Datasets']}
              />
            </LineChart>
          </ResponsiveContainer>
        </StyledSmallChartSpan>
      </MetricCard>
    );
  };

  renderUsersRegistered = (metrics: MetricsModel, styleObject?: {}) => {
    if (metrics.userCountsByMonth.length === 0) {
      return null;
    }
    const barColor = MetricsUtils.getBarColor(dapTheme);
    const xInterval = this.getXAxisIntervalForTicks(
      metrics.userCountsByMonth.length,
      5
    );
    return (
      <MetricCard
        icon={faUser}
        count={metrics.userCount}
        countSubtitle={'Users'}
        styleObject={styleObject && Object.keys(styleObject).length !== 0 ? styleObject : this.styleObject}
      >
        <StyledSmallChartSpan>
          <Typography gutterBottom>Cumulative user registrations</Typography>
          <ResponsiveContainer height={250} width="100%">
            <LineChart data={metrics.userCountsByMonth}>
              <XAxis
                mirror={true}
                interval={xInterval}
                dataKey="0"
                tickFormatter={(tickValue) =>
                  this.formatLaravelxAxisTicks(
                    tickValue,
                    metrics.userCountsByMonth[0][0],
                    metrics.userCountsByMonth[
                    metrics.userCountsByMonth.length - 1
                    ][0]
                  )
                }
              />
              <YAxis
                mirror={true}
                tickFormatter={(value) => (value === 0 ? '' : value)}
              />
              <Line dataKey="1" stroke={barColor} dot={false} strokeWidth={2} />
              <Tooltip
                labelFormatter={this.formatLaravelCountsTooltipLabel}
                formatter={(value, name, props) => [value, 'Users']}
              />
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={250} width="100%">
            <PieChart width={250} height={250}>
              <Pie
                data={metrics.usersByDomain}
                fill="#8884d8"
                dataKey="1"
                nameKey="0"
                label={false}
                labelLine={false}
              >
                {metrics.usersByDomain.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={this.getColor(index)} />
                ))}
              </Pie>
              <Legend />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </StyledSmallChartSpan>
      </MetricCard>
    );
  };

  renderBytesStored = (metrics: MetricsModel) => {
    return (
      <MetricCard
        icon={faDatabase}
        count={metrics.fileSizeStat.value}
        countSubtitle={`${metrics.fileSizeStat.symbol} Stored`}
        styleObject={this.styleObject}
      />
    );
  };

  renderFilesStored = (metrics: MetricsModel, styleObject?: {}) => {
    return (
      <MetricCard
        icon={faFile}
        count={metrics.fileCount}
        countSubtitle={'Files Stored'}
        styleObject={styleObject && Object.keys(styleObject).length !== 0 ? styleObject : this.styleObject}
      />
    );
  };

  getIntroSection = (): React.ReactNode => (
    <Typography variant="subtitle1" gutterBottom>
      The Livewire Data Platform collects transportation and mobility-related
      data to support research in the Energy Efficient Mobility Systems Program.
      These metrics show what’s powering Livewire, including the amount of
      datasets, files, storage capacity, and users within the platform.
    </Typography>
  );

  renderContent = (metrics, cardClasses, gapiAvailable) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {this.getIntroSection()}
        </Grid>
        <Grid item xs={6}>
          {this.renderUsersRegistered(metrics)}
        </Grid>
        <Grid item xs={6}>
          {this.renderProjectCount(metrics)}
        </Grid>
        <Grid item xs={3}>
          {this.renderFilesStored(metrics)}
        </Grid>
        <Grid item xs={3}>
          {this.renderBytesStored(metrics)}
        </Grid>
        <Grid item xs={3}>
          {this.renderDatasetCount(metrics)}
        </Grid>
        <Grid item xs={3}>
          {this.renderPublicationCount(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadFileSizes(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadFileCounts(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadUserCount(metrics)}
        </Grid>
        <Grid item xs={12}>
          {this.renderDownloadDatasetCount(metrics)}
        </Grid>
        {this.renderWebsiteUsage()}
      </Grid>
    );
  };

  public render() {
    const { metricsService } = this.props;

    let content = <div />;
    if (metricsService) {
      const { metrics, gapiAvailable, gapiAccessToken, gapiViewId } = metricsService;
      /** For other projects that have Google Analytics */
      if (metrics !== null && gapiAvailable !== null) {
        if (gapiAvailable) {
          this.loadGaCharts(gapiAccessToken, gapiViewId);
        }
        content = this.renderContent(metrics, gapiAvailable);
      } else if (metrics !== null) {
        /** Not using loadGaCharts() for SPP, SPP doesn't have Google Analytics set up  */
        content = this.renderContent(metrics, gapiAvailable);
      }
    }
    return <StyledRootDiv>{content}</StyledRootDiv>;
  }
}
export default inject((store: any) => ({
  metricsService: store.metricsService,
}))(Metrics);
