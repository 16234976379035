import { useState } from 'react';
import styled from '@emotion/styled';
import moment, { Moment } from 'moment';

import Dataset from '@extensions/models/Dataset';
import TimelineSet from '@extensions/components/dataset/TimelineSet';
import TimelineController, {
  ViewValue,
  ViewStat,
} from '@extensions/components/dataset/TimelineController';

export interface ITimelineContainerProps {
  dataset: Dataset;
}

const Wrapper = styled.div``;

const TimelineContainerSingle = ({ dataset }: ITimelineContainerProps) => {
  const [zoom, setZoom] = useState(undefined as [Moment, Moment] | undefined);
  const [view, setView] = useState([null, null] as [
    ViewValue | null,
    ViewStat | null
  ]);

  let min = dataset.epochBegins;
  let max = dataset.epochEnds;

  let beg = min ? moment.unix(min) : moment();
  let end = max ? moment.unix(max).add(1, 'days') : moment();
  if (zoom && zoom[0].isBefore(beg)) {
    beg = zoom[0];
  }
  if (zoom && zoom[1].isAfter(end)) {
    end = zoom[1];
  }

  return (
    <Wrapper>
      <TimelineController
        beg={beg}
        end={end}
        zoom={zoom}
        viewStat={view[1] || undefined}
        viewValue={view[0] || undefined}
        handleZoom={([beg, end]) => setZoom([beg, end])}
        handleViewChange={(value, stat) =>
          setView([value || null, stat || null])
        }
      />
      <TimelineSet
        dataset={dataset}
        beg={beg}
        end={end}
        zoom={zoom}
        view={view}
        handleZoom={(zoom) => setZoom(zoom)}
        isSingleContext={true}
      />
    </Wrapper>
  );
};

export default TimelineContainerSingle;
