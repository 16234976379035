import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Typography,
} from '@mui/material';
import moment from 'moment';
import get from 'lodash/get';
import filesize from 'filesize';
import { styled } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { ICartService } from '@dapclient/services/ICartService';

import theme from '@extensions/services/Theme';
import Dataset from '@extensions/models/Dataset';
import AccessLevel from '@extensions/models/AccessLevel';
import DistributionType from '@extensions/models/DistributionType';
import AddToCartIconButton from '@extensions/components/dataset/AddToCartIconButton';
import DatasetResultIcons from '@dapclient/components/dataset-search/DatasetResultIcons';

const MAX_DESCRIPTION_LENGTH = 160;

export interface IDatasetResultProps {
  datasetDocument: any;
  fileTypes?: string[];
  cartService?: ICartService;
}

const StyledRootDiv = styled('div')(({
  marginBottom: '2rem',
}));

const StyledDatasetLink = styled('a')(({
  display: 'flex',
  flexDirection: 'column-reverse',
  textDecoration: 'none'
}));

const StyledTitleTypography = styled(Typography)(({
  // fontSize: '1.25rem !important',
  fontSize: '1.25rem',
  lineHeight: '20px !important',
  fontWeight: '500 !important',
  margin: 0,
}));

const StyledNameCode = styled('code')(() => ({
  fontSize: '0.875rem',
  lineHeight: 1,
  fontWeight: theme.typography.fontWeightMedium,
  color: 'rgb(60, 64, 67)',
  fontStyle: 'normal',
  marginBottom: theme.spacing(1),
}));

const StyledSummaryP = styled('p')(() => ({
  color: theme.palette.text.secondary,
  margin: 0,
  marginTop: '0.25rem',
}));

const StyledCommentP = styled('p')(({
  fontSize: '80%',
  fontStyle: 'italic',
  backgroundColor: '#efefef',
  borderRadius: '3px',
  padding: '0.25rem 0.5rem',
}));

const StyledExitToAppIcon = styled(ExitToAppIcon)(({
  marginBottom: '-5px'
}));

@inject('cartService')
@observer
class DatasetResult extends React.Component<IDatasetResultProps> {

  hasAffectedDataQuality = (datasetDocument: any): boolean => {
    if (datasetDocument.events
      && datasetDocument.events.length > 0
      && datasetDocument.events.some((event) => event.affectsDataQuality)) {
      return true;
    }
    return false;
  }

  render() {
    const { datasetDocument, fileTypes, cartService } = this.props;
    if (!cartService) {
      return null;
    }

    let datasetTitle = datasetDocument.title;
    if (!datasetTitle) {
      const datasetNameNoProject = datasetDocument.identifier.split('/')[1];
      const datasetNameParts = datasetNameNoProject.split('.');
      const titleParts = datasetNameParts
        .slice(0, 3)
        .map((part) => part.toLocaleUpperCase());
      datasetTitle = titleParts.join(' • ');
    }

    const hasExternalLink = datasetDocument.distributionType[0] === DistributionType.ExternalLink;

    const completeTitle = `${datasetDocument.project_name.toLocaleUpperCase()} - ${datasetTitle}`;
    const summaryIcons = (
      <DatasetResultIcons
        datasetName={datasetDocument.name}
        isGeneralUseReady={datasetDocument.generalUseReady}
        isPublic={datasetDocument.accessLevel === AccessLevel.Public}
        isMfaRestricted={datasetDocument.dapAccessRequiresMfa}
        isDataQualityAffected={this.hasAffectedDataQuality(datasetDocument)}
      />
    );
    const summaryPieces: string[] = [];
    const { count, size, types } = datasetDocument.dapFileSummary;
    if (count) {
      let fileString = `${count.toLocaleString()} files`;
      if (types) {
        fileString += ` (${types.join(', ')})`;
      }
      summaryPieces.push(fileString);
    }

    if (size) {
      summaryPieces.push(`${filesize(size, { round: 0 })}`);
    }

    if (datasetDocument.dapFileSummary.updated) {
      const statsLastUpdated = moment(datasetDocument.dapFileSummary.updated);
      const durationSinceUpdate = moment.duration(
        moment().diff(statsLastUpdated)
      );
      summaryPieces.push(`Updated ${durationSinceUpdate.humanize()} ago`);
    }

    let description = get(
      datasetDocument,
      'description',
      'No description provided'
    );
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      description = `${description.slice(0, MAX_DESCRIPTION_LENGTH)}...`;
    }

    // Because the i0 datasets are in the index for companion
    // datasets, it can be misleading to show results filtered
    // for a file type that may not really part of the dataset
    // being shown
    const filteredOut =
      fileTypes !== undefined &&
      fileTypes.length > 0 &&
      datasetDocument.dapFileSummary.extensions !== undefined &&
      datasetDocument.imagesDataset &&
      datasetDocument.dapFileSummary.extensions.filter(
        (ext) => fileTypes.indexOf(ext.split('.').pop() || '') >= 0
      ).length === 0;
    return (
      <StyledRootDiv>
        <div style={filteredOut ? { opacity: 0.5 } : {}}>
          {/*
           * column-reverse allows the cite to come before the h2 visually, while
           * still allowing screen readers to correctly determine that the cite
           * belongs to the section of the page labeled by the h2
           */}
          <StyledDatasetLink href={`/data/${datasetDocument.name}`}>
            <StyledTitleTypography variant='h2'>
              {completeTitle}
            </StyledTitleTypography>
            <StyledNameCode>{datasetDocument.name}</StyledNameCode>
          </StyledDatasetLink>
          <StyledSummaryP>
            {summaryIcons} {summaryPieces.join(' • ')}
            {hasExternalLink
              ? <><StyledExitToAppIcon /> External Link</>
              : <AddToCartIconButton
                dataset={new Dataset(datasetDocument)}
                fileCount={count}
              />
            }
          </StyledSummaryP>
          <p style={{ marginBottom: 0, marginBlockStart: '0' }}>{description}</p>
        </div>
        {
          filteredOut && (
            <StyledCommentP>
              <em>
                <strong>NOTE: </strong> This dataset is only shown because an
                otherwise hidden "images" dataset (
                {datasetDocument.imagesDataset}) is related to this one and
                matches your current file type filter. It does NOT directly
                contain files of the selected file type(s).
              </em>
            </StyledCommentP>
          )
        }
      </StyledRootDiv >
    );
  }
}

export default DatasetResult;
