import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { faStream } from '@fortawesome/free-solid-svg-icons';
import { Switch, FormControlLabel } from '@mui/material';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';

interface TimelineToggleProps {
  defaultChecked: boolean;
  onChange: (on: boolean) => void;
}

const StyledDiv = styled('div')(({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  paddingLeft: '0.5rem',
  borderRadius: '1rem',
}));

const TimelineToggle = ({ defaultChecked, onChange }: TimelineToggleProps) => {
  const [isChecked, setIsCheckeed] = useState(defaultChecked);

  return (
    <StyledDiv>
      <FormControlLabel
        sx={{
          height: '2rem',
        }}
        control={
          <Switch
            onChange={(e) => {
              const checked = e.target.checked;
              setIsCheckeed(checked);
              onChange(checked);
            }}
            checked={isChecked}
            sx={{
              '& .MuiSwitch-thumb': {
                color: (theme) => isChecked ? theme.palette.secondary.main : theme.palette.common.white
              },
              '& .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#386e87',
                borderRadius: 20 / 2,
              },
              '& .Mui-checked+.MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.secondary.light,
                borderRadius: 20 / 2,
              }
            }}
          />
        }
        label={
          <div>
            <AddIcon icon={faStream} size="xs" />
            &nbsp;Visualizations
          </div>
        }
        labelPlacement="end"
      />
    </StyledDiv>
  );
};

export default TimelineToggle;
