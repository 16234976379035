import React from 'react';
import { Field } from 'formik';
import { inject } from 'mobx-react';
// import { TextField } from 'formik-material-ui';
import { TextField } from 'formik-mui';

import {
    UserProfileForm as DapUserProfileForm,
} from '@dapclient/components/user/UserProfileForm';

export class UserProfileForm extends DapUserProfileForm {
    getInitialFormData(): object {
        const user = this.getUser();
        return {
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            orcId: user.orcId || '',
            justification: user.justification || '',
            sshkey: user.sshkey || '',
            email: user.email || '',
            password: '',
            confirm: '',
            agreement: user.agreed,
        };
    }
    renderSshKeyField = () => {
        return (
            <Field
                component={TextField}
                name="sshkey"
                type="text"
                label="SSH Public Key"
                id="sshkey-input"
                multiline
                rows={3}
                placeholder={"SSH Public Key"}
                {...this.fieldProps}
            />
        );
    };
}

export default inject((store: any) => ({
    securityService: store.securityService,
    contactUsService: store.contactUsService,
}))(UserProfileForm);
