import React from 'react';
import filesize from 'filesize';
import { inject, observer } from 'mobx-react';

import {
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
} from '@mui/material';

import Reference from '@extensions/models/Reference';
import DownloadIconButton from '@extensions/components/core/DownloadIconButton';

export interface IRefReviewTableProps {
  className?: string;
  references: Reference[];
  labeledBy: string;
}

export interface IRefReviewTableState { }

@observer
export class RefReviewTable extends React.Component<
  IRefReviewTableProps,
  IRefReviewTableState
> {
  render() {
    const { references } = this.props;
    return (
      <TableContainer
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Size</TableCell>
              <TableCell aria-label="actions"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {references.map(ref => (
              <TableRow key={ref.referenceURL}>
                <TableCell component="th" scope="ref">
                  {ref.referenceTitle}
                </TableCell>
                <TableCell>{ref?.custom?.fileSize != null ? filesize(ref?.custom?.fileSize) : 'N/A'}</TableCell>
                <TableCell align="right">
                  <DownloadIconButton url={ref.referenceURL} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(RefReviewTable);
