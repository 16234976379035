import clsx from 'clsx';
import React from 'react';
import { Moment } from 'moment';
import { observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { Typography, Paper, Divider } from '@mui/material';

import theme from '@extensions/services/Theme';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledHeading = styled(Typography)(({
  marginBottom: theme.spacing(2)
}));

const StyledDiv = styled('div')(({
  maxHeight: '28rem',
  overflow: 'auto',
}));

const StyledText = styled(Typography)(({
  width: '100%',
  fontSize: '16px !important'
}));

const StyledDivider = styled(Divider)(({
  backgroundColor: theme.palette.grey['500'],
  margin: `${theme.spacing(1)}px 0`,
}));

export interface ICitationProps {
  className?: string;
  order: FileOrderGroup;
}

@observer
class Citation extends React.Component<ICitationProps> {
  componentDidMount() {
    this.props.order.loadDatasets();
  }
  render() {
    const { className, order } = this.props;
    const dateRange =
      order.dateRange.startDate === undefined ||
        order.dateRange.endDate === undefined
        ? undefined
        : ([order.dateRange.startDate, order.dateRange.endDate] as [
          Moment,
          Moment
        ]);
    let body: React.ReactNode;
    if (order.datasets) {
      body = (
        <StyledDiv>
          {order.datasets.map((dataset, index) => (
            <div key={dataset.name}>
              {index > 0 && <StyledDivider />}
              <StyledText variant="body1">
                {dataset.getCitationWithAccessDate(order.created, dateRange)}
              </StyledText>
            </div>
          ))}
        </StyledDiv>
      );
    } else {
      body = <CenteredCircularProgress />;
    }
    return (
      <Paper className={clsx(className)}>
        <StyledHeading variant="h2" sx={{ fontSize: '1.9375rem' }}>
          Citations
        </StyledHeading>
        {body}
      </Paper>
    );
  }
}

export default (Citation);
