import clsx from 'clsx';
import React from 'react';
import { observer } from 'mobx-react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import {
  DownloadOption,
  canDownloadUsingOption,
} from '@extensions/models/FileOrder';
import AddIcon from '@extensions/utils/AddIcon';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledLi = styled('li')(({ theme }) => ({
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
  marginRight: theme.spacing(2),
}));

export interface IFileLinksDialogProps {
  className?: string;
  open: boolean;
  onClose: () => void;
  order: FileOrderGroup;
}

@observer
class FileLinksDialog extends React.Component<IFileLinksDialogProps> {
  componentDidMount() {
    const { order } = this.props;
    if (canDownloadUsingOption(DownloadOption.LINKS, order)) {
      order.loadDownloadUrls();
    }
  }

  render() {
    const { className, open, onClose, order } = this.props;
    let downloadList: React.ReactNode;
    if (order.downloadUrls) {
      downloadList = (
        <List aria-label="file download links">
          {order.downloadUrls.map(({ url, name }) => (
            <StyledLi key={url} >
              <StyledListItem
                button
                component="a"
                href={url}
                download={name}
              >
                <StyledIcon >
                  <AddIcon icon={faFileDownload} size="lg" />
                </StyledIcon>
                <ListItemText primary={name} />
              </StyledListItem>
            </StyledLi>
          ))}
        </List>
      );
    } else {
      downloadList = <CenteredCircularProgress />;
    }
    return (
      <Dialog
        className={clsx(className)}
        open={open}
        onClose={onClose}
        aria-labelledby="file-links-dialog"
        scroll="paper"
      >
        <DialogTitle id="file-links-dialog">File Links</DialogTitle>
        <DialogContent dividers>{downloadList}</DialogContent>
      </Dialog>
    );
  }
}

export default (FileLinksDialog);
