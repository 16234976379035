// import { useCallback } from 'react'
import { useState, useCallback, useEffect } from 'react'
import { Modal, ModalProps, IconButton } from '@mui/material';
import Slider from '@mui/material/Slider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import styled from '@emotion/styled'

import { Downloadable } from '@dapclient/models/Dataset'
import useWindowDimensions from '@extensions/hooks/useWindowDimensions'

const StyledModal = styled(Modal)`
  display: flex;
  alignItems: center
  justifyContent: center
`

const Outer = styled.div`
  position: relative;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  border-radius: 3px;
  background-color: #fff;
  overflow: hidden;
  & > button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
`

const Body = styled.div`
  & > img.loading {
    opacity: 0.1;
  }
`

const Controls = styled.div`
  background-color: #eee;
  padding: 1rem;
  display: flex;
  & > :first-of-type {
    flex: 1;
    padding: 0 4rem;
  }
`

const NavButtons = styled.div`
  padding: 1rem;
  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > :first-of-type {
    display: flex;
  }
`

const IMGPAD = [3*16, 16, 16, 16]

interface ImageModalProps extends Omit<ModalProps, 'children'> {
  images: Downloadable[]
  startImage: Downloadable | null
  imageSizeRatio: number
  handleClose: () => void
}

const ImageModal = ({ images, startImage, imageSizeRatio, handleClose, ...rest }: ImageModalProps) => {

  const { width, height } = useWindowDimensions()

  const [imageDims, setImageDims] = useState({ width: 0, height: 0})
  const [currentImage, setCurrentImage] = useState(startImage)
  const [imagesPerSecond, /*setImagesPerSecond*/] = useState(3)
  const [, setRefresh] = useState(false)
  const [playing, setPlaying] = useState(null as number | null)

  const next = useCallback((image: Downloadable | null) => {
    if (image !== null && image.downloaded) {
      const index = images.indexOf(image)
      return index > 0 ? images[index-1] : images[images.length-1]
    }
    return image
  }, [images])

  const prev = useCallback((image: Downloadable | null) => {
    if (image !== null && image.downloaded) {
      const index = images.indexOf(image)
      return index < images.length-1 ? images[index+1] : images[0]
    }
    return image
  }, [images])

  const handleChange = useCallback(
    (event: any, newValue: number | number[]) => {
      let index = newValue as number
      const img = images[Math.abs(index)]
      setCurrentImage(img)
    },
    [images, setCurrentImage]
  )

  const handleImageLoaded = useCallback(
    () => {
      if (currentImage !== null) {
        currentImage.downloaded = true
        // Setting a value on the current image does not force a refresh
        setRefresh(refresh => !refresh)
      }
    },
    [currentImage]
  )

  // const handleSpeedChange = useCallback(
  //   (event: any, newValue: number | number[]) => setImagesPerSecond(newValue as number),
  //   [setImagesPerSecond]
  // )

  const handleBackClick = useCallback(() => setCurrentImage(prev), [prev])

  const handleNextClick = useCallback(() => setCurrentImage(next), [next])

  const handlePlayToggleClick = useCallback(
    () => {
      if (playing !== null) {
        clearInterval(playing)
        setPlaying(null)
        return
      }
      setPlaying(
        setInterval(
          () => setCurrentImage(next),
          (1/imagesPerSecond)*1000
        ) as unknown as number
      )
    },
    [playing, next, imagesPerSecond]
  )

  useEffect(() => {
    if (currentImage === null || startImage === null) {
      setCurrentImage(startImage)
    }
  }, [currentImage, startImage])

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const controlsRef = useCallback(elem => {
    if (elem === null) {
      return
    }
    const controlsHeight = elem.getBoundingClientRect().height
    const maxHeight = height * 0.8 - controlsHeight - IMGPAD[0] - IMGPAD[2]
    let imgWidth = width * 0.8 - IMGPAD[1] - IMGPAD[3]
    let imgHeight = height * 0.8 - IMGPAD[0] - IMGPAD[2]
    if (imageSizeRatio > 1) {
      imgHeight = Math.min(imgWidth / imageSizeRatio, maxHeight)
    }
    imgWidth = imgHeight * imageSizeRatio
    setImageDims({ width: imgWidth, height: imgHeight })
  }, [height, width, imageSizeRatio])

  if (!images.length || currentImage === null) {
    return (
      <></>
    )
  }

  const currentIndex = images.indexOf(currentImage)

  return (
    <StyledModal onClose={handleClose} {...rest}>
      <Outer>
        <IconButton
          size="small"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Body style={{padding: IMGPAD.map(p => `${p}px`).join(' ')}}>
          <img
            style={{
              opacity: !currentImage || !currentImage.downloaded ? 0.1 : 1.0,
              width: `${imageDims.width}px`,
              height: `${imageDims.height}px`,
            }}
            alt={currentImage.name}
            src={currentImage.url}
            onLoad={handleImageLoaded}
          />
        </Body>
        <Controls ref={controlsRef}>
          <div>
            <Slider
              min={(images.length-1) * -1}
              max={0}
              step={1}
              value={currentIndex * -1}
              onChange={handleChange}
              marks={[
                {
                  value: 0,
                  label: images[0].date.format('YYYY MM DD'),
                },
                {
                  value: -1 * (images.length-1),
                  label: images[images.length-1].date.format('YYYY MM DD'),
                }
              ]}
            />
          </div>
          <NavButtons>
            <div>
              <IconButton
                size="small"
                disabled={playing !== null || currentIndex === images.length-1}
                onClick={handleBackClick}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={handlePlayToggleClick}
              >
                {playing ? (<PauseIcon />) : (<PlayArrowIcon />)}
              </IconButton>
              <IconButton
                size="small"
                disabled={playing !== null || currentIndex === 0}
                onClick={handleNextClick}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            {/* <div>
              <Slider
                min={1}
                max={5}
                step={1}
                value={imagesPerSecond}
                onChange={handleSpeedChange}
              />
            </div> */}
          </NavButtons>
        </Controls>
      </Outer>
    </StyledModal>
  )
}

export default ImageModal
