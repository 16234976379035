import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';

import {
  FiltersWrapper,
  FilterSectionHeader,
  MultiList,
} from '@extensions/components/search-core';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';
import { SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';

const StyledMultiList = styled(MultiList)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& h6': {
    fontSize: '0.875rem'
  }
}));

const StyledLastMultiListInSection = styled(MultiList)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  '& h6': {
    fontSize: '0.875rem'
  }
}));

const FiltersTypography = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey['400']}`,
  marginBottom: theme.spacing(3),
  '&&': {
    color: 'black',
  }
}));

export interface IProjectFiltersProps { }

export const DATASET_PARTICIPATING_ORGS_ID = 'DATASET_PARTICIPATING_ORGS';
export const DATASET_KEYWORDS_ID = 'DATASET_KEYWORDS';
export const PROJECT_DATA_ACCESS_ID = 'PROJECT_DATA_ACCESS';

export const REACTIVE_IDS = [
  DATASET_PARTICIPATING_ORGS_ID,
  DATASET_KEYWORDS_ID,
  PROJECT_DATA_ACCESS_ID,
];

export interface IProjectFiltersState { }

@observer
export class ProjectFilters extends React.Component<
  IProjectFiltersProps,
  IProjectFiltersState
> {
  getFilterReactiveIds = () => REACTIVE_IDS;

  allReactiveIdsExcept = (excludeThisOne: string): string[] => {
    return getAllReactiveIdsExcept([
      ...this.getFilterReactiveIds(),
      SEARCH_BAR_REACTIVE_ID,
    ])(excludeThisOne);
  };

  getSectionHeader = (title: string): React.ReactNode => {
    return (
      <FilterSectionHeader variant="h5" component="h3">
        {title}
      </FilterSectionHeader>
    );
  };

  getDatasetCriteriaTitle = (): React.ReactNode => {
    return this.getSectionHeader('Dataset Criteria');
  };

  getProjectCriteriaTitle = (): React.ReactNode => {
    return this.getSectionHeader('Project Criteria');
  };

  renderDatasetCriteria = (): React.ReactNode => {
    return (
      <>
        {this.getDatasetCriteriaTitle()}
        <StyledMultiList
          componentId={PROJECT_DATA_ACCESS_ID}
          title="Data Access Method"
          filterLabel="Data Access Method"
          dataField="dataset.distributionType.keyword"
          react={{
            and: this.allReactiveIdsExcept(PROJECT_DATA_ACCESS_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams
        />
        <StyledMultiList
          componentId={DATASET_KEYWORDS_ID}
          title="Keyword"
          filterLabel="Keyword"
          dataField="dataset.keyword.keyword"
          react={{
            and: this.allReactiveIdsExcept(DATASET_KEYWORDS_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams
        />
      </>
    );
  };

  renderProjectCriteria = (): React.ReactNode => {
    return (
      <>
        {this.getProjectCriteriaTitle()}
        <StyledLastMultiListInSection
          componentId={DATASET_PARTICIPATING_ORGS_ID}
          title="Participating Organizations"
          dataField="participatingOrganizations.name.keyword"
          react={{
            and: this.allReactiveIdsExcept(DATASET_PARTICIPATING_ORGS_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams
          filterLabel="Participating Organizations"
        />
      </>
    );
  };

  render() {
    return (
      <FiltersWrapper>
        <div>
          <FiltersTypography
            variant="h6"
            component="h2"
          >
            FILTERS
          </FiltersTypography>
          {this.renderProjectCriteria()}
          {this.renderDatasetCriteria()}
        </div>
      </FiltersWrapper>
    );
  }
}

export default inject((store: any) => ({}))(ProjectFilters);
