import { inject } from 'mobx-react';

import {
  MetadataGuard as DapMetadataGuard,
  GuardResult,
} from '@dapclient/components/metadata/MetadataGuard';


export class MetadataGuard extends DapMetadataGuard {
  check = (): GuardResult => {
    const { securityService } = this.props;
    if (!securityService || !securityService.userIsLoggedIn) {
      return GuardResult.NOT_AUTHENTICATED;
    }
    if (securityService
      && securityService.userIsLoggedIn
      && securityService.user
      && securityService.user.tools?.find(tool => tool.url.startsWith('/metadata'))) {
      return GuardResult.ALLOWED;
    }

    return GuardResult.DEFAULT_NOT_AUTHORIZED;
  };
}

export default inject((store: any) => ({
  securityService: store.securityService,
}))(MetadataGuard);
