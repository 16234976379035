import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, ButtonProps } from '@mui/material';
import ConfirmButton from '@extensions/components/core/ConfirmButton';

export interface ICancelBtnProps extends Omit<ButtonProps, 'classes'> {
  className?: string;
  confirm?: boolean;
}

export interface ICancelBtnState { }

@observer
export class CancelBtn extends React.Component<
  ICancelBtnProps,
  ICancelBtnState
> {
  render() {
    const { confirm, ...btnProps } = this.props;
    const sharedProps = {
      variant: 'text' as 'text',
      color: 'primary' as 'primary',
    };
    if (confirm) {
      return (
        <ConfirmButton
          {...sharedProps}
          confirmOptions={{
            confirmationText: 'Yes, Cancel',
            cancellationText: 'No',
            description: 'Any unsaved changes will be lost',
          }}
          {...btnProps}
        >
          Cancel
        </ConfirmButton>
      );
    }
    return (
      <Button {...sharedProps} {...btnProps}>
        Cancel
      </Button>
    );
  }
}

export default inject((store: any) => ({}))(CancelBtn);
