import {
  action,
  computed,
  observable,
  reaction,
  runInAction,
  makeObservable,
} from 'mobx';
import React from 'react';
import { inject, observer } from 'mobx-react';

import { Paper, styled } from '@mui/material';

import theme from '@extensions/services/Theme';
import { DocAction } from '@extensions/services/IMetadataService';
import { IMetadataService } from '@extensions/services/IMetadataService';

import MetaDocument from '@extensions/models/MetaDocument';
import MetadataDest from '@extensions/models/MetadataDest';
import Review from '@extensions/components/metadata/Review';
import PreFill from '@extensions/components/metadata/PreFill';
import EditorStep, { IStepParams } from '@extensions/models/EditorStep';
import ChooseDataset from '@extensions/components/metadata/ChooseDataset';
import ProgressTracker from '@extensions/components/metadata/ProgressTracker';
import ProvideMetadata from '@extensions/components/metadata/ProvideMetadata';

const StyledSideBarDiv = styled('div')(({
  position: 'sticky',
  top: 0,
  willChange: 'transform',
  width: '20rem',
  display: 'inline-block',
  flexShrink: 0,
}));

const StyledDividerDiv = styled('div')(({
  width: '2px',
  backgroundColor: theme.palette.grey['200'],
  alignSelf: 'stretch',
}));

const StyledContentDiv = styled('div')(({
  display: 'inline-block',
  flex: '1 1 auto',
  padding: '1rem 1.5rem',
}));

export interface IMetadataEditorProps {
  className?: string;
  metadataService?: IMetadataService;
  document: MetaDocument;
  destination?: MetadataDest;
  onCancel: () => void;
}

export interface IMetadataEditorState { }

@observer
export class MetadataEditor extends React.Component<
  IMetadataEditorProps,
  IMetadataEditorState
> {
  // Needed to prevent a flash between when metadata loads and
  // when the activeStep is initialized
  @observable
  activeStepInitialized = false;
  @observable
  activeStep = 0;

  constructor(props: IMetadataEditorProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get steps(): EditorStep[] {
    const { document } = this.props;
    const steps = [
      {
        label: 'Select Dataset',
        subLabel: document.datasetName,
        render: (params: IStepParams) => <ChooseDataset {...params} />,
      },
      {
        label: 'Import Metadata',
        optional: true,
        render: (params: IStepParams) => <PreFill {...params} />,
      },
      {
        label: 'Edit Metadata',
        render: (params: IStepParams) => <ProvideMetadata {...params} />,
      },
      {
        label: 'Review',
        render: (params: IStepParams) => <Review {...params} />,
      },
    ];
    return steps;
  }

  @computed
  get currentStep(): EditorStep {
    return this.steps[this.activeStep];
  }

  componentDidMount() {
    reaction(
      () => this.props.document.metadataLoaded,
      (loaded: boolean) => {
        if (loaded) {
          const docAction = this.props.metadataService?.selectedDocAction;
          let defaultStep = 2;
          if (docAction === DocAction.New) {
            defaultStep = 0;
          }
          runInAction(() => {
            this.activeStep = defaultStep;
            this.activeStepInitialized = true;
          });
        }
      },
      { fireImmediately: true }
    );
  }

  @action
  setActiveStep = (newStep) => (this.activeStep = newStep);

  next = (save: boolean) => {
    const { document } = this.props;
    if (save) {
      document.save({
        onSuccess: () =>
          this.setActiveStep(
            Math.min(this.activeStep + 1, this.steps.length - 1)
          ),
      });
    } else {
      this.setActiveStep(Math.min(this.activeStep + 1, this.steps.length - 1));
    }
  };

  previous = () => this.setActiveStep(Math.max(this.activeStep - 1, 0));

  render() {
    const { document, destination, onCancel } = this.props;
    if (document.metadataLoaded && this.activeStepInitialized) {
      return (
        <Paper sx={{ display: 'flex' }}>
          <StyledSideBarDiv>
            <ProgressTracker
              steps={this.steps}
              activeStep={this.activeStep}
              setActiveStep={this.setActiveStep}
            />
          </StyledSideBarDiv>
          <StyledDividerDiv />
          <StyledContentDiv>
            {this.currentStep.render({
              next: this.next,
              previous: this.previous,
              cancel: onCancel,
              destination: destination,
              document,
            })}
          </StyledContentDiv>
        </Paper>
      );
    }
    return null;
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
}))(MetadataEditor);
