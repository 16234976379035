import { Component } from 'react';
import { Helmet } from 'react-helmet';

interface IHeadManagerProps {
    title: string;
    description: string;
}

class HeadManager extends Component<IHeadManagerProps> {
    render() {
        const { title, description } = this.props;

        return (
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
        )
    }
}

export default HeadManager;