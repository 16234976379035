import React from 'react';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import OptionButton from '@extensions/components/order/OptionButton';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import { DownloadOption } from '@extensions/models/FileOrder';
import AddIcon from '@extensions/utils/AddIcon';

export interface IZipOptionProps {
  className?: string;
  order: FileOrderGroup;
}

class ZipOption extends React.Component<IZipOptionProps> {
  startZipping = (): void => {
    this.props.order.downloadZip();
  };

  render() {
    const { className, order } = this.props;
    return (
      <OptionButton
        className={clsx(className)}
        startIcon={<AddIcon icon={faFileArchive} />}
        onClick={this.startZipping}
        order={order}
        option={DownloadOption.ZIP}
      >
        Zip
      </OptionButton>
    );
  }
}

export default (ZipOption);
