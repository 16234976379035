import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Alert
} from '@mui/material';
import { inject } from 'mobx-react';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';

import Link from '@dapclient/components/core/Link';
import {
    DatasetPublications as DapDatasetPublications,
} from '@dapclient/components/dataset-search/DatasetPublications';

type TableContainerExtraProps = {
    component: React.ElementType;
};

const StyledTableContainer = styled(TableContainer, {
    shouldForwardProp: (props) => props !== 'isStacked'
})<TableContainerExtraProps & { isStacked: boolean }>(({ isStacked }) => {
    if (isStacked) {
        return {
            maxHeight: '500px',
            marginTop: '24px'
        }
    } else {
        return { maxHeight: '500px' }
    }
});

const ExternalReferencesWrapper = styled('div')<{
    hasReferences: boolean;
}>(({ hasReferences }) => ({
    marginTop: hasReferences ? theme.spacing(4) : 0
}));

const StyledAlert = styled(Alert)(() => ({
    ...theme.MuiAlert.outlinedInfo,
    marginTop: theme.spacing(3)
}));

export class DatasetPublications extends DapDatasetPublications {
    renderAdditionalReferences = (isStacked: boolean) => {
        const { tableSize, dataset } = this.props;
        const size = tableSize ? tableSize : 'medium';

        const hasReferences = dataset.references && dataset.references.length > 0;
        const hasDescription = dataset.referencesDescription && dataset.referencesDescription.length > 0;

        if (!hasReferences && !hasDescription) {
            return <StyledAlert severity="info">No Associated Reference Document Found</StyledAlert>;
        }

        return (
            <div>
                {hasReferences && (
                    <StyledTableContainer component={Paper} isStacked={isStacked}>
                        <Table size={size} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Reference Title <small>({dataset.references.length})</small>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    dataset.references
                                        .slice()
                                        .map((reference) => {
                                            return (
                                                <TableRow key={reference.referenceURL}>
                                                    <TableCell>
                                                        <Link to={reference.referenceURL}>{reference.referenceTitle}</Link>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                }
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                )}
                {hasDescription && (
                    <ExternalReferencesWrapper hasReferences={hasReferences}>
                        <StyledTableContainer component={Paper} isStacked={isStacked}>
                            <Table size={size} stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            External References
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {dataset.referencesDescription.split('\n').map((description, index, array) => (
                                                <React.Fragment key={index}>
                                                    {description}
                                                    {array.length - 1 > 1 ? <br /> : null}
                                                </React.Fragment>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                    </ExternalReferencesWrapper>
                )}
            </div>
        );
    }
}

export default inject((store: any) => ({
    securityService: store.securityService,
    notificationService: store.notificationService,
    membershipService: store.membershipService,
}))(DatasetPublications);
