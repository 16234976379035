export default class UserTool {
  url: string;
  label: string;
  external: boolean;
  fontAwesomeIconName: string;
  constructor({
    url,
    label,
    icon,
    external,
  }: {
    url: string;
    label: string;
    icon: string;
    external?: boolean;
  }) {
    this.url = url;
    this.label = label;
    this.external = external || false;
    this.fontAwesomeIconName = icon;
  }
}
